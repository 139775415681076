import React from 'react'

import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'
import { campaigns } from 'constants/app'

import Tag from 'antd/lib/tag'

import { CreditType, IHiringTeamMember, UserBalance, UsersRole } from 'types'

// TODO should update JobCampaign
export const columns = [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    ellipsis: true,
    width: 180,
    minWidth: 180,
    render: (_: unknown, user: IHiringTeamMember) => user.fullName,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    ellipsis: true,
    width: 110,
    minWidth: 110,
    render: () => <Tag color="cyan">Live</Tag>,
  },
  {
    title: 'Access Level',
    dataIndex: 'accessLevel',
    width: 160,
    minWidth: 160,
    ellipsis: true,
    render: (_: unknown, { role }: { role: UsersRole }) => (
      <AccessLevel role={role} />
    ),
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    width: '100%',
    minWidth: '100%',
    ellipsis: true,
  },
  {
    title: campaigns.advertiser,
    dataIndex: CreditType.Advertiser,
    ellipsis: true,
    width: 120,
    minWidth: 120,
    render: (_: unknown, { balance }: { balance: UserBalance }) =>
      balance.advertiser,
  },
  {
    title: campaigns.managed,
    dataIndex: CreditType.Managed,
    ellipsis: true,
    width: 120,
    minWidth: 120,
    render: (_: unknown, { balance }: { balance: UserBalance }) =>
      balance.managed,
  },
  {
    title: campaigns.hire,
    dataIndex: CreditType.Hire,
    ellipsis: true,
    width: 120,
    minWidth: 120,
    render: (_: unknown, { balance }: { balance: UserBalance }) => balance.hire,
  },
]
