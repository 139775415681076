import styled from 'styled-components/macro'

export const InfoBlock = styled.div`
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  width: 320px;
  font-size: 14px;

  h2 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: ${({ isJHAdmin }: { isJHAdmin: boolean }) =>
      isJHAdmin ? '237px' : '60px'};
  }
`
export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray7};
  margin-bottom: ${(props: { bottom?: number }) =>
    props.bottom ? `${props.bottom}px` : '0'};
  ul {
    padding-left: 20px;
    margin: 0;
  }
`
