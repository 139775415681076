import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { ROUTES } from 'routes'

const Home = () => {
  const history = useHistory()

  useEffect(() => {
    history.push(ROUTES.JOBS)
  }, [])
  return null
}

export default Home
