import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { transferCreditsPromiseCreator } from 'store/credits'
import { fetchCompanyUsersPromiseCreator } from 'store/users'
import { fetchViewerPromiseCreator, getViewerSelector } from 'store/viewer'

import { yupResolver } from '@hookform/resolvers/yup'

import { ALGOLIA_FILTER, campaignCreditsTypes } from '../constants'
import CreditsCounter from 'app/components/AssignCredits/CreditsCounter/CreditsCounter'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import snackbar from 'app/components/Snackbar/Snackbar'
import { campaigns } from 'constants/app'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { AssignCreditsSchema } from 'validation'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Select, { BaseOptionType } from 'antd/lib/select'

import { InfoCircleOutlined } from '@ant-design/icons'

import {
  ButtonWrapper,
  Column,
  FormWrapper,
  InputItem,
  RowItem,
} from './styles'

import { RootState } from 'types'

interface IFormInputs {
  Advertiser: number
  Managed: number
  Hire: number
  assignFrom: string
  assignTo: string
}

interface CompanyUser {
  key: string
  fullName: string
}

const { Option } = Select

const AssignCreditsForm = () => {
  const fetchCompanyUsers = useActionsRoutines(fetchCompanyUsersPromiseCreator)
  const transferCredits = useActionsRoutines(transferCreditsPromiseCreator)
  const fetchViewer = useActionsRoutines(fetchViewerPromiseCreator)
  const viewer = useSelector(getViewerSelector)
  const [isLoading, setLoading] = useState(false)

  const methods = useForm({
    resolver: yupResolver(AssignCreditsSchema),
    defaultValues: {
      Advertiser: 0,
      Managed: 0,
      Hire: 0,
      fromHire: 0,
      fromManaged: 0,
      fromAdvertiser: 0,
      assignFrom: '',
      assignTo: '',
    },
  })
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods

  const { Advertiser, Managed, Hire, assignFrom, assignTo } = getValues()

  const isTransferCreditsExist = !!Advertiser || !!Managed || !!Hire

  const handleFormSubmit = async ({
    Advertiser,
    Managed,
    Hire,
    assignFrom,
    assignTo,
  }: IFormInputs) => {
    try {
      setLoading(true)
      await transferCredits({
        fromUserId: assignFrom,
        toUserId: assignTo,
        advertiser: Advertiser,
        managed: Managed,
        hire: Hire,
      })
      const transferCreditTypes = [
        `${Advertiser} ${campaigns.advertiser}`,
        `${Managed} ${campaigns.managed}`,
        `${Hire} ${campaigns.hire}`,
      ]

      const existTransferCredits = transferCreditTypes.filter(
        item => !item.includes('0'),
      )

      snackbar({
        content: `${existTransferCredits.join(
          ' and ',
        )} credits assigned successfully.`,
        showIcon: true,
        type: 'info',
        icon: InfoCircleOutlined,
        closeText: 'OK',
      })

      if (viewer.id === assignFrom || viewer.id === assignTo) {
        fetchViewer()
      }
      fetchCompanyUsers({ filters: ALGOLIA_FILTER, hitsPerPage: 500 })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      reset()
    }
  }

  const clearCredits = () => {
    setValue('Advertiser', 0)
    setValue('Managed', 0)
    setValue('Hire', 0)
  }

  useEffect(() => {
    fetchCompanyUsers({ filters: ALGOLIA_FILTER, hitsPerPage: 500 })
  }, [])

  const { hits: companyUsers } = useSelector(
    (state: RootState) => state.users.fetchCompanyUsers,
  )

  const setAssignFromUserCredits = (key: string) => {
    const findUser = companyUsers.find(user => user.key === key)
    if (typeof findUser !== undefined) {
      clearCredits()
      setValue('fromAdvertiser', findUser?.balance?.advertiser!)
      setValue('fromManaged', findUser?.balance?.managed!)
      setValue('fromHire', findUser?.balance?.hire!)
    }
  }

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          onFinish={methods.handleSubmit(handleFormSubmit)}
        >
          <RowItem gutter={16}>
            <Col span={18}>
              <Form.Item
                label="Select user to assign credits from"
                validateStatus={errors.assignFrom ? 'error' : 'success'}
                help={errors.assignFrom?.message}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={key => {
                        setAssignFromUserCredits(key)
                        field.onChange(key)
                      }}
                      showSearch
                      filterOption={(
                        input: string,
                        option: BaseOptionType | undefined,
                      ) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {companyUsers.map(({ key, fullName }: CompanyUser) => (
                        <Option
                          value={key}
                          key={key}
                          disabled={key === assignTo}
                        >
                          {fullName}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name="assignFrom"
                />
              </Form.Item>
            </Col>
            {campaignCreditsTypes.map(({ title, valueFrom }) => (
              <Column span={2} key={valueFrom}>
                <FormItem
                  name={valueFrom}
                  label={title}
                  component={InputItem}
                  componentProps={{
                    disabled: true,
                    allowClear: false,
                  }}
                />
              </Column>
            ))}
          </RowItem>
          <RowItem gutter={16}>
            <Col span={18}>
              <Form.Item
                label="Who would you like to assign credits to?"
                validateStatus={errors.assignTo ? 'error' : 'success'}
                help={errors.assignTo?.message}
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={value => {
                        clearCredits()
                        setAssignFromUserCredits(assignFrom)
                        field.onChange(value)
                      }}
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {companyUsers.map(({ key, fullName }: CompanyUser) => (
                        <Option
                          value={key}
                          key={key}
                          disabled={key === assignFrom}
                        >
                          {fullName}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name="assignTo"
                />
              </Form.Item>
            </Col>
            {campaignCreditsTypes.map(({ title, value, valueFrom }: any) => (
              <Column span={2} key={value}>
                <Form.Item label={title}>
                  <Controller
                    render={({ field }) => (
                      <CreditsCounter
                        {...field}
                        name={value}
                        valueFrom={valueFrom}
                      />
                    )}
                    control={control}
                    name={value}
                  />
                </Form.Item>
              </Column>
            ))}
          </RowItem>
          <ButtonWrapper>
            <Button
              type="primary"
              onClick={methods.handleSubmit(handleFormSubmit)}
              loading={isLoading}
              disabled={!isTransferCreditsExist}
            >
              Assign selected credits
            </Button>
          </ButtonWrapper>
        </Form>
      </FormProvider>
    </FormWrapper>
  )
}

export default AssignCreditsForm
