import styled from 'styled-components/macro'

export const OptionItem = styled.div`
  display: flex;

  strong {
    margin-left: 10px;
  }
`

export const OptionItemTitle = styled.div`
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const OptionItemCandidates = styled.div`
  margin-left: auto;
  font-weight: 600;
`
