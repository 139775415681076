import * as Yup from 'yup'

import { email, password } from './baseSchema'

const signInSchema = Yup.object({
  email,
  password,
})

export default signInSchema
