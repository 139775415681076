import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { showSnackbar } from '../../../../store/app'
import { createJobPromiseCreator } from 'store/jobs'

import { useQuery } from 'helpers/useQuery'

import { yupResolver } from '@hookform/resolvers/yup'

import CreateJobForm from 'app/components/Jobs/CreateJobForm/CreateJobForm'
import CreateJobInfo from 'app/components/Jobs/CreateJobInfo/CreateJobInfo'
import snackbar from 'app/components/Snackbar/Snackbar'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import HistoryBlockModal from 'app/components/UI/HictoryBlockModal/HistoryBlockModal'
import useActionsRoutines from 'hooks/useActionsRoutines'
import pickBy from 'lodash/pickBy'
import { ROUTES } from 'routes'
import { createJobAdminSchema, createJobSchema } from 'validation'

import Button from 'antd/lib/button'
import Tabs from 'antd/lib/tabs'

import {
  Container,
  CreateJobContainer,
  Header,
  JobActions,
  Main,
  SubmitButtonBottom,
} from './styles'

import {
  CreateJobTab,
  ICreateJob,
  JobCampaign,
  RootState,
  UsersRole,
} from 'types'

const { TabPane } = Tabs

const CreateJob = () => {
  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentTab =
    (query.get('tab') as CreateJobTab) || CreateJobTab.JobDetails
  const campaignType = query.get('campaignType') || JobCampaign.Jobheron
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false)
  const [activeTab, setActiveTab] = useState(CreateJobTab.JobDetails)
  const [isBlocked, setIsBlocked] = useState(false)
  const viewer = useSelector((state: RootState) => state.viewer)
  const jobs = useSelector((state: RootState) => state.jobs)
  const createJob = useActionsRoutines(createJobPromiseCreator)
  const isAdmin = viewer.viewer.role === UsersRole.JHSuperAdmin
  const methods = useForm({
    resolver: yupResolver(isAdmin ? createJobAdminSchema : createJobSchema),
  })

  const { isDirty, isSubmitted, isValid } = methods.formState

  useEffect(() => {
    isDirty && setIsBlocked(true)
  }, [isDirty])

  useEffect(() => {
    if (isValid && isSubmitted) {
      setIsBlocked(false)
    }
    if (!isValid && isSubmitted) {
      dispatch(showSnackbar({ message: 'Fill all required fields first' }))
    }
  }, [isSubmitted])

  useEffect(() => {
    setActiveTab(currentTab)
  }, [currentTab])

  const handleFormSubmit = async (job: ICreateJob) => {
    const newJob = pickBy({ campaignType, ...job }, item => item !== '')

    createJob({ job: newJob })
      .then(({ job }) => {
        setIsBlocked(false)
        history.push(`${ROUTES.JOB}/${job.id}/preview`)
      })
      .catch(e => {
        const apiErrors = e?.errors
        if (!!apiErrors && !!apiErrors.length) {
          snackbar({
            content:
              apiErrors[0].code === 400
                ? 'Please fill in valid fields'
                : 'An error has occurred. Please try again later.',
            showIcon: true,
            type: 'error',
            closable: true,
          })
        }
      })
  }

  const handleShowTooltip = useCallback(() => {
    setIsVisibleTooltip(true)
  }, [isVisibleTooltip])

  const handleHideTooltip = useCallback(() => {
    setIsVisibleTooltip(false)
  }, [isVisibleTooltip])

  const handleChangeTab = useCallback(
    activeKey => {
      history.replace({ search: `?tab=${activeKey}` })
    },
    [activeTab],
  )

  return (
    <>
      <HistoryBlockModal
        isValid={isValid}
        isBlocked={isBlocked}
        callback={methods.handleSubmit(handleFormSubmit)}
      />
      <Container>
        <Header>
          <BackButton
            title={
              activeTab === CreateJobTab.SelectCampaign
                ? 'Create a new job'
                : 'Enter job details'
            }
          />

          {activeTab === CreateJobTab.JobDetails && (
            <JobActions>
              <Button
                type="primary"
                htmlType="submit"
                loading={jobs.createJob.isLoading}
                onClick={methods.handleSubmit(handleFormSubmit)}
              >
                Save & continue
              </Button>
            </JobActions>
          )}
        </Header>

        <Tabs onChange={handleChangeTab} centered activeKey={activeTab}>
          {/* <TabPane
            tab={
              <Tooltip
                visible={
                  isVisibleTooltip && activeTab === CreateJobTab.SelectCampaign
                }
                placement="bottomLeft"
                title="Select Campaign type first"
                overlayClassName={'crete-job-tooltip'}
              >
                <span>Select campaign</span>
              </Tooltip>
            }
            key={CreateJobTab.SelectCampaign}
          >
            <SelectCampaignType setActiveTab={setActiveTab} />
          </TabPane> */}
          <TabPane
            tab={
              <span
                onMouseEnter={handleShowTooltip}
                onMouseLeave={handleHideTooltip}
              >
                Job details
              </span>
            }
            key={CreateJobTab.JobDetails}
          >
            <CreateJobContainer>
              <Main>
                <FormProvider {...methods}>
                  <CreateJobForm errors={jobs.createJob.errors} />
                </FormProvider>

                <SubmitButtonBottom
                  type="primary"
                  htmlType="submit"
                  loading={jobs.createJob.isLoading}
                  onClick={methods.handleSubmit(handleFormSubmit)}
                >
                  Save & continue
                </SubmitButtonBottom>
              </Main>
              <CreateJobInfo />
            </CreateJobContainer>
          </TabPane>
          <TabPane
            tab={
              <span
                onMouseEnter={handleShowTooltip}
                onMouseLeave={handleHideTooltip}
              >
                Hiring Team (Optional)
              </span>
            }
            key={CreateJobTab.HiringTeam}
            disabled
          >
            External Recruiters3
          </TabPane>
        </Tabs>
      </Container>
    </>
  )
}

export default CreateJob
