import styled from 'styled-components'

import Form from 'antd/lib/form'
import Radio from 'antd/lib/radio'

export const ModalFooter = styled.div`
  text-align: left;
  margin-top: 30px;

  .ant-btn {
    margin-right: 10px;
  }
`
export const Text = styled.p`
  margin-bottom: 24px;
`
export const RadioTitle = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
`
export const RadioGroupItem = styled(Radio.Group)`
  .ant-radio-wrapper {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
`

export const FormItem = styled(Form.Item)`
  margin-bottom: 24px;
`
