import styled from 'styled-components/macro'

import { UserStatus } from 'types'

export const Container = styled.div`
  background: #ffffff;
`

export const UserItem = styled.div`
  border: 1px solid rgb(240, 240, 240);
  border-bottom: 0;
  padding: 15px 10px;
  ${({ status }: { status: UserStatus }) => {
    if (status === UserStatus.NotVerified) {
      return ` background: #FFFBF2;`
    }
  }}
  &:last-child {
    border-bottom: 1px solid rgb(240, 240, 240);
  }

  a {
    color: ${({ theme }) => theme.colors.gray9};
    text-decoration: underline;
  }
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    justify-content: center;
  }
`

export const RoleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 5px 20px;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`

export const TableContainer = styled.div`
  .ant-table-row {
    height: 57px;
  }
`
