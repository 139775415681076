import { createReducer } from '@reduxjs/toolkit'

import {
  createStage,
  deleteStage,
  fetchStages,
  reorderStage,
  updateStage,
} from './actions'
import orderBy from 'lodash/orderBy'

import { IStagesState } from 'types'

export const STAGES_STATE: IStagesState = {
  fetchStages: { isLoading: false, stages: [] },
  createStage: { isLoading: false },
  deleteStage: { isLoading: false },
  updateStage: { isLoading: false },
  reorderStage: { isLoading: false },
}

const stagesReducer = createReducer(STAGES_STATE, {
  [fetchStages.TRIGGER]: state => {
    state.fetchStages.isLoading = true
  },
  [fetchStages.SUCCESS]: (state, { payload }) => {
    state.fetchStages.isLoading = false
    state.fetchStages.stages = payload.stages
  },
  [fetchStages.FAILURE]: state => {
    state.fetchStages.isLoading = false
  },

  [createStage.TRIGGER]: state => {
    state.createStage.isLoading = true
  },
  [createStage.SUCCESS]: (state, { payload }) => {
    state.createStage.isLoading = false
    state.fetchStages.stages = [...state.fetchStages.stages, payload.stage]
  },
  [createStage.FAILURE]: state => {
    state.createStage.isLoading = false
  },

  [updateStage.TRIGGER]: state => {
    state.updateStage.isLoading = true
  },
  [updateStage.SUCCESS]: (state, { payload }) => {
    state.updateStage.isLoading = false
    const filteredStages = state.fetchStages.stages.filter(
      ({ id }) => id !== payload.stage.id,
    )
    state.fetchStages.stages = orderBy(
      [...filteredStages, payload.stage],
      'order',
    )
  },
  [updateStage.FAILURE]: state => {
    state.updateStage.isLoading = false
  },

  [reorderStage.TRIGGER]: (state, { payload }) => {
    state.fetchStages.stages = payload.reorderedStages
  },
  [reorderStage.SUCCESS]: () => {},
  [reorderStage.FAILURE]: () => {},

  [deleteStage.TRIGGER]: state => {
    state.deleteStage.isLoading = true
  },
  [deleteStage.SUCCESS]: (state, { payload }) => {
    state.deleteStage.isLoading = false
    state.fetchStages.stages = state.fetchStages.stages.filter(
      ({ id }) => id !== payload.stageId,
    )
  },
  [deleteStage.FAILURE]: state => {
    state.deleteStage.isLoading = false
  },
})

export default stagesReducer
