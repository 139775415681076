import React from 'react'
import { useSelector } from 'react-redux'

import { OnFilterProp } from '../../DetailJob'
import { FilterAttribute, ItemsProps } from './FilterList'
import Slider from 'app/components/UI/Slider/Slider'

import Spin from 'antd/lib/spin'

import {
  SpinContainer,
  StageCount,
  StageItem,
  StageItemTitle,
} from '../CandidatesStages/styles'

import { RootState } from 'types'

interface FilterSliderProp {
  attribute: FilterAttribute
  items: ItemsProps[]
  onFilter: (prop: OnFilterProp) => void
}

const FilterSlider = ({ attribute, items, onFilter }: FilterSliderProp) => {
  const { isLoading: isLoadingCountByKeyword } = useSelector(
    (state: RootState) => state.candidates.fetchCandidateCountByKeyword,
  )
  const { isLoading: isLoadingCountByFilters } = useSelector(
    (state: RootState) => state.candidates.fetchCandidateCountByFilters,
  )
  const isLoading = isLoadingCountByKeyword || isLoadingCountByFilters
  return (
    <>
      {!!items.length ? (
        <Slider>
          {items.map(({ label, value, count, isRefined }: any) => (
            <div key={label}>
              <StageItem
                count={count}
                isRefined={isRefined}
                onClick={() => onFilter({ key: attribute, value })}
              >
                <StageItemTitle>{label}</StageItemTitle>
                <StageCount>{isLoading ? <Spin /> : count}</StageCount>
              </StageItem>
            </div>
          ))}
        </Slider>
      ) : (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      )}
    </>
  )
}

export default FilterSlider
