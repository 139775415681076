import React from 'react'

import { formatDate } from 'helpers/formatDate'

import FullName from '../UserTable/FullName/FullName'
import Actions from './Actions'

import { IAlgoliaUser } from 'types'

export const externalRecruitersColumns = [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    width: 180,
    minWidth: 180,
    ellipsis: true,
    render: (_: unknown, user: IAlgoliaUser) => <FullName {...user} />,
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    width: 150,
    minWidth: 150,
    ellipsis: true,
  },
  {
    title: 'Sign Up Date',
    dataIndex: 'SignUpDate',
    width: 170,
    minWidth: 170,
    render: (_: unknown, user: IAlgoliaUser) => (
      <div>{formatDate(user.signUpDate)}</div>
    ),
  },
  {
    title: '',
    dataIndex: 'actions',
    width: 170,
    minWidth: 170,
    render: (_: unknown, user: IAlgoliaUser) => <Actions {...user} />,
  },
]
