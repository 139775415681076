import { takeLatest } from 'redux-saga/effects'

import {
  approveUser,
  changeUserRole,
  createUser,
  deleteUser,
  fetchCompanyUsers,
  fetchUser,
  fetchUsersCounter,
  fetchUsersFromAlgolia,
  moveUserToAnotherCompany,
  updateUser,
  updateUserCompany,
} from './actions'
import {
  ensureApproveUser,
  ensureChangeUserRole,
  ensureCreateUser,
  ensureDeleteUser,
  ensureFetchCompanyUsers,
  ensureFetchUser,
  ensureFetchUsersCounter,
  ensureFetchUsersFromAlgolia,
  ensureMoveUserToAnotherCompany,
  ensureUpdateUser,
  ensureUpdateUserCompany,
} from './workers'

export function* watchFetchUser() {
  yield takeLatest(fetchUser.TRIGGER, ensureFetchUser)
}

export function* watchUpdateUser() {
  yield takeLatest(updateUser.TRIGGER, ensureUpdateUser)
}

export function* watchUpdateUserCompany() {
  yield takeLatest(updateUserCompany.TRIGGER, ensureUpdateUserCompany)
}

export function* watchMoveUserToAnotherCompany() {
  yield takeLatest(
    moveUserToAnotherCompany.TRIGGER,
    ensureMoveUserToAnotherCompany,
  )
}

export function* watchApproveUser() {
  yield takeLatest(approveUser.TRIGGER, ensureApproveUser)
}

export function* watchFetchUsersCounter() {
  yield takeLatest(fetchUsersCounter.TRIGGER, ensureFetchUsersCounter)
}

export function* watchChangeUserRole() {
  yield takeLatest(changeUserRole.TRIGGER, ensureChangeUserRole)
}

export function* watchFetchUsersFromAlgolia() {
  yield takeLatest(fetchUsersFromAlgolia.TRIGGER, ensureFetchUsersFromAlgolia)
}

export function* watchCreateUser() {
  yield takeLatest(createUser.TRIGGER, ensureCreateUser)
}

export function* watchFetchCompanyUsers() {
  yield takeLatest(fetchCompanyUsers.TRIGGER, ensureFetchCompanyUsers)
}

export function* watchDeleteUser() {
  yield takeLatest(deleteUser.TRIGGER, ensureDeleteUser)
}
