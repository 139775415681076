import React, { ReactNode } from 'react'

import message from 'antd/lib/message'

import { InfoCircleOutlined } from '@ant-design/icons'

import { AlertItem, SnackBarButton } from './styles'

interface SnackbarProps {
  type: 'success' | 'info' | 'warning' | 'error'
  undoFunc?: () => void
  closable?: boolean
  showIcon?: boolean
  content: string
  duration?: number
  action?: ReactNode
  onClose?: () => void
  icon?: any
  closeText?: string
}

message.config({
  maxCount: 1,
})

const snackbar = ({
  content,
  type,
  undoFunc,
  closable = true,
  showIcon,
  action,
  duration = 5,
  onClose,
  closeText,
  icon: Icon = InfoCircleOutlined,
}: SnackbarProps) => {
  message.info(
    <AlertItem
      icon={<Icon />}
      message={content}
      type={type ? type : 'info'}
      closable={closable}
      showIcon={showIcon}
      onClose={onClose}
      closeText={closeText}
      action={
        undoFunc ? (
          <SnackBarButton
            size="small"
            type="text"
            onClick={() => {
              undoFunc()
              message.destroy()
            }}
          >
            UNDO
          </SnackBarButton>
        ) : action ? (
          <span onClick={() => message.destroy()}> {action}</span>
        ) : null
      }
    />,
    duration,
  )
}

export default snackbar
