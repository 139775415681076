import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import AddMultipleItems from 'app/components/Forms/FormMultipleItems/AddMultipleItems/AddMultipleItems'

import Form from 'antd/lib/form'

interface FormMultipleItemsProps {
  name: any
  isRequired: boolean
  label?: string
  placeholder?: string
}

const FormMultipleItems: FC<FormMultipleItemsProps> = ({
  name,
  isRequired,
  label,
  placeholder,
}) => {
  const {
    formState: { errors },
    control,
    register,
  } = useFormContext()
  return (
    <Form.Item
      validateStatus={errors[name] ? 'error' : 'success'}
      help={errors[name]?.message}
      label={label}
    >
      <Controller
        render={({ field }) => (
          <AddMultipleItems {...field} placeholder={placeholder} />
        )}
        {...register(name, { required: isRequired })}
        control={control}
        name={name}
      />
    </Form.Item>
  )
}

export default FormMultipleItems
