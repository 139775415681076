import { createAction } from '@reduxjs/toolkit'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const startHiringTeamRefreshAlgoliaCache = createAction(
  'app/startHiringTeamRefreshAlgoliaCache',
)
export const stopHiringTeamRefreshAlgoliaCache = createAction(
  'app/stopHiringTeamRefreshAlgoliaCache',
)

export const getHiringTeamCounters = createRoutine(
  'hiringTeam/getHiringTeamCounters',
)
export const getHiringTeamCountersPromiseCreator = promisifyRoutine(
  getHiringTeamCounters,
)

export const inviteCompanyMember = createRoutine(
  'hiringTeam/inviteCompanyMember',
)
export const inviteCompanyMemberPromiseCreator = promisifyRoutine(
  inviteCompanyMember,
)

export const deleteMember = createRoutine('hiringTeam/deleteMember')
export const deleteMemberPromiseCreator = promisifyRoutine(deleteMember)

export const inviteMember = createRoutine('hiringTeam/inviteMember')
export const inviteMemberPromiseCreator = promisifyRoutine(inviteMember)
