import React, { FC, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { showDeleteJobModal } from 'store/jobs'
import { getViewerSelector } from 'store/viewer'

import { checkHighRoleAccessibility } from 'helpers/jobHelpers'

import BottomRowItems from '../components/BottomRowItems/BottomRowItems'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'

import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons'

import {
  ActionBlock,
  BottomLeftInfo,
  BottomRow,
  JobCreatedAt,
  JobItem,
  MenuButtonRed,
  MenuItem,
  Title,
  TitleBlock,
  TopRow,
  TopRowRight,
} from './styles'

import { IJobStatuses } from 'types'

const JobDraft: FC<IJobStatuses> = ({
  objectID,
  title,
  status,
  company,
  isJHCampaign,
  referenceCode,
  createdAt,
  relatedCampaign,
  ownerId,
  isExpired,
  isSSCampaign,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const viewer = useSelector(getViewerSelector)
  const isHighRole = checkHighRoleAccessibility(ownerId, viewer)
  const handleDeleteJob = () => {
    dispatch(
      showDeleteJobModal({
        jobTitle: title,
        jobId: objectID,
        jobStatus: status,
      }),
    )
  }

  const menu = (
    <Menu>
      {isHighRole && (
        <MenuItem>
          <MenuButtonRed
            onClick={handleDeleteJob}
            icon={<DeleteOutlined />}
            type="text"
            block
          >
            Delete Draft
          </MenuButtonRed>
        </MenuItem>
      )}
    </Menu>
  )

  return (
    <JobItem>
      <TopRow>
        <TitleBlock>
          <Title>{title}</Title>
        </TitleBlock>
        <TopRowRight>
          <ActionBlock
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.preventDefault()
            }}
          >
            {isHighRole && (
              <>
                <Button
                  onClick={() => {
                    history.push(`${ROUTES.JOB}/${objectID}/edit`)
                  }}
                >
                  Edit job
                </Button>

                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <Button icon={<EllipsisOutlined />} />
                </Dropdown>
              </>
            )}
          </ActionBlock>
        </TopRowRight>
      </TopRow>

      <BottomRow>
        <BottomLeftInfo>
          <JobCreatedAt>
            Created
            <span>
              {formatDistanceToNowStrict(new Date(createdAt), {
                addSuffix: true,
              })}
            </span>
          </JobCreatedAt>
        </BottomLeftInfo>

        <BottomRowItems
          isJHCampaign={isJHCampaign}
          relatedCampaign={relatedCampaign}
          referenceCode={referenceCode}
          company={company}
          status={status}
          isExpired={isExpired}
          isSSCampaign={isSSCampaign}
        />
      </BottomRow>
    </JobItem>
  )
}

export default JobDraft
