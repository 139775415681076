import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const fetchStages = createRoutine('stages/fetchStages')
export const fetchStagesPromiseCreator = promisifyRoutine(fetchStages)

export const createStage = createRoutine('stages/createStage')
export const createStagePromiseCreator = promisifyRoutine(createStage)

export const updateStage = createRoutine('stages/updateStage')
export const updateStagePromiseCreator = promisifyRoutine(updateStage)

export const reorderStage = createRoutine('stages/reorderStage')
export const reorderStagePromiseCreator = promisifyRoutine(reorderStage)

export const deleteStage = createRoutine('stages/deleteStage')
export const deleteStagePromiseCreator = promisifyRoutine(deleteStage)
