import React, { MouseEvent, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { showSnackbar } from 'store/app'
import {
  clearJob,
  fetchJobPreviewPromiseCreator,
  showDeleteJobModal,
  updateJobPromiseCreator,
} from 'store/jobs'
import { isExternalRecruiterOrTeamMemberSelector } from 'store/viewer'

import { useQuery } from 'helpers/useQuery'

import { yupResolver } from '@hookform/resolvers/yup'

import DetailJob from '../../../components/Jobs/DetailJob/DetailJob'
import HiringTeam from '../../../components/Jobs/JobHiringTeam/JobHiringTeam'
import HistoryBlockModal from '../../../components/UI/HictoryBlockModal/HistoryBlockModal'
import JobActions from './JobActions/JobActions'
import JobApproved from './JobApproved/JobApproved'
import JobPublished from './JobPublished/JobPublished'
import CreateJobForm from 'app/components/Jobs/CreateJobForm/CreateJobForm'
import CreateJobInfo from 'app/components/Jobs/CreateJobInfo/CreateJobInfo'
import InviteTeamMember from 'app/components/Modals/InviteTeamMember/InviteTeamMember'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import useActionsRoutines from 'hooks/useActionsRoutines'
import pickBy from 'lodash/pickBy'
import { ROUTES } from 'routes'
import { createJobSchema } from 'validation'

import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'

import { JobActionsItem } from './JobActions/styles'
import {
  Container,
  EditJobContainer,
  Header,
  JobActionsContainer,
  Main,
  SpinContainer,
} from './styles'

import { CreateJobTab, ICreateJob, JobPage, JobStatus, RootState } from 'types'

const { TabPane } = Tabs

const PreviewJob = () => {
  const query = useQuery()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { jobId } = useParams<{ jobId: string }>()
  const defaultTabQuery = query.get('tab') || CreateJobTab.JobDetails
  const jobStatus = query.get('status')
  const [activeTab, setActiveTab] = useState(defaultTabQuery as CreateJobTab)
  const [isBlocked, setIsBlocked] = useState(false)

  const { job, isLoading } = useSelector(
    (state: RootState) => state.jobs.fetchJobPreview,
  )
  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )
  const fetchJobPreview = useActionsRoutines(fetchJobPreviewPromiseCreator)
  const updateJob = useActionsRoutines(updateJobPromiseCreator)

  const methods = useForm({
    resolver: yupResolver(createJobSchema),
  })

  const { isDirty, isSubmitted, isValid } = methods.formState

  const isEditingJob = location.pathname.endsWith('edit')
  const isShowInviteTeamMemberBtn =
    activeTab === CreateJobTab.HiringTeam && !isExternalRecruiterOrTeamMember

  useEffect(() => {
    dispatch(clearJob())
    fetchJobPreview({ jobId })
  }, [])

  useEffect(() => {
    setActiveTab(defaultTabQuery as CreateJobTab)
    setIsBlocked(false)
    methods.reset(job)
  }, [defaultTabQuery])

  useEffect(() => {
    isDirty && setIsBlocked(true)
  }, [isDirty])

  useEffect(() => {
    if (isValid && isSubmitted) {
      setIsBlocked(false)
    }
    if (!isValid && isSubmitted) {
      dispatch(showSnackbar({ message: 'Fill all required fields first' }))
    }
  }, [isSubmitted])

  const handleChangeTabs = (activeKey: string) => {
    history.replace({ search: `?tab=${activeKey}` })
  }

  const handleUpdateJob = (jobData: ICreateJob, lastLocation: any) => {
    const campaignType = job?.campaignType
    const id = job?.id

    const updatedJob = pickBy(
      { campaignType, id, ...jobData },
      item => item !== '',
    )

    updateJob({ job: updatedJob })
      .then(({ job }) => {
        setIsBlocked(false)
        methods.reset(job)
        dispatch(showSnackbar({ message: 'Job was updated' }))

        if (lastLocation && lastLocation.pathname) {
          return history.push(
            `${lastLocation.pathname}${
              lastLocation.search && lastLocation.search
            }`,
          )
        }

        if (job.status === (JobStatus.Live || JobStatus.Archived)) {
          return history.push(`${ROUTES.JOB}/${job.id}`)
        }

        history.push(`${ROUTES.JOB}/${job.id}/preview`)
      })
      .catch(() => {})
  }

  const handleDeleteJob = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(
      showDeleteJobModal({
        jobTitle: job?.title,
        jobId: job?.id.toString(),
        jobStatus: job?.status,
      }),
    )
  }

  const getJobTitle = () => {
    if (activeTab === CreateJobTab.SelectCampaign) {
      return 'Create a new job'
    }
    if (activeTab === CreateJobTab.JobDetails) {
      return isEditingJob ? 'Enter job details' : 'Review job details'
    }
    if (activeTab === CreateJobTab.HiringTeam) {
      return 'Select hiring team'
    }

    return ''
  }

  if (!job || isLoading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    )
  }

  if (jobStatus === 'published') {
    return <JobPublished jobId={jobId} />
  }

  if (jobStatus === 'approved') {
    return <JobApproved />
  }

  return (
    <Container>
      <HistoryBlockModal
        isValid={isValid}
        isBlocked={isBlocked}
        callback={methods.handleSubmit(handleUpdateJob)}
      />
      <Header>
        <BackButton title={getJobTitle()} type="jobs" />
        <JobActionsContainer>
          {isShowInviteTeamMemberBtn && (
            <JobActionsItem>
              <InviteTeamMember
                title="Invite a new Member"
                type="default"
                jobId={job.id}
                companyId={job.companyId}
              />
            </JobActionsItem>
          )}

          <JobActions
            job={job}
            isEditingJob={isEditingJob}
            activeTab={activeTab}
            handleDeleteJob={handleDeleteJob}
            handleUpdateJob={methods.handleSubmit(handleUpdateJob)}
          />
        </JobActionsContainer>
      </Header>

      <Tabs centered activeKey={activeTab} onChange={handleChangeTabs}>
        {/* <TabPane tab="Select campaign" key={CreateJobTab.SelectCampaign}>
          <SelectCampaignType setActiveTab={setActiveTab} />
        </TabPane> */}
        <TabPane tab="Jobheron Campaign" key={CreateJobTab.JobDetails}>
          {isEditingJob ? (
            <EditJobContainer>
              <Main>
                <FormProvider {...methods}>
                  <CreateJobForm job={job} />
                </FormProvider>
              </Main>
              <CreateJobInfo />
            </EditJobContainer>
          ) : (
            <DetailJob job={job} page={JobPage.PreviewJob} />
          )}
        </TabPane>
        <TabPane tab="Hiring Team (Optional)" key={CreateJobTab.HiringTeam}>
          <HiringTeam job={job} isPreviewPage />
        </TabPane>
      </Tabs>
    </Container>
  )
}

export default PreviewJob
