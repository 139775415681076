import { createReducer, current } from '@reduxjs/toolkit'

import {
  addCandidate,
  addCandidates,
  clearCandidate,
  clearCandidateNotes,
  clearCandidateTimeline,
  deleteCandidate,
  fetchCandidate,
  fetchCandidateCountByFilters,
  fetchCandidateCountByKeyword,
  fetchCandidateNotes,
  fetchCandidateTimeline,
  forwardCandidates,
  listCandidates,
  postCandidateNote,
  storeCandidates,
  updateCandidate,
  updateCandidatesStage,
  updateCandidatesStatus,
} from './actions'

import { ICandidate, ICandidateStore, IStage } from 'types'

const initialList = {
  items: [],
  first: false,
  last: false,
  pageNumber: 0,
  numberOfElements: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
}

export const initialCandidateNotes = initialList
export const initialCandidateTimeline = initialList
export const initialCandidateList = initialList
export const initialCandidateCount = {
  totalElements: 0,
  statusCount: [],
  stageCount: [],
  sourceCount: [],
}

export const CANDIDATE_STATE: ICandidateStore = {
  fetchCandidate: { isLoading: false, candidate: undefined },
  candidateList: [],
  listCandidates: { isLoading: false, data: initialCandidateList },
  fetchCandidateCountByKeyword: {
    isLoading: false,
    data: initialCandidateCount,
  },
  fetchCandidateCountByFilters: {
    isLoading: false,
    data: initialCandidateCount,
  },
  addCandidate: { isLoading: false },
  addCandidates: { isLoading: false },
  updateCandidate: { isLoading: false },
  updateCandidatesStatus: { isLoading: false },
  updateCandidatesStage: { isLoading: false },
  postCandidateNote: { isLoading: false },
  fetchCandidateTimeline: { isLoading: false },
  fetchCandidateNotes: { isLoading: false },
  candidateNotes: initialCandidateNotes,
  candidateTimeline: initialCandidateTimeline,
  deleteCandidate: { isLoading: false },
  forwardCandidates: { isLoading: false },
}

const candidatesReducer = createReducer(CANDIDATE_STATE, {
  [fetchCandidate.TRIGGER]: state => {
    state.fetchCandidate.isLoading = true
  },
  [fetchCandidate.SUCCESS]: (state, { payload }) => {
    state.fetchCandidate.isLoading = false
    state.fetchCandidate.candidate = payload.candidate
  },
  [fetchCandidate.FAILURE]: state => {
    state.fetchCandidate.isLoading = false
  },

  [listCandidates.TRIGGER]: state => {
    state.listCandidates.isLoading = true
  },
  [listCandidates.SUCCESS]: (state, { payload }) => {
    state.listCandidates.isLoading = false
    state.listCandidates.data = payload
  },
  [listCandidates.FAILURE]: state => {
    state.listCandidates.isLoading = false
  },

  [fetchCandidateCountByKeyword.TRIGGER]: state => {
    state.fetchCandidateCountByKeyword.isLoading = true
  },
  [fetchCandidateCountByKeyword.SUCCESS]: (state, { payload }) => {
    state.fetchCandidateCountByKeyword.isLoading = false
    state.fetchCandidateCountByKeyword.data = payload
  },
  [fetchCandidateCountByKeyword.FAILURE]: state => {
    state.fetchCandidateCountByKeyword.isLoading = false
  },

  [fetchCandidateCountByFilters.TRIGGER]: state => {
    state.fetchCandidateCountByFilters.isLoading = true
  },
  [fetchCandidateCountByFilters.SUCCESS]: (state, { payload }) => {
    state.fetchCandidateCountByFilters.isLoading = false
    state.fetchCandidateCountByFilters.data = payload
  },
  [fetchCandidateCountByFilters.FAILURE]: state => {
    state.fetchCandidateCountByFilters.isLoading = false
  },

  [String(storeCandidates)]: (state, { payload }) => {
    state.candidateList = payload.hits
  },

  [String(clearCandidate)]: state => {
    state.fetchCandidate.candidate = undefined
  },
  [String(clearCandidateTimeline)]: state => {
    state.candidateTimeline = initialCandidateNotes
  },
  [String(clearCandidateNotes)]: state => {
    state.candidateNotes = initialCandidateNotes
  },

  [addCandidate.TRIGGER]: state => {
    state.addCandidate.isLoading = true
  },
  [addCandidate.SUCCESS]: state => {
    state.addCandidate.isLoading = false
  },
  [addCandidate.FAILURE]: state => {
    state.addCandidate.isLoading = false
  },

  [addCandidates.TRIGGER]: state => {
    state.addCandidates.isLoading = true
  },
  [addCandidates.SUCCESS]: state => {
    state.addCandidates.isLoading = false
  },
  [addCandidates.FAILURE]: state => {
    state.addCandidates.isLoading = false
  },

  [postCandidateNote.TRIGGER]: state => {
    state.postCandidateNote.isLoading = true
  },
  [postCandidateNote.SUCCESS]: state => {
    state.postCandidateNote.isLoading = false
    state.candidateNotes.pageNumber = 0
  },
  [postCandidateNote.FAILURE]: state => {
    state.postCandidateNote.isLoading = false
  },

  [updateCandidate.TRIGGER]: state => {
    state.updateCandidate.isLoading = true
  },
  [updateCandidate.SUCCESS]: (state, { payload }) => {
    state.updateCandidate.isLoading = false
    if (state.fetchCandidate.candidate) {
      state.fetchCandidate.candidate = {
        ...state.fetchCandidate.candidate,
        ...payload.candidate,
      }
    }
  },
  [updateCandidate.FAILURE]: state => {
    state.updateCandidate.isLoading = false
  },

  [fetchCandidateTimeline.TRIGGER]: state => {
    state.fetchCandidateTimeline.isLoading = true
  },
  [fetchCandidateTimeline.FAILURE]: state => {
    state.fetchCandidateTimeline.isLoading = false
  },
  [fetchCandidateTimeline.SUCCESS]: (state, { payload }) => {
    if (payload.pageNumber === 0) {
      state.candidateTimeline = payload
    } else {
      const newState = {
        ...payload,
        items: [...state.candidateTimeline.items, ...payload.items],
      }
      state.candidateTimeline = newState
    }
    state.fetchCandidateTimeline.isLoading = false
  },

  [fetchCandidateNotes.TRIGGER]: state => {
    state.fetchCandidateNotes.isLoading = true
  },
  [fetchCandidateNotes.FAILURE]: state => {
    state.fetchCandidateNotes.isLoading = false
  },
  [fetchCandidateNotes.SUCCESS]: (state, { payload }) => {
    if (payload.pageNumber === 0) {
      state.candidateNotes = payload
    } else {
      const newState = {
        ...payload,
        items: [...state.candidateNotes.items, ...payload.items],
      }
      state.candidateNotes = newState
    }
    state.fetchCandidateNotes.isLoading = false

    if (!!state.candidateNotes.items.length && state.fetchCandidate.candidate) {
      state.fetchCandidate.candidate.noteExist = true
    }
  },

  [updateCandidatesStatus.TRIGGER]: (state, { payload }) => {
    state.updateCandidatesStatus.isLoading = true
    if (!!state.fetchCandidate.candidate) {
      state.fetchCandidate.candidate = {
        ...state.fetchCandidate.candidate,
        status: payload.status,
      } as ICandidate
    }

    const oldState = current(state)
    const newCandidateList = oldState.candidateList.map(candidate => {
      if (payload.candidatesId.includes(candidate.key)) {
        return {
          ...candidate,
          status: payload.status,
        }
      }
      return candidate
    })

    state.candidateList = newCandidateList
  },
  [updateCandidatesStatus.SUCCESS]: (state, { payload }) => {
    state.updateCandidatesStatus.isLoading = false
    if (!!state.fetchCandidate.candidate) {
      state.fetchCandidate.candidate = {
        ...state.fetchCandidate.candidate,
        status: payload.status,
      } as ICandidate
    }
  },
  [updateCandidatesStatus.FAILURE]: state => {
    state.updateCandidatesStatus.isLoading = false
  },

  [updateCandidatesStage.TRIGGER]: (state, { payload }) => {
    state.updateCandidatesStage.isLoading = true
    const newStage = payload.stages.find(
      (stage: IStage) => stage.id === payload.stageId,
    )
    if (!!state.fetchCandidate.candidate) {
      state.fetchCandidate.candidate = {
        ...state.fetchCandidate.candidate,
        stage: newStage,
      } as ICandidate
    }

    const oldState = current(state)
    const newCandidateList = oldState.candidateList.map(candidate => {
      if (payload.ids.includes(candidate.key)) {
        return {
          ...candidate,
          stage: newStage.name,
        }
      }
      return candidate
    })
    state.candidateList = newCandidateList
  },
  [updateCandidatesStage.SUCCESS]: (state, { payload }) => {
    state.updateCandidatesStage.isLoading = false
    if (!!state.fetchCandidate.candidate) {
      state.fetchCandidate.candidate = {
        ...state.fetchCandidate.candidate,
        stage: payload.stage,
      } as ICandidate
    }
  },
  [updateCandidatesStage.FAILURE]: state => {
    state.updateCandidatesStage.isLoading = false
  },

  [deleteCandidate.TRIGGER]: state => {
    state.deleteCandidate.isLoading = true
  },
  [deleteCandidate.SUCCESS]: state => {
    state.deleteCandidate.isLoading = false
  },
  [deleteCandidate.FAILURE]: state => {
    state.deleteCandidate.isLoading = false
  },

  [forwardCandidates.TRIGGER]: state => {
    state.forwardCandidates.isLoading = true
  },
  [forwardCandidates.SUCCESS]: state => {
    state.forwardCandidates.isLoading = false
  },
  [forwardCandidates.FAILURE]: state => {
    state.forwardCandidates.isLoading = false
  },
})

export default candidatesReducer
