import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .ant-tabs-nav-list {
    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.gray6};
    }
  }

  .ant-tabs-nav {
    &:before {
      border-bottom: 0;
    }
  }

  .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.gray7};
  }

  .ant-tabs {
    width: 100%;
  }
  .ant-table-wrapper {
    margin-top: 20px;
  }
`

export const HiringTeamTab = styled.span`
  display: flex;
  align-items: center;
`
