import * as React from 'react'

function Tables(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={161}
      height={145}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.369 120.569c32.093 0 58.111-26.095 58.111-58.285S113.462 4 81.369 4C49.275 4 23.258 30.095 23.258 62.284c0 32.19 26.017 58.285 58.11 58.285z"
        fill="#F3F6FF"
      />
      <path
        d="M25.323 28.143a2.934 2.934 0 001.877-3.695 2.921 2.921 0 00-3.684-1.881 2.933 2.933 0 00-1.876 3.694 2.921 2.921 0 003.683 1.882zM31.703 18.577a1.995 1.995 0 001.276-2.512 1.986 1.986 0 00-2.505-1.28 1.995 1.995 0 00-1.276 2.512 1.986 1.986 0 002.505 1.28zM139.985 30.004a4.466 4.466 0 00.283-6.296 4.434 4.434 0 00-6.277-.284 4.466 4.466 0 00-.283 6.296 4.434 4.434 0 006.277.284zM136.954 93.645a1.88 1.88 0 00.119-2.651 1.867 1.867 0 00-2.643-.12 1.88 1.88 0 00-.119 2.652c.697.765 1.88.818 2.643.119z"
        fill="#EAEEF9"
      />
      <path
        d="M28.526 103.305a2.342 2.342 0 002.338-2.345 2.342 2.342 0 00-2.338-2.345 2.342 2.342 0 00-2.338 2.345 2.342 2.342 0 002.338 2.345z"
        fill="url(#prefix__paint0_linear_4689:125027)"
      />
      <path
        d="M126.382 10.333c1.034 0 1.871-.84 1.871-1.877a1.873 1.873 0 00-1.871-1.876c-1.033 0-1.87.84-1.87 1.876 0 1.037.837 1.877 1.87 1.877z"
        fill="#FFC069"
      />
      <g filter="url(#prefix__filter0_d_4689:125027)">
        <path
          d="M138.895 54.662l-19.527 51.482c-.701 1.759-2.689 2.58-4.326 1.877L65.35 88.436c-1.754-.703-2.572-2.697-1.87-4.339L89.32 18.19c.7-1.759 2.688-2.58 4.325-1.876l36.013 14.19 9.237 24.158z"
          fill="#fff"
        />
      </g>
      <path
        d="M116.446 86.677L86.631 74.95c-.468-.117-.585-.587-.468-1.056.117-.469.585-.586 1.053-.469l29.815 11.728c.468.117.585.586.468 1.055-.234.352-.702.586-1.053.47zM82.889 73.425l-5.262-2.11c-.468-.118-.585-.587-.468-1.056.117-.47.585-.586 1.053-.47l5.261 2.112c.468.117.585.586.468 1.055-.117.47-.585.587-1.052.47zM119.486 78.585l-13.33-5.277c-.467-.117-.584-.586-.467-1.056.117-.469.584-.586 1.052-.469l13.329 5.278c.468.117.585.586.468 1.055-.117.47-.585.587-1.052.47zM100.544 71.08l-19.41-7.623c-.467-.117-.584-.586-.467-1.055.117-.47.585-.587 1.052-.47l19.41 7.623c.467.118.584.587.467 1.056-.116.469-.584.586-1.052.469zM122.877 70.61l-5.144-1.993c-.468-.117-.585-.586-.468-1.055.117-.47.584-.587 1.052-.47l5.145 1.994c.467.118.584.587.467 1.056-.116.469-.584.586-1.052.469zM112.938 66.624L84.525 55.365c-.467-.117-.584-.586-.467-1.055.117-.47.584-.586 1.052-.47L113.522 65.1c.468.117.585.586.468 1.055-.117.47-.584.704-1.052.47zM90.373 86.56l-1.754-.704c-.468-.117-.584-.586-.468-1.055.118-.47.585-.587 1.053-.47l1.754.704c.467.118.584.587.467 1.056-.233.469-.701.586-1.052.469zM85.346 84.566l-10.524-4.222c-.467-.117-.584-.586-.467-1.055.117-.47.584-.587 1.052-.47l10.523 4.223c.468.117.585.586.468 1.055-.117.47-.702.586-1.052.47zM126.384 62.636l-18.357-7.27c-.467-.118-.584-.587-.467-1.056.117-.47.584-.586 1.052-.47l18.357 7.272c.468.117.585.586.468 1.055-.117.47-.702.587-1.053.47zM102.064 53.02l-14.148-5.63c-.468-.117-.584-.586-.468-1.055.117-.469.585-.586 1.053-.469l14.147 5.747c.468.117.585.586.468 1.055-.117.47-.584.586-1.052.352z"
        fill="#E3EAF2"
      />
      <path
        d="M119.251 38.83l-23.852-9.382c-.585-.234-.819-.82-.585-1.407.234-.587.819-.821 1.403-.587l23.853 9.382c.584.235.818.821.584 1.408-.234.586-.818.82-1.403.586zM102.298 38.713l-9.12-3.636c-.584-.234-.818-.82-.584-1.407.233-.587.818-.821 1.403-.587l9.12 3.636c.584.234.818.82.584 1.407-.234.587-.935.821-1.403.587z"
        fill="#CED7E2"
      />
      <path
        d="M129.659 30.503l-5.495 14.073c-.819 1.994.35 4.34 2.221 5.043l12.511 4.925"
        fill="#E6EDF5"
      />
      <g filter="url(#prefix__filter1_d_4689:125027)">
        <path
          d="M83.708 32.497l22.8 50.076c.702 1.759 0 3.752-1.754 4.456L55.88 108.607c-1.754.704-3.741 0-4.443-1.759L23.025 42.114c-.702-1.76 0-3.753 1.754-4.457l35.427-15.714 23.502 10.554z"
          fill="#fff"
        />
      </g>
      <path
        d="M90.606 70.963L61.258 83.98c-.351.234-.819 0-1.053-.352-.233-.352 0-.821.351-1.055l29.348-13.018c.35-.234.818 0 1.052.352.234.352 0 .938-.35 1.056zM57.635 85.504l-5.145 2.229c-.35.234-.818 0-1.052-.352-.234-.352 0-.821.35-1.056l5.145-2.228c.351-.234.819 0 1.053.352.233.469.117.938-.351 1.055zM86.982 63.105l-13.095 5.747c-.351.234-.819 0-1.053-.352-.234-.352 0-.821.351-1.056l13.096-5.746c.35-.235.818 0 1.052.352.234.352.117.82-.35 1.055zM68.39 71.314l-19.058 8.444c-.35.235-.819 0-1.052-.352-.234-.352 0-.82.35-1.055l19.059-8.444c.35-.234.818 0 1.052.352.234.469.117.938-.35 1.055zM83.825 55.014l-5.028 2.228c-.35.234-.819 0-1.052-.352-.234-.352 0-.821.35-1.056l5.028-2.228c.35-.234.819 0 1.052.352.234.47 0 .938-.35 1.056zM74.003 59.352L46.06 71.784c-.351.235-.819 0-1.053-.351-.234-.352 0-.821.351-1.056l28.062-12.43c.35-.235.818 0 1.052.351.117.47 0 .938-.468 1.056zM72.25 89.492l-1.754.82c-.35.235-.818 0-1.052-.351-.234-.352 0-.821.35-1.056l1.754-.82c.351-.235.819 0 1.053.351.234.352 0 .821-.351 1.056zM67.222 91.72l-10.406 4.573c-.35.235-.818 0-1.052-.352-.234-.351 0-.82.35-1.055l10.407-4.574c.35-.234.819 0 1.052.352.234.352.117.821-.35 1.056zM80.551 47.039l-18.006 7.974c-.35.235-.819 0-1.052-.351-.234-.352 0-.821.35-1.056l18.007-7.974c.35-.235.818 0 1.052.352.234.351 0 .82-.35 1.055zM56.699 57.594l-13.914 6.098c-.35.234-.818 0-1.052-.352-.234-.352 0-.821.35-1.056l13.914-6.098c.351-.234.819 0 1.053.352.234.352.117.821-.351 1.056z"
        fill="#E3EAF2"
      />
      <path
        d="M58.688 35.312l-23.502 10.32c-.585.234-1.17 0-1.403-.587-.234-.586 0-1.172.585-1.407l23.384-10.32c.585-.234 1.17 0 1.403.587.234.586 0 1.172-.467 1.407zM46.642 47.39l-9.003 3.988c-.584.235-1.17 0-1.403-.586-.234-.587 0-1.173.585-1.408l9.003-3.987c.584-.234 1.169 0 1.403.587.234.469 0 1.172-.585 1.407z"
        fill="#CED7E2"
      />
      <path
        d="M60.205 22.06l6.08 13.838c.819 1.876 3.274 2.814 5.145 1.993l12.277-5.394"
        fill="#E6EDF5"
      />
      <g filter="url(#prefix__filter2_d_4689:125027)">
        <path
          d="M119.602 36.367l.818 70.364c0 2.345-1.871 4.339-4.326 4.339H47.928a4.323 4.323 0 01-4.326-4.339V16.314c0-2.346 1.87-4.34 4.326-4.34h49.458l22.216 24.393z"
          fill="#fff"
        />
      </g>
      <path
        d="M76.808 96.997h-2.455a1.05 1.05 0 01-1.052-1.055 1.05 1.05 0 011.052-1.056h2.456c.584 0 1.052.47 1.052 1.056a1.05 1.05 0 01-1.053 1.055zM69.91 96.997H55.412a1.05 1.05 0 01-1.053-1.055 1.05 1.05 0 011.053-1.056H69.91c.585 0 1.053.47 1.053 1.056a1.05 1.05 0 01-1.053 1.055z"
        fill="#E3EAF2"
      />
      <path
        d="M88.85 26.75H56.113c-.702 0-1.403-.585-1.403-1.406 0-.704.585-1.408 1.403-1.408h32.739c.701 0 1.403.587 1.403 1.408-.117.82-.702 1.407-1.403 1.407zM68.623 34.608h-12.51c-.703 0-1.404-.586-1.404-1.407 0-.704.585-1.407 1.403-1.407h12.51c.702 0 1.404.586 1.404 1.407-.117.82-.702 1.407-1.403 1.407z"
        fill="#CED7E2"
      />
      <path
        d="M97.387 12.091v19.35c0 2.698 2.338 4.926 5.027 4.926h17.188"
        fill="#E6EDF5"
      />
      <path
        d="M108.845 49.384H91.774h-.117-17.655-.117-16.603c-1.871 0-3.274 1.525-3.274 3.401v29.788c0 1.876 1.52 3.4 3.274 3.4h51.446c1.871 0 3.274-1.524 3.274-3.4V52.785c.117-1.876-1.403-3.4-3.157-3.4zM74.937 75.771v-6.685h15.785v6.685H74.937zm15.785 1.993v6.216H74.937v-6.216h15.785zm0-17.473v6.684H74.937v-6.684h15.785zm-15.785-1.994v-6.919h15.785v6.92H74.937zm35.194 8.678H92.709v-6.684h17.422v6.684zm-37.182 0H55.996v-6.684h16.836v6.684h.117zm-16.953 2.111h16.836v6.685H55.996v-6.685zm36.713 0h17.422v6.685H92.709v-6.685zm17.422-16.3v5.511H92.709v-6.919h16.136c.701 0 1.286.586 1.286 1.407zm-52.85-1.408h15.551v6.92H55.996v-5.513c0-.82.584-1.407 1.286-1.407zm-1.285 31.195v-4.809h16.836v6.216h-15.55c-.702 0-1.286-.587-1.286-1.407zm52.849 1.407H92.709v-6.216h17.422v4.809c0 .82-.585 1.407-1.286 1.407z"
        fill="#E3EAF2"
      />
      <defs>
        <filter
          id="prefix__filter0_d_4689:125027"
          x={41.228}
          y={5.062}
          width={119.666}
          height={136.21}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4689:125027"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4689:125027"
            result="shape"
          />
        </filter>
        <filter
          id="prefix__filter1_d_4689:125027"
          x={0.756}
          y={10.943}
          width={128.021}
          height={130.934}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4689:125027"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4689:125027"
            result="shape"
          />
        </filter>
        <filter
          id="prefix__filter2_d_4689:125027"
          x={21.602}
          y={0.974}
          width={120.818}
          height={143.095}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4689:125027"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4689:125027"
            result="shape"
          />
        </filter>
        <linearGradient
          id="prefix__paint0_linear_4689:125027"
          x1={26.188}
          y1={100.968}
          x2={30.734}
          y2={100.968}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83A6FF" />
          <stop offset={1} stopColor="#5A78FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Tables
