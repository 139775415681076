import React from 'react'

import {
  CheckCircleOutlined,
  HighlightOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  SearchOutlined,
  UserAddOutlined,
} from '@ant-design/icons'

import { StageType } from '../types'

export const renderStageIcon = (type: StageType) => {
  switch (type) {
    case StageType.Sourced:
      return <SearchOutlined />
    case StageType.Applied:
      return <UserAddOutlined />
    case StageType.PhoneScreen:
      return <PhoneOutlined />
    case StageType.Interview:
      return <MessageOutlined />
    case StageType.Assessment:
      return <OrderedListOutlined />
    case StageType.Offer:
      return <HighlightOutlined />
    case StageType.Hired:
      return <CheckCircleOutlined />
    default:
      return null
  }
}
