import React, { FC, ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import logo from 'assets/images/logo.svg'

import { Block, Body, Header } from './styles'

interface IAuthContainer {
  title?: string
  children: ReactNode
}

const AuthContainer: FC<IAuthContainer> = ({ title = 'Sign Up', children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header>
        <Block>
          <img src={logo} alt="JobHeron" />
        </Block>
      </Header>

      <Body>
        <Block>{children}</Block>
      </Body>
    </>
  )
}

export default AuthContainer
