import { call, put, select } from 'redux-saga/effects'

import { ensureRefreshAlgoliaCache } from 'store/app/workers'
import { ensureViewer } from 'store/viewer/workers'

import { signUp } from '../viewer'
import { getViewerSelector } from './../viewer/selectors'
import {
  approveUser,
  changeUserRole,
  createUser,
  deleteUser,
  fetchCompanyUsers,
  fetchUser,
  fetchUsersCounter,
  fetchUsersFromAlgolia,
  moveUserToAnotherCompany,
  startRefreshUserAlgoliaCache,
  stopRefreshUserAlgoliaCache,
  updateUser,
  updateUserCompany,
} from './actions'
import algolia from 'services/algolia'
import Api from 'services/api'

import {
  ApiDeleteUser,
  IApproveUser,
  ICreateUser,
  IUpdateUserCompany,
  MoveUserToAnotherCompany,
  UserStatus,
  UsersRole,
} from 'types'

export function* ensureRefreshUserAlgoliaCache() {
  yield put(startRefreshUserAlgoliaCache())
  yield put(stopRefreshUserAlgoliaCache())
}

export function* ensureFetchUser({
  payload,
}: {
  type: typeof fetchUser.TRIGGER
  payload: any
}) {
  try {
    const { userId } = payload
    const user = yield call(Api.users.getUser, { userId })
    yield put(fetchUser.success({ user: user.data }))
  } catch (err) {
    console.log(err)
    yield put(fetchUser.failure())
  }
}

export function* ensureUpdateUser({
  payload,
}: {
  type: typeof updateUser.TRIGGER
  payload: any
}) {
  try {
    const { id } = payload
    const user = yield call(Api.users.updateUser, payload)
    const viewer = yield select(getViewerSelector)
    if (id === viewer.id) {
      yield call(ensureViewer)
    }

    yield put(updateUser.success({ user: user.data }))
  } catch (err) {
    console.log(err)
    const { errors } = err.response.data.errors
    yield put(updateUser.failure(errors))
  }
}

export function* ensureUpdateUserCompany({
  payload,
}: {
  type: typeof updateUserCompany.TRIGGER
  payload: IUpdateUserCompany
}) {
  try {
    const userCompany = yield call(Api.users.updateUserCompany, payload)
    yield put(updateUserCompany.success({ userCompany: userCompany.data }))
  } catch (err) {
    console.log(err)
    const { errors } = err.response.data.errors
    yield put(updateUserCompany.failure(errors))
  }
}

export function* ensureMoveUserToAnotherCompany({
  payload,
}: {
  type: typeof moveUserToAnotherCompany.TRIGGER
  payload: MoveUserToAnotherCompany
}) {
  try {
    const user = yield call(Api.users.moveUserToAnotherCompany, payload)
    yield put(moveUserToAnotherCompany.success({ user: user.data }))
  } catch (err) {
    console.log(err)
    const { errors } = err.response.data.errors
    yield put(moveUserToAnotherCompany.failure(errors))
  }
}

export function* ensureApproveUser({
  payload,
}: {
  type: typeof signUp.TRIGGER
  payload: IApproveUser
}) {
  const { userId, role, companyId } = payload
  try {
    const user = yield call(Api.users.approveUser, { userId, role, companyId })
    yield put(approveUser.success({ user: user.data }))
    yield call(ensureRefreshUserAlgoliaCache)
  } catch (err) {
    console.log(err)
    yield put(approveUser.failure())
  }
}

export function* ensureFetchUsersCounter() {
  try {
    const users = yield algolia.getUsersFacets()
    yield put(fetchUsersCounter.success({ counters: users.facets.status }))
  } catch (error) {
    yield put(fetchUsersCounter.failure())
  }
}

export function* ensureCreateUser({
  payload,
}: {
  type: typeof createUser.TRIGGER
  payload: ICreateUser
}) {
  try {
    yield call(Api.users.createUser, payload)
    yield put(createUser.success())
  } catch (err) {
    const { errors } = err.response.data.errors
    yield put(createUser.failure(errors))
  }
}

export function* ensureChangeUserRole({
  payload,
}: {
  type: typeof changeUserRole.TRIGGER
  payload: {
    userId: string
    role: UsersRole
  }
}) {
  const { userId, role } = payload
  try {
    const user = yield call(Api.users.changeUserRole, { userId, role })
    yield put(changeUserRole.success({ user: user.data }))
    yield call(ensureRefreshUserAlgoliaCache)
  } catch (err) {
    console.log(err)
    yield put(changeUserRole.failure())
  }
}

export function* ensureFetchUsersFromAlgolia() {
  try {
    const users = yield algolia.getUsers({
      query: '',
      options: { filters: `status: ${UserStatus.NotVerified}` },
    })
    yield put(fetchUsersFromAlgolia.success(users))
    return users
  } catch (error) {
    yield put(fetchUsersFromAlgolia.failure())
  }
}

export function* ensureFetchCompanyUsers({
  payload,
}: {
  type: typeof fetchCompanyUsers.TRIGGER
  payload: {
    filters: string
    hitsPerPage: number
  }
}) {
  try {
    const { filters, hitsPerPage } = payload
    const users = yield algolia.getCompanyUsers({
      filters,
      hitsPerPage,
    })
    yield put(fetchCompanyUsers.success(users.hits))
    yield call(ensureRefreshAlgoliaCache)
  } catch (error) {
    yield put(fetchCompanyUsers.failure())
  }
}

export function* ensureDeleteUser({
  payload,
}: {
  type: typeof deleteUser.TRIGGER
  payload: ApiDeleteUser
}) {
  try {
    yield call(Api.users.deleteUser, payload)
    yield put(deleteUser.success())
    yield call(ensureRefreshUserAlgoliaCache)
  } catch (err) {
    const { errors } = err.response.data.errors
    yield put(deleteUser.failure({ errors }))
  }
}
