import { call, put } from 'redux-saga/effects'

import algolia from '../../services/algolia'
import { ensureRefreshAlgoliaCache } from '../app/workers'
import { approveCompany, fetchCompanies } from './actions'
import Api from 'services/api'

export function* ensureApproveCompany({
  payload,
}: {
  type: typeof approveCompany.TRIGGER
  payload: { id: number }
}) {
  const { id } = payload
  try {
    yield call(Api.companies.approveCompany, { id })

    yield put(approveCompany.success())
    yield call(ensureRefreshAlgoliaCache)
  } catch (err) {
    console.log(err)
    yield put(approveCompany.failure())
  }
}

export function* ensureFetchCompanies({
  payload,
}: {
  type: typeof approveCompany.TRIGGER
  payload: any
}) {
  try {
    const { query, options } = payload
    const companies = yield algolia.getCompaniesToApprove({ query, options })
    yield put(fetchCompanies.success({ companies }))
  } catch (err) {
    console.log(err)
    yield put(fetchCompanies.failure())
  }
}
