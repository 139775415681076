import styled from 'styled-components/macro'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 ${({ theme }) => theme.space.medium} 0;

  @media (min-width: 1024px) {
    font-size: 38px;
  }
`

export const Description = styled.p`
  font-size: 14px;
  margin: 0 0 ${({ theme }) => theme.space.medium} 0;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`

export const FormBlock = styled.div`
  width: 100%;
  max-width: 520px;
  margin: auto;
`

export const FullWidthButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  img {
    margin-right: 10px;
  }
`

export const NextButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`

export const BottomText = styled.div`
  display: flex;
  justify-content: center;
  p {
    color: ${({ theme }) => theme.colors.gray7};
  }

  a {
    padding-left: 5px;
  }
`

export const SignDivider = styled(Divider)`
  &.ant-divider {
    margin: 24px 0;
  }

  .ant-divider-inner-text {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.gray7};
    font-size: 10px;
  }
`

export const SocialContainer = styled.div`
  .ant-btn {
    margin-bottom: 24px;
  }
`

export const AlmostDone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 200px);
  text-align: center;

  img {
    max-width: 140px;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
  }
`
