import React from 'react'
import { Link } from 'react-router-dom'

import mailImg from '../assets/mail.svg'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Space from 'antd/lib/space'

import { Confirmation } from '../styles'

const JobApproved = () => {
  return (
    <Confirmation>
      <img
        src={mailImg}
        alt="Your advert is on it’s way to our editorial team."
      />
      <h2>
        Your advert is on it’s way <br /> to our editorial team.
      </h2>
      <p>
        Keep an eye on your inbox for an email from us. We’ll let you know once
        we have reviewed your advert. <br /> You can check your job's status
        anytime in your pending jobs.
      </p>

      <Space>
        <Button type="primary">
          <Link to={ROUTES.JOBS}>View jobs</Link>
        </Button>
      </Space>
    </Confirmation>
  )
}

export default JobApproved
