import styled from 'styled-components/macro'

import Dropdown from 'antd/lib/dropdown'
import Space from 'antd/lib/space'

export const ModalBody = styled.div`
  .ant-form-item-has-success {
    margin-bottom: 0;
  }
`

export const ActionButtons = styled(Space)`
  margin-top: 20px;
`

export const DraggerContainer = styled.div`
  .ant-upload-list {
    max-height: 300px;
    overflow: auto;
  }

  .ant-upload.ant-upload-drag {
    background: #fafafa;
    border: 2px dashed #d9d9d9;
    padding: 20px 14px;
  }
  .anticon-cloud-upload {
    font-size: 35px;
    color: #2688fc;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0 8px;
    span {
      color: #2688fc;
      text-decoration: underline;
    }
  }

  p {
    color: #8c8c8c;
    font-size: 14px;
  }
`

export const SourcedContainer = styled.div`
  margin-top: 20px;
`

export const DropdownWrapper = styled(Dropdown.Button)``

export const FileItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  svg {
    color: #8c8c8c;
    margin-right: 10px;
  }
`
