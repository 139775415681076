import styled from 'styled-components/macro'

import Modal from 'antd/lib/modal'

export const ModalFooter = styled.div`
  text-align: left;
  margin-top: 32px;
  display: flex;
  gap: 10px;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0;
  svg {
    margin-right: 9px;
  }
`

export const ModalItem = styled(Modal)`
  ul {
    margin: 0 0 0 20px;
    padding: 0;
  }
  li {
    font-size: 14px;
    line-height: 22px;
    list-style-type: disc;
  }
`
