import React from 'react'
import {
  Configure,
  Index,
  InstantSearch,
  connectHits,
} from 'react-instantsearch-dom'

import CompanyUsersTable from './CompanyUsersTable'
import { companyTableColumns } from './columns'

import Table from 'antd/lib/table'

import { IAlgoliaUser } from 'types'

interface ICompaniesToVerifyTable {
  hits: any
  searchClient: any
  indexName: string
  refreshAlgoliaCache: boolean
  isLoading: boolean
}

const CompaniesToVerifyTable = ({
  hits,
  searchClient,
  indexName,
  refreshAlgoliaCache,
  isLoading,
}: ICompaniesToVerifyTable) => {
  const expandedRowRender = (e: IAlgoliaUser) => {
    return (
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh={refreshAlgoliaCache}
      >
        <Index indexName={indexName}>
          <Configure hitsPerPage={100} filters={`companyId: ${e.companyId}`} />
          <CompanyUsersTable />
        </Index>
      </InstantSearch>
    )
  }

  return (
    <Table
      loading={isLoading}
      columns={companyTableColumns}
      expandable={{ expandedRowRender }}
      dataSource={hits}
      scroll={{ x: 1024 }}
      pagination={false}
    />
  )
}

export default connectHits(CompaniesToVerifyTable)
