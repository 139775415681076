export interface IAppState {
  modals: {
    isShowInviteTeamMemberModal: boolean
  }
  refreshAlgoliaCache: boolean
  lastActivePage: {
    jobs: string
    candidates: string
  }
  appSnackbar: {
    isOpen: boolean
    message: string
  }
}

export enum OptionsType {
  Companies = 'Companies',
  UsersGroupByCompanies = 'GroupByCompanies',
  Users = 'Users',
  UsersWithRole = 'UsersWithRole',
}
