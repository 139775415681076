import React, { FC, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getViewerSelector, isAdminOrCompanyAdminSelector } from 'store/viewer'

import { checkHighRoleAccessibility } from 'helpers/jobHelpers'

import ApprovePublishJob from './ApprovePublishJob'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { JobActionsItem } from './styles'

import { CreateJobTab, IJob, JobStatus, RootState } from 'types'

interface IJobActions {
  job: IJob
  isEditingJob: boolean
  activeTab: CreateJobTab
  handleUpdateJob: () => void
  handleDeleteJob: (e: MouseEvent<HTMLButtonElement>) => void
}

const JobActions: FC<IJobActions> = ({
  job,
  isEditingJob,
  activeTab,
  handleUpdateJob,
  handleDeleteJob,
}) => {
  const viewer = useSelector(getViewerSelector)
  const isAdminOrCompanyAdmin = useSelector(isAdminOrCompanyAdminSelector)
  const isHighRole = checkHighRoleAccessibility(job.user.id, viewer)
  const { isLoading } = useSelector((state: RootState) => state.jobs.updateJob)

  const isCanEditRole = !(
    job.status === JobStatus.NoCredits && !isAdminOrCompanyAdmin
  )

  if (!isHighRole) {
    return null
  }

  return (
    <>
      {isEditingJob && activeTab === CreateJobTab.JobDetails && (
        <JobActionsItem>
          <Button type="primary" onClick={handleUpdateJob} loading={isLoading}>
            Save & continue
          </Button>
        </JobActionsItem>
      )}

      {!isEditingJob && activeTab === CreateJobTab.JobDetails && isHighRole && (
        <JobActionsItem>
          <Button onClick={handleDeleteJob}>Delete draft</Button>
          {isCanEditRole && (
            <Button>
              <Link to={`${ROUTES.JOB}/${job.id}/edit`}>Edit job</Link>
            </Button>
          )}
        </JobActionsItem>
      )}

      <ApprovePublishJob
        job={job}
        activeTab={activeTab}
        isEditingJob={isEditingJob}
      />
    </>
  )
}

export default JobActions
