import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearCandidateTimeline,
  fetchCandidateTimelinePromiseCreator,
} from 'store/candidates'

import { capitalizeFirstLetter } from 'helpers/candidateHelpers'
import { generateAvatarName } from 'helpers/generateAvatarName'

import TimelineTag from '../TimelineTag/TimelineTag'
import RecruiterIcon from './assets/RecruiterIcon'
import Loader from 'app/components/UI/Loader/Loader'
import Logo from 'assets/icons/Logo'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import useActionsRoutines from 'hooks/useActionsRoutines'

import {
  AgencyName,
  CommentInfo,
  CommentTime,
  CommentWrapper,
  Container,
  Content,
  StageTag,
  UserAvatar,
  UserName,
  UsernameWrapper,
} from './styles'

import { RootState, TimelineType } from 'types'

const CandidateTimeline = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const fetchTimeline = useActionsRoutines(fetchCandidateTimelinePromiseCreator)

  const { candidateTimeline } = useSelector(
    (state: RootState) => state.candidates,
  )

  const {
    items: timeline,
    totalPages,
    pageNumber: pageNumberFromState,
  } = candidateTimeline

  useEffect(() => {
    fetchTimeline({ pageNumber: 0 }).finally(() => setIsLoading(false))
    return () => {
      dispatch(clearCandidateTimeline())
    }
  }, [])

  if (isLoading) {
    return <Loader />
  }

  const hasMorePages = totalPages !== pageNumberFromState + 1

  const commentTitle = (stage: TimelineType, oldValue?: string) => {
    switch (stage) {
      case TimelineType.Stage:
        return 'Stage updated'
      case TimelineType.Status:
        return 'Status updated'
      case TimelineType.Uploaded:
        return oldValue
          ? capitalizeFirstLetter(oldValue)
          : capitalizeFirstLetter(stage)
    }
  }

  return (
    <Container>
      {!!timeline.length && (
        <InfiniteScroll
          dataLength={timeline.length}
          next={() => fetchTimeline({ pageNumber: pageNumberFromState + 1 })}
          hasMore={hasMorePages}
          loader={<Loader />}
        >
          {timeline.map(({ user, id, type, createdAt, newValue, oldValue }) => {
            return (
              <CommentWrapper
                key={id}
                author={
                  <CommentInfo>{commentTitle(type, oldValue)}</CommentInfo>
                }
                avatar={
                  <UserAvatar>
                    {user ? (
                      <>
                        {generateAvatarName({
                          fullName: user.fullName,
                        })}
                      </>
                    ) : (
                      <Logo color="#2688FC" width={16} height={16} />
                    )}
                  </UserAvatar>
                }
                content={
                  <Content>
                    {user && (
                      <UsernameWrapper>
                        {user.isExternalRecruiter && <RecruiterIcon />}

                        <UserName>{user.fullName}</UserName>
                        {user.isExternalRecruiter && (
                          <AgencyName>({user.company})</AgencyName>
                        )}
                      </UsernameWrapper>
                    )}

                    {type === TimelineType.Stage && (
                      <>
                        moved stage from <StageTag>{oldValue}</StageTag> to{' '}
                        <StageTag>{newValue}</StageTag>
                      </>
                    )}

                    {type === TimelineType.Status && (
                      <>
                        changed status <TimelineTag tagStatus={oldValue} /> to
                        <TimelineTag tagStatus={newValue} />
                      </>
                    )}

                    {type === TimelineType.Uploaded && oldValue}
                  </Content>
                }
                datetime={
                  <CommentTime>
                    {formatDistanceToNowStrict(new Date(createdAt), {
                      addSuffix: true,
                    })}
                  </CommentTime>
                }
              />
            )
          })}
        </InfiniteScroll>
      )}
    </Container>
  )
}

export default CandidateTimeline
