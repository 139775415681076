import styled from 'styled-components/macro'

import Tag from 'antd/lib/tag'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-tag > .anticon + span,
  .ant-tag > span + .anticon {
    margin-left: 0;
  }

  .ant-tag-default {
    .ant-badge-status-text {
      margin: 0;
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`

export const CandidateStatusMenuContainer = styled.div`
  background: #fff;
  padding: 8px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 8px;
`
export const TagItem = styled(Tag)`
  min-height: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  &.ant-tag-red {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.red5};
  }
`
export const TagIcon = styled(Tag)`
  width: 24px;
  height: 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TagItemNew = styled(Tag)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue6};
`
export const TagItemShortlisted = styled(TagIcon)`
  background: #13c2c2;
`
export const TagItemMaybe = styled(TagIcon)`
  background: ${({ theme }) => theme.colors.orange5};
`
export const TagItemRejected = styled(TagIcon)`
  background: ${({ theme }) => theme.colors.red4};
`
