import styled from 'styled-components/macro'

export const Container = styled.div`
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
  p {
    color: ${({ theme }) => theme.colors.gray9};
  }
`
