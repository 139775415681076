import { takeLatest } from 'redux-saga/effects'

import { getCheckoutInfo, purchaseCredits, transferCredits } from './actions'
import {
  ensureGetCheckoutInfo,
  ensurePurchaseCredits,
  ensureTransferCredits,
} from './workers'

export function* watchPurchaseCredits() {
  yield takeLatest(purchaseCredits.TRIGGER, ensurePurchaseCredits)
}

export function* watchGetCheckoutInfo() {
  yield takeLatest(getCheckoutInfo.TRIGGER, ensureGetCheckoutInfo)
}

export function* watchTransferCredits() {
  yield takeLatest(transferCredits.TRIGGER, ensureTransferCredits)
}
