import * as Yup from 'yup'

import { companyName, companyWebsite } from './baseSchema'

const updateUserSchema = Yup.object().shape({
  name: companyName.required('Please enter company name'),
  website: companyWebsite.required('Please enter correct url'),
})

export default updateUserSchema
