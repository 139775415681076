import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getViewerSelector } from 'store/viewer'

import { ROUTES } from 'routes'

import { Title, TitleLink } from './styles'

import { IAlgoliaUser, UserStatus } from 'types'

const FullName = ({ objectID, fullName, status }: IAlgoliaUser) => {
  const location = useLocation()
  const viewer = useSelector(getViewerSelector)

  const isUserAccount = viewer.id === objectID

  if (location.pathname.includes('/job/')) {
    return <Title>{fullName}</Title>
  }

  if (status !== UserStatus.Verified && status !== UserStatus.NotVerified) {
    return <Title>{fullName}</Title>
  }

  if (isUserAccount) {
    return <TitleLink to={`${ROUTES.SETTING_PROFILE}`}>{fullName}</TitleLink>
  } else {
    return <TitleLink to={`/users/${objectID}`}>{fullName}</TitleLink>
  }
}

export default FullName
