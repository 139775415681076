import React, { FC } from 'react'

import { formatDate } from 'helpers/formatDate'

import DownloadCv from '../DownloadCV/DownloadCV'
import { candidateSources } from '../constanst'
import ActionsSideBar from './ActionsSideBar/ActionsSideBar'
import ForwardCVModal from 'app/components/Modals/ForwardCVModal/ForwardCVModal'
import { ROUTES } from 'routes'

import Collapse from 'antd/lib/collapse'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import {
  CaretDownOutlined,
  CheckCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons'

import {
  CandidateButtons,
  CandidateInfo,
  CandidateInfoBlock,
  CandidateInfoItem,
  CandidateInfoWrapper,
  CollapseItem,
  DividerItem,
  JobItem,
  JobItemCampaign,
  JobItemInfo,
  JobPosition,
  TagItem,
} from './styles'

import { CandidateSource, ICandidate, JobStatus } from 'types'

const { Panel } = Collapse

interface CandidateSideBarProps {
  candidate: ICandidate
}

const CandidateSideBar: FC<CandidateSideBarProps> = ({ candidate }) => {
  const screens = useBreakpoint()

  const {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    applied,
    source,
    campaigns,
    cv,
    campaignTitle,
  } = candidate

  const candidateSourcesIndex = candidateSources.findIndex(
    item => item.value === source,
  )
  const formattedSource = candidateSources[candidateSourcesIndex].title

  const generateJobLink = ({
    id,
    status,
  }: {
    id: string
    status: JobStatus
  }) => {
    if (status === JobStatus.Live) {
      return `${ROUTES.JOB}/${id}`
    }
    return `${ROUTES.JOB}/${id}/preview`
  }

  return (
    <div>
      {screens.xl && <ActionsSideBar />}

      <CandidateInfo>
        <CandidateInfoWrapper>
          {phoneNumber && (
            <CandidateInfoItem>
              <PhoneOutlined />
              <a href={`tel:${phoneNumber}`}> {phoneNumber}</a>
            </CandidateInfoItem>
          )}

          {email && (
            <CandidateInfoItem>
              <MailOutlined />
              <a target="_blank" rel="noreferrer" href={`mailto:${email}`}>
                {email}
              </a>
            </CandidateInfoItem>
          )}

          <CandidateButtons>
            <DownloadCv
              id={id}
              fullName={`${firstName} ${lastName}`}
              isHasCv={!!cv}
            />

            <ForwardCVModal
              candidateId={id}
              buttonTitle="Forward CV"
              isHasCv={!!cv}
              icon={null}
            />
          </CandidateButtons>
        </CandidateInfoWrapper>
        <DividerItem />
        <CandidateInfoWrapper>
          <CandidateInfoBlock>
            <h2>JOB</h2>
            <h3>{campaignTitle}</h3>
          </CandidateInfoBlock>
          <CandidateInfoBlock>
            <h2>ADDED</h2>
            <h3>{formatDate(applied, 'dd/MM/yyyy')}</h3>
          </CandidateInfoBlock>
          <CandidateInfoBlock>
            <h2>CV SOURCE</h2>
            <h3>
              {formattedSource}{' '}
              {source !== CandidateSource.JobheronDb && 'Campaign'}
            </h3>
          </CandidateInfoBlock>
        </CandidateInfoWrapper>
      </CandidateInfo>

      {!!campaigns.length && (
        <CollapseItem
          defaultActiveKey={['1']}
          ghost
          expandIcon={() => <CaretDownOutlined />}
          bordered={false}
        >
          <Panel header={`Other jobs (${campaigns.length})`} key="1">
            {campaigns.map(campaign => {
              const { id, title, stage, type, status } = campaign
              return (
                <JobItem
                  to={`${generateJobLink({ id: String(id), status })}`}
                  key={id}
                >
                  <JobPosition>{title}</JobPosition>
                  <JobItemInfo>
                    <JobItemCampaign>
                      <CheckCircleOutlined /> {type} Campaign
                    </JobItemCampaign>
                    <TagItem>{stage}</TagItem>
                  </JobItemInfo>
                </JobItem>
              )
            })}
          </Panel>
        </CollapseItem>
      )}
    </div>
  )
}

export default CandidateSideBar
