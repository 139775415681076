import styled from 'styled-components/macro'

import Menu from 'antd/lib/menu'

export const MenuContainer = styled(Menu)`
  max-width: 186px;
  padding: 0;

  .ant-dropdown-menu-item {
    white-space: normal;
  }

  h2 {
    font-weight: bold;
    font-size: 14px;
  }
  p {
    color: #8c8c8c;
    font-size: 14px;
  }
`
