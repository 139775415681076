import React, { FC } from 'react'
import {
  Configure,
  Index,
  InstantSearch,
  connectAutoComplete,
} from 'react-instantsearch-dom'

import { groupHitsByCompany } from 'helpers'
import { camelCaseToString } from 'helpers/viewerHelpers'

import Select from 'antd/lib/select'

import {
  OptionItemCandidates,
  OptionItemTitle,
} from '../../Modals/InviteTeamMember/SearchCompaniesAutocomplete/styles'
import { OptionItem } from './styles'

import { IAlgoliaUser, ICompany, OptionsType } from 'types'

const { Option, OptGroup } = Select

interface IAlgoliaAutocomplete {
  optionsType: OptionsType
  filters?: string
  hitsPerPage?: number
  searchClient: any
  indexName: string
  isGroupByCompany?: boolean
  handleOnSelect: (value: string) => void
  placeholder: string
  isDisabled?: boolean
}

const AlgoliaAutocomplete: FC<IAlgoliaAutocomplete> = ({
  optionsType,
  filters = '',
  hitsPerPage = 1000,
  searchClient,
  indexName,
  isGroupByCompany = false,
  handleOnSelect,
  placeholder,
  isDisabled,
  ...rest
}) => {
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <Index indexName={indexName}>
        <Configure hitsPerPage={hitsPerPage} filters={filters} />
        <ConnectAutocomplete
          {...rest}
          placeholder={placeholder}
          handleOnSelect={handleOnSelect}
          isGroupByCompany={isGroupByCompany}
          isDisabled={isDisabled}
          optionsType={optionsType}
        />
      </Index>
    </InstantSearch>
  )
}

const AutoComplete = ({
  hits,
  refine,
  placeholder,
  isGroupByCompany,
  handleOnSelect,
  isDisabled,
  optionsType,
  ...rest
}: any) => {
  return (
    <Select
      {...rest}
      showSearch={true}
      filterOption={false}
      onSearch={event => refine(event)}
      placeholder={placeholder}
      onSelect={handleOnSelect}
      defaultValue={rest.value}
      disabled={isDisabled}
    >
      {optionsType === OptionsType.UsersWithRole && (
        <>
          {hits.map(({ fullName, objectID, role }: IAlgoliaUser) => (
            <Option key={objectID} value={objectID}>
              <OptionItem>
                <div>{fullName}</div>
                <div>{camelCaseToString(role)}</div>
              </OptionItem>
            </Option>
          ))}
        </>
      )}

      {optionsType === OptionsType.Companies && (
        <>
          {hits.map(({ name, objectID, numberOfMembers }: ICompany) => (
            <Option key={objectID} value={objectID}>
              <OptionItem>
                <OptionItemTitle>{name}</OptionItemTitle>
                <OptionItemCandidates>
                  {numberOfMembers} Members
                </OptionItemCandidates>
              </OptionItem>
            </Option>
          ))}
        </>
      )}

      {optionsType === OptionsType.Users && (
        <>
          {hits.map(({ fullName, userId }: IAlgoliaUser) => (
            <Option key={userId} value={userId}>
              <OptionItem>
                <div>{fullName}</div>
              </OptionItem>
            </Option>
          ))}
        </>
      )}

      {optionsType === OptionsType.UsersGroupByCompanies && (
        <>
          {groupHitsByCompany(hits).map(({ companyTitle, companyUsers }) => (
            <OptGroup key={companyTitle} label={companyTitle}>
              {companyUsers.map(
                ({ fullName, email, objectID }: IAlgoliaUser) => (
                  <Option key={objectID} value={objectID}>
                    <OptionItem>
                      <div>{fullName}</div>
                      {<span>{email}</span>}
                    </OptionItem>
                  </Option>
                ),
              )}
            </OptGroup>
          ))}
        </>
      )}
    </Select>
  )
}

const ConnectAutocomplete: any = connectAutoComplete(AutoComplete)

export default AlgoliaAutocomplete
