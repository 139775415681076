import * as React from 'react'

function Forward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.992 6.573L10.714 2.34c-.732-.725-1.991-.21-1.991.817v1.942c-3.353.1-7.389.744-7.389 4.844 0 1.774.924 3.241 2.373 4.185.902.588 2.086-.262 1.755-1.31-.592-1.87-.302-2.872 3.26-3.077v1.883c0 1.027 1.258 1.544 1.992.817l4.278-4.233a1.147 1.147 0 000-1.634zM9.89 11.625V8.547c-4 .043-6.744.814-5.542 4.61-.875-.57-1.847-1.604-1.847-3.215 0-3.423 3.908-3.691 7.389-3.706V3.155l4.277 4.235-4.277 4.235z"
        fill="#262626"
      />
    </svg>
  )
}

export default Forward
