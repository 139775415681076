import React, { FC } from 'react'

import { RoleBlock, RoleTitle } from './styles'

import { UserStatus, UsersRole } from 'types'

interface IAccessLevel {
  role: UsersRole
  status?: UserStatus
  userId?: string
  fullName?: string
}

const AccessLevel: FC<IAccessLevel> = ({ role }) => {
  const roleToString = (userRole: UsersRole) => {
    switch (userRole) {
      case UsersRole.JHSuperAdmin:
        return 'JH Super Admin'
      case UsersRole.CompanyAdmin:
        return 'Company Admin'
      case UsersRole.RecruitmentManager:
        return 'Recruitment Manager'
      case UsersRole.ExternalRecruiter:
        return 'External Recruiter'
      case UsersRole.HiringManager:
        return 'Hiring Manager'
      case UsersRole.TeamMember:
        return 'Team Member'
    }
  }

  return (
    <RoleBlock>
      <RoleTitle>{roleToString(role)}</RoleTitle>
    </RoleBlock>
  )
}

export default AccessLevel
