import React, { FC, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { getViewerSelector } from 'store/viewer'

import { candidateSources } from '../../Candidate/constanst'
import AlgoliaAutocomplete from '../AlgoliaAutocomplete/AlgoliaAutocomplete'
import algoliasearch from 'algoliasearch/lite'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'

import { Container } from './styles'

import {
  CandidateSource,
  CandidateSourcedBy,
  InvitationStatus,
  OptionsType,
  UsersRole,
} from 'types'

const { Option } = Select

interface IAddCandidateSource {
  sourcedBy?: CandidateSourcedBy
  jobId: string
}

const AddCandidateSource: FC<IAddCandidateSource> = ({ sourcedBy, jobId }) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useFormContext()

  const currentSource = watch('source')

  const viewer = useSelector(getViewerSelector)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.teamMembers.token,
      ),
    [],
  )

  const handleOnSelect = (userId: string) => {
    setValue('userId', userId)
  }

  useEffect(() => {
    reset({ userId: null, source: CandidateSource.JobheronDb })
  }, [])

  return (
    <Container>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            validateStatus={errors.source ? 'error' : 'success'}
            help={errors.source?.message}
            label="Source"
          >
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={field.onChange}
                  placeholder="Select source..."
                >
                  {candidateSources.map(({ value, title }) => {
                    if (value === CandidateSource.ExternalRecruiter) {
                      return null
                    }
                    return (
                      <Option key={value} value={value}>
                        {title}
                      </Option>
                    )
                  })}
                </Select>
              )}
              control={control}
              name="source"
              defaultValue={CandidateSource.JobheronDb}
            />
          </Form.Item>
        </Col>
        {currentSource === CandidateSource.Sourced && (
          <Col xs={24} md={12}>
            <Form.Item
              label="Sourcer name"
              hasFeedback={!!errors.userId}
              validateStatus={errors.userId ? 'error' : 'success'}
              help={errors.userId?.message}
            >
              <Controller
                render={({ field }) => (
                  <AlgoliaAutocomplete
                    {...field}
                    placeholder="Select sourcer"
                    searchClient={searchClient}
                    indexName={viewer.algoliaKeys.teamMembers.indexName}
                    filters={`advertId:${jobId} AND invitationStatus:${InvitationStatus.Accepted} AND NOT role:${UsersRole.ExternalRecruiter}`}
                    handleOnSelect={handleOnSelect}
                    optionsType={OptionsType.Users}
                  />
                )}
                control={control}
                name="userId"
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default AddCandidateSource
