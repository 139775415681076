import { call, put } from 'redux-saga/effects'

import {
  createStage,
  deleteStage,
  fetchStages,
  reorderStage,
  updateStage,
} from './actions'
import { AxiosResponse } from 'axios'
import Api from 'services/api'

import { ApiCreateStage, ApiDeleteStage, IStage } from 'types'

export function* ensureFetchStages({
  payload,
}: {
  type: typeof fetchStages.TRIGGER
  payload: { companyId: string }
}) {
  try {
    const { companyId } = payload
    const stages: AxiosResponse = yield call(Api.stages.getStages, {
      companyId,
    })
    yield put(fetchStages.success({ stages: stages.data }))
  } catch (err) {
    console.log(err)
    yield put(fetchStages.failure())
  }
}

export function* ensureCreateStage({
  payload,
}: {
  type: typeof createStage.TRIGGER
  payload: ApiCreateStage
}) {
  try {
    const stage: AxiosResponse = yield call(Api.stages.createStage, payload)
    yield put(createStage.success({ stage: stage.data }))
  } catch (err) {
    yield put(createStage.failure(err?.response?.data?.errors))
  }
}

export function* ensureUpdateStage({
  payload,
}: {
  type: typeof updateStage.TRIGGER
  payload: ApiCreateStage
}) {
  try {
    const stage: AxiosResponse = yield call(Api.stages.updateStage, payload)
    yield put(updateStage.success({ stage: stage.data }))
  } catch (err) {
    console.log(err)
    yield put(updateStage.failure(err?.response?.data.errors))
  }
}

export function* ensureReorderStage({
  payload,
}: {
  type: typeof reorderStage.TRIGGER
  payload: { requestedStage: ApiCreateStage; reorderedStages: IStage[] }
}) {
  try {
    yield call(Api.stages.updateStage, payload.requestedStage)
    yield put(reorderStage.success())
  } catch (err) {
    console.log(err)
    yield put(reorderStage.failure(err?.response?.data.errors))
  }
}

export function* ensureDeleteStage({
  payload,
}: {
  type: typeof deleteStage.TRIGGER
  payload: ApiDeleteStage
}) {
  try {
    yield call(Api.stages.deleteStage, payload)
    yield put(deleteStage.success({ stageId: payload.id }))
  } catch (err) {
    console.log(err)
    yield put(deleteStage.failure(err?.response?.data.errors))
  }
}
