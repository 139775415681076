import React, { FC } from 'react'

interface IWarning {
  size?: number
  color?: string
}

const Warning: FC<IWarning> = ({ size = 14, color = '#FFA940' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9328 12.375L7.43284 1.125C7.33597 0.957813 7.16878 0.875 7.00003 0.875C6.83128 0.875 6.66253 0.957813 6.56722 1.125L0.0672172 12.375C-0.12497 12.7094 0.115655 13.125 0.50003 13.125H13.5C13.8844 13.125 14.125 12.7094 13.9328 12.375ZM6.50003 5.5C6.50003 5.43125 6.55628 5.375 6.62503 5.375H7.37503C7.44378 5.375 7.50003 5.43125 7.50003 5.5V8.375C7.50003 8.44375 7.44378 8.5 7.37503 8.5H6.62503C6.55628 8.5 6.50003 8.44375 6.50003 8.375V5.5ZM7.00003 11C6.80377 10.996 6.6169 10.9152 6.47951 10.775C6.34213 10.6348 6.26518 10.4463 6.26518 10.25C6.26518 10.0537 6.34213 9.86522 6.47951 9.725C6.6169 9.58478 6.80377 9.50401 7.00003 9.5C7.19629 9.50401 7.38316 9.58478 7.52055 9.725C7.65793 9.86522 7.73488 10.0537 7.73488 10.25C7.73488 10.4463 7.65793 10.6348 7.52055 10.775C7.38316 10.9152 7.19629 10.996 7.00003 11Z"
        fill={color}
      />
    </svg>
  )
}

export default Warning
