import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  deleteMemberPromiseCreator,
  inviteCompanyMemberPromiseCreator,
  inviteMemberPromiseCreator,
} from 'store/hiringTeam'
import { getViewerSelector } from 'store/viewer'

import { checkIfCanRemoveFromJob } from 'helpers/jobHelpers'

import snackbar from 'app/components/Snackbar/Snackbar'
import DeleteIcon from 'assets/icons/Delete'
import SuccessIcon from 'assets/icons/Success'
import useActionsRoutines from 'hooks/useActionsRoutines'

import Button from 'antd/lib/button'

import { InfoCircleOutlined } from '@ant-design/icons'

import {
  ButtonGray,
  HiringTeamTableActionsContainer,
  HiringTeamTableActionsItem,
} from './styles'

import { InvitationStatus } from 'types'

const HiringTeamTableActions = ({
  userId,
  invitationStatus,
  objectID,
  invitedBy,
  job,
  email,
  role,
  fullName,
}: any) => {
  const [isInviteLoading, setInviteLoading] = useState(false)
  const [isDeleteMemberLoading, setDeleteMemberLoading] = useState(false)
  const { jobId } = useParams<{ jobId: string }>()
  const viewer = useSelector(getViewerSelector)
  const deleteMember = useActionsRoutines(deleteMemberPromiseCreator)
  const inviteCompanyMember = useActionsRoutines(
    inviteCompanyMemberPromiseCreator,
  )
  const resendInvite = useActionsRoutines(inviteMemberPromiseCreator)

  const handleDeleteMember = async () => {
    try {
      setDeleteMemberLoading(true)
      await deleteMember({ id: jobId, userId, objectID })
      snackbar({
        content: 'Pending invite deleted',
        showIcon: true,
        type: 'info',
        icon: InfoCircleOutlined,
        closable: true,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setDeleteMemberLoading(false)
    }
  }
  const isCanRemoveFromJob = checkIfCanRemoveFromJob({
    viewerRole: viewer.role,
    viewerId: viewer.id,
    jobCreatorId: job.user.id,
    jobUserId: userId,
    invitedBy,
  })

  const handleResendInvite = async () => {
    try {
      setInviteLoading(true)
      if (fullName === 'Invite Sent') {
        await resendInvite({
          userId: invitedBy,
          email,
          role,
          adverts: [jobId],
        })
      } else {
        await inviteCompanyMember({ id: jobId, userId })
      }

      snackbar({
        content: 'Pending invite resent',
        showIcon: true,
        type: 'info',
        icon: InfoCircleOutlined,
        closable: true,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setInviteLoading(false)
    }
  }

  if (isCanRemoveFromJob && invitationStatus === InvitationStatus.Accepted) {
    return (
      <HiringTeamTableActionsItem>
        <Button type="link" onClick={handleDeleteMember}>
          Remove from job
        </Button>
      </HiringTeamTableActionsItem>
    )
  }

  if (isCanRemoveFromJob && invitationStatus === InvitationStatus.Pending) {
    return (
      <HiringTeamTableActionsContainer>
        <ButtonGray
          type="link"
          icon={<DeleteIcon />}
          onClick={handleDeleteMember}
          loading={isDeleteMemberLoading}
        >
          Cancel
        </ButtonGray>

        <Button
          type="link"
          icon={<SuccessIcon />}
          onClick={handleResendInvite}
          loading={isInviteLoading}
        >
          Resend
        </Button>
      </HiringTeamTableActionsContainer>
    )
  }

  return null
}

export default HiringTeamTableActions
