import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
  getViewerSelector,
  isAdminSelector,
  isExternalRecruiterSelector,
  isTeamMemberSelector,
  logoutPromiseCreator,
} from 'store/viewer'

import { generateAvatarName } from 'helpers/generateAvatarName'

import Logo from '../../../assets/icons/Logo'
import MobileMenu from './MobileMenu/MobileMenu'
import PaymentBlock from './PaymentBlock/PaymentBlock'
import ReferAndEarnModal from 'app/components/Modals/ReferAndEarnModal/ReferAndEarnModal'
import { CREATE_JHCAMPAIGN_JOB_URL } from 'app/modules/Jobs/CreateJob/SelectCampaignType/SelectCampaignType'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'
import { adminMenu, userMenu } from 'routes'

import Dropdown from 'antd/lib/dropdown'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Menu from 'antd/lib/menu'
import Tooltip from 'antd/lib/tooltip'

import {
  CaretDownOutlined,
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'

import {
  Container,
  HelpButton,
  Main,
  MenuBlock,
  MenuCompany,
  MenuItem,
  MenuName,
  MenuTag,
  MenuTop,
  RightActions,
  SubMenuWrapper,
  TopMenu,
  UserName,
  UserNameContainer,
} from './styles'

import { CreateJobTab, UserStatus } from 'types'

const Header = () => {
  const history = useHistory()
  const location = useLocation()
  const screens = useBreakpoint()
  const viewer = useSelector(getViewerSelector)
  const isTeamMember = useSelector(isTeamMemberSelector)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const isAdmin = useSelector(isAdminSelector)
  const logout = useActionsRoutines(logoutPromiseCreator)

  const { firstName, lastName, role, company, status } = viewer

  const menu = (
    <MenuBlock>
      <MenuTop>
        <MenuName>{`${firstName} ${lastName}`}</MenuName>
        <MenuCompany>{company.name}</MenuCompany>
        {status === UserStatus.NotVerified && <MenuTag>Unverified</MenuTag>}
      </MenuTop>
      {!isAdmin && (
        <MenuItem icon={<SettingOutlined />}>
          <Link to={ROUTES.SETTING_PROFILE}>Settings</Link>
        </MenuItem>
      )}
      <MenuItem icon={<LogoutOutlined />} onClick={() => logout()}>
        Sign out
      </MenuItem>
    </MenuBlock>
  )

  return (
    <Container>
      <Main>
        <Link to={ROUTES.JOBS}>
          <Logo color={isAdmin ? '#FA8C16' : '#2688FC'} />
        </Link>

        {(isAdmin ? !screens.md : !screens.lg) ? (
          <MobileMenu viewer={viewer} logout={logout} />
        ) : (
          <>
            <TopMenu mode="horizontal" selectedKeys={[location.pathname]}>
              {isAdmin ? (
                <>
                  {adminMenu.map(({ title, link }) => (
                    <Menu.Item key={link}>
                      <Link to={link}>{title}</Link>
                    </Menu.Item>
                  ))}
                </>
              ) : (
                <>
                  {userMenu.map(({ title, link, enableSubMenu }) => {
                    if (enableSubMenu && link === ROUTES.JOBS) {
                      return (
                        <SubMenuWrapper
                          key="top-menu"
                          title={title}
                          icon={<CaretDownOutlined />}
                          onTitleClick={() => history.push(ROUTES.JOBS)}
                        >
                          <Menu.Item key={ROUTES.JOBS}>
                            <Link to={ROUTES.JOBS}>All jobs</Link>
                          </Menu.Item>
                          {!isTeamMember && !isExternalRecruiter && (
                            <Menu.Item key={ROUTES.JOB_CREATE}>
                              <Link to={CREATE_JHCAMPAIGN_JOB_URL}>
                                Post a job
                              </Link>
                            </Menu.Item>
                          )}
                        </SubMenuWrapper>
                      )
                    }

                    return (
                      <Menu.Item key={link}>
                        <Link to={link}>{title}</Link>
                      </Menu.Item>
                    )
                  })}
                </>
              )}
            </TopMenu>

            <RightActions>
              {screens.md && (
                <>
                  {/* JA-1591 Temporarily Hide for Now */}
                  {/* {!isAdmin && <ReferAndEarnModal />} */}
                  {!isAdmin && (
                    <Tooltip title="Help">
                      <HelpButton type="link">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={ROUTES.JOBHERON_HELP_CENTER}
                        >
                          <QuestionCircleOutlined />
                        </a>
                      </HelpButton>
                    </Tooltip>
                  )}
                  <PaymentBlock />
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    placement="bottomRight"
                    getPopupContainer={trigger => trigger}
                  >
                    <UserNameContainer>
                      <UserName role={role}>
                        {generateAvatarName({ firstName, lastName })}
                      </UserName>
                      <DownOutlined />
                    </UserNameContainer>
                  </Dropdown>
                </>
              )}
            </RightActions>
          </>
        )}
      </Main>
    </Container>
  )
}

export default Header
