import styled from 'styled-components'

import Button from 'antd/lib/button'

export const Container = styled.div`
  position: relative;
`

export const FrameItem = styled.iframe`
  display: block;
  border: none;
  border-radius: 0;
  width: 100%;
  min-height: 700px;
  height: 100%;

  @media (min-width: 576px) {
    height: 1014px;
  }
`

export const PreviewWrapper = styled.div`
  width: 100%;
  height: 70vh;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (max-height: 460px) {
    svg {
      margin-top: -40px;
      transform: scale(0.5);
    }
    h2 {
      margin-top: -40px;
      font-size: 18px;
    }
  }
`

export const ButtonPrev = styled(Button)`
  position: absolute;
  left: 0;
  bottom: 16px;
`

export const ButtonNext = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 16px;
`
