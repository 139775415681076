import styled from 'styled-components/macro'

import Button from 'antd/lib/button'

export const Container = styled.div`
  .ant-table-row-expand-icon {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.gray6};
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0;
    border: none;
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table table {
    border: none;
  }

  .ant-table-expanded-row.ant-table-expanded-row-level-1 > {
    .ant-table-cell {
      padding: 0;
    }
  }
`

export const Header = styled.div`
  margin-bottom: 24px;
`

export const ActionButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${props => props.color};
  margin-right: 15px;

  svg {
    margin-right: 5px;
  }
  &:focus,
  &:hover {
    color: ${props => props.color};
  }
`

export const RightAction = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-select {
    width: 100%;
    max-width: 250px;
  }

  .ant-input-search {
    width: 100%;
    max-width: 250px;
    margin-left: 8px;
    input {
      &::placeholder {
        color: ${({ theme }) => theme.colors.gray7};
      }
    }
  }
`
