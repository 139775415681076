import { takeLatest } from 'redux-saga/effects'

import {
  completeAuth,
  fetchViewer,
  forgotPassword,
  logout,
  resendSignUp,
  resetPassword,
  signUp,
  signUpCompany,
} from './actions'
import {
  ensureCompleteAuth,
  ensureForgotPassword,
  ensureLogout,
  ensureResendSignUp,
  ensureResetPassword,
  ensureSignUp,
  ensureSignUpCompany,
  ensureViewer,
} from './workers'

export function* watchCurrentViewer() {
  yield takeLatest(fetchViewer.TRIGGER, ensureViewer)
}

export function* watchSignUp() {
  yield takeLatest(signUp.TRIGGER, ensureSignUp)
}

export function* watchSignUpCompany() {
  yield takeLatest(signUpCompany.TRIGGER, ensureSignUpCompany)
}

export function* watchCompleteAuth() {
  yield takeLatest(completeAuth.TRIGGER, ensureCompleteAuth)
}

export function* watchLogout() {
  yield takeLatest(logout.TRIGGER, ensureLogout)
}
export function* watchResendSignUp() {
  yield takeLatest(resendSignUp.TRIGGER, ensureResendSignUp)
}
export function* watchForgotPassword() {
  yield takeLatest(forgotPassword.TRIGGER, ensureForgotPassword)
}

export function* watchResetPassword() {
  yield takeLatest(resetPassword.TRIGGER, ensureResetPassword)
}
