import styled from 'styled-components/macro'

export const Container = styled.div`
  background: #ffffff;
  border: 7px solid #e6f7ff;
`

export const UserItem = styled.div`
  border: 1px solid rgb(240, 240, 240);
  border-bottom: 0;
  padding: 15px 10px;

  &:last-child {
    border-bottom: 1px solid rgb(240, 240, 240);
  }
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    justify-content: center;
  }
`

export const RoleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
`
