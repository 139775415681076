import React from 'react'

const SettingsIcon = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#262626"
    >
      <path
        d="M3.00033 8.33329L1.66699 8.33329M3.00033 8.33329C3.00033 9.06967 3.59728 9.66663 4.33366 9.66663C5.07004 9.66663 5.66699 9.06967 5.66699 8.33329M3.00033 8.33329C3.00033 7.59691 3.59728 6.99996 4.33366 6.99996C5.07004 6.99996 5.66699 7.59691 5.66699 8.33329M5.66699 8.33329L12.3337 8.33329M11.0003 2.33329C11.0003 1.59691 10.4034 0.999959 9.66699 0.999959C8.93061 0.999959 8.33366 1.59691 8.33366 2.33329M11.0003 2.33329C11.0003 3.06967 10.4034 3.66663 9.66699 3.66663C8.93061 3.66663 8.33366 3.06967 8.33366 2.33329M11.0003 2.33329L12.3337 2.33329M8.33366 2.33329L1.66699 2.33329"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SettingsIcon
