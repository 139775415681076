import React, { FC } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  addCandidatesPromiseCreator,
  fetchCandidateCountByFiltersPromiseCreator,
  fetchCandidateCountByKeywordPromiseCreator,
  listCandidatesPromiseCreator,
} from 'store/candidates'
import { isAdminSelector } from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'
import {
  getStoredCandidateFilters,
  isValidCvSize,
} from 'helpers/candidateHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import AddCandidateSource from '../../UI/AddCandidateSource/AddCandidateSource'
import UploadFile from '../../UI/UploadFile/UploadFile'
import { allowedUploadTypes } from '../constants'
import useActionsRoutines from 'hooks/useActionsRoutines'
import uniqBy from 'lodash/uniqBy'
import { addCandidatesByAdminSchema, addCandidatesSchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'

import { ActionButtons, ModalBody } from './styles'

import { ApiAddCandidate, CandidateSource, RootState } from 'types'

interface IAddCandidate {
  jobId: string
  isOpenCandidatesModal: boolean
  handleCloseCandidatesModal: () => void
  candidate: any
}

export interface IUploadFile {
  uid: string
  size?: number
  name: string
  fileName?: string
  lastModified?: number
  lastModifiedDate?: Date
  url?: string
  percent?: number
  type: string
  originFileObj: any
}

const AddCandidatesModal: FC<IAddCandidate> = ({
  jobId,
  isOpenCandidatesModal,
  handleCloseCandidatesModal,
}) => {
  const isAdmin = useSelector(isAdminSelector)
  const listCandidatesAction = useActionsRoutines(listCandidatesPromiseCreator)
  const fetchCandidateCountByKeyword = useActionsRoutines(
    fetchCandidateCountByKeywordPromiseCreator,
  )
  const fetchCandidateCountByFilters = useActionsRoutines(
    fetchCandidateCountByFiltersPromiseCreator,
  )
  const addCandidates = useActionsRoutines(addCandidatesPromiseCreator)
  const { isLoading: isListLoading } = useSelector(
    (state: RootState) => state.candidates.listCandidates,
  )
  const { isLoading: isUpdating } = useSelector(
    (state: RootState) => state.candidates.addCandidates,
  )

  const methods = useForm({
    resolver: yupResolver(
      isAdmin ? addCandidatesByAdminSchema : addCandidatesSchema,
    ),
  })

  const handleCloseModal = () => {
    methods.reset({
      source: CandidateSource.JobheronDb,
    })
    handleCloseCandidatesModal()
  }

  const {
    formState: { errors },
    setValue,
    control,
    setError,
    watch,
  } = methods

  const currentCvs: IUploadFile[] = watch('cvs') || []

  const validCvs: IUploadFile[] = currentCvs.filter(
    cv =>
      allowedUploadTypes.some(type => cv.name.endsWith(type)) &&
      isValidCvSize(cv.size),
  )

  const handleAddCandidate = (candidate: ApiAddCandidate) => {
    const candidatesFormData = new FormData()
    candidatesFormData.append('advertId', String(jobId))

    validCvs.forEach(({ originFileObj }) => {
      candidatesFormData.append('cvs', (originFileObj as unknown) as Blob)
    })

    if (isAdmin) {
      candidatesFormData.append('source', candidate.source!)

      candidate.source === CandidateSource.Sourced &&
        candidatesFormData.append('userId', candidate.userId!)
    }

    addCandidates(candidatesFormData)
      .then(() => {
        // Refresh Counts
        fetchCandidateCountByKeyword(
          getStoredCandidateFilters({
            jobId,
            parseQs: true,
            isByKeywordOnly: true,
          }),
        )
        fetchCandidateCountByFilters(
          getStoredCandidateFilters({ jobId, parseQs: true }),
        )

        // Refresh List
        listCandidatesAction(
          getStoredCandidateFilters({ jobId, parseQs: true }),
        ).then(() => {
          handleCloseModal()
        })
      })
      .catch(({ errors }) => {
        setFormErrors({
          setError,
          defaultItem: 'cvs',
          errors,
        })
      })
  }

  const uploadFileProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => false,
    maxCount: 50,
    fileList: currentCvs,
    onChange(info: any) {
      setValue('cvs', uniqBy([...currentCvs, ...info.fileList], 'uid'))
    },
  }

  const handleOnRemoveFile = (file: IUploadFile) => {
    setValue(
      'cvs',
      currentCvs.filter(cv => cv.uid !== file.uid),
    )
  }

  const isLoading = isListLoading || isUpdating

  return (
    <Modal
      width={600}
      footer={null}
      title="Add candidates"
      visible={isOpenCandidatesModal}
      onCancel={handleCloseModal}
    >
      <ModalBody>
        <FormProvider {...methods}>
          <Form layout="vertical">
            <Form.Item
              validateStatus={errors.cvs ? 'error' : 'success'}
              help={errors.cvs?.message}
            >
              <Controller
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    uploadFileProps={uploadFileProps}
                    onRemoveFile={handleOnRemoveFile}
                  />
                )}
                control={control}
                name="cvs"
                defaultValue=""
              />
            </Form.Item>

            {isAdmin && <AddCandidateSource jobId={jobId} />}

            {!!validCvs.length && (
              <ActionButtons>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  onClick={methods.handleSubmit(handleAddCandidate)}
                >
                  {validCvs.length === 1 ? 'Add' : 'Add Candidates'}
                </Button>
              </ActionButtons>
            )}
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  )
}

export default AddCandidatesModal
