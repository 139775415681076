/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { fetchViewerPromiseCreator } from 'store/viewer'

import '../App.less'
import PageLoader from './components/Loaders/PageLoader/PageLoader'
import { NotFoundPage } from './components/NotFoundPage/Loadable'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Callback from './modules/Auth/Callback/Callback'
import { ForgotPassword } from './modules/Auth/ForgotPassword/Loadable'
import Invitation from './modules/Auth/Invitation/Invitation'
import { ResetPassword } from './modules/Auth/ResetPassword/Loadable'
import { SignIn } from './modules/Auth/SignIn/Loadable'
import { SignUp } from './modules/Auth/SignUp/Loadable'
import { SignUpCompany } from './modules/Auth/SignUpCompany/Loadable'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES, allRoutes } from 'routes'
import { getToken, removeToken } from 'services/localStorage'
import { GlobalStyle } from 'styles/global-styles'

export function App() {
  const locations = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const fetchViewer = useActionsRoutines(fetchViewerPromiseCreator)
  const accessToken = getToken()

  useEffect(() => {
    if (locations.pathname === '/auth/callback') return setIsLoading(false)

    accessToken
      ? fetchViewer()
          .then(() => {
            setIsLoading(false)
          })
          .catch(() => {
            removeToken()
            history.push(ROUTES.SIGN_IN)
            setIsLoading(false)
          })
      : setIsLoading(false)
  }, [])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <Helmet titleTemplate="%s - Jobheron" defaultTitle="Jobheron">
        <meta
          name="description"
          content="Jobheron – recruit for less. Advertise your role on all the UK’s leading job boards."
        />
      </Helmet>

      <Switch>
        <Route exact path="/auth/sign-up">
          <SignUp />
        </Route>
        <Route exact path="/auth/sign-in">
          <SignIn />
        </Route>
        <Route exact path="/auth/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/auth/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/auth/sign-up-company">
          <SignUpCompany />
        </Route>
        <Route exact path="/auth/callback">
          <Callback />
        </Route>
        <Route exact path="/auth/invitation">
          <Invitation />
        </Route>
        {allRoutes.map(({ path, component, permissions, ...rest }) => (
          <PrivateRoute
            key={path}
            path={path}
            component={component}
            permissions={permissions}
            {...rest}
          />
        ))}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
      <GlobalStyle />
    </>
  )
}
