import { call, put } from 'redux-saga/effects'

import { ensureRefreshUserAlgoliaCache } from '../users/workers'
import {
  deleteMember,
  getHiringTeamCounters,
  inviteCompanyMember,
  inviteMember,
  startHiringTeamRefreshAlgoliaCache,
  stopHiringTeamRefreshAlgoliaCache,
} from './actions'
import algolia from 'services/algolia'
import Api from 'services/api'

import { IInviteMember } from 'types'

export function* ensureRefreshHiringTeamAlgoliaCache() {
  yield put(startHiringTeamRefreshAlgoliaCache())
  yield put(stopHiringTeamRefreshAlgoliaCache())
}

export function* ensureInviteCompanyMember({
  payload,
}: {
  type: typeof inviteCompanyMember.TRIGGER
  payload: any
}) {
  const { id, userId } = payload
  try {
    yield call(Api.hiringTeam.inviteMember, {
      id,
      userId,
    })
    yield put(inviteCompanyMember.success())
    yield call(ensureRefreshHiringTeamAlgoliaCache)
  } catch (err) {
    console.log(err)
    yield put(inviteCompanyMember.failure())
  }
}

export function* ensureDeleteMember({
  payload,
}: {
  type: typeof deleteMember.TRIGGER
  payload: any
}) {
  const { id, userId } = payload
  try {
    yield call(Api.hiringTeam.deleteMember, { id, userId })
    yield put(deleteMember.success({ userId }))
    yield call(ensureRefreshHiringTeamAlgoliaCache)
  } catch (err) {
    console.log(err)
    yield put(deleteMember.failure())
  }
}

export function* ensureInviteMember({
  payload,
}: {
  type: typeof inviteMember.TRIGGER
  payload: IInviteMember
}) {
  const { userId, email, role, adverts, companyId } = payload
  try {
    yield call(Api.users.inviteMember, {
      userId,
      adverts,
      email,
      role,
      companyId,
    })
    yield put(inviteMember.success())
    yield call(ensureRefreshHiringTeamAlgoliaCache)
    yield call(ensureRefreshUserAlgoliaCache)
  } catch (err) {
    const { errors } = err.response.data.errors
    yield put(inviteMember.failure({ errors }))
  }
}

export function* ensureGetHiringTeamCounters({
  payload,
}: {
  type: typeof getHiringTeamCounters.TRIGGER
  payload: any
}) {
  try {
    const { companyMembersFilters, externalRecruiterFilters } = payload
    const companyUsersCounts = yield algolia.getCompanyUsersFacets({
      filters: companyMembersFilters,
    })

    const externalRecruiterCounts = yield algolia.getExternalRecruiterFacets({
      filters: externalRecruiterFilters,
    })

    yield put(
      getHiringTeamCounters.success({
        isExistER: !!externalRecruiterCounts.nbHits,
        isExistCM: !!companyUsersCounts.nbHits,
      }),
    )
  } catch (err) {
    const { errors } = err.response.data.errors
    yield put(getHiringTeamCounters.failure({ errors }))
  }
}
