export interface IError {
  message: string
  code: number
  item: string | null
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
