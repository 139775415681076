import { ROUTES } from './constants'
import AccountMembers from 'app/modules/Settings/AccountMembers/AccountMembers'
import CompanyInfo from 'app/modules/Settings/CompanyInfo/CompanyInfo'
import GDPR from 'app/modules/Settings/GDPR/GDPR'
import EditJobsStages from 'app/modules/Settings/JobsStages/EditJobsStages/EditJobsStages'
import JobsStages from 'app/modules/Settings/JobsStages/JobsStages'
import Profile from 'app/modules/Settings/Profile/Profile'

import { UsersRole } from '../types'

export const allSettingsRoutes = [
  {
    path: ROUTES.SETTING_PROFILE,
    component: Profile,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.ExternalRecruiter,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
    ],
  },
  {
    path: ROUTES.SETTING_COMPANY_INFO,
    component: CompanyInfo,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
    ],
  },
  {
    path: ROUTES.SETTING_ACCOUNT_MEMBERS,
    component: AccountMembers,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
    ],
  },
  {
    path: ROUTES.SETTING_JOBS_STAGES,
    component: JobsStages,
    permissions: [UsersRole.CompanyAdmin],
  },
  {
    path: ROUTES.SETTING_JOBS_STAGES_EDIT,
    component: EditJobsStages,
    permissions: [UsersRole.CompanyAdmin],
  },
  {
    path: ROUTES.SETTING_GDPR,
    component: GDPR,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
    ],
  },
]
