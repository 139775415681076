import React from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const SearchPlaceAutocomplete = (props: any) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: props.isError ? '#ff4d4f' : '#d9d9d9',
      borderRadius: '8px',
    }),
  }

  return (
    <GooglePlacesAutocomplete
      {...props}
      autocompletionRequest={{
        types: ['geocode'],
      }}
      selectProps={{
        defaultInputValue: props.value,
        onChange: props.onChange,
        placeholder: '',
        styles: customStyles,
        components: { LoadingIndicator: null, DropdownIndicator: null },
      }}
      apiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
    />
  )
}

export default SearchPlaceAutocomplete
