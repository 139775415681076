import styled from 'styled-components'

import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'
import Tag from 'antd/lib/tag'

export const CandidateActionsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .ant-spin-nested-loading {
    order: 2;
  }

  @media (min-width: 768px) {
    justify-content: flex-end;

    .ant-spin-nested-loading {
      order: inherit;
    }
  }
`

export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: #fafafa;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
`

export const MenuContainer = styled(Menu)`
  margin-top: 5px;
  & .ant-menu-title-content {
    width: 100%;
  }

  @media (min-width: 768px) {
    &.ant-menu-root.ant-menu-vertical {
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
      border-radius: 8px;
    }
  }
`

export const MenuItem = styled(Menu.Item)`
  min-width: 170px;
  @media (min-width: 576px) {
    width: 245px;
  }

  &.ant-menu-item.ant-menu-item-only-child {
    padding: 0;
    margin: 0;
  }
  & .ant-btn:hover {
    background: transparent;
  }
  &.ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0;
    height: auto;
  }
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray3};
  }
  & .ant-menu-vertical > .ant-menu-item {
    height: auto;
  }
  .ant-btn {
    border-radius: 0;
    text-align: left;
    width: 100%;
    padding: 10px 16px;
    height: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.gray9};
    svg {
      display: flex;
    }
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
`
export const MenuItemCurrent = styled(MenuItem)`
  & .ant-btn {
    font-weight: 600;
  }
  .ant-tag {
    background: #f0f0f0;
    border: 1px solid #d9d9d9;
    font-size: 14px;
  }
  svg {
    margin-left: 5px;
  }
`
export const NextStageContainer = styled.div`
  .ant-btn-primary {
    max-width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  order: 3;
  gap: 8px;

  @media (min-width: 768px) {
    order: inherit;
  }
`

export const CandidateNumber = styled.div`
  white-space: nowrap;
  display: none;
  @media (min-width: 400px) {
    display: block;
  }
`

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const ButtonItem = styled(Button)`
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 210px;
  }
`

export const TagItem = styled(Tag)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 210px;
`
