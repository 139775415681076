import React from 'react'
import { connectHits } from 'react-instantsearch-dom'

import { companyUserColumns } from './columns'

import Table from 'antd/lib/table'

const CompanyUsersTable = ({ hits }: { hits: any }) => {
  return (
    <Table
      columns={companyUserColumns}
      dataSource={hits}
      showHeader={false}
      pagination={false}
      locale={{ emptyText: <span /> }}
    />
  )
}

export default connectHits(CompanyUsersTable)
