import { CVForwardTypes } from 'types'

export const cVForwardTypes = [
  { title: 'Daily', value: CVForwardTypes.Daily },
  { title: 'As CVs come', value: CVForwardTypes.AsCVCome },
]

// export const allowedUploadTypes = [
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ]

export const allowedUploadTypes = ['.pdf', '.doc', '.docx']
