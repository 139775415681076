import React, { FC } from 'react'

import { TagItem, TagItemRejected } from './styles'

import { TimelineTagStatus } from 'types'

interface TimelineTagProps {
  tagStatus?: string
}

const TimelineTag: FC<TimelineTagProps> = ({ tagStatus }) => {
  switch (tagStatus) {
    case TimelineTagStatus.New:
      return <TagItem color="blue">{tagStatus}</TagItem>
    case TimelineTagStatus.Viewed:
      return <TagItem color="purple">{tagStatus}</TagItem>
    case TimelineTagStatus.Shortlisted:
      return <TagItem color="cyan">{tagStatus}</TagItem>
    case TimelineTagStatus.Maybe:
      return <TagItem color="gold">{tagStatus}</TagItem>
    case TimelineTagStatus.Rejected:
      return <TagItemRejected>{tagStatus}</TagItemRejected>
    default:
      return null
  }
}

export default TimelineTag
