import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray9};
`

export const TitleLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gray9};
  text-decoration: underline;
`
