import styled from 'styled-components/macro'

export const OptionItem = styled.div`
  display: flex;
`

export const OptionItemTitle = styled.div`
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const OptionItemCandidates = styled.div`
  margin-left: auto;
`
