import { Link } from 'react-router-dom'

import styled from 'styled-components'

import Collapse from 'antd/lib/collapse'
import Divider from 'antd/lib/divider'
import Menu from 'antd/lib/menu'
import Tag from 'antd/lib/tag'

export const CandidateInfo = styled.div`
  background: #f8f8f8;
  border-radius: 8px;
  padding: 24px;
  margin: 0 0 24px;
  h2,
  h3 {
    margin: 0;
  }

  @media (min-width: 1200px) {
    margin-top: 20px;
  }
`
export const CandidateInfoItem = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  margin-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  svg {
    margin-right: 8px;
  }
  a {
    color: ${({ theme }) => theme.colors.gray9};
  }
  @media (max-width: 992px) and (min-width: 576px) {
    margin-right: 33px;
    margin-bottom: 0;
  }
`
export const CandidateButtons = styled(Menu)`
  justify-content: space-between;
  display: flex;
  width: 100%;
  background: transparent;
  margin-bottom: 10px;
  border: 0;

  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
  }

  .ant-menu-item {
    padding: 0;
    margin: 0;
    width: 100%;
    font-weight: 600;
  }

  & .ant-btn {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 992px) and (min-width: 576px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    width: auto;
    justify-content: flex-end;
    & .ant-btn {
      margin: 0 10px;
      padding: 0 10px;
    }
  }
`

export const DividerItem = styled(Divider)`
  margin: 0;
`
export const CandidateInfoBlock = styled.div`
  margin-top: 32px;
  width: 100%;
  h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.gray7};
  }
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray9};
  }
  @media (min-width: 992px) and (max-width: 576px) {
    width: 33%;
  }
`
export const CollapseItem = styled(Collapse)`
  & .ant-collapse-header > .anticon {
    transition: all 0.3s;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.gray7};
  }
  & .ant-collapse-item-active > .ant-collapse-header {
    .anticon {
      transform: rotate(180deg);
    }
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`

export const JobItem = styled(Link)`
  display: block;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray7};
  margin-bottom: 16px;
  &:hover {
    border: 1px solid #40a9ff;
  }

  @media (max-width: 992px) and (min-width: 576px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
export const JobPosition = styled.h2`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 22px;
  svg {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 24px;
  }
  @media (max-width: 992px) and (min-width: 576px) {
    margin-bottom: 0;
  }
`
export const JobItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const JobItemCampaign = styled.h3`
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
  margin-right: 5px;
  svg {
    margin-right: 8px;
  }
  @media (max-width: 992px) and (min-width: 576px) {
    width: 210px;
  }
`
export const TagItem = styled(Tag)`
  color: ${({ theme }) => theme.colors.gray8};
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CandidateInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  align-items: flex-start;
  @media (max-width: 992px) and (min-width: 576px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }
`
