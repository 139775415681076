import { all, fork } from 'redux-saga/effects'

import { watchCandidatesActions } from 'store/candidates/sagas'
import { watchCompaniesToApproveActions } from 'store/companyToApprove/sagas'
import { watchCreditsActions } from 'store/credits/sagas'
import { watchHiringTeamActions } from 'store/hiringTeam/sagas'
import { watchJobsActions } from 'store/jobs/sagas'
import { watchStagesActions } from 'store/stages/sagas'
import { watchUsersActions } from 'store/users/sagas'
import { watchViewerActions } from 'store/viewer/sagas'

export function* watchAllActions() {
  yield all([
    fork(watchUsersActions),
    fork(watchViewerActions),
    fork(watchJobsActions),
    fork(watchHiringTeamActions),
    fork(watchCompaniesToApproveActions),
    fork(watchCandidatesActions),
    fork(watchCreditsActions),
    fork(watchStagesActions),
  ])
}
