import React, { FC } from 'react'

import WarningIcon from 'assets/icons/Warning'

import { Container } from './styles'

import { CompanyStatus } from 'types'

interface ICompany {
  id: string
  company: string
  companyStatus: CompanyStatus
}

const Company: FC<ICompany> = ({ company, companyStatus }) => {
  return (
    <Container>
      {companyStatus === CompanyStatus.NotVerified && <WarningIcon />}
      {company}
    </Container>
  )
}

export default Company
