import React, { FC, useEffect, useMemo, useState } from 'react'

import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'
import algolia from 'services/algolia'

import Checkbox from 'antd/lib/checkbox'
import Select from 'antd/lib/select'

import { OptionItem, OptionItemCandidates, OptionItemTitle } from './styles'

import { IAlgoliaJob } from 'types'

const { Option, OptGroup } = Select

interface ISearchJobAutocomplete {
  selectedAdverts: string[]
  selectedCompany?: number
  selectedCompanyId: null | string
  setSelectedCompanyId: (value: null | string) => void
}

interface JobList {
  jobStatus: string
  jobs: IAlgoliaJob[]
}

const SearchJobAutocomplete: FC<ISearchJobAutocomplete> = ({
  selectedAdverts,
  selectedCompany,
  selectedCompanyId,
  setSelectedCompanyId,
  ...rest
}) => {
  const [jobs, setJobs] = useState<IAlgoliaJob[]>([])

  const filters = useMemo(() => {
    if (selectedCompany) {
      return `company.id: ${selectedCompany}`
    }
    if (selectedCompanyId) {
      return `company.id: ${selectedCompanyId}`
    }
    return ''
  }, [selectedCompany, selectedCompanyId])

  const handleSearchUsers = async (query: string) => {
    const jobs = await algolia.getJobs({
      query,
      options: { filters, hitsPerPage: 100 },
    })
    setJobs((jobs.hits as unknown) as IAlgoliaJob[])
  }

  const handleSelectJobs = (jobId: string) => {
    const selectedJob = jobs.find(({ objectID }) => objectID === jobId)
    setSelectedCompanyId((selectedJob?.company.id as unknown) as string)
  }

  const handleClearSelectJobs = () => {
    setSelectedCompanyId(null)
  }

  useEffect(() => {
    handleSearchUsers('')
  }, [])

  useEffect(() => {
    handleSearchUsers('')
  }, [selectedCompany, selectedAdverts])

  const groupedByCompany = groupBy(jobs, 'statusFilter')
  const jobsList: JobList[] = Object.keys(groupedByCompany).map(key => ({
    jobStatus: key,
    jobs: groupedByCompany[key],
  }))

  return (
    <Select
      {...rest}
      allowClear
      mode="multiple"
      showArrow={true}
      showSearch={true}
      filterOption={false}
      placeholder="Select or search for a job..."
      onSearch={debounce(handleSearchUsers, 400)}
      onClear={handleClearSelectJobs}
      onSelect={(jobId: string) => handleSelectJobs(jobId)}
      dropdownClassName="job-autocomplete-list"
    >
      {jobsList.map(({ jobStatus, jobs }) => (
        <OptGroup key={jobStatus} label={jobStatus}>
          {jobs.map(({ title, totalCandidates, objectID }: IAlgoliaJob) => (
            <Option key={objectID} value={objectID}>
              <OptionItem>
                <Checkbox checked={selectedAdverts.includes(objectID)} />
                <OptionItemTitle>{title}</OptionItemTitle>
                <OptionItemCandidates>{`${totalCandidates} Candidates`}</OptionItemCandidates>
              </OptionItem>
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  )
}

export default SearchJobAutocomplete
