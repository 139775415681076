import { createReducer } from '@reduxjs/toolkit'

import { updateUser } from '../users'
import {
  completeAuth,
  fetchViewer,
  forgotPassword,
  getViewerBalance,
  hideMessage,
  logout,
  resetPassword,
  showMessage,
  signUp,
  signUpCompany,
} from './actions'

import { IViewerState } from 'types'

export const VIEWER_STATE: IViewerState = {
  signUp: { isLoading: false, error: null, isError: false },
  forgotPassword: { isLoading: false },
  resetPassword: { isLoading: false },
  signUpCompany: { isLoading: false },
  completeAuth: { isLoading: false },
  fetchViewer: { isLoading: false, error: null, isError: false },
  isAuthenticated: false,
  isLoaded: false,
  viewer: null,
  showMessage: false,
}

const viewerReducer = createReducer(VIEWER_STATE, {
  [getViewerBalance.SUCCESS]: (state, { payload }) => {
    state.viewer.balance = payload.balance
  },

  [updateUser.SUCCESS]: (state, { payload }) => {
    if (state.viewer.id === payload.user.id) {
      state.viewer = {
        ...state.viewer,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        phoneNumber: payload.user.phoneNumber,
      }
    }
  },

  [fetchViewer.SUCCESS]: (state, { payload }) => {
    state.viewer = payload.viewer
  },

  [fetchViewer.FAILURE]: state => {
    state.isLoaded = true
    state.isAuthenticated = false
  },

  [signUp.TRIGGER]: state => {
    state.signUp.isLoading = true
  },
  [signUp.SUCCESS]: state => {
    state.signUp.isLoading = false
  },
  [signUp.FAILURE]: state => {
    state.signUp.isLoading = false
  },

  [resetPassword.TRIGGER]: state => {
    state.resetPassword.isLoading = true
  },
  [resetPassword.SUCCESS]: state => {
    state.resetPassword.isLoading = false
  },
  [resetPassword.FAILURE]: state => {
    state.resetPassword.isLoading = false
  },

  [forgotPassword.SUCCESS]: state => {
    state.forgotPassword.isLoading = false
  },

  [forgotPassword.TRIGGER]: state => {
    state.forgotPassword.isLoading = true
  },

  [forgotPassword.FAILURE]: state => {
    state.forgotPassword.isLoading = false
  },

  [completeAuth.SUCCESS]: (state, action) => {
    state.viewer = action.payload.viewer
    state.completeAuth.isLoading = false
  },

  [completeAuth.TRIGGER]: state => {
    state.completeAuth.isLoading = true
  },

  [completeAuth.FAILURE]: state => {
    state.completeAuth.isLoading = false
  },

  [signUpCompany.TRIGGER]: state => {
    state.signUpCompany.isLoading = true
  },

  [signUpCompany.SUCCESS]: (state, action) => {
    state.viewer = action.payload.viewer
    state.signUpCompany.isLoading = false
  },

  [signUpCompany.FAILURE]: state => {
    state.signUpCompany.isLoading = false
  },

  [signUp.FAILURE]: state => {
    state.signUp.isLoading = false
  },
  [logout.SUCCESS]: state => {
    state.viewer = null
  },
  [String(hideMessage)]: state => {
    state.showMessage = false
  },
  [String(showMessage)]: (state, action) => {
    state.showMessage = action.payload.isShowMessage
  },
})

export default viewerReducer
