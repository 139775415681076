import { StageType } from 'types'

export const stageTypes = [
  {
    name: 'Phone Screen',
    type: StageType.PhoneScreen,
  },
  {
    name: 'Assessment',
    type: StageType.Assessment,
  },
  {
    name: 'Interview',
    type: StageType.Interview,
  },
  {
    name: 'Offer',
    type: StageType.Offer,
  },
  {
    name: 'Hired',
    type: StageType.Hired,
  },
]
