import { takeLatest } from 'redux-saga/effects'

import {
  addCandidate,
  addCandidates,
  deleteCandidate,
  downloadCv,
  fetchCandidate,
  fetchCandidateAssociatedMembers,
  fetchCandidateCountByFilters,
  fetchCandidateCountByKeyword,
  fetchCandidateNotes,
  fetchCandidateTimeline,
  forwardCandidates,
  forwardCandidatesCSV,
  listCandidates,
  parseCandidateByER,
  postCandidateNote,
  updateCandidate,
  updateCandidatesStage,
  updateCandidatesStatus,
} from './actions'
import {
  ensureAddCandidate,
  ensureAddCandidates,
  ensureDeleteCandidate,
  ensureDownloadCv,
  ensureFetchCandidate,
  ensureFetchCandidateAssociatedMembers,
  ensureFetchCandidateCountByFilters,
  ensureFetchCandidateCountByKeyword,
  ensureFetchCandidateNotes,
  ensureFetchCandidateTimeline,
  ensureForwardCandidates,
  ensureForwardCandidatesCSV,
  ensureListCandidates,
  ensureParseCandidateByER,
  ensurePostCandidateNote,
  ensureUpdateCandidate,
  ensureUpdateCandidatesStage,
  ensureUpdateCandidatesStatus,
} from './workers'

export function* watchFetchCandidate() {
  yield takeLatest(fetchCandidate.TRIGGER, ensureFetchCandidate)
}

export function* watchListCandidates() {
  yield takeLatest(listCandidates.TRIGGER, ensureListCandidates)
}

export function* watchFetchCandidateCountByKeyword() {
  yield takeLatest(
    fetchCandidateCountByKeyword.TRIGGER,
    ensureFetchCandidateCountByKeyword,
  )
}

export function* watchFetchCandidateCountByFilters() {
  yield takeLatest(
    fetchCandidateCountByFilters.TRIGGER,
    ensureFetchCandidateCountByFilters,
  )
}

export function* watchAddCandidate() {
  yield takeLatest(addCandidate.TRIGGER, ensureAddCandidate)
}

export function* watchUpdateCandidate() {
  yield takeLatest(updateCandidate.TRIGGER, ensureUpdateCandidate)
}

export function* watchAddCandidates() {
  yield takeLatest(addCandidates.TRIGGER, ensureAddCandidates)
}

export function* watchParseCandidateByER() {
  yield takeLatest(parseCandidateByER.TRIGGER, ensureParseCandidateByER)
}

export function* watchFetchCandidateAssociatedMembers() {
  yield takeLatest(
    fetchCandidateAssociatedMembers.TRIGGER,
    ensureFetchCandidateAssociatedMembers,
  )
}

export function* watchUpdateCandidatesStatus() {
  yield takeLatest(updateCandidatesStatus.TRIGGER, ensureUpdateCandidatesStatus)
}

export function* watchUpdateCandidatesStage() {
  yield takeLatest(updateCandidatesStage.TRIGGER, ensureUpdateCandidatesStage)
}

export function* watchPostCandidateNote() {
  yield takeLatest(postCandidateNote.TRIGGER, ensurePostCandidateNote)
}

export function* watchFetchCandidateTimeline() {
  yield takeLatest(fetchCandidateTimeline.TRIGGER, ensureFetchCandidateTimeline)
}

export function* watchFetchCandidateNotes() {
  yield takeLatest(fetchCandidateNotes.TRIGGER, ensureFetchCandidateNotes)
}

export function* watchDeleteCandidate() {
  yield takeLatest(deleteCandidate.TRIGGER, ensureDeleteCandidate)
}

export function* watchDownloadCv() {
  yield takeLatest(downloadCv.TRIGGER, ensureDownloadCv)
}

export function* watchForwardCandidates() {
  yield takeLatest(forwardCandidates.TRIGGER, ensureForwardCandidates)
}

export function* watchForwardCandidatesCSV() {
  yield takeLatest(forwardCandidatesCSV.TRIGGER, ensureForwardCandidatesCSV)
}
