import React, { FC, useState } from 'react'

import {
  DEBOUNCE_TIME,
  defaultPlaceholder,
} from '../AlgoliaSearch/AlgoliaSearch'

import Input from 'antd/lib/input'

const { Search } = Input

interface ICustomSearchBox {
  onSearchSubmit: (value: string) => void
  onSearchTyping: (value: string) => void
  placeholder?: string
  search: string
}

const CustomSearchBox: FC<ICustomSearchBox> = ({
  onSearchSubmit,
  onSearchTyping,
  placeholder = defaultPlaceholder,
  search: defaultSearch,
}) => {
  const [search, setSearch] = useState(defaultSearch)
  const setStateId = React.useRef<number>()

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    clearTimeout(setStateId.current)
    setStateId.current = window.setTimeout(
      () => onSearchTyping(value),
      DEBOUNCE_TIME,
    )
    setSearch(value)
  }

  const handleOnSearch = (value: string, _: any) => {
    onSearchSubmit(value)
  }

  return (
    <Search
      allowClear
      value={search}
      placeholder={placeholder}
      onChange={handleOnChange}
      onPressEnter={handleOnChange}
      onSearch={handleOnSearch}
    />
  )
}

export default CustomSearchBox
