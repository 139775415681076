import * as React from 'react'

function RecruiterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.22 2.922l8.938 15.469a.688.688 0 01-.595 1.03H1.688a.687.687 0 01-.595-1.03L10.03 2.92a.677.677 0 01.596-.343.68.68 0 01.595.344zM9.555 16H8.5v-5.818h2.182c1.335 0 2.051.75 2.051 1.86 0 .79-.36 1.376-1.046 1.651L12.947 16H11.77l-1.142-2.13H9.554V16zm0-4.938v1.932h.972c.78 0 1.133-.332 1.133-.951 0-.62-.352-.98-1.14-.98h-.965z"
        fill="#2688FC"
      />
    </svg>
  )
}

export default RecruiterIcon
