import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { isAdminSelector, isExternalRecruiterSelector } from 'store/viewer'

import JobCampaign from 'app/components/UI/JobCampaign/JobCampaign'
import WarningTooltip from 'app/components/UI/WarningTooltip/WarningTooltip'

import { BankOutlined } from '@ant-design/icons'

import {
  BottomRowActionsBlock,
  CompanyBlock,
  CompanyLink,
  ReferenceCode,
  ReferenceCodeWrapper,
} from './styles'

import { CompanyStatus, JobStatus } from 'types'

interface BottomRowItemsProps {
  isJHCampaign: boolean
  isSSCampaign: boolean
  referenceCode: string
  company: {
    name: string
    id: number
    status: CompanyStatus
  }
  relatedCampaign?: {
    referenceCode: string
  }
  status: JobStatus
  isExpired: boolean
}

const BottomRowItems: FC<BottomRowItemsProps> = ({
  isJHCampaign,
  isSSCampaign,
  relatedCampaign,
  referenceCode,
  company,
  status,
  isExpired,
}) => {
  const isAdmin = useSelector(isAdminSelector)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)

  const isReferenceCodeVisible =
    status !== JobStatus.Draft && status !== JobStatus.Archived && isAdmin

  return (
    <>
      <ReferenceCodeWrapper>
        <ReferenceCode>
          {isReferenceCodeVisible && `REF${referenceCode}`}{' '}
        </ReferenceCode>
        {relatedCampaign && isReferenceCodeVisible && (
          <ReferenceCode>{`REF${relatedCampaign.referenceCode}`}</ReferenceCode>
        )}
      </ReferenceCodeWrapper>

      {(isAdmin || isExternalRecruiter) && (
        <CompanyBlock>
          <BankOutlined />
          <CompanyLink status={status}>{company.name}</CompanyLink>
          {company.status === CompanyStatus.NotVerified && (
            <WarningTooltip title="Company unverified" />
          )}
        </CompanyBlock>
      )}

      <BottomRowActionsBlock>
        <JobCampaign
          isExpired={isExpired}
          isJHCampaign={isJHCampaign}
          isSSCampaign={isSSCampaign}
        />
      </BottomRowActionsBlock>
    </>
  )
}

export default BottomRowItems
