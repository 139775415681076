import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { ROUTES } from '../../../routes'
import Layout from 'layouts/Main/Main'
import { getToken } from 'services/localStorage'

import { RootState, UsersRole } from 'types'

interface IPrivateRoute {
  path: string
  component: any
  permissions: any
  rest?: any
}

const PrivateRoute: FC<IPrivateRoute> = ({
  path,
  component: Component,
  permissions,
  rest,
}) => {
  const token = getToken()
  const viewer = useSelector((state: RootState) => state.viewer)
  const viewerRole = viewer.viewer?.role

  const isAllowedRole = permissions.includes(viewerRole)

  if (!token) {
    return <Redirect to={ROUTES.SIGN_IN} />
  }

  if (isAllowedRole) {
    return (
      <Route {...rest} path={path}>
        <Layout>
          <Component />
        </Layout>
      </Route>
    )
  }

  if (viewerRole === UsersRole.JHSuperAdmin) {
    return (
      <Route {...rest} path={path}>
        <Layout>
          <h1>Please register as a user</h1>
        </Layout>
      </Route>
    )
  }

  return (
    <Route {...rest} path={path}>
      <Layout>
        <h1>Access Denied, You don’t have permission to access</h1>
      </Layout>
    </Route>
  )
}

export default PrivateRoute
