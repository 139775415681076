import React, { FC } from 'react'

import { approveCompanyPromiseCreator } from 'store/companyToApprove'

import { comingSoon } from 'helpers/jobHelpers'

import DeleteIcon from 'assets/icons/Delete'
import SuccessIcon from 'assets/icons/Success'
import useActionsRoutines from 'hooks/useActionsRoutines'

import Space from 'antd/lib/space'

import { LinkOutlined } from '@ant-design/icons'

import { ActionButton, ActionsContainer } from './styles'

interface ICompanyActions {
  companyId: number
}

const CompanyActions: FC<ICompanyActions> = ({ companyId }) => {
  const approveCompany = useActionsRoutines(approveCompanyPromiseCreator)
  const handleApproveCompany = () => approveCompany({ id: companyId })

  return (
    <ActionsContainer>
      <Space>
        <ActionButton
          type="link"
          color="#8C8C8C"
          icon={<LinkOutlined />}
          onClick={comingSoon}
        >
          Generate Confirmation link
        </ActionButton>
        <ActionButton
          color="#FF4D4F"
          type="link"
          icon={<DeleteIcon color="#FF4D4F" />}
          onClick={comingSoon}
        >
          Reject
        </ActionButton>
        <ActionButton
          type="link"
          icon={<SuccessIcon color="#08979C" />}
          onClick={handleApproveCompany}
          color="#08979C"
        >
          Approve
        </ActionButton>
      </Space>
    </ActionsContainer>
  )
}

export default CompanyActions
