import React, { FC } from 'react'
import { connectHits } from 'react-instantsearch-dom'

import JobItem from '../../JobItem/JobItem'

import Empty from 'antd/lib/empty'

import { JobSubTitle } from './styles'

interface ICustomHits {
  hits: any[]
}

const CustomHits: FC<ICustomHits> = ({ hits }) => {
  return (
    <>
      {hits.length ? (
        hits.map((hit, idx, array) => {
          const { statusFilter, statusFilterExternal } = hit

          const currentStatus = statusFilter || statusFilterExternal
          const nextStatus =
            array[idx - 1]?.statusFilter || array[idx - 1]?.statusFilterExternal

          return (
            <div key={hit.objectID}>
              {currentStatus !== nextStatus && (
                <JobSubTitle>{currentStatus} jobs</JobSubTitle>
              )}
              <JobItem {...hit} />
            </div>
          )
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  )
}

const Hits = connectHits(CustomHits)

export default Hits
