import styled from 'styled-components'

import Button from 'antd/lib/button'

export const InputWrapper = styled.div`
  position: relative;
`
export const ButtonIncrease = styled(Button)`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`

export const ButtonDecrease = styled(ButtonIncrease)`
  left: 5px;
  right: unset;
`
