import React, { FC } from 'react'
import { connectPagination } from 'react-instantsearch-dom'

import Pagination from 'antd/lib/pagination'

import { Container } from './styles'

interface ICustomPagination {
  pageSize: number
  setPageSize: (value: number) => void
  currentRefinement: number
  nbPages: number
  refine: (value: any) => void
  scrollToTop?: boolean
  showSizeChanger?: boolean
}

const CustomPagination: FC<ICustomPagination> = ({
  pageSize = 10,
  setPageSize,
  currentRefinement,
  nbPages,
  refine,
  scrollToTop = true,
  showSizeChanger = true,
}) => {
  const handleChangePagination = (page: number) => {
    refine(page)
    if (scrollToTop) window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const total = nbPages * pageSize
  return (
    <Container>
      <Pagination
        total={total}
        hideOnSinglePage
        current={currentRefinement}
        onChange={handleChangePagination}
        onShowSizeChange={(current, size) => {
          setPageSize(size)
          refine(size)
        }}
        pageSizeOptions={['10', '20', '50']}
        showSizeChanger={showSizeChanger}
      />
    </Container>
  )
}

const AlgoliaPagination = connectPagination(CustomPagination)

export default AlgoliaPagination
