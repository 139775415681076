import * as Yup from 'yup'

import { email, firstName, lastName, password } from './baseSchema'

const signUpSchemaWithOutCompany = Yup.object().shape({
  firstName,
  lastName,
  email,
  password,
})

export default signUpSchemaWithOutCompany
