import styled from 'styled-components/macro'

import Menu from 'antd/lib/menu'

export const MenuContainer = styled(Menu)`
  border: none;
  & .ant-menu-title-content {
    width: 100%;
  }
  @media (min-width: 576px) {
    padding: 10px 0;
    &.ant-menu-root.ant-menu-vertical {
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
      border-radius: 8px;
    }
  }
`

export const MenuItem = styled(Menu.Item)`
  width: 100%;
  svg + span {
    margin-left: 8px;
  }

  &.ant-menu-item.ant-menu-item-only-child {
    padding: 0;
    margin: 0;
    min-height: 50px;
  }
  & .ant-btn:hover {
    background: transparent;
  }
  &.ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0;
    height: auto;
  }
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray3};
  }
  & .ant-menu-vertical > .ant-menu-item {
    height: auto;
  }
  .ant-btn {
    border-radius: 0;
    text-align: left;
    width: 100%;
    padding: 15px 30px 15px 15px;
    min-height: 50px;
    height: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.gray9};

    a {
      margin-left: 8px;
      &:hover {
        color: ${({ theme }) => theme.colors.gray9};
      }
    }

    svg {
      display: flex;
    }

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }
`
