import styled from 'styled-components/macro'

export const JobActionsItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .ant-btn {
    margin: 5px;
    width: 100%;
  }

  .ant-btn-primary {
    width: 100%;
    min-width: inherit;
  }

  @media (min-width: 768px) {
    width: inherit;

    .ant-btn-primary {
      min-width: inherit;
      width: auto;
    }
  }
`

export const JobActionsApproveItem = styled(JobActionsItem)`
  flex-wrap: wrap;
  @media (min-width: 576px) {
    flex-wrap: nowrap;
  }
`
