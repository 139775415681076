import { call, put, select } from 'redux-saga/effects'

import { fetchRejectedCandidatesCount } from 'store/jobs'
import {
  ensureFetchJob,
  ensureFetchRejectedCandidatesCount,
} from 'store/jobs/workers'

import {
  getCVFormat,
  simplifyCandidateData,
} from '../../helpers/candidateHelpers'

import algolia from '../../services/algolia'
import { ensureRefreshAlgoliaCache } from '../app/workers'
import { ensureFetchStages } from '../stages/workers'
import { isAdminSelector, isExternalRecruiterSelector } from '../viewer'
import {
  addCandidate,
  addCandidates,
  deleteCandidate,
  downloadCv,
  fetchCandidate,
  fetchCandidateAssociatedMembers,
  fetchCandidateCountByFilters,
  fetchCandidateCountByKeyword,
  fetchCandidateNotes,
  fetchCandidateTimeline,
  forwardCandidates,
  forwardCandidatesCSV,
  listCandidates,
  parseCandidateByER,
  postCandidateNote,
  updateCandidate,
  updateCandidatesStage,
  updateCandidatesStatus,
} from './actions'
import { AxiosResponse } from 'axios'
import Api from 'services/api'

import {
  ApiAddCandidates,
  ApiDeleteCandidate,
  ApiDownloadCV,
  ApiUpdateCandidatesStatus,
  CandidateSource,
  CandidateStatus,
  IApiFetchCandidateAssociatedMembers,
  IApiForwardCandidates,
  IApiPostCandidateNote,
  RootState,
} from '../../types'
import {
  ApiListCandidates,
  IApiCandidate,
  ICandidate,
} from './../../types/candidates'

export function* ensureFetchCandidate({
  payload,
}: {
  type: typeof fetchCandidate.TRIGGER
  payload: { candidateId: string }
}) {
  try {
    const { candidateId } = payload

    const candidate: AxiosResponse = yield call(Api.candidates.getCandidate, {
      candidateId,
    })

    yield call(ensureFetchStages, {
      type: '',
      payload: { companyId: candidate.data.company.id },
    })

    yield put(fetchCandidate.success({ candidate: candidate.data }))
  } catch (err) {
    yield put(fetchCandidate.failure())
  }
}

export function* ensureListCandidates({
  payload,
}: {
  type: typeof listCandidates.TRIGGER
  payload: ApiListCandidates
}) {
  try {
    const response: AxiosResponse = yield call(
      Api.candidates.listCandidates,
      payload,
    )

    // Simplify candidate data
    const items = response.data.items.map((value: IApiCandidate) =>
      simplifyCandidateData(value),
    )

    yield put(listCandidates.success({ ...response.data, items }))
  } catch (err) {
    yield put(listCandidates.failure())
  }
}

export function* ensureFetchCandidateCountByKeyword({
  payload,
}: {
  type: typeof fetchCandidateCountByKeyword.TRIGGER
  payload: ApiListCandidates
}) {
  try {
    const response: AxiosResponse = yield call(
      Api.candidates.fetchCandidateCount,
      payload,
    )

    yield put(fetchCandidateCountByKeyword.success(response.data))
  } catch (err) {
    yield put(fetchCandidateCountByKeyword.failure())
  }
}

export function* ensureFetchCandidateCountByFilters({
  payload,
}: {
  type: typeof fetchCandidateCountByFilters.TRIGGER
  payload: ApiListCandidates
}) {
  try {
    const response: AxiosResponse = yield call(
      Api.candidates.fetchCandidateCount,
      payload,
    )

    yield put(fetchCandidateCountByFilters.success(response.data))
  } catch (err) {
    yield put(fetchCandidateCountByFilters.failure())
  }
}

export function* ensureAddCandidate({
  payload,
}: {
  type: typeof addCandidate.TRIGGER
  payload: any
}) {
  try {
    const isAdmin = yield select(isAdminSelector)
    const isExternalRecruiter = yield select(isExternalRecruiterSelector)

    const { jobId, newCandidate, currentCv } = payload
    const candidateFormData = new FormData()
    candidateFormData.append('advertId', String(jobId))
    candidateFormData.append('firstName', newCandidate.firstName)

    if (newCandidate.lastName) {
      candidateFormData.append('lastName', newCandidate.lastName)
    }
    if (newCandidate.email) {
      candidateFormData.append('email', newCandidate.email)
    }
    if (newCandidate.note) {
      candidateFormData.append('note', newCandidate.note)
    }
    if (newCandidate.phoneNumber) {
      candidateFormData.append('phoneNumber', newCandidate.phoneNumber)
    }

    if (isExternalRecruiter) {
      candidateFormData.append('cv', currentCv)
    }

    if (isAdmin) {
      candidateFormData.append('source', newCandidate.source!)

      newCandidate.source === CandidateSource.Sourced &&
        candidateFormData.append('userId', newCandidate.userId!)
    }

    const candidate = yield call(Api.candidates.addCandidate, candidateFormData)
    yield put(addCandidate.success({ candidate: candidate.data }))
    const isCandidateAutoRejected =
      candidate.data.status === CandidateStatus.AutoRejected

    if (isCandidateAutoRejected) {
      const { fetchJob } = yield select((state: RootState) => state.jobs)
      if (fetchJob?.job?.id) {
        yield call(ensureFetchRejectedCandidatesCount, {
          type: typeof fetchRejectedCandidatesCount.TRIGGER,
          payload: { advertId: fetchJob.job.id },
        })
      }
    }
    yield call(ensureRefreshAlgoliaCache)
  } catch (err) {
    yield put(addCandidate.failure(err?.response?.data?.errors?.errors))
  }
}

export function* ensureUpdateCandidate({
  payload,
}: {
  type: typeof updateCandidate.TRIGGER
  payload: any
}) {
  try {
    const isAdmin = select(isAdminSelector)

    const { candidateId, newCandidate, currentCv } = payload

    const candidateFormData = new FormData()
    candidateFormData.append('id', String(candidateId))
    candidateFormData.append('firstName', newCandidate.firstName)

    if (newCandidate.lastName) {
      candidateFormData.append('lastName', newCandidate.lastName)
    }
    if (newCandidate.email) {
      candidateFormData.append('email', newCandidate.email)
    }

    if (newCandidate.phoneNumber) {
      candidateFormData.append('phoneNumber', newCandidate.phoneNumber)
    }

    if (currentCv) {
      candidateFormData.append('cv', currentCv)
    }

    if (isAdmin) {
      candidateFormData.append('source', newCandidate.source!)

      newCandidate.source === CandidateSource.Sourced &&
        candidateFormData.append('userId', newCandidate.userId!)
    }

    const candidate = yield call(
      Api.candidates.updateCandidate,
      candidateFormData,
    )
    yield put(updateCandidate.success({ candidate: candidate.data }))
    yield call(ensureRefreshAlgoliaCache)
  } catch (err) {
    yield put(updateCandidate.failure(err?.response?.data?.errors?.errors))
  }
}

export function* ensureAddCandidates({
  payload,
}: {
  type: typeof addCandidates.TRIGGER
  payload: ApiAddCandidates
}) {
  try {
    const newCandidates = yield call(Api.candidates.addCandidates, payload)
    yield put(addCandidates.success({ candidate: newCandidates.data }))
    yield call(ensureRefreshAlgoliaCache)

    const autoRejectedCandidates = newCandidates.data.filter(
      (candidate: ICandidate) =>
        candidate.status === CandidateStatus.AutoRejected,
    )

    if (!!autoRejectedCandidates.length) {
      const { fetchJob } = yield select((state: RootState) => state.jobs)
      if (fetchJob?.job?.id) {
        yield call(ensureFetchRejectedCandidatesCount, {
          type: typeof fetchRejectedCandidatesCount.TRIGGER,
          payload: { advertId: fetchJob.job.id },
        })
      }
    }
  } catch (err) {
    yield put(addCandidates.failure(err?.response?.data?.errors))
  }
}

export function* ensureParseCandidateByER({
  payload,
}: {
  type: typeof parseCandidateByER.TRIGGER
  payload: any
}) {
  try {
    const newCandidate = yield call(Api.candidates.parseCandidateByER, payload)
    yield put(parseCandidateByER.success(newCandidate.data))
  } catch (err) {
    yield put(parseCandidateByER.failure(err?.response?.data?.errors?.errors))
  }
}

export function* ensureFetchCandidateAssociatedMembers({
  payload,
}: {
  type: typeof fetchCandidateAssociatedMembers.TRIGGER
  payload: {
    options: IApiFetchCandidateAssociatedMembers
    isExternalRecruiter: boolean
  }
}) {
  try {
    const { options, isExternalRecruiter } = payload

    const { hits } = yield algolia.getCandidateAssociatedMembers({
      options,
      isExternalRecruiter,
    })
    yield put(fetchCandidateAssociatedMembers.success(hits))
  } catch (err) {
    console.log(err)
  }
}

export function* ensureUpdateCandidatesStatus({
  payload,
}: {
  type: typeof updateCandidatesStatus.TRIGGER
  payload: ApiUpdateCandidatesStatus
}) {
  try {
    const newStatus = yield call(Api.candidates.updateCandidatesStatus, payload)

    yield put(updateCandidatesStatus.success(newStatus.data[0]))

    yield call(ensureRefreshAlgoliaCache)
    const { candidate } = yield select(
      (state: RootState) => state.candidates.fetchCandidate,
    )
    const { fetchJob } = yield select((state: RootState) => state.jobs)

    if (fetchJob?.job?.id) {
      yield call(ensureFetchRejectedCandidatesCount, {
        type: typeof fetchRejectedCandidatesCount.TRIGGER,
        payload: { advertId: fetchJob.job.id },
      })
    }
    if (candidate) {
      yield call(ensureFetchCandidateTimeline, {
        type: typeof fetchCandidateTimeline.TRIGGER,
        payload: { pageNumber: 0 },
      })
    }

    // manual sync algolia
    yield call(Api.algoliaSync.candidates.updateCandidatesStatus, payload)
  } catch (err) {
    yield put(updateCandidatesStatus.failure())
  }
}

export function* ensureUpdateCandidatesStage({
  payload,
}: {
  type: typeof updateCandidatesStage.TRIGGER
  payload: any
}) {
  try {
    const { ids, stageId } = payload

    const candidate = yield call(Api.candidates.updateCandidatesStage, {
      ids,
      stageId,
    })
    yield put(
      updateCandidatesStage.success({ stage: candidate.data[0].stage, ids }),
    )

    const candidateData = yield select(
      (state: RootState) => state.candidates.fetchCandidate.candidate,
    )
    if (candidateData) {
      yield call(ensureFetchCandidateTimeline, {
        type: typeof fetchCandidateTimeline.TRIGGER,
        payload: { pageNumber: 0 },
      })
    }
    yield call(ensureRefreshAlgoliaCache)

    // manual sync algolia
    yield call(Api.algoliaSync.candidates.updateCandidatesStage, payload)
  } catch (err) {
    yield put(updateCandidatesStage.failure())
  }
}

export function* ensurePostCandidateNote({
  payload,
}: {
  type: typeof postCandidateNote.TRIGGER
  payload: IApiPostCandidateNote
}) {
  try {
    const { id, text, users } = payload
    yield call(Api.candidates.postCandidateNote, { id, text, users })
    yield put(postCandidateNote.success())
    yield call(ensureFetchCandidateNotes, {
      type: typeof fetchCandidateNotes.TRIGGER,
      payload: { pageNumber: 0 },
    })
  } catch (err) {
    yield put(postCandidateNote.failure())
  }
}

export function* ensureFetchCandidateTimeline({
  payload,
}: {
  type: typeof fetchCandidateTimeline.TRIGGER
  payload: { pageNumber: number }
}) {
  try {
    const { pageNumber } = payload

    const { id: candidateId } = yield select(
      (state: RootState) => state.candidates.fetchCandidate.candidate,
    )

    const response: AxiosResponse = yield call(
      Api.candidates.fetchCandidateTimeline,
      { candidateId, pageNumber },
    )

    yield put(fetchCandidateTimeline.success(response.data))
  } catch (err) {
    console.log(err)
    yield put(fetchCandidateTimeline.failure())
  }
}

export function* ensureFetchCandidateNotes({
  payload,
}: {
  type: typeof fetchCandidateNotes.TRIGGER
  payload: { pageNumber: number }
}) {
  try {
    const { pageNumber } = payload
    const { id: candidateId } = yield select(
      (state: RootState) => state.candidates.fetchCandidate.candidate,
    )
    const response: AxiosResponse = yield call(
      Api.candidates.fetchCandidateNotes,
      { candidateId, pageNumber },
    )
    yield put(fetchCandidateNotes.success(response.data))
  } catch (err) {
    yield put(fetchCandidateNotes.failure())
  }
}

export function* ensureDeleteCandidate({
  payload,
}: {
  type: typeof deleteCandidate.TRIGGER
  payload: ApiDeleteCandidate
}) {
  try {
    yield call(Api.candidates.deleteCandidate, payload)
    yield put(deleteCandidate.success())

    const { fetchJob } = yield select((state: RootState) => state.jobs)

    if (fetchJob?.job?.id) {
      yield call(ensureFetchRejectedCandidatesCount, {
        type: typeof fetchRejectedCandidatesCount.TRIGGER,
        payload: { advertId: fetchJob.job.id },
      })
    }

    yield call(ensureRefreshAlgoliaCache)
    const { id: jobId } = yield select(
      (state: RootState) => state.jobs.fetchJob.job,
    )
    yield call(ensureFetchJob, {
      type: typeof fetchJob.TRIGGER,
      payload: { jobId },
    })
  } catch (err) {
    yield put(deleteCandidate.failure())
  }
}

export function* ensureDownloadCv({
  payload,
}: {
  type: typeof downloadCv.TRIGGER
  payload: ApiDownloadCV
}) {
  try {
    const { fullName } = payload
    const response = yield call(Api.candidates.downloadCv, payload)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    const cvFormat = getCVFormat(response.data.type)
    link.setAttribute('download', `${fullName}${cvFormat}`)
    document.body.appendChild(link)
    link.click()
    link.remove()
    yield put(downloadCv.success())
  } catch (err) {
    yield put(downloadCv.failure())
  }
}

export function* ensureForwardCandidates({
  payload,
}: {
  type: typeof forwardCandidates.TRIGGER
  payload: IApiForwardCandidates
}) {
  try {
    yield call(Api.candidates.forwardCandidates, payload)
    yield put(forwardCandidates.success())
  } catch (err) {
    yield put(forwardCandidates.failure())
  }
}

export function* ensureForwardCandidatesCSV({
  payload,
}: {
  type: typeof forwardCandidatesCSV.TRIGGER
  payload: IApiForwardCandidates
}) {
  try {
    yield call(Api.candidates.forwardCandidatesCSV, payload)
    yield put(forwardCandidatesCSV.success())
  } catch (err) {
    yield put(forwardCandidatesCSV.failure())
  }
}
