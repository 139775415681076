import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: -30px;
  }
`

export const MenuContainer = styled.div`
  .ant-menu-item-group:first-child {
    border-bottom: 1px solid #f0f0f0;
    margin: 10px 0;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #e6f7ff;
    font-weight: 600;
  }

  .ant-menu-item-group-title {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray9};
    padding-left: 24px;
  }
`

export const Wrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    padding: 24px;
    width: calc(100% - 225px);
  }
`
export const BuyCreditsButton = styled.div`
  margin: 20px 0 0 20px;
`
