import React, { useCallback, useMemo, useState } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import { getRefreshUserSelector } from 'store/users'
import {
  getViewerSelector,
  isAdminOrCompanyAdminSelector,
  isCompanyAdminSelector,
  isRecruitmentManagerSelector,
} from 'store/viewer'

import ExternalRecruitersList from '../../../components/ExternalRecruiters/ExternalRecruitersList/ExternalRecruitersList'
import AccessLevelInfo from '../../../components/UI/AccessLevelInfo/AccessLevelInfo'
import AlgoliaPagination from '../../../components/UI/AlgoliaPagination/AlgoliaPagination'
import WarningTooltip from '../../../components/UI/WarningTooltip/WarningTooltip'
import CustomHits from '../../Admin/ManageUsers/HiringTeam/Hits'
import SettingsAction from '../SettingsActions/SettingsActions'
import { columns } from './columns'
import algoliasearch from 'algoliasearch/lite'
import CustomAlgoliaLoaderWrapper from 'app/components/Loaders/AlgoliaLoaderWrapper/AlgoliaLoaderWrapper'
import { clone } from 'lodash'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Tabs from 'antd/lib/tabs'

import { Container, HiringTeamTab } from './styles'

import { UserStatus } from 'types'

const { TabPane } = Tabs

const AccountMembers = () => {
  const screens = useBreakpoint()
  const viewer = useSelector(getViewerSelector)
  const [pageSize, setPageSize] = useState(10)
  const [isExistNotVerifiedUsers, setExistNotVerifiedUsers] = useState(false)
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const isAdminOrCompanyAdmin = useSelector(isAdminOrCompanyAdminSelector)
  const isRecruitmentManager = useSelector(isRecruitmentManagerSelector)
  const refreshAlgoliaCache = useSelector(getRefreshUserSelector)
  const [hiringTeamCount, setHiringTeamCount] = useState(0)
  const [ExternalRecruitersCount, setExternalRecruitersCount] = useState(0)

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  const isShowExternalRecruitersTabs = isCompanyAdmin || isRecruitmentManager

  const settingColumns = useMemo(() => {
    let newColumns = clone(columns)

    if (!isAdminOrCompanyAdmin && !isRecruitmentManager) {
      newColumns = newColumns.filter(ea => ea.dataIndex !== 'credits')
    }

    return newColumns
  }, [columns, isAdminOrCompanyAdmin, isRecruitmentManager])

  const TabRightContent = {
    right: <SettingsAction />,
  }

  const handleCheckIfExistNotVerifiedUsers = useCallback(
    ({ hits, nbHits }: any) => {
      setHiringTeamCount(nbHits)
      const isNotVerifiedUsersExist = hits.some(
        (hit: any) => hit.status === UserStatus.NotVerified,
      )
      setExistNotVerifiedUsers(isNotVerifiedUsersExist)
    },
    [isExistNotVerifiedUsers],
  )

  const handleCheckCountExternalRecruiters = useCallback(
    ({ nbHits }: { nbHits: number }) => {
      setExternalRecruitersCount(nbHits)
    },
    [],
  )

  return (
    <InstantSearch
      searchClient={searchClient}
      refresh={refreshAlgoliaCache}
      indexName={viewer.algoliaKeys.users.listOfUsers}
    >
      <Container>
        <h1>Account Members</h1>

        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={screens.lg && TabRightContent}
        >
          <TabPane
            tab={
              <HiringTeamTab>
                {isExistNotVerifiedUsers && (
                  <WarningTooltip title="You have users to verify" />
                )}
                Hiring Team {hiringTeamCount > 0 && `(${hiringTeamCount})`}
              </HiringTeamTab>
            }
            key="1"
          >
            <AccessLevelInfo />

            <Index indexName={viewer.algoliaKeys.users.listOfUsers}>
              <Configure hitsPerPage={pageSize} />
              <CustomAlgoliaLoaderWrapper
                callback={handleCheckIfExistNotVerifiedUsers}
              >
                <CustomHits columns={settingColumns} />
              </CustomAlgoliaLoaderWrapper>
              <AlgoliaPagination
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </Index>
          </TabPane>
          {isShowExternalRecruitersTabs && (
            <TabPane
              forceRender
              tab={
                <>
                  External Recruiters
                  {ExternalRecruitersCount > 0 &&
                    ` (${ExternalRecruitersCount})`}
                </>
              }
              key="2"
            >
              <ExternalRecruitersList
                refresh={refreshAlgoliaCache}
                filters=""
                callback={handleCheckCountExternalRecruiters}
              />
            </TabPane>
          )}
        </Tabs>
      </Container>
    </InstantSearch>
  )
}

export default AccountMembers
