import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Tag from 'antd/lib/tag'

import { CandidateStatus } from './../../../../types/candidates'

type TableWrapperProps = {
  $toolbarOffset: number
}

export const MobileCandidateLink = styled(Link)`
  display: block;
  background: ${({ theme, $candidateStatus }) =>
    $candidateStatus === CandidateStatus.New
      ? theme.colors.white
      : theme.colors.gray0};
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray6};
  font-weight: ${({
    $candidateStatus,
  }: {
    $candidateStatus: CandidateStatus
  }) => ($candidateStatus === CandidateStatus.New ? '600' : '400')};
  color: ${({ theme }) => theme.colors.gray9};
  cursor: pointer;
  .ant-tag {
    margin-left: auto;
  }
`
export const MobileSecondRow = styled(Row)`
  margin-top: 10px;
  & .ant-col .ant-tag {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray8};
    background: ${({ theme }) => theme.colors.gray4};
    border: 1px solid ${({ theme }) => theme.colors.gray5};
  }
`

export const TableWrapper = styled.div`
  position: relative;

  &.has-selection {
    padding-bottom: ${({ $toolbarOffset }: TableWrapperProps) =>
      $toolbarOffset}px;
  }

  .ant-table-body {
    min-height: 57px;
    overflow-y: auto !important;
  }
`

export const ColItem = styled(Col)`
  padding-right: 10px;
`

export const TagItem = styled(Tag)`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 1px);
`
