import styled from 'styled-components/macro'

export const FooterButtons = styled.div`
  display: flex;
  width: 100%;

  .ant-btn-dangerous {
    margin-left: auto;
  }
`
