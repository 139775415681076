import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import InviteCompanyMember from 'app/components/Jobs/JobHiringTeam/InviteCompanyMember'
import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'

import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Row from 'antd/lib/row'
import Table from 'antd/lib/table'

import { Container, UserItem } from './styles'

import { IAlgoliaUser, RootState } from 'types'

interface IHiringTeamCompanyMembersTable {
  hits: any
  columns: any
}

const HiringTeamCompanyMembersTable: FC<IHiringTeamCompanyMembersTable> = ({
  columns,
  hits,
}) => {
  const screens = useBreakpoint()
  const hiringTeam = useSelector((state: RootState) => state.hiringTeam)

  if (!hits.length) return null

  return (
    <>
      {!screens.lg ? (
        <>
          <Container>
            {hits.map((user: IAlgoliaUser) => {
              const { fullName, role, email } = user

              return (
                <UserItem>
                  <Row justify="space-between">
                    <Col xs={24} sm={12} md={6}>
                      {fullName}
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <AccessLevel role={role} />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      {email}
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                      <InviteCompanyMember {...user} />
                    </Col>
                  </Row>
                </UserItem>
              )
            })}
          </Container>
        </>
      ) : (
        <Table
          loading={hiringTeam.inviteCompanyMember.isLoading}
          columns={columns}
          dataSource={hits}
          pagination={false}
        />
      )}
    </>
  )
}

export default HiringTeamCompanyMembersTable
