import { CompanyStatus, IStage, JobCampaign, JobStatus } from 'types'

export enum CVForwardTypes {
  Daily = 'Daily',
  AsCVCome = 'As CVs come',
}

export enum CandidateStatus {
  New = 'New',
  Viewed = 'Viewed',
  Shortlisted = 'Shortlisted',
  Maybe = 'Maybe',
  Rejected = 'Rejected',
  AutoRejected = 'AutoRejected',
}

export enum CandidateStatusLabels {
  New = 'New',
  Viewed = 'Viewed',
  Shortlisted = 'Shortlisted',
  Maybe = 'Maybe',
  Rejected = 'Rejected',
  AutoRejected = 'Auto Rejected',
}

export enum CandidateStage {
  Sourced = 'Sourced',
  Applied = 'Applied',
  PhoneScreen = 'PhoneScreen',
  Offer = 'Offer',
  Hired = 'Hired',
  Rejected = 'Rejected',
}

export enum CandidateStageLabels {
  Sourced = 'Sourced',
  Applied = 'Applied',
  PhoneScreen = 'Phone Screen',
  Offer = 'Offer',
  Hired = 'Hired',
  Rejected = 'Rejected',
}

export enum CandidateSource {
  JobheronDb = 'JobheronDb',
  JobheronApp = 'JobheronApp',
  Sourced = 'Sourced',
  ExternalRecruiter = 'ExternalRecruiter',
}

export enum CandidateSourceLabels {
  JobheronDb = 'JH CV search',
  JobheronApp = 'Jobheron Application',
  Sourced = 'Sourced',
  ExternalRecruiter = 'External Recruiter',
}

export enum CandidateStatusSource {
  Jobheron = 'Jobheron',
  SelfService = 'SelfService',
  Sourced = 'Sourced',
}

export enum CandidateSortCriteria {
  ID = 'id',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  APPLIED = 'applied',
}

export interface IAlgoliaCandidate {
  advertId: number
  applied: string
  companyId: number
  email: string
  firstName: string
  lastName: string
  key: string
  objectID: string
  hasCv: boolean
  source: CandidateSource
  stage: CandidateStage
  status: CandidateStatus
}

export interface ICandidateSimplified extends IAlgoliaCandidate {
  stageName: string
}
export interface ICandidateList {
  items: ICandidateSimplified[]
  first: boolean
  last: boolean
  pageNumber: number
  numberOfElements: number
  size: number
  sorted?: string
  sortCriteria?: string
  filterCriteria?: string
  totalElements: number
  totalPages: number
}

export interface ICandidateCount {
  totalElements: number
  statusCount: { key: string; count: number }[]
  stageCount: { key: string; count: number }[]
  sourceCount: { key: string; count: number }[]
}

export enum CandidateTabs {
  CV = 'CV',
  Details = 'Details',
  Summary = 'Summary',
  Notes = 'Notes',
  Timeline = 'Timeline',
}

export enum TimelineTagStatus {
  New = 'New',
  Viewed = 'Viewed',
  Shortlisted = 'Shortlisted',
  Maybe = 'Maybe',
  Rejected = 'Rejected',
}

export enum TimelineType {
  Status = 'Status',
  Stage = 'Stage',
  Uploaded = 'Uploaded',
}

export interface ICandidateStatus {
  title: string
  value: CandidateStatus
}

export interface ICandidateSource {
  title: string
  value: CandidateSource
}

export type ICandidateStage = Pick<IStage, 'name' | 'type' | 'order'>

export interface ICandidateNoteItems {
  id: number
  text: string
  createdAt: Date
  user: {
    id: string
    fullName: string
    email: string
    company: string
    companyStatus: CompanyStatus
  }
}
export interface ICandidateNote {
  items: ICandidateNoteItems[]
  first: boolean
  last: boolean
  pageNumber: number
  numberOfElements: number
  size: number
  sorted?: string
  sortCriteria?: string
  filterCriteria?: string
  totalElements: number
  totalPages: number
}
export interface ICandidateTimelineItems {
  id: number
  createdAt: Date
  type: TimelineType
  oldValue: string
  newValue: string

  user: {
    id: string
    fullName: string
    email: string
    isExternalRecruiter: boolean
    company: string
    companyStatus: CompanyStatus
  }
}
export interface ICandidateTimeline {
  items: ICandidateTimelineItems[]
  first: boolean
  last: boolean
  pageNumber: number
  numberOfElements: number
  size: number
  sorted?: string
  sortCriteria?: string
  filterCriteria?: string
  totalElements: number
  totalPages: number
}

export interface ICandidateAdvert {
  id: string
  type: JobCampaign
  status: JobStatus
  title: string
  isFeature: boolean
  stage: CandidateStatus
}

export interface CandidateSourcedBy {
  id: string
  company: string
  fullName: string
  isExternalRecruiter: boolean
  email: string
}

export interface ICandidate {
  id: number
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  tags: [string]
  cv?: string
  source: CandidateSource
  status: CandidateStatus
  stage: IStage
  applied: string
  campaignTitle: string
  advert: ICandidateAdvert
  campaigns: ICandidateAdvert[]
  previousCandidate: number | null
  nextCandidate: number | null
  sequencesNumber: number
  total: number
  noteExist: boolean
  appliedFromLm: boolean
  sourcedBy: CandidateSourcedBy
  company: {
    id: number
    name: string
    status: string
  }
}
export interface IApiCandidate {
  id: number
  cv: string
  source: CandidateSource
  status: CandidateStatus
  stage: {
    id: number
    name: string
    type: CandidateStage
    order: number
    isEditable: boolean
    hasCandidates: boolean
  }
  applied: string
  campaignTitle: string
  campaigns: null
  sourcedBy: {
    id: string
    fullName: string
    company: string
    isExternalRecruiter: boolean
  }
  total: number
  sequencesNumber: number
  nextCandidate: null
  previousCandidate: null
  advert: {
    id: number
    type: string
    status: string
    title: string
    isFeature: boolean
    stage: null
  }
  company: {
    id: number
    name: string
    status: string
  }
  noteExist: boolean
  appliedFromLm: boolean
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

export interface ApiAddCandidate {
  firstName: string
  lastName?: string
  email?: string
  phoneNumber?: string
  jobId: number
  cv: File
  userId?: string
  source?: CandidateSource
  note: string
}

export interface ApiAddCandidates {
  jobId: number
  userId?: string
  cvs: File[]
  source?: CandidateSource
}

export interface ApiUpdateCandidatesStatus {
  candidatesId: string[]
  status: CandidateStatus
  jobId: string
}

export interface ApiUpdateCandidatesStage {
  ids: string[]
  stageId: string
}

export interface ApiDeleteCandidate {
  id: number
}

export interface ApiListCandidates {
  companyId?: number
  advertId?: number
  candidateStatuses?: string[]
  candidateStageTypes?: string[]
  candidateSources?: string[]
  pageNumber?: number
  pageSize?: number
  searchString?: string
}

export interface ApiDownloadCV {
  id: number
  fullName: string
}

export interface ICandidateStore {
  fetchCandidate: {
    isLoading: boolean
    candidate?: ICandidate
  }
  candidateList: IAlgoliaCandidate[]
  listCandidates: {
    isLoading: boolean
    data: ICandidateList
  }
  fetchCandidateCountByKeyword: {
    isLoading: boolean
    data: ICandidateCount
  }
  fetchCandidateCountByFilters: {
    isLoading: boolean
    data: ICandidateCount
  }
  addCandidate: { isLoading: boolean }
  addCandidates: { isLoading: boolean }
  updateCandidate: { isLoading: boolean }
  updateCandidatesStatus: { isLoading: boolean }
  updateCandidatesStage: { isLoading: boolean }
  postCandidateNote: { isLoading: boolean }
  fetchCandidateTimeline: { isLoading: boolean }
  fetchCandidateNotes: { isLoading: boolean }
  candidateNotes: ICandidateNote
  candidateTimeline: ICandidateTimeline
  deleteCandidate: { isLoading: boolean }
  forwardCandidates: { isLoading: boolean }
}

export interface IApiPostCandidateNote {
  id: number
  text: string
  users?: string[]
}

export interface IApiFetchCandidateAssociatedMembers {
  options: { filters: string; hitsPerPage: number }
}

export interface IApiFetchCandidateTimeline {
  candidateId: string
  pageNumber: number
}

export interface IApiFetchCandidateNotes {
  candidateId: string
  pageNumber: number
}

export interface IApiForwardCandidates {
  ids: string[]
  emailList: string[]
  comment?: string
}
