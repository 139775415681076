import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  getCheckoutInfoPromiseCreator,
  purchaseCreditsPromiseCreator,
} from 'store/credits'

import { useQuery } from 'helpers'

import woman from './assets/woman.png'
import { campaignTypes } from './constants'
import { loadStripe } from '@stripe/stripe-js'
import CampaignBundle from 'app/components/Checkout/CampaignBundle/CampaignBundle'
import CardItem from 'app/components/Checkout/CardItem/CardItem'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import Loader from 'app/components/UI/Loader/Loader'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { groupBy } from 'lodash'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import {
  BoldText,
  BundleAd,
  BundleAdContent,
  BundleAdContentSubTitle,
  BundleAdContentTitle,
  ColItem,
  GrayText,
  RowCards,
  RowHeader,
  SalesBox,
  VatItem,
} from './styles'

import { CampaignBundles, ICampaignCreditType, RootState } from 'types'

const Checkout = () => {
  const query = useQuery()
  const history = useHistory()
  const screens = useBreakpoint()
  const redirect = query.get('redirectURL')
  const advertId = query.get('advertId')
  const [loading, setLoading] = useState<number | null>(null)

  const currentUrl = window.location.origin

  const encodeUrl = redirect && encodeURI(redirect)

  const redirectToAdvert = advertId
    ? `${currentUrl}${ROUTES.JOB}/${advertId}/preview?status=approved`
    : encodeUrl

  const successUrl = redirect ? redirectToAdvert : `${currentUrl}${ROUTES.JOBS}`

  const cancelUrl = redirect ? encodeUrl : `${currentUrl}${ROUTES.CHECKOUT}`

  const purchaseCredits = useActionsRoutines(purchaseCreditsPromiseCreator)
  const fetchCreditsInfo = useActionsRoutines(getCheckoutInfoPromiseCreator)

  const { fetchCheckoutInfo, isLoading } = useSelector(
    (state: RootState) => state.credits.getCheckoutInfo,
  )

  useEffect(() => {
    if (!fetchCheckoutInfo) {
      fetchCreditsInfo()
    }
  }, [])

  if (isLoading) {
    return <Loader />
  }

  const handleBuyCredits = async (packId: number, credits: number) => {
    try {
      setLoading(packId)
      const { data } = await purchaseCredits({
        packId,
        credits,
        advertId,
        successUrl,
        cancelUrl,
      })
      const { sessionId, publicKey } = data
      if (sessionId) {
        const stripe = await loadStripe(publicKey)
        stripe!.redirectToCheckout({ sessionId })
      }
    } catch (err) {
    } finally {
      setLoading(null)
    }
  }

  const campaignTypesFromServer = fetchCheckoutInfo?.filter(
    (campaign: ICampaignCreditType) => campaignTypes.includes(campaign.name),
  )

  const campaignBundles = fetchCheckoutInfo?.filter(
    (bundle: ICampaignCreditType) => bundle.description?.includes('Bundles'),
  )
  const groupedCampaignBundles = groupBy(campaignBundles, 'description')

  const bundleArray = Object.keys(groupedCampaignBundles).map(key => ({
    title: key,
    types: groupedCampaignBundles[key],
  }))

  const handleRedirect = () => {
    if (redirect) {
      const domain = window.location.origin
      history.push(redirect.replace(domain, ''))
    } else {
      history.push(ROUTES.JOBS)
    }
  }

  return (
    <>
      <RowHeader>
        <Col xs={24} md={10}>
          <BackButton title="Buy Jobheron campaigns" onBack={handleRedirect} />
        </Col>
        <ColItem xs={24} md={14}>
          <SalesBox>
            Looking to mix and match a bundle?{' '}
            <Button type="primary">
              <a
                target="_blank"
                rel="noreferrer"
                href={ROUTES.JOBHERON_CONTACT_US}
              >
                Contact sales
              </a>
            </Button>
          </SalesBox>
        </ColItem>
      </RowHeader>

      <RowCards gutter={16}>
        {campaignTypesFromServer &&
          campaignTypesFromServer.map(campaign => (
            <Col key={campaign.id} span={24} md={{ span: 8 }} sm={{ span: 12 }}>
              <CardItem
                {...campaign}
                handleBuyCredits={handleBuyCredits}
                loading={loading}
              />
            </Col>
          ))}
      </RowCards>
      <VatItem>Prices are exclusive of VAT</VatItem>
      <BundleAd>
        <BundleAdContent>
          <BundleAdContentTitle>
            <GrayText> Save more {!screens.sm && 'with'} </GrayText>
            <BoldText> {screens.sm && 'with'} Jobheron Bundles</BoldText>{' '}
          </BundleAdContentTitle>

          <BundleAdContentSubTitle>
            You have 24 months to use any credits before they expire.{' '}
            <Button type="link">
              <a
                target="_blank"
                rel="noreferrer"
                href={`${ROUTES.JOBHERON_HELP_CENTER}/article/how-do-i-purchase-a-job-credit`}
              >
                Learn more
              </a>
            </Button>
          </BundleAdContentSubTitle>
        </BundleAdContent>
        {screens.md && <img src={woman} alt="woman" width={152} height={160} />}
      </BundleAd>
      <div>
        {bundleArray.map((bundle: CampaignBundles) => (
          <CampaignBundle
            key={bundle.title}
            {...bundle}
            handleBuyCredits={handleBuyCredits}
            loading={loading}
          />
        ))}
      </div>
    </>
  )
}

export default Checkout
