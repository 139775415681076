import * as Yup from 'yup'

const ForwardCVSchema = Yup.object({
  emailList: Yup.array()
    .min(1, 'Provide email address in order to send CV')
    .required('Provide email address in order to send CV'),
  comment: Yup.string(),
})

export default ForwardCVSchema
