import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { isExternalRecruiterOrTeamMemberSelector } from '../../../../store/viewer'

import { useQuery } from 'helpers'

import AddCandidate from '../../../modules/Jobs/DetailJob/AddCandidate/AddCandidate'
import Search from '../../UI/Search/Search'
import CloneJob from '../CloneJob/CloneJob'
import CandidateFilterMenu from './CandidateFilterMenu/CandidateFilterMenu'
import JobSettingsMenu from './JobSettingMenu/JobSettingsMenu'

import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import Dropdown from 'antd/lib/dropdown'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { CloseOutlined, EllipsisOutlined } from '@ant-design/icons'

import {
  ButtonItem,
  JobSettingsWrapper,
  MobileDrawerHeader,
  SearchBlock,
} from './styles'

import { IJob, JobDetailTab, JobStatus } from 'types'

interface IJobSettings {
  isCanInviteToJob: boolean
  job: IJob
  onSearchSubmit: (value: string) => void
  onSearchTyping: (value: string) => void
  search: string
}

const JobSettings: FC<IJobSettings> = ({
  isCanInviteToJob,
  job,
  onSearchSubmit,
  onSearchTyping,
  search,
}) => {
  const screens = useBreakpoint()
  const query = useQuery()
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
  const [isDropDownVisible, setDropDownVisible] = useState(false)
  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )
  const activeTab = query.get('tab')

  const handleCloseDropDown = useCallback(() => {
    setDropDownVisible(false)
  }, [isDropDownVisible])

  const handleOpenMobileMenu = useCallback(() => {
    setIsOpenMobileMenu(true)
  }, [isOpenMobileMenu])

  const handleCloseMobileMenu = useCallback(() => {
    setIsOpenMobileMenu(false)
  }, [isOpenMobileMenu])

  const isCandidateTab = activeTab === JobDetailTab.Candidates

  return (
    <JobSettingsWrapper>
      {isCandidateTab && (
        <SearchBlock>
          <Search
            search={search}
            onSearchTyping={onSearchTyping}
            onSearchSubmit={onSearchSubmit}
            placeholder="Search candidates..."
          />
        </SearchBlock>
      )}
      {screens.sm && job.status === JobStatus.Archived && (
        <CloneJob
          title="Copy job"
          id={job.id.toString()}
          campaignType={job.campaignType}
          buttonType="primary"
        />
      )}
      {screens.sm && job.status !== JobStatus.Archived && (
        <AddCandidate
          jobId={String(job.id)}
          companyId={String(job.companyId)}
        />
      )}

      {!isExternalRecruiterOrTeamMember && (
        <>
          {!screens.md && isCandidateTab && <CandidateFilterMenu />}
          {!screens.xs ? (
            <Dropdown
              overlay={
                <JobSettingsMenu
                  job={job}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                  handleCloseDropDown={handleCloseDropDown}
                  isCanInviteToJob={isCanInviteToJob}
                />
              }
              trigger={['click']}
              placement="bottomRight"
              visible={isDropDownVisible}
              onVisibleChange={visible => setDropDownVisible(visible)}
            >
              <ButtonItem icon={<EllipsisOutlined />} />
            </Dropdown>
          ) : (
            <>
              <ButtonItem
                icon={<EllipsisOutlined />}
                onClick={handleOpenMobileMenu}
              />
              <Drawer
                key="bottom"
                placement="bottom"
                closable={false}
                height="auto"
                onClose={handleCloseMobileMenu}
                visible={isOpenMobileMenu}
              >
                <MobileDrawerHeader>
                  <h3>{job.title}</h3>
                  <Button
                    onClick={e => {
                      e.preventDefault()
                      setIsOpenMobileMenu(false)
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </MobileDrawerHeader>
                <JobSettingsMenu
                  job={job}
                  handleCloseMobileMenu={handleCloseMobileMenu}
                  handleCloseDropDown={handleCloseDropDown}
                  isCanInviteToJob={isCanInviteToJob}
                />
              </Drawer>
            </>
          )}
        </>
      )}
    </JobSettingsWrapper>
  )
}

export default JobSettings
