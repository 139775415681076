import React, { FC } from 'react'

import linkedIn from './assets/linkedin.svg'

import { FullWidthButton } from '../../modules/Auth/SignUp/styles'

interface ISignInWithLinkedIn {
  title: string
  onClick: () => void
}

const SignInWithLinkedIn: FC<ISignInWithLinkedIn> = ({ title, onClick }) => {
  return (
    <FullWidthButton onClick={onClick}>
      <img src={linkedIn} alt={title} />
      {title}
    </FullWidthButton>
  )
}

export default SignInWithLinkedIn
