import React, { FC } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import Table from 'antd/lib/table'

import { RootState } from 'types'

interface IHits {
  hits: any[]
  columns: any
}

const Hits: FC<IHits> = ({ hits, columns }) => {
  const { isLoading } = useSelector(
    (state: RootState) => state.users.fetchCompanyUsers,
  )
  return (
    <Table
      dataSource={hits}
      columns={columns}
      pagination={false}
      loading={isLoading}
    />
  )
}

const CustomHits = connectHits(Hits)

export default CustomHits
