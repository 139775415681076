import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { isAdminSelector } from 'store/viewer'

import FormItem from 'app/components/Forms/FormItem/FormItem'
import { campaigns } from 'constants/app'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import InputNumber from 'antd/lib/input-number'
import Row from 'antd/lib/row'

import { Actions, GroupContainer, Title } from './styles'

import { IUser, UserStatus, UsersRole } from 'types'

interface ICredits {
  user: IUser
}

const UpdateUserCredits: FC<ICredits> = ({ user }) => {
  const isJHAdmin = useSelector(isAdminSelector)

  const { advertiser, managed, hire } = user.balance

  if (
    user.role === UsersRole.ExternalRecruiter ||
    user.role === UsersRole.TeamMember ||
    user.status !== UserStatus.Verified
  )
    return null

  return (
    <GroupContainer>
      <Title>Credits</Title>
      <Form>
        <Row gutter={24}>
          <Col xs={24} md={4}>
            <FormItem
              name="advertiser"
              label={`${campaigns.advertiser} Campaign`}
              component={InputNumber}
              defaultValue={String(advertiser)}
              componentProps={{ min: 0, max: 999, disabled: !isJHAdmin }}
            />
          </Col>
          <Col xs={24} md={4}>
            <FormItem
              name="managed"
              label={`${campaigns.managed} Campaign`}
              component={InputNumber}
              defaultValue={String(managed)}
              componentProps={{ min: 0, max: 999, disabled: !isJHAdmin }}
            />
          </Col>
          <Col xs={24} md={4}>
            <FormItem
              name="hire"
              label={`${campaigns.hire} Campaign`}
              component={InputNumber}
              defaultValue={String(hire)}
              componentProps={{ min: 0, max: 999, disabled: !isJHAdmin }}
            />
          </Col>
          {!isJHAdmin && (
            <Col xs={24} md={4}>
              <Actions>
                <Button type="primary">
                  <Link to={ROUTES.SETTING_ASSIGN_CREDITS}>Assign Credits</Link>
                </Button>
              </Actions>
            </Col>
          )}
        </Row>
      </Form>
    </GroupContainer>
  )
}

export default UpdateUserCredits
