import { firstLetterToLowerCase } from './jobHelpers'
import { isNil, omitBy } from 'lodash'

import { IError } from 'types'

interface setFormErrorsProps {
  setError: any
  errors: IError[]
  defaultItem: string
}

export const setFormErrors = ({
  setError,
  errors,
  defaultItem,
}: setFormErrorsProps) => {
  if (!errors) {
    setError(defaultItem, {
      message: 'An error ocurred. Please try again later.',
    })
    return
  }

  errors.forEach(({ item, message }) => {
    if (item) {
      const name = firstLetterToLowerCase(item)

      setError(name, { message })
    } else {
      setError(defaultItem, {
        message,
      })
    }
  })
}

// Parse Query String data with repeating keys
// Current behavior:  http://example.com/?foo[]=5&foo[]=2&foo[]=11
// Expected behavior: http://example.com/?foo=5&foo=2&foo=11
export const parseQsDuplicateKeys = (params: any) => {
  const simplifyParams = omitBy(params, isNil)

  const data = Object.entries(simplifyParams)
  const qs = new URLSearchParams()

  for (let query of data) {
    const key = query[0]
    const value = query[1]
    if (Array.isArray(value)) {
      for (let val of value) {
        qs.append(key, String(val))
      }
      continue
    }
    qs.append(key, String(value))
  }

  return qs
}
