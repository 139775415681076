import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import Close from 'assets/icons/Close'
import Success from 'assets/icons/Success'
import { emailRegex } from 'validation/baseSchema'

import Button from 'antd/lib/button'
import Input from 'antd/lib/input'

import { PlusOutlined } from '@ant-design/icons'

import {
  AddNewEmail,
  Container,
  EmailTag,
  InputContainer,
  InputContainerActions,
  InputWithPlaceholder,
} from './styles'

const AddMultipleItems = (props: any) => {
  const { name } = props

  const {
    clearErrors,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()

  const addEmailInput = useRef<Input>(null)

  const [inputValue, setInputValue] = useState('')
  const [isPlaceholderVisible, setPlaceholderVisible] = useState(true)

  const emails = getValues(name) || []
  const [isInputVisible, setIsInputVisible] = useState(false)
  const showInput = () => {
    setIsInputVisible(true)
  }

  const handleInputClose = (e: any) => {
    clearErrors(name)
    setPlaceholderVisible(true)
    setInputValue('')
    setIsInputVisible(false)
  }

  useEffect(() => {
    isInputVisible && addEmailInput.current?.focus()
  }, [isInputVisible])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInputValue(value.trim())
  }

  const handleInputConfirm = () => {
    let newEmails = emails
    if (!emailRegex.test(inputValue)) {
      addEmailInput.current?.focus()
      setError(props.name, {
        message: 'Please provide correct email address',
      })
      return
    } else {
      clearErrors(props.name)
    }

    if (inputValue.trim().length && emails.indexOf(inputValue) === -1) {
      newEmails = [...emails, inputValue.trim()]
    }
    setValue(name, newEmails)
    setIsInputVisible(false)
    setInputValue('')
    props.onChange(newEmails)
  }

  const handleClose = (email: string) => {
    const newEmails = emails.filter((item: any) => item !== email)
    setValue(name, newEmails)
    props.onChange(newEmails)
  }

  return (
    <Container>
      {emails.map((email: string) => {
        return (
          <EmailTag key={email} closable={false}>
            <span>{email}</span>{' '}
            <Button
              type="text"
              onClick={() => {
                setPlaceholderVisible(true)
                handleClose(email)
              }}
            >
              <Close color="#8C8C8C" />
            </Button>
          </EmailTag>
        )
      })}

      {isPlaceholderVisible && !emails.length ? (
        <InputWithPlaceholder
          placeholder={props.placeholder}
          onClick={() => {
            setPlaceholderVisible(false)
            setIsInputVisible(true)
          }}
        />
      ) : (
        <>
          {isInputVisible && (
            <InputContainer>
              <Input
                type="text"
                ref={addEmailInput}
                value={inputValue}
                onChange={handleInputChange}
                addonAfter={
                  <InputContainerActions>
                    <Button
                      type="text"
                      onClick={handleInputClose}
                      icon={<Close />}
                    />
                    <Button
                      type="text"
                      onClick={handleInputConfirm}
                      disabled={!!errors.name}
                      icon={<Success color="#13C2C2" />}
                    />
                  </InputContainerActions>
                }
              />
            </InputContainer>
          )}
          {!isInputVisible && (
            <AddNewEmail onClick={showInput} color="blue">
              <PlusOutlined /> Add another
            </AddNewEmail>
          )}
        </>
      )}
    </Container>
  )
}

export default AddMultipleItems
