import React, { FC, useState } from 'react'

import { promoteJobPromiseCreator } from 'store/jobs'

import useActionsRoutines from 'hooks/useActionsRoutines'

import Button from 'antd/lib/button'

interface IPromoteJob {
  title: string
  jobId: number
  type?: 'text' | 'link' | 'ghost' | 'default' | 'primary' | 'dashed'
  icon: React.ReactNode
}

const JobAccessMultiposter: FC<IPromoteJob> = ({
  title,
  type,
  jobId,
  icon,
}) => {
  const promoteJob = useActionsRoutines(promoteJobPromiseCreator)
  const [loading, setLoading] = useState(false)
  const handlePromoteJob = () => {
    setLoading(true)
    promoteJob({ id: jobId })
      .then(({ redirectUrl }) => {
        window.open(redirectUrl, '_blank')
      })
      .finally(() => setLoading(false))
  }

  return (
    <Button
      type={type}
      onClick={handlePromoteJob}
      icon={icon}
      loading={loading}
    >
      {title}
    </Button>
  )
}

export default JobAccessMultiposter
