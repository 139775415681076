import styled from 'styled-components'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

export const RowHeader = styled(Row)`
  margin-bottom: 10px;
`
export const SalesBox = styled.div`
  background: #e6f7ff;
  border-radius: 8px;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  max-height: unset;
  text-align: center;
  .ant-btn {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    max-height: 56px;
    margin-top: 0;
    display: block;
    width: auto;
    padding: 8px 8px 8px 24px;
    font-weight: 600;

    .ant-btn {
      margin-left: 16px;
      margin-top: 0px;
    }
  }
`

export const ColItem = styled(Col)`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
`

export const RowCards = styled(Row)`
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 24px;
`
export const VatItem = styled.div`
  font-size: 14px;
  line-height: 22px;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 24px;
  text-align: center;
  padding: 8px;
`
export const BundleAd = styled.div`
  background: #2688fc;
  border-radius: 8px;
  position: relative;
  margin-bottom: 24px;
  img {
    position: absolute;
    right: 30px;
    bottom: 0;
  }
  @media (min-width: 1024px) {
    img {
      position: absolute;
      right: 110px;
      bottom: 0;
    }
  }
`
export const BundleAdContent = styled.div`
  padding: 24px;
  color: #bfd5ef;
  text-align: center;
  @media (min-width: 576px) {
    text-align: initial;
  }
`

export const BundleAdContentTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`

export const BundleAdContentSubTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  .ant-btn {
    color: #ffd591;
    margin: 0;
    padding: 0;
    height: auto;
    span {
      text-decoration: underline;
    }
  }
`
export const BoldText = styled.span`
  color: #fff;
  display: block;
  margin-bottom: 16px;
  @media (min-width: 576px) {
    display: inline;
    margin-bottom: 0;
  }
`
export const GrayText = styled.span`
  display: block;

  @media (min-width: 576px) {
    display: inline;
  }
`
