import styled from 'styled-components'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Space from 'antd/lib/space'

export interface GroupContainerProps {
  isShowBorder?: boolean
}

export const RowHeader = styled(Row)`
  margin-bottom: 52px;
`

export const ColItem = styled(Col)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`

export const Warning = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 16px;
  margin-bottom: 32px;
  background: #fff2e8;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.orange6};
`

export const Actions = styled(Space)`
  padding-top: 28px;
`

export const GroupContainer = styled.div<GroupContainerProps>`
  border-top: 1px solid ${({ theme }) => theme.colors.gray5};
  padding: 24px 0;
  margin-top: 8px;

  &:first-child {
    border-top: none;
  }

  .ant-input-number-handler-wrap {
    opacity: 1;
  }

  .ant-input-number {
    width: 100%;
  }
`

export const CompanyActions = styled(Space)`
  display: flex;
  justify-content: flex-end;
`

export const ActionsTitle = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CompanyTable = styled.div`
  .ant-table {
    .ant-table-cell:last-child {
      text-align: right;
    }
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 12px;
  }
`
