export const defaultPageSize = 50

export const referralEmail = 'sales@jobheron.co.uk'
export const referralCode = 'REFER150'

export const API_PAGE_START = 0
export const APP_PAGE_START = 1

// list of all the campaigns used to display the campaign name in the UI
export const campaigns = {
  advertiser: 'Advertiser +',
  managed: 'Branded +',
  hire: 'Hire Pro',
}
