import React, { FC } from 'react'

import CreditsMenu from './CreditsMenu'

import Dropdown from 'antd/lib/dropdown'

const CreditsDropdown: FC = ({ children }) => {
  return (
    <Dropdown
      overlay={CreditsMenu}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={item => item}
    >
      {children}
    </Dropdown>
  )
}

export default CreditsDropdown
