import * as Yup from 'yup'

import { credit, firstName, lastName, phoneNumber } from './baseSchema'

const updateUserSchema = Yup.object().shape({
  firstName,
  lastName,
  phoneNumber,
  role: Yup.string().required('Please select user role below.'),
  advertiser: credit,
  managed: credit,
  hire: credit,
})

export default updateUserSchema
