import React, { FC } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import ComingSoonImage from './assets/ComingSoonImage'
import JHCampaignImage from './assets/JHCampaignImage'
import SSCampaignGrayImage from './assets/SSCampaignGrayImage'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { InfoCircleOutlined } from '@ant-design/icons'

import {
  CampaignBlock,
  CampaignBlockText,
  CampaignBlockTitle,
  CampaignHeader,
  CampaignItem,
  CampaignItemImageContainer,
  CampaignItemList,
  CampaignItemTitle,
  CampaignItemTitleGray,
  CampaignRow,
  CampaignSubtitle,
  ComingSoonContainer,
} from './styles'

import { CreateJobTab, JobCampaign } from 'types'

export const CREATE_JOB_URL = `${ROUTES.JOB_CREATE}?tab=${CreateJobTab.SelectCampaign}`
export const CREATE_JHCAMPAIGN_JOB_URL = `${ROUTES.JOB_CREATE}?campaignType=${JobCampaign.Jobheron}&tab=${CreateJobTab.JobDetails}`

interface ISelectCampaignType {
  setActiveTab: (value: CreateJobTab) => void
}

const SelectCampaignType: FC<ISelectCampaignType> = ({ setActiveTab }) => {
  const history = useHistory()
  const { jobId } = useParams<{ jobId: string }>()

  const handleSelectCampaign = () => {
    const JHCampaignLink = jobId
      ? `${ROUTES.JOB}/${jobId}/preview`
      : CREATE_JHCAMPAIGN_JOB_URL

    setActiveTab(CreateJobTab.JobDetails)
    history.push(JHCampaignLink)
  }

  return (
    <>
      <CampaignBlock>
        <CampaignHeader>
          <CampaignBlockTitle>Select Campaign Type</CampaignBlockTitle>
          <CampaignBlockText>
            You can select the other Campaign type later if you want to.
          </CampaignBlockText>
        </CampaignHeader>

        <CampaignRow>
          <CampaignItem onClick={handleSelectCampaign}>
            <CampaignItemImageContainer>
              <JHCampaignImage />
            </CampaignItemImageContainer>
            <CampaignItemTitle>Jobheron Campaign</CampaignItemTitle>
            <CampaignSubtitle>Our premium advertising service</CampaignSubtitle>
            <CampaignItemList>
              <li> Boost your hiring power</li>
              <li> Post to the top UK job boards</li>
              <li>Job ad written and optimised </li>
              <li>Access advanced hiring tools</li>
            </CampaignItemList>
            <Button type="primary">Select</Button>
            <Button type="link" onClick={e => e.stopPropagation()}>
              <a
                target="_blank"
                rel="noreferrer"
                href={ROUTES.JOBHERON_HELP_CENTER}
              >
                Learn more
              </a>
            </Button>
          </CampaignItem>

          <CampaignItem>
            <CampaignItemImageContainer>
              <SSCampaignGrayImage />
            </CampaignItemImageContainer>
            <ComingSoonContainer>
              <ComingSoonImage />
            </ComingSoonContainer>
            <CampaignItemTitleGray>Self Service Campaign</CampaignItemTitleGray>
            {/* <CampaignSubtitleGray>
              £69 per job per month <InfoCircleOutlined />
            </CampaignSubtitleGray> */}
            <CampaignItemList>
              <li>10 free job boards </li>
              <li>
                Integrate with 400+ job boards <InfoCircleOutlined />
              </li>
              <li>Invite your team & set access levels</li>
              <li>Custom hiring stages</li>
            </CampaignItemList>
            <Button type="primary" disabled>
              Claim your free trial
            </Button>
            <Button type="link" disabled>
              <Link to={'/'}>Learn more</Link>
            </Button>
          </CampaignItem>
        </CampaignRow>
      </CampaignBlock>
    </>
  )
}

export default SelectCampaignType
