import * as Yup from 'yup'

import { companyName, companyWebsite, phoneNumber } from './baseSchema'

const SignUpCompanySchema = Yup.object().shape({
  companyName: companyName.required('Please enter company name'),
  companyWebsite: companyWebsite.required('Please enter correct url'),
  phoneNumber,
})

export default SignUpCompanySchema
