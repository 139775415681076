import styled from 'styled-components/macro'

export const ActionButtons = styled.div`
  display: none;

  .ant-btn {
    margin-left: 8px;
    font-weight: 600;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
`
