import styled from 'styled-components'

import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'

type ButtonProps = {
  $isActive: boolean
  $isShowAllActive?: boolean
  $isDefaultColored?: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  animation: 0.15s ease-in-out 0s 1 bounce;

  .ant-space {
    margin-left: auto;
  }

  @media (min-width: 1260px) {
    position: absolute;
    left: -1px;
    width: calc(100% + 1px);
  }
`

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActionButton = styled(Button)`
  &.ant-btn {
    border: 1px solid #d9d9d9;
    background: #f8f8f8;
    color: #bfbfbf;
  }
  &:hover,
  &:focus {
    border-color: unset;
  }
`

export const SuccessButton = styled(ActionButton)`
  ${({ $isDefaultColored, $isShowAllActive, $isActive }: ButtonProps) => {
    if ($isShowAllActive) {
      return ` &.ant-btn{
          border: 1px solid #d9d9d9;
          background: #e6fffb;
          color: #2f979c;`
    } else if ($isActive) {
      return ` &.ant-btn{
          border: 1px solid #13C2C2;
          background: #13C2C2;
          color: #ffffff;`
    } else if ($isDefaultColored) {
      return ` &.ant-btn{
          background: #E6FFFB;
          color: #13C2C2;`
    }
  }}
  &.ant-btn {
    @media (min-width: 576px) {
      &:hover {
        border: 1px solid #d9d9d9;
        background: #e6fffb;
        color: #2f979c;
      }
    }
  }
`

export const MaybeButton = styled(ActionButton)`
  ${({ $isDefaultColored, $isShowAllActive, $isActive }: ButtonProps) => {
    if ($isShowAllActive) {
      return ` &.ant-btn{
          border: 1px solid #d9d9d9;
          background: #fffbe6;
          color: #FF773E;`
    } else if ($isActive) {
      return ` &.ant-btn{
          border: 1px solid #FFA940;
          background: #FFA940;
          color: #ffffff;`
    } else if ($isDefaultColored) {
      return ` &.ant-btn{
          background: #FFFBE6;
          color: #FFA940;`
    }
  }}
  &.ant-btn {
    @media (min-width: 576px) {
      &:hover {
        border: 1px solid #d9d9d9;
        background: #fffbe6;
        color: ${({ theme }) => theme.colors.orange6};
      }
    }
  }
`

export const RejectButton = styled(ActionButton)`
  ${({ $isDefaultColored, $isShowAllActive, $isActive }: ButtonProps) => {
    if ($isShowAllActive) {
      return ` &.ant-btn{
          border: 1px solid #d9d9d9;
          background: #fff1f0;
          color: #f5222d;`
    } else if ($isActive) {
      return ` &.ant-btn{
          border: 1px solid #FF7875;
          background: #FF7875;
          color: #ffffff;`
    } else if ($isDefaultColored) {
      return ` &.ant-btn{
          background: #FFF1F0;
          color: #FF7875;`
    }
  }}

  &.ant-btn {
    @media (min-width: 576px) {
      &:hover {
        border: 1px solid #d9d9d9;
        background: #fff1f0;
        color: #f5222d;
      }
    }
  }
`
export const LeftContent = styled.div`
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  .ant-btn {
    font-size: 12px;
    line-height: 20px;
  }
`
export const CandidateNumber = styled.span`
  font-weight: 600;
  margin-right: 3px;
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid #28a3fb;
  background: #ffffff;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 10px;
`

export const ForwardMenu = styled(Menu)`
  .ant-menu-item {
    height: auto;
    min-width: auto;
    min-height: auto;
    padding: 0;
    color: #262626;
    margin: 0;
    line-height: initial;
  }
`

export const ItemMenu = styled(Menu.Item)`
  &.ant-menu-item {
    padding: 0 16px 0 12px;
  }
  &.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
    padding: 14px 12px;
  }
  svg {
    fill: currentColor;
  }
  & .ant-dropdown-menu-title-content {
    display: flex;
  }
`
