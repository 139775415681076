import { createAction } from '@reduxjs/toolkit'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const logout = createRoutine('auth/logout')

export const getViewerBalance = createRoutine('auth/getViewerBalance')
export const logoutPromiseCreator = promisifyRoutine(logout)

export const fetchViewer = createRoutine('auth/fetchViewer')
export const fetchViewerPromiseCreator = promisifyRoutine(fetchViewer)

export const completeAuth = createRoutine('auth/completeAuth')
export const completeAuthPromiseCreator = promisifyRoutine(completeAuth)

export const signUp = createRoutine('auth/signUp')
export const signUpPromiseCreator = promisifyRoutine(signUp)

export const signUpCompany = createRoutine('auth/signUpCompany')
export const signUpCompanyPromiseCreator = promisifyRoutine(signUpCompany)

export const resendSignUp = createRoutine('auth/resendSignUp')
export const resendSignUpPromiseCreator = promisifyRoutine(resendSignUp)

export const hideMessage = createAction('app/hideMessage')
export const showMessage = createAction<{ isShowMessage: boolean }>(
  'app/showMessage',
)

export const forgotPassword = createRoutine('auth/forgotPassword')
export const forgotPasswordPromiseCreator = promisifyRoutine(forgotPassword)

export const resetPassword = createRoutine('auth/resetPassword')
export const resetPasswordPromiseCreator = promisifyRoutine(resetPassword)
