import { UsersRole } from './user'

export interface IViewerCompany {
  companyName: string
  companyWebsite: string
  phoneNumber?: string
}

export interface IAddViewer extends IViewerCompany {
  firstName: string
  lastName: string
  email: string
  password: string
}

export interface IInviteMember {
  userId: string
  email: string
  role: UsersRole
  adverts: string[]
  companyId?: string
}

export enum AuthStatus {
  InvalidToken = 'invalid_token',
  ExpiredToken = 'expired_token',
  TokenAlreadyUsed = 'token_already_used',
  CheckYourEmail = 'check-your-email',
  NeedToBeVerified = 'need-to-be-verified',
  Success = 'success',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface IViewerState {
  signUp: {
    isLoading: boolean
    error: any
    isError: boolean
  }
  completeAuth: {
    isLoading: boolean
  }
  signUpCompany: {
    isLoading: boolean
  }
  fetchViewer: { isLoading: boolean; error: any; isError: boolean }
  isAuthenticated: boolean
  isLoaded: boolean
  viewer: any
  showMessage: boolean
  forgotPassword: { isLoading: boolean }
  resetPassword: { isLoading: boolean }
}

export interface IResetPassword {
  token: string
  password: string
  verifyPassword: string
}
