import React from 'react'

import BalanceColumn from './ManageUsersActions/BalanceColumn/BalanceColumn'
import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'
import Actions from 'app/components/ResponsiveTables/UserTable/Actions/Actions'
import Company from 'app/components/ResponsiveTables/UserTable/Company/Company'
import FullName from 'app/components/ResponsiveTables/UserTable/FullName/FullName'
import Status from 'app/components/ResponsiveTables/UserTable/Status/Status'

import {
  CompanyStatus,
  IAlgoliaUser,
  UserBalance,
  UserInvites,
  UserStatus,
  UsersRole,
} from 'types'

export const columns = [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    ellipsis: true,
    render: (_: any, user: IAlgoliaUser) => <FullName {...user} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 110,
    minWidth: 110,
    ellipsis: true,
    render: (_: any, { status }: { status: UserStatus }) => (
      <Status status={status} />
    ),
  },
  {
    title: 'Company',
    dataIndex: 'company',
    ellipsis: true,
    width: 160,
    minWidth: 160,
    render: (
      _: unknown,
      {
        objectID,
        company,
        companyStatus,
      }: { objectID: string; company: string; companyStatus: CompanyStatus },
    ) => (
      <Company id={objectID} company={company} companyStatus={companyStatus} />
    ),
  },
  {
    title: 'Access Level',
    dataIndex: 'accessLevel',
    width: 160,
    minWidth: 160,

    render: (
      _: unknown,
      {
        role,
        status,
        objectID,
        fullName,
      }: {
        role: UsersRole
        status: UserStatus
        objectID: string
        fullName: string
      },
    ) => (
      <AccessLevel
        role={role}
        status={status}
        userId={objectID}
        fullName={fullName}
      />
    ),
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    ellipsis: true,
    responsive: ['xl'] as any,
  },
  {
    title: 'Credits',
    dataIndex: 'credits',
    ellipsis: true,
    width: 100,
    minWidth: 100,

    render: (_: unknown, { balance }: { balance: UserBalance }) => (
      <BalanceColumn balance={balance} />
    ),
  },

  {
    title: 'Created',
    dataIndex: 'actions',
    width: 210,
    minWidth: 210,
    render: (_: any, user: IAlgoliaUser) => (
      <Actions user={user} inviteType={UserInvites.HiringTeam} />
    ),
  },
]

export const userBlockCell = {
  title: 'Blocking Date',
  dataIndex: 'actions',
  width: 220,
  minWidth: 220,
  render: (_: any, user: IAlgoliaUser) => (
    <Actions inviteType={UserInvites.HiringTeam} user={user} />
  ),
}
