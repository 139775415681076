import * as React from 'react'

function Signal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="#262626"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.521 8a5.247 5.247 0 01-1.227 3.392.123.123 0 01-.175.014l-.67-.581a.134.134 0 01-.014-.184c.598-.704.961-1.628.961-2.64a4.056 4.056 0 00-.963-2.64.134.134 0 01.015-.184l.67-.581a.124.124 0 01.175.012 5.248 5.248 0 011.228 3.393zm2.813 0a8.397 8.397 0 01-1.85 5.287.123.123 0 01-.177.016l-.67-.58a.131.131 0 01-.016-.182 7.222 7.222 0 001.588-4.54 7.23 7.23 0 00-1.588-4.542.131.131 0 01.016-.181l.67-.581a.123.123 0 01.176.016A8.405 8.405 0 0114.333 8zm-7.208-.746a.984.984 0 01.707-.303c.266 0 .52.11.708.303.187.194.293.457.293.73 0 .275-.106.538-.293.731a.984.984 0 01-.708.303.984.984 0 01-.707-.303 1.051 1.051 0 01-.293-.73c0-.274.106-.537.293-.731zm-2.98.745c0-1.298.463-2.483 1.227-3.39a.123.123 0 01.175-.015l.67.581c.053.047.06.13.014.184A4.063 4.063 0 005.27 8c0 1.013.362 1.936.963 2.64a.134.134 0 01-.015.184l-.67.582a.124.124 0 01-.175-.013 5.248 5.248 0 01-1.228-3.393zM1.333 8c0-2.012.696-3.854 1.85-5.286a.123.123 0 01.177-.016l.67.58c.053.046.06.127.016.182a7.222 7.222 0 00-1.588 4.54 7.23 7.23 0 001.588 4.542.131.131 0 01-.016.181l-.67.581a.123.123 0 01-.176-.016A8.405 8.405 0 011.333 8z"
      />
    </svg>
  )
}

export default Signal
