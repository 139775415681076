import { campaigns } from 'constants/app'

import { CreditType, UserStatus, UsersRole } from 'types'

export enum TransferCreditsFrom {
  fromAdvertiser = 'fromAdvertiser',
  fromHire = 'fromHire',
  fromManaged = 'fromManaged',
}

export const campaignCreditsTypes = [
  {
    title: campaigns.advertiser,
    value: CreditType.Advertiser,
    valueFrom: TransferCreditsFrom.fromAdvertiser,
  },
  {
    title: campaigns.managed,
    value: CreditType.Managed,
    valueFrom: TransferCreditsFrom.fromManaged,
  },
  {
    title: campaigns.hire,
    value: CreditType.Hire,
    valueFrom: TransferCreditsFrom.fromHire,
  },
]

export const ALGOLIA_FILTER = `status: ${UserStatus.Verified} AND(role: ${UsersRole.CompanyAdmin} OR role: ${UsersRole.HiringManager} OR role: ${UsersRole.RecruitmentManager}) `
