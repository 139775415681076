import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const purchaseCredits = createRoutine('credits/purchaseCredits')
export const purchaseCreditsPromiseCreator = promisifyRoutine(purchaseCredits)

export const getCheckoutInfo = createRoutine('credits/getCheckoutInfo')
export const getCheckoutInfoPromiseCreator = promisifyRoutine(getCheckoutInfo)

export const transferCredits = createRoutine('credits/transferCredits')
export const transferCreditsPromiseCreator = promisifyRoutine(transferCredits)
