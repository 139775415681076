/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import * as React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { ThemeProvider } from 'styled-components'

import { configureAppStore } from 'store/configureStore'

import auth from './services/auth'
import theme from './utils/theme'
// Import root app
import { App } from 'app'

const store = configureAppStore()
const MOUNT_NODE = document.getElementById('root') as HTMLElement

auth.init()

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ThemeProvider>
    </Router>
  </Provider>,
  MOUNT_NODE,
)

if (module.hot) {
  module.hot.accept()
}
