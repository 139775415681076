import styled from 'styled-components/macro'

import { Tag } from 'antd'

export const StatusTag = styled(Tag)`
  &.ant-tag {
    padding: 2px 10px;
    font-size: 13px;
    font-weight: 600;
    margin-right: 0;
  }

  &.ant-tag-gold {
    color: #faad14;
    background: #fffbe6;
    border-color: #ffe58f;
  }
`
