import styled from 'styled-components/macro'

import { Button } from 'antd'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
  color: ${props => props.color};
`

export const RoleBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RoleTitle = styled.div`
  white-space: wrap;
  @media (min-width: 576px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
