import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { createUserPromiseCreator, getRefreshUserSelector } from 'store/users'
import { getViewerSelector } from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import CustomHits from '../ManageUsers/HiringTeam/Hits'
import { columns } from './columns'
import algoliasearch from 'algoliasearch/lite'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import SearchCompaniesAutocomplete from 'app/components/Modals/InviteTeamMember/SearchCompaniesAutocomplete/SearchCompaniesAutocomplete'
import AlgoliaPagination from 'app/components/UI/AlgoliaPagination/AlgoliaPagination'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import { roleForCreateUser } from 'app/modules/Jobs/constants'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'
import createUserSchema from 'validation/createUserSchema'

import { RadioChangeEvent } from 'antd'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'

import {
  ColItem,
  GroupContainer,
  RadioGroup,
  RowHeader,
  Title,
  Warning,
} from './styles'

import {
  CompanyExistStatus,
  ICreateUser,
  ManageUsersTabs,
  RootState,
  UserStatus,
  UsersRole,
} from 'types'

const companyStatus = ['Existing', 'New']

const { Option } = Select

interface Inputs extends ICreateUser {
  verifyPassword: string
  companyStatus: CompanyExistStatus
}

const CreateUser = () => {
  const refreshAlgoliaCache = useSelector(getRefreshUserSelector)
  const viewer = useSelector(getViewerSelector)
  const { isLoading } = useSelector(
    (state: RootState) => state.users.createUser,
  )
  const createUser = useActionsRoutines(createUserPromiseCreator)
  const history = useHistory()

  const [selectedCompanyStatus, setSelectedCompanyStatus] = useState(
    CompanyExistStatus.Existing,
  )

  const methods = useForm({
    resolver: yupResolver(createUserSchema),
    mode: 'onSubmit',
  })
  const {
    formState: { errors },
    control,
    setValue,
    setError,
    watch,
  } = methods

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  const selectedCompanyId = watch('companyId')
  const currentCompanyStatus = watch('companyStatus')

  useEffect(() => {
    if (currentCompanyStatus === CompanyExistStatus.New) {
      setValue('role', UsersRole.CompanyAdmin)
    }
  }, [currentCompanyStatus])

  const handleChangeCompanyStatus = (e: RadioChangeEvent) => {
    setSelectedCompanyStatus(e.target.value)
  }

  const handleFormSubmit = (data: Inputs) => {
    const {
      verifyPassword,
      companyStatus,
      companyId: id,
      ...filteredData
    } = data

    const payload = {
      ...filteredData,
      companyId: id ? +id : undefined,
    }
    createUser(payload)
      .then(() =>
        history.push(
          `${ROUTES.ADMIN_MANAGE_USERS}?tab=${ManageUsersTabs.HiringTeam}`,
        ),
      )
      .catch(errors =>
        setFormErrors({
          setError,
          defaultItem: 'email',
          errors,
        }),
      )
  }

  const clearValues = () => {
    setValue('companyName', undefined)
    setValue('companyWebsite', undefined)
    setValue('companyId', undefined)
  }

  return (
    <div>
      <Helmet>
        <title>Create a user</title>
      </Helmet>
      <RowHeader>
        <Col span={12}>
          <BackButton title="Create user" />
        </Col>
        <ColItem span={12}>
          <Button type="default" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={methods.handleSubmit(handleFormSubmit)}
            loading={isLoading}
          >
            Save
          </Button>
        </ColItem>
      </RowHeader>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          onFinish={methods.handleSubmit(handleFormSubmit)}
        >
          <GroupContainer>
            <Title>User details</Title>
            <Row gutter={24} justify="start">
              <Col xs={24} md={6}>
                <FormItem
                  name="firstName"
                  label="First name"
                  component={Input}
                  defaultValue=""
                />
              </Col>

              <Col xs={24} md={6}>
                <FormItem
                  name="lastName"
                  label="Last name"
                  component={Input}
                  defaultValue=""
                />
              </Col>
              <Col xs={24} md={6}>
                <Form.Item
                  hasFeedback={!!errors.role}
                  validateStatus={errors.role ? 'error' : 'success'}
                  help={errors.role && errors.role?.message}
                  label="Role"
                >
                  <Controller
                    render={({ field }) => (
                      <Select
                        disabled={
                          currentCompanyStatus === CompanyExistStatus.New
                        }
                        {...field}
                        onChange={field.onChange}
                        defaultValue=""
                      >
                        {roleForCreateUser.map(({ value, title }) => (
                          <Option
                            key={value}
                            value={value}
                            hidden={value === UsersRole.ExternalRecruiter}
                          >
                            {title}
                          </Option>
                        ))}
                      </Select>
                    )}
                    control={control}
                    name="role"
                    defaultValue=""
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} justify="start">
              <Col xs={24} md={6}>
                <FormItem
                  name="email"
                  label="Email address"
                  component={Input}
                  defaultValue=""
                  componentProps={{ autoComplete: 'new-password' }}
                />
              </Col>
              <Col xs={24} md={6}>
                <FormItem
                  name="phoneNumber"
                  label="Phone number (optional)"
                  component={Input}
                />
              </Col>
            </Row>
          </GroupContainer>
          <GroupContainer>
            <Title>User password</Title>
            <Row gutter={24} justify="start">
              <Col xs={24} md={6}>
                <FormItem
                  name="password"
                  label="Password"
                  component={Input.Password}
                  componentProps={{ autoComplete: 'new-password' }}
                />
              </Col>
              <Col xs={24} md={6}>
                <FormItem
                  name="verifyPassword"
                  label="Re-type password"
                  component={Input.Password}
                />
              </Col>
            </Row>
          </GroupContainer>
          <Title>Company</Title>

          <Form.Item>
            <Controller
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    clearValues()
                    handleChangeCompanyStatus(e)
                  }}
                  value={selectedCompanyStatus}
                >
                  {companyStatus.map(status => (
                    <Radio value={status} key={status}>
                      {status}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
              defaultValue={CompanyExistStatus.Existing}
              name="companyStatus"
              control={control}
            />
          </Form.Item>

          {selectedCompanyStatus === CompanyExistStatus.Existing && (
            <Row>
              <Col xs={24} md={18}>
                <Form.Item
                  label="Select a company"
                  hasFeedback={!!errors.companyId}
                  validateStatus={
                    errors && errors.companyId ? 'error' : 'success'
                  }
                  help={errors?.companyId && errors.companyId?.message}
                >
                  <Controller
                    render={({ field }) => (
                      <SearchCompaniesAutocomplete
                        {...field}
                        selectedCompanyId={''}
                        selectedAdverts={[]}
                        placeholder="Type to search company"
                      />
                    )}
                    defaultValue=""
                    control={methods.control}
                    name="companyId"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedCompanyStatus === CompanyExistStatus.New && (
            <>
              <Row>
                <Col xs={24} md={12}>
                  <Warning>
                    Please check if company exists before adding new one.
                  </Warning>
                </Col>
              </Row>
              <Row gutter={24} justify="start">
                <Col xs={24} md={6}>
                  <FormItem
                    name="companyName"
                    label="Company name"
                    component={Input}
                    defaultValue=""
                  />
                </Col>
                <Col xs={24} md={6}>
                  <FormItem
                    name="companyWebsite"
                    label="Company website"
                    component={Input}
                    defaultValue=""
                  />
                </Col>
              </Row>
            </>
          )}
        </Form>
        {selectedCompanyStatus === CompanyExistStatus.Existing &&
          !!selectedCompanyId && (
            <div>
              <InstantSearch
                searchClient={searchClient}
                refresh={refreshAlgoliaCache}
                indexName={viewer.algoliaKeys.users.listOfUsers}
              >
                <Index indexName={viewer.algoliaKeys.users.listOfUsers}>
                  <Configure
                    filters={`companyId: ${selectedCompanyId} AND status: ${UserStatus.Verified}`}
                  />
                  <CustomHits columns={columns} />
                </Index>
                <AlgoliaPagination
                  pageSize="10"
                  setPageSize={() => {}}
                  scrollToTop={false}
                  showSizeChanger={false}
                />
              </InstantSearch>
            </div>
          )}
      </FormProvider>
    </div>
  )
}

export default CreateUser
