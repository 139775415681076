import React, { FC } from 'react'
import { useHistory } from 'react-router'

import DownloadCv from '../DownloadCV/DownloadCV'
import PreviewIcon from './assets/PreviewIcon'
import AddCVModal from 'app/components/Modals/AddCVModal/AddCVModal'
import EmptyBlock from 'app/components/UI/EmptyBlock/EmptyBlock'
import { ROUTES } from 'routes'

import { Button } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'

import {
  ButtonNext,
  ButtonPrev,
  Container,
  FrameItem,
  PreviewWrapper,
} from './styles'

import { JobStatus } from 'types'

interface CandidateSummaryProps {
  cv?: string
  fullName: string
  nextCandidate: number | null
  previousCandidate: number | null
  candidateId: string
  jobStatus: JobStatus
}

const CandidateSummary: FC<CandidateSummaryProps> = ({
  cv,
  fullName,
  nextCandidate,
  previousCandidate,
  candidateId,
  jobStatus,
}) => {
  const history = useHistory()
  const screens = useBreakpoint()

  // CVs uploaded from JH (doc, docx, pdf) will be returned as converted pdf as preview URL
  // (Actual file on Download CV will be in original filetype)
  // CVs retrieved from LM that are not supported is not viewable in our viewer
  // Hence, we show our own error message with Download button
  const renderCv = cv?.includes('.pdf') ? (
    <FrameItem
      src={`${process.env.REACT_APP_IFRAME_CV_URL}${encodeURIComponent(cv!)}`}
    />
  ) : (
    <PreviewWrapper>
      <EmptyBlock title="Preview Unavailable" icon={<PreviewIcon />}>
        <p>
          CV preview is not available.
          <br />
          Please click "Download CV" to view the CV.
        </p>
        <DownloadCv
          component={Button}
          componentProps={{ type: 'primary' }}
          id={Number(candidateId)}
          fullName={fullName}
          isHasCv={true}
        />
      </EmptyBlock>
    </PreviewWrapper>
  )

  return (
    <Container>
      {cv ? (
        renderCv
      ) : (
        <PreviewWrapper>
          <EmptyBlock title="No CV added" icon={<PreviewIcon />}>
            {jobStatus !== JobStatus.Archived && (
              <AddCVModal buttonTitle="Upload CV" candidateId={candidateId} />
            )}
          </EmptyBlock>
        </PreviewWrapper>
      )}

      {screens.xs && (
        <>
          {previousCandidate && (
            <ButtonPrev
              icon={<CaretLeftOutlined />}
              onClick={() =>
                history.push(`${ROUTES.CANDIDATE}/${previousCandidate}`)
              }
            />
          )}
          {nextCandidate && (
            <ButtonNext
              onClick={() =>
                history.push(`${ROUTES.CANDIDATE}/${nextCandidate}`)
              }
              icon={<CaretRightOutlined />}
            />
          )}
        </>
      )}
    </Container>
  )
}

export default CandidateSummary
