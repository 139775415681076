import * as React from 'react'

function NotVerified(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={134}
      height={163}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M133.25 73.766c0 15.063-5.02 28.927-13.342 40.124-4.624 5.998-10.172 11.33-16.513 15.462-10.303 6.799-22.589 10.798-35.798 10.798-36.328 0-65.654-29.726-65.654-66.25 0-36.658 29.458-66.25 65.654-66.25 13.21 0 25.495 3.999 35.798 10.664 6.341 4.132 11.889 9.464 16.513 15.462 8.454 11.064 13.342 24.928 13.342 39.99zM6.241 23.441a3.473 3.473 0 002.247-4.382c-.598-1.826-2.572-2.826-4.41-2.232a3.473 3.473 0 00-2.246 4.382c.597 1.826 2.571 2.826 4.41 2.232zM13.88 12.095a2.362 2.362 0 001.528-2.98 2.383 2.383 0 00-2.998-1.518 2.362 2.362 0 00-1.528 2.98 2.383 2.383 0 002.998 1.518zM120.605 9.89a1.8 1.8 0 00.116-2.555 1.828 1.828 0 00-2.571-.115 1.8 1.8 0 00-.116 2.555 1.828 1.828 0 002.571.115z"
        fill="#EAEEF9"
      />
      <path
        d="M15.228 125.966c.913-.83.977-2.237.142-3.145a2.25 2.25 0 00-3.164-.141 2.215 2.215 0 00-.142 3.144 2.25 2.25 0 003.164.142z"
        fill="#EAEEF9"
      />
      <g filter="url(#prefix__filter0_d_4689_125027)">
        <path
          d="M110.432 16.653v107.39c0 3.06-2.519 5.564-5.599 5.564H31.486c-3.08 0-5.6-2.504-5.6-5.564V16.653c0-3.06 2.52-5.564 5.6-5.564h73.347c3.08 0 5.599 2.504 5.599 5.564z"
          fill="#fff"
        />
      </g>
      <path
        d="M68.16 29.868c3.247 0 5.88-2.615 5.88-5.842 0-3.227-2.633-5.843-5.88-5.843s-5.879 2.616-5.879 5.843 2.632 5.842 5.88 5.842zM93.354 85.232c-.56.557-.98 1.113-1.54 1.53-6.298 5.843-14.697 9.32-24.075 9.32-7.42 0-14.418-2.225-20.157-6.26-1.68-1.112-3.22-2.503-4.62-3.894.7-.974 2.1-1.67 4.9-2.782l1.12-.418c2.1-.834 5.039-1.947 8.818-3.477.56-.279 1.12-.557 1.4-1.113.14-.278.42-.557.42-.835.28-.417.28-.974.28-1.53v-7.094c-.14-.14-.14-.279-.28-.418-.56-.973-.98-1.947-1.12-3.06l-.56-.278c-1.82.417-1.68-1.53-2.1-5.286-.14-1.53 0-1.809.7-2.087l.56-.834c-1.4-3.06-1.96-5.565-1.96-7.79 0-3.478 1.54-5.843 3.64-6.956-.98-2.225-1.12-3.199-1.12-3.199s7.42 1.252 9.939.696c3.22-.696 8.258.139 10.078 4.45.42.14.7.28 1.12.418 2.38 1.252 3.22 2.921 3.5 4.73.42 3.338-1.4 6.955-1.82 8.485 0 0 0 .14.14.14.56.278.7.695.42 2.086-.42 3.617-.28 5.703-2.1 5.286l-1.68 2.782c0 .278-.14.417-.14.696v7.929c0 .556.28.973.56 1.39l.42.418c.42.417.84.695 1.4.974 4.199 1.808 7.278 2.92 9.518 3.755 2.1.974 3.5 1.67 4.34 2.226z"
        fill="#EAEEF9"
      />
      <path
        d="M81.179 59.776c-.42 3.617-.28 5.703-2.1 5.286l-1.68 2.782c0 .278-.14.417-.14.696v7.929h-8.118V44.335h9.798c2.38 1.252 3.22 2.921 3.5 4.73.42 3.338-1.4 6.955-1.82 8.485 0 0 0 .14.14.14.42.278.56.695.42 2.086z"
        fill="#E3E7F5"
      />
      <path
        d="M96.714 95.665c-6.299 5.843-19.596.557-28.975.557-7.419 0-22.956 5.842-28.695 1.947-1.12-.835.28-4.034.7-5.008.7-1.947 1.4-3.895 2.38-5.703 2.1-4.034 11.758-5.982 15.677-8.068.7-.418 1.12-.696 1.4-1.113 4.619 6.399 14.137 6.399 18.756.14.42.416.84.695 1.4.973.14 0 .28.139.42.139 13.018 5.286 13.858 4.59 15.538 9.46.28.834 1.96 6.12 1.4 6.676z"
        fill="#fff"
      />
      <path
        d="M96.714 95.665c-6.299 5.843-19.596.556-28.975.556-7.419 0-22.956 5.843-28.695 1.948-1.12-.835.28-4.034.7-5.008.7-1.947 1.4-3.895 2.38-5.703 2.1-4.034 11.758-5.982 15.677-8.068h.14c5.319 7.372 16.377 7.372 21.696.139h.14c13.018 5.286 13.858 4.59 15.538 9.459.28.835 1.96 6.12 1.4 6.677z"
        fill="url(#prefix__paint0_linear_4689_125027)"
      />
      <path
        d="M80.477 57.55c.14-.834-.28-1.947-.56-2.782 0-.139-.14-.278-.14-.417-1.4-2.782-4.619-4.034-7.558-4.173-7.7-.418-8.4 1.113-10.919-1.113.7 1.113.84 2.921-.28 5.147-.84 1.53-2.38 2.225-3.779 2.643-3.5-7.93-1.82-12.937 1.68-14.745-1.12-2.365-1.26-3.339-1.26-3.339s7.419 1.252 9.938.696c3.22-.696 8.259.139 10.079 4.45 3.08 1.253 4.339 3.2 4.619 5.287.56 3.338-1.4 6.955-1.82 8.346z"
        fill="#1C3754"
      />
      <path
        d="M60.04 76.051v-7.094c-.14-.14-.28-.278-.28-.417v-.418c.42.696.84 1.252 1.54 1.67l5.599 3.894c1.26 1.113 3.219 1.113 4.479 0l5.179-4.73c.14-.138.42-.277.56-.556v7.373c0 .417 0 .696.14.974H59.9c.14 0 .14-.418.14-.696z"
        fill="url(#prefix__paint1_linear_4689_125027)"
      />
      <path
        d="M110.432 89.962v34.08c0 3.061-2.519 5.565-5.599 5.565H31.486c-3.08 0-5.6-2.504-5.6-5.565v-34.08h84.546z"
        fill="#fff"
      />
      <path
        d="M79.077 103.038H57.241c-1.12 0-1.96-.835-1.96-1.948s.84-1.947 1.96-1.947h21.836c1.12 0 1.96.834 1.96 1.947s-.84 1.948-1.96 1.948zM88.038 111.662H48.425c-1.12 0-1.96-.834-1.96-1.947s.84-1.948 1.96-1.948h39.613c1.12 0 1.96.835 1.96 1.948s-.98 1.947-1.96 1.947zM75.998 120.426H60.461c-.7 0-1.26-.557-1.26-1.252v-1.391c0-.696.56-1.252 1.26-1.252h15.537c.7 0 1.26.556 1.26 1.252v1.391c0 .695-.56 1.252-1.26 1.252z"
        fill="#E3EAF2"
      />
      <path
        d="M110.368 104.826h-3.831v2.536h3.831v-2.536zM95.81 109.871l-2.115 2.126 1.52 1.528 2.114-2.126-1.52-1.528zM120.225 111.224l2.115 2.126 1.387-1.395-2.114-2.126-1.388 1.395z"
        fill="#C8CEE1"
      />
      <path
        d="M108.78 144.38c10.218 0 18.502-8.329 18.502-18.603 0-10.274-8.284-18.603-18.502-18.603-10.219 0-18.503 8.329-18.503 18.603 0 10.274 8.284 18.603 18.503 18.603z"
        fill="#EAEEF9"
      />
      <path
        d="M92.519 126.811a.84.84 0 01-.841-.846v-.282c0-8.737 6.448-16.066 15.044-17.193.468-.094.842.282.935.751.093.47-.28.846-.748.94-7.756.94-13.643 7.704-13.643 15.502v.188c0 .564-.374.94-.747.94zM108.873 142.97c-1.869 0-3.644-.281-5.42-.845-.467-.188-.654-.564-.56-1.034.186-.469.56-.657 1.027-.563 1.589.563 3.178.751 4.86.751 7.756 0 14.39-5.825 15.418-13.623.094-.47.467-.752.935-.658.467.094.747.47.654.94-.561 4.134-2.523 7.892-5.7 10.711-2.991 2.818-7.102 4.321-11.214 4.321z"
        fill="#D9DEED"
      />
      <path
        d="M123.356 125.777c-.28 7.892-6.728 14.187-14.577 14.187-7.943 0-14.39-6.295-14.578-14.187.28-7.892 6.728-14.187 14.578-14.187 7.943-.094 14.39 6.295 14.577 14.187z"
        fill="#fff"
      />
      <path
        d="M121.207 126.341h-2.336a.64.64 0 01-.654-.658.64.64 0 01.654-.657h2.336a.64.64 0 01.654.657.64.64 0 01-.654.658zM98.035 126.341H95.7a.64.64 0 01-.654-.658.64.64 0 01.654-.657h2.336a.64.64 0 01.654.657.64.64 0 01-.654.658zM108.406 139.212a.64.64 0 01-.654-.657v-2.349a.64.64 0 01.654-.658.64.64 0 01.654.658v2.349a.64.64 0 01-.654.657zM108.406 115.912a.64.64 0 01-.654-.658v-2.349a.64.64 0 01.654-.657.64.64 0 01.654.657v2.349a.64.64 0 01-.654.658zM108.593 127.562a2.155 2.155 0 002.149-2.161 2.155 2.155 0 00-2.149-2.161c-1.187 0-2.15.968-2.15 2.161 0 1.194.963 2.161 2.15 2.161z"
        fill="#DAE1ED"
      />
      <path
        d="M117.19 116.194c-.28-.282-.654-.188-.934 0l-7.663 8.08h-.093a1.04 1.04 0 00-1.028 1.033v.094l-1.215 1.316c-.28.281-.187.657 0 .939a.71.71 0 00.467.188.71.71 0 00.467-.188l1.215-1.315h.094c.56 0 1.028-.47 1.028-1.034v-.188l7.662-8.08c.28-.187.28-.563 0-.845z"
        fill="#EB358A"
      />
      <path
        d="M117.004 116.476l-10.373 10.898h-.187c-.093-.094-.093-.282.094-.469l9.998-10.523a.265.265 0 01.468 0v.094z"
        fill="#FF4FA2"
      />
      <path
        d="M112.237 101.255v2.443c0 .94-.747 1.691-1.682 1.691h-4.111a1.679 1.679 0 01-1.682-1.691v-2.443c0-.939.747-1.69 1.682-1.69h4.111a1.68 1.68 0 011.682 1.69zM96.165 110.087l-2.243 2.255c-.467.47-1.308.47-1.869 0l-1.308-1.316c-.467-.469-.467-1.315 0-1.879l2.243-2.255c.467-.469 1.308-.469 1.869 0l1.308 1.316c.467.564.467 1.409 0 1.879zM121.21 110.087l2.242 2.255c.468.47 1.309.47 1.869 0l1.309-1.316c.467-.469.467-1.315 0-1.879l-2.243-2.255c-.467-.469-1.308-.469-1.869 0l-1.308 1.316c-.467.564-.467 1.409 0 1.879z"
        fill="#172D45"
      />
      <path
        d="M108.593 125.589a.188.188 0 000-.376.188.188 0 000 .376z"
        fill="#1C3754"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_4689_125027"
          x1={38.671}
          y1={89.427}
          x2={96.811}
          y2={89.427}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40A9FF" />
          <stop offset={0.927} stopColor="#2688FC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_4689_125027"
          x1={68.535}
          y1={77.069}
          x2={68.535}
          y2={72.113}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAEEF9" />
          <stop offset={0.991} stopColor="#CED5E6" />
        </linearGradient>
        <filter
          id="prefix__filter0_d_4689_125027"
          x={3.887}
          y={0.089}
          width={128.545}
          height={162.518}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4689_125027"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4689_125027"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default NotVerified
