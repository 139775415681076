import React, { FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { getViewerSelector, isAdminSelector } from 'store/viewer'

import { replaceCharacters } from 'helpers'
import { scrollTo } from 'helpers/viewerHelpers'

import Editor from '../../Editor/Editor'
import FormItem from '../../Forms/FormItem/FormItem'
import AlgoliaAutocomplete from '../../UI/AlgoliaAutocomplete/AlgoliaAutocomplete'
import AddJobKeywords from './AddJobKeywords/AddJobKeywords'
import SearchPlaceAutocomplete from './SearchPlaceAutocomplete/SearchPlaceAutocomplete'
import algoliasearch from 'algoliasearch/lite'
import {
  jobTimeEngagements,
  jobTimeInterval,
  jobTypes,
} from 'app/modules/Jobs/constants'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Space from 'antd/lib/space'
import Switch from 'antd/lib/switch'

import { PoundCircleOutlined } from '@ant-design/icons'

import { Body, ColItem, JobForm, OptionItem, Title } from './styles'

import {
  IError,
  IJob,
  JobTimeEngaging,
  JobTimeInterval,
  JobType,
  OptionsType,
  UserStatus,
} from 'types'

const { Option } = Select

interface ICreateJobForm {
  job?: IJob
  errors?: IError[] | null
}

const CreateJobForm: FC<ICreateJobForm> = ({
  job = {},
  errors: apiErrors = null,
}) => {
  const isAdmin = useSelector(isAdminSelector)
  const viewer = useSelector(getViewerSelector)

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  const {
    formState: { errors },
    control,
    setValue,
    setError,
    getValues,
  } = useFormContext()

  // FE errors
  React.useEffect(() => {
    scrollToError()
  }, [errors])

  // API errors
  React.useEffect(() => {
    if (apiErrors) {
      const fields = Object.keys(getValues())
      for (let err of apiErrors) {
        const errorField = !!err.item ? err.item.toLowerCase() : null
        if (errorField && fields.includes(errorField)) {
          setError(errorField as any, {
            message: err.message,
          })
        }
      }
      scrollToError()
    }
  }, [apiErrors])

  const scrollToError = () => {
    // Manual scroll to field with validation error
    // This applies to fields using custom plugins: User, Job Description, Location
    const errorKeys = errors ? Object.keys(errors) : []
    const firstError = errorKeys.length ? errorKeys[0] : null
    if (firstError) {
      scrollTo({ id: `field-group-${firstError}` })
    }
  }

  const {
    title = '',
    description = '',
    salaryTop = '',
    salaryBottom = '',
    timeInterval = JobTimeInterval.Annual,
    timeEngaging = JobTimeEngaging.FullTime,
    needApproval = true,
    postCode = '',
    preferKw = [],
    benefits = '',
    showSalary = true,
    type = JobType.Permanent,
    remote = false,
    location = '',
    user = {},
  } = job

  const handleOnSelect = (userId: string) => {
    setValue('userId', userId)
  }

  return (
    <JobForm layout="vertical">
      {isAdmin && (
        <>
          <Title>Post on behalf of the user</Title>
          <Body id="field-group-userId">
            {job?.id ? (
              <Form.Item
                label="Select a user"
                hasFeedback={!!errors.userId}
                validateStatus={errors.userId ? 'error' : 'success'}
                help={errors.userId?.message}
                name="userId"
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={true}
                      placeholder="Type to search for a user, email or a company"
                    >
                      <Option key={user.id} value={user.id}>
                        <OptionItem>
                          <div>{user.fullName}</div>
                          <span>{user.email}</span>
                        </OptionItem>
                      </Option>
                    </Select>
                  )}
                  control={control}
                  name="userId"
                  defaultValue={user.id}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Select a user"
                hasFeedback={!!errors.userId}
                validateStatus={errors.userId ? 'error' : 'success'}
                help={errors.userId?.message}
                name="userId"
              >
                <Controller
                  render={({ field }) => (
                    <AlgoliaAutocomplete
                      {...field}
                      isGroupByCompany
                      searchClient={searchClient}
                      isDisabled={Boolean(user.id)}
                      handleOnSelect={handleOnSelect}
                      filters={`status: ${UserStatus.Verified}`}
                      indexName={viewer.algoliaKeys.users.listOfUsers}
                      placeholder="Type to search for a user, email or a company"
                      optionsType={OptionsType.UsersGroupByCompanies}
                    />
                  )}
                  control={control}
                  name="userId"
                  defaultValue={user?.id}
                />
              </Form.Item>
            )}
          </Body>
        </>
      )}

      <Title>General info</Title>

      <Body>
        <FormItem
          name="title"
          label="Job title"
          component={Input}
          componentProps={{
            placeholder: 'Type job title here',
          }}
          defaultValue={title}
        />
      </Body>

      <Title>Description</Title>

      <Body id="field-group-description">
        <Form.Item
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description?.message}
          label="Job description"
          name="description"
        >
          <Controller
            render={({ field }) => (
              <Editor {...field} onChange={field.onChange} />
            )}
            control={control}
            name="description"
            defaultValue={description}
          />
        </Form.Item>

        <FormItem
          name="benefits"
          label="Benefits"
          component={Input}
          defaultValue={benefits}
          componentProps={{
            placeholder: 'i.e. flexible working hours, free coffee',
          }}
        />
      </Body>

      <Title>Job type</Title>

      <Body>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback={!!errors.type}
              validateStatus={errors.type ? 'error' : 'success'}
              label="Job type"
            >
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={field.onChange}
                    defaultValue={JobType.Permanent}
                  >
                    {jobTypes.map(({ value, title }) => (
                      <Option key={value} value={value}>
                        {title}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name="type"
                defaultValue={type}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback={!!errors.timeEngaging}
              validateStatus={errors.timeEngaging ? 'error' : 'success'}
              label="Time Engagement"
              help={errors.timeEngaging?.message}
            >
              <Controller
                render={({ field }) => (
                  <Select {...field} onChange={field.onChange}>
                    {jobTimeEngagements.map(({ value, title }) => (
                      <Option key={value} value={value}>
                        {title}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name="timeEngaging"
                defaultValue={timeEngaging}
              />
            </Form.Item>
          </Col>
        </Row>
      </Body>

      <Title>Salary</Title>

      <Body>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <Form.Item
              validateStatus={errors.salaryBottom ? 'error' : 'success'}
              help={errors.salaryBottom?.message}
              name="salaryBottom"
              label="Salary from"
            >
              <Controller
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<PoundCircleOutlined />}
                    onBlur={e =>
                      field.onChange(replaceCharacters(e.target.value))
                    }
                  />
                )}
                name="salaryBottom"
                control={control}
                defaultValue={String(salaryBottom)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              validateStatus={errors.salaryTop ? 'error' : 'success'}
              help={errors.salaryTop?.message}
              name="salaryTop"
              label="Salary to"
            >
              <Controller
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<PoundCircleOutlined />}
                    onBlur={e =>
                      field.onChange(replaceCharacters(e.target.value))
                    }
                  />
                )}
                name="salaryTop"
                control={control}
                defaultValue={String(salaryTop)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              hasFeedback={!!errors.timeInterval}
              validateStatus={errors.timeInterval ? 'error' : 'success'}
              label="Time interval"
            >
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={field.onChange}
                    defaultValue={JobTimeInterval.Annual}
                  >
                    {jobTimeInterval.map(({ value, title }) => (
                      <Option key={value} value={value}>
                        {title}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name="timeInterval"
                defaultValue={timeInterval}
              />
            </Form.Item>
          </Col>
        </Row>

        <Space size="middle" align="center">
          <Controller
            render={({ field }) => (
              <Switch
                {...field}
                checkedChildren="1"
                unCheckedChildren="0"
                defaultChecked={showSalary}
                onChange={field.onChange}
              />
            )}
            control={control}
            name="showSalary"
            defaultValue={showSalary}
          />
          <div>Display Salary on Job Boards*</div>
        </Space>
      </Body>

      <Title>Location</Title>

      <Body id="field-group-location">
        <Row gutter={24}>
          <Col xs={24} md={11}>
            <Form.Item
              label="City or Town"
              hasFeedback={!!errors.location}
              validateStatus={errors.location ? 'error' : 'success'}
              help={errors.location?.message}
              name="location"
            >
              <Controller
                render={({ field }) => (
                  <SearchPlaceAutocomplete
                    {...field}
                    onChange={({ label }: { label: string }) => {
                      field.onChange(label)
                    }}
                    isError={errors.location?.message}
                  />
                )}
                control={control}
                name="location"
                defaultValue={location}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={7}>
            <FormItem
              name="postCode"
              label="Postcode"
              component={Input}
              defaultValue={postCode}
            />
          </Col>

          <ColItem xs={24} md={6}>
            <Space size="middle" align="center">
              <Controller
                render={({ field }) => (
                  <Switch
                    {...field}
                    checkedChildren="1"
                    unCheckedChildren="0"
                    defaultChecked={remote}
                    onChange={field.onChange}
                  />
                )}
                control={control}
                name="remote"
                defaultValue={remote}
              />
              <div>Remote job</div>
            </Space>
          </ColItem>
        </Row>
      </Body>

      <Title>Filter applicants by keywords (optional)</Title>

      <Body>
        <Form.Item
          label="Enter keyword"
          validateStatus={errors.preferKw ? 'error' : 'success'}
          help={errors.preferKw?.message}
        >
          <Controller
            render={({ field }) => <AddJobKeywords {...field} />}
            name="preferKw"
            control={control}
            defaultValue={preferKw}
          />
        </Form.Item>
      </Body>

      <Title> Editorial approval</Title>

      <Body>
        <Space size="middle" align="center">
          <Controller
            render={({ field }) => (
              <Switch
                {...field}
                checkedChildren="1"
                unCheckedChildren="0"
                defaultChecked={needApproval}
                onChange={field.onChange}
              />
            )}
            control={control}
            name="needApproval"
            defaultValue={needApproval}
          />
          <div>I want to approve changes</div>
        </Space>
      </Body>
    </JobForm>
  )
}

export default CreateJobForm
