import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import {
  fetchCandidateCountByFiltersPromiseCreator,
  fetchCandidateCountByKeywordPromiseCreator,
  listCandidatesPromiseCreator,
  updateCandidatesStatusPromiseCreator,
} from 'store/candidates'

import { getStoredCandidateFilters } from 'helpers/candidateHelpers'

import { candidateStatusButtons, candidateStatusesWithIcon } from '../constanst'
import ActiveStatusItem from './ActiveStatusItem/ActiveStatusItem'
import snackbar from 'app/components/Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'
import isFunction from 'lodash/isFunction'
import { ROUTES } from 'routes'

import Menu from 'antd/lib/menu'
import Space from 'antd/lib/space'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import { InfoCircleOutlined } from '@ant-design/icons'

import { ItemMenu, MenuItem } from './styles'

import { CandidateStatus, RootState } from 'types'

interface ICandidateStatusMenu {
  onUpdateStatuses?: () => void
  isDefaultColored?: boolean
  isList?: boolean
  status?: CandidateStatus
  candidatesId: string[]
  jobId: string
  candidatePage?: boolean
  clearSelectionRows?: () => void
}

const CandidateStatusMenu: FC<ICandidateStatusMenu> = ({
  candidatesId,
  status,
  onUpdateStatuses,
  isDefaultColored = false,
  isList,
  jobId,
  candidatePage,
  clearSelectionRows,
}) => {
  const listCandidatesAction = useActionsRoutines(listCandidatesPromiseCreator)
  const fetchCandidateCountByKeyword = useActionsRoutines(
    fetchCandidateCountByKeywordPromiseCreator,
  )
  const fetchCandidateCountByFilters = useActionsRoutines(
    fetchCandidateCountByFiltersPromiseCreator,
  )
  const updateCandidatesStatus = useActionsRoutines(
    updateCandidatesStatusPromiseCreator,
  )

  const { isLoading: isListLoading } = useSelector(
    (state: RootState) => state.candidates.listCandidates,
  )

  const { isLoading: isUpdating } = useSelector(
    (state: RootState) => state.candidates.updateCandidatesStatus,
  )

  const handleUpdateStatus = ({
    newStatus,
  }: {
    newStatus: CandidateStatus
  }) => {
    const onSuccess = () => {
      clearSelectionRows && clearSelectionRows()
      if (!window.location.pathname.includes(ROUTES.CANDIDATE)) {
        snackbar({
          content: `Status changed to "${newStatus}"`,
          showIcon: true,
          type: 'info',
          icon: InfoCircleOutlined,
          closeText: 'OK',
        })
      }
    }

    isFunction(onUpdateStatuses) && onUpdateStatuses()

    updateCandidatesStatus({
      candidatesId,
      status: newStatus,
      jobId: jobId,
    }).then(() => {
      if (candidatePage) {
        onSuccess()
        return
      }

      // Only refresh candidates list when on candidate list screen
      fetchCandidateCountByKeyword(
        getStoredCandidateFilters({
          jobId,
          parseQs: true,
          isByKeywordOnly: true,
        }),
      )
      fetchCandidateCountByFilters(
        getStoredCandidateFilters({
          jobId,
          parseQs: true,
        }),
      )
      listCandidatesAction(
        getStoredCandidateFilters({
          jobId,
          parseQs: true,
        }),
      ).then(() => {
        onSuccess()
      })
    })
  }

  const isShowAllActive =
    status === CandidateStatus.New || status === CandidateStatus.Viewed

  if (isList) {
    return (
      <>
        {candidateStatusesWithIcon.map(({ title, value, icon }) => {
          const isActive = status === value

          if (isActive) {
            return (
              <ItemMenu disabled={isActive} key={value}>
                <ActiveStatusItem status={value} title={title} icon={icon} />
              </ItemMenu>
            )
          }

          return (
            <Menu.Item
              disabled={isActive}
              icon={icon}
              key={value}
              onClick={() => handleUpdateStatus({ newStatus: value })}
            >
              <MenuItem>{title}</MenuItem>
            </Menu.Item>
          )
        })}
      </>
    )
  }

  const tooltipInfo = (statusName: CandidateStatus) => {
    switch (statusName) {
      case CandidateStatus.Shortlisted:
        return 'Shortlist'
      case CandidateStatus.Rejected:
        return 'Reject, candidate will not be notified'
      default:
        return statusName
    }
  }

  const isLoading = isListLoading || isUpdating

  return (
    <Spin size="small" spinning={isLoading}>
      <Space>
        {candidateStatusButtons.map(({ statusName, icon, Component }) => {
          const isActive = statusName === status

          if (candidatePage) {
            return (
              <Tooltip
                key={statusName}
                placement="bottomLeft"
                title={tooltipInfo(statusName)}
                destroyTooltipOnHide={true}
              >
                <Component
                  key={statusName}
                  $isShowAllActive={isShowAllActive}
                  $isActive={isActive}
                  disabled={isActive}
                  icon={icon}
                  onClick={() =>
                    handleUpdateStatus({
                      newStatus: statusName,
                    })
                  }
                ></Component>
              </Tooltip>
            )
          }
          return (
            <Component
              key={statusName}
              $isDefaultColored={isDefaultColored}
              $isShowAllActive={isShowAllActive}
              $isActive={isActive}
              disabled={isActive}
              icon={icon}
              onClick={() =>
                handleUpdateStatus({
                  newStatus: statusName,
                })
              }
            />
          )
        })}
      </Space>
    </Spin>
  )
}

export default CandidateStatusMenu
