import React, { FC, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { isAdminSelector, isExternalRecruiterSelector } from 'store/viewer'

import FormItem from 'app/components/Forms/FormItem/FormItem'
import EditCompanyDetails from 'app/components/Modals/EditCompanyDetails/EditCompanyDetails'
import MoveToExistingCompany from 'app/components/Modals/MoveToExistingCompany/MoveToExistingCompany'
import Status from 'app/components/ResponsiveTables/UserTable/Status/Status'
import { ROUTES } from 'routes'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Table from 'antd/lib/table'

import { CompanyActions, CompanyTable, GroupContainer, Title } from './styles'

import { IUser, UserStatus, UsersRole } from 'types'

const { Option } = Select

const externalRecruitersColumns = [
  {
    title: 'Company name',
    dataIndex: 'name',
    ellipsis: true,
    width: 200,
  },
  {
    title: 'Company URL',
    dataIndex: 'website',
    ellipsis: true,
    width: 200,
  },
  {
    title: '',
    dataIndex: 'actions',
  },
]

interface IUpdateUserCompany {
  user: IUser
}

const UpdateUserCompany: FC<IUpdateUserCompany> = ({ user }) => {
  const isJHAdmin = useSelector(isAdminSelector)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const { control, reset } = useFormContext()

  const isUserExternalRecruiter = user.role === UsersRole.ExternalRecruiter

  const isShowExternalRecruiterCompanies =
    (isUserExternalRecruiter && isJHAdmin) || isExternalRecruiter

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        companyName: user.company.name,
        companyWebsite: user.company.website[0],
        companyStatus: user.company.status,
      })
    }
  }, [user])

  if (user.status !== UserStatus.Verified) return null

  const isProfilePage = window.location.pathname.includes(
    ROUTES.SETTING_PROFILE,
  )

  return (
    <>
      <GroupContainer>
        <Row>
          <Col xs={24} md={12}>
            <Title>
              {isUserExternalRecruiter && !isExternalRecruiter && !isProfilePage
                ? 'Recruiter company details'
                : 'Company details'}
            </Title>
          </Col>

          {isJHAdmin && (
            <Col xs={24} md={12}>
              <CompanyActions>
                <EditCompanyDetails user={user} />
                <MoveToExistingCompany user={user} />
              </CompanyActions>
            </Col>
          )}
        </Row>

        <Row gutter={24} justify="start">
          <Col xs={24} md={6}>
            <FormItem
              name="companyName"
              label="Company name"
              component={Input}
              componentProps={{ disabled: true }}
            />
          </Col>
          <Col xs={24} md={6}>
            <FormItem
              name="companyWebsite"
              label="Company URL"
              component={Input}
              componentProps={{ disabled: true }}
            />
          </Col>

          {isJHAdmin && (
            <Col xs={24} md={6}>
              <Form.Item label="Status">
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={true}
                      onChange={field.onChange}
                    >
                      <Option
                        key={user.company.status}
                        value={user.company.status}
                      >
                        <Status
                          status={
                            (user.company.status as unknown) as UserStatus
                          }
                        />
                      </Option>
                    </Select>
                  )}
                  control={control}
                  name="companyStatus"
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </GroupContainer>

      {isShowExternalRecruiterCompanies && (
        <>
          <Title>Companies recruiting for</Title>
          <CompanyTable>
            <Table
              columns={externalRecruitersColumns}
              dataSource={user.invitedToCompanies}
              pagination={false}
            />
          </CompanyTable>
        </>
      )}
    </>
  )
}

export default UpdateUserCompany
