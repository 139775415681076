import * as Yup from 'yup'

import {
  emailNotRequired,
  firstName,
  lastNameNotRequired,
  noteNotRequired,
  phoneNumber,
} from './baseSchema'

import { CandidateSource } from '../types'

export const addCandidateSchema = Yup.object().shape({
  firstName,
  lastName: lastNameNotRequired,
  email: emailNotRequired,
  phoneNumber,
  note: noteNotRequired,
})

export const editCandidateSchema = Yup.object().shape({
  firstName,
  lastName: lastNameNotRequired,
  email: emailNotRequired,
  phoneNumber,
})

export const addCandidateByAdminSchema = addCandidateSchema.shape({
  source: Yup.string().required('Select source in order to add Candidate.'),
  userId: Yup.string().when('source', {
    is: (value: CandidateSource) => value === CandidateSource.Sourced,
    then: Yup.string().required('Has to be a valid user'),
    otherwise: Yup.string(),
  }),
})

export const addCandidateByExternalRecruiterSchema = addCandidateSchema.shape({
  cv: Yup.mixed().required('File is required'),
})
