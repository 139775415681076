import { call, put, select } from 'redux-saga/effects'

import { ensureUpdateBalance } from 'store/viewer/workers'

import { ensureRefreshAlgoliaCache } from '../app/workers'
import { getViewerSelector, isExternalRecruiterSelector } from '../viewer'
import {
  approveJob,
  archiveJob,
  cloneJob,
  createJob,
  deleteJob,
  expireJob,
  fetchJob,
  fetchJobPreview,
  fetchJobsCounters,
  fetchRejectedCandidatesCount,
  promoteJob,
  publishJob,
  publishJobWithOutApproval,
  unArchiveJob,
  updateJob,
  updateJobForwardSettings,
} from './actions'
import algolia from 'services/algolia'
import Api from 'services/api'

import {
  ApiPublishJob,
  ArchiveJob,
  ExpireJob,
  IApproveJob,
  ICreateJob,
  IJobForwardSettings,
} from 'types'

export function* ensureFetchJobPreview({
  payload,
}: {
  type: typeof fetchJobPreview.TRIGGER
  payload: { jobId: string }
}) {
  try {
    const { jobId } = payload
    const job = yield call(Api.jobs.getJobPreview, { jobId })
    yield put(fetchJobPreview.success({ job: job.data }))
  } catch (err) {
    console.log(err)
    yield put(fetchJobPreview.failure())
  }
}

export function* ensureFetchJob({
  payload,
}: {
  type: typeof fetchJob.TRIGGER
  payload: { jobId: string }
}) {
  try {
    const { jobId } = payload
    const job = yield call(Api.jobs.getJob, { jobId })
    yield put(fetchJob.success({ job: job.data }))
  } catch (err) {
    console.log(err)
    yield put(fetchJob.failure())
  }
}

export function* ensureFetchJobsCounters() {
  try {
    const isExternalRecruiter = yield select(isExternalRecruiterSelector)
    const viewer = yield select(getViewerSelector)

    const jobsIndexName = isExternalRecruiter
      ? viewer.algoliaKeys.adverts.listOfAdvertsExternalRecruiter
      : viewer.algoliaKeys.adverts.listOfAdverts

    const jobStatusFilter = isExternalRecruiter
      ? 'statusFilterExternal'
      : 'statusFilter'

    const jobs = yield algolia.getJobsFacets({ jobsIndexName, jobStatusFilter })
    yield put(fetchJobsCounters.success({ counters: jobs.facets }))
  } catch (err) {
    console.log(err)
    yield put(fetchJobsCounters.failure())
  }
}

export function* ensureCreateJob({
  payload,
}: {
  type: typeof createJob.TRIGGER
  payload: { job: ICreateJob }
}) {
  try {
    const { job } = payload
    const createdJob = yield call(Api.jobs.createJob, { job })
    yield put(createJob.success({ job: createdJob.data }))
  } catch (err) {
    console.log(err)
    yield put(
      createJob.failure({ errors: err?.response?.data?.errors?.errors }),
    )
  }
}

export function* ensureUpdateJob({
  payload,
}: {
  type: typeof updateJob.TRIGGER
  payload: { job: ICreateJob }
}) {
  try {
    const { job } = payload
    const updatedJob = yield call(Api.jobs.updateJob, { job })
    yield put(updateJob.success({ job: updatedJob.data }))
    yield call(ensureRefreshAlgoliaCache)
  } catch (err) {
    console.log(err)
    yield put(updateJob.failure())
  }
}

export function* ensureApproveJob({
  payload,
}: {
  type: typeof approveJob.TRIGGER
  payload: IApproveJob
}) {
  try {
    const { advertId, creditType } = payload
    const job = yield call(Api.jobs.approve, { advertId, creditType })
    yield put(approveJob.success({ job: job.data }))
  } catch (err) {
    console.log(err)
    yield put(approveJob.failure())
  }
}

export function* ensurePublishJob({
  payload,
}: {
  type: typeof publishJob.TRIGGER
  payload: ApiPublishJob
}) {
  try {
    const { id, creditType, userApproval } = payload
    const job = yield call(Api.jobs.publishJob, {
      id,
      creditType,
      userApproval,
    })
    yield call(ensureUpdateBalance)
    yield put(publishJob.success({ job: job.data }))
  } catch (err) {
    console.log(err)
    yield put(publishJob.failure())
  }
}

export function* ensureDeleteJob({
  payload,
}: {
  type: typeof deleteJob.TRIGGER
  payload: { jobId: string }
}) {
  try {
    const { jobId } = payload
    yield call(Api.jobs.deleteJob, { jobId })
    yield put(deleteJob.success())
    yield call(ensureRefreshAlgoliaCache)
    yield call(ensureFetchJobsCounters)
  } catch (err) {
    console.log(err)
    yield put(deleteJob.failure())
  }
}

export function* ensureExpireJob({
  payload,
}: {
  type: typeof expireJob.TRIGGER
  payload: ExpireJob
}) {
  try {
    const { id, undo } = payload
    const updatedJob = yield call(Api.jobs.expireJob, { id, undo })
    yield put(expireJob.success({ isExpired: updatedJob.data.isExpired }))
  } catch (err) {
    console.log(err)
    yield put(expireJob.failure())
  }
}

export function* ensureCloneJob({
  payload,
}: {
  type: typeof cloneJob.TRIGGER
  payload: {
    id: number
    campaignType: string
  }
}) {
  try {
    const { id, campaignType } = payload
    const response = yield call(Api.jobs.cloneJob, { id, campaignType })
    yield put(cloneJob.success(response.data.id))
  } catch (err) {
    console.log(err)
    yield put(cloneJob.failure())
  }
}

export function* ensurePromoteJob({
  payload,
}: {
  type: typeof promoteJob.TRIGGER
  payload: { id: number }
}) {
  try {
    const { id } = payload
    const promotedJob = yield call(Api.jobs.promoteJob, { id })
    yield put(promoteJob.success({ redirectUrl: promotedJob.data.redirectUrl }))
  } catch (err) {
    console.log(err)
    yield put(promoteJob.failure())
  }
}

export function* ensureArchiveJob({
  payload,
}: {
  type: typeof archiveJob.TRIGGER
  payload: ArchiveJob
}) {
  try {
    const { id, undo } = payload
    const updatedJob = yield call(Api.jobs.archiveJob, { id, undo })
    yield put(
      archiveJob.success({ status: updatedJob.data.status, isExpired: !undo }),
    )
    yield call(ensureRefreshAlgoliaCache)
    yield call(ensureFetchJobsCounters)
  } catch (err) {
    console.log(err)
    yield put(archiveJob.failure())
  }
}
export function* ensureUnArchiveJob({
  payload,
}: {
  type: typeof unArchiveJob.TRIGGER
  payload: { id: string }
}) {
  try {
    const { id } = payload
    yield call(Api.jobs.unArchiveJob, { id })
    yield put(unArchiveJob.success())
    yield call(ensureRefreshAlgoliaCache)
    yield call(ensureFetchJobsCounters)
  } catch (err) {
    console.log(err)
    yield put(archiveJob.failure())
  }
}

export function* ensurePublishJobWithOutApproval({
  payload,
}: {
  type: typeof publishJobWithOutApproval.TRIGGER
  payload: any
}) {
  try {
    const { id, userApproval } = payload
    yield call(Api.jobs.publishJob, { id, userApproval })
    yield put(publishJobWithOutApproval.success())
  } catch (err) {
    console.log(err)
    yield put(publishJobWithOutApproval.failure())
  }
}

export function* ensureUpdateJobForwardSettings({
  payload,
}: {
  type: typeof updateJobForwardSettings.TRIGGER
  payload: IJobForwardSettings
}) {
  try {
    const response = yield call(Api.jobs.updateJobForwardSettings, payload)
    yield put(updateJobForwardSettings.success(response.data.data))
  } catch (err) {
    console.log(err)
    yield put(updateJobForwardSettings.failure())
  }
}

export function* ensureFetchRejectedCandidatesCount({
  payload,
}: {
  type: typeof fetchRejectedCandidatesCount.TRIGGER
  payload: { advertId: string }
}) {
  try {
    const { advertId } = payload
    const response = yield algolia.getJobRejectedCandidatesCount({ advertId })
    yield put(fetchRejectedCandidatesCount.success(response.hits))
  } catch (err) {
    console.log(err)
    yield put(fetchRejectedCandidatesCount.failure())
  }
}
