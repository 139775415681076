import { lazy, number, string } from 'yup'

export const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/

export const baseEmail = string()
  .trim()
  .email('Please provide correct email address.')

export const email = baseEmail.required('Please provide correct email address.')

export const emailNotRequired = baseEmail.notRequired().nullable()

export const password = string()
  .trim()
  .required(
    'At least 8 characters, one uppercase letter, one lowercase letter, one digit.',
  )
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    'At least 8 characters, one uppercase letter, one lowercase letter, one digit.',
  )

export const companyName = string()
  .trim()
  .min(2, 'Has to be between 2 and 50 characters')
  .max(50, 'Has to be between 2 and 50 characters')

export const companyWebsite = string()
  .trim()
  .matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    'Please enter correct url',
  )
  .min(3, 'Has to be between 3 and 70 characters')
  .max(70, 'Has to be between 3 and 70 characters')

export const phoneNumber = lazy(phone =>
  !!phone
    ? string()
        .trim()
        .matches(/^[- +()]*[0-9][- +()0-9]*$/, 'Has to be a valid phone number')
        .min(3, 'Has to be between 3 and 30 characters')
        .max(30, 'Has to be between 3 and 30 characters')
    : string().nullable(),
)

export const firstName = string()
  .trim()
  .required('Has to be between 3 and 50 characters')
  .min(3, 'Has to be between 3 and 50 characters')
  .max(50, 'Has to be between 3 and 50 characters')

export const lastNameBase = string()
  .trim()
  .min(3, 'Has to be between 3 and 50 characters')
  .max(50, 'Has to be between 3 and 50 characters')

export const lastName = lastNameBase.required(
  'Has to be between 3 and 50 characters',
)

export const lastNameNotRequired = lazy(lastName =>
  !!lastName ? lastNameBase : string().nullable(),
)

export const noteBase = string()
  .trim()
  .min(3, 'Has to be between 3 and 1000 characters')
  .max(1000, 'Has to be between 3 and 1000 characters')

export const note = noteBase.required('Has to be between 3 and 1000 characters')

export const noteNotRequired = lazy(note =>
  !!note ? noteBase : string().nullable(),
)

export const credit = number()
  .integer('Has to be a valid credits')
  .positive('Has to be a valid credits')
  .min(0, 'Has to be a valid credits')
  .max(999, 'Has to be a valid credits')
