import React, { FC } from 'react'

import { downloadCvPromiseCreator } from 'store/candidates'

import useActionsRoutines from 'hooks/useActionsRoutines'

import Menu from 'antd/lib/menu'

import { DownloadOutlined } from '@ant-design/icons'

interface IDownloadCv {
  component?: any
  componentProps?: any
  id: number
  fullName: string
  isHasCv: boolean
  isShowIcon?: boolean
}

const DownloadCv: FC<IDownloadCv> = ({
  component: Component = Menu.Item,
  componentProps = {},
  id,
  fullName,
  isHasCv,
  isShowIcon = false,
}) => {
  const downloadCv = useActionsRoutines(downloadCvPromiseCreator)

  const handleDownloadCV = async () => {
    try {
      await downloadCv({ id, fullName })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Component
      {...componentProps}
      key="Download CV"
      disabled={!isHasCv}
      icon={isShowIcon && <DownloadOutlined />}
      onClick={handleDownloadCV}
    >
      Download CV
    </Component>
  )
}

export default DownloadCv
