import React from 'react'

import HiringTeamTableActions from './HiringTeamTableActions'
import HiringTeamTableFullName from './HiringTeamTableFullName'
import HiringTeamTableStatus from './HiringTeamTableStatus'
import InviteCompanyMember from './InviteCompanyMember'
import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'

import {
  IAlgoliaUser,
  IHiringTeamMember,
  IJob,
  IUser,
  InvitationStatus,
  UsersRole,
} from 'types'

export const hiringTeamMembersColumns = (job: IJob) => [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    ellipsis: true,
    render: (_: unknown, user: IHiringTeamMember) => (
      <HiringTeamTableFullName {...user} job={job} />
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    ellipsis: true,
    render: (
      _: unknown,
      { invitationStatus }: { invitationStatus: InvitationStatus },
    ) => <HiringTeamTableStatus invitationStatus={invitationStatus} />,
  },
  {
    title: 'Access Level',
    dataIndex: 'accessLevel',
    ellipsis: true,
    render: (_: unknown, { role }: { role: UsersRole }) => (
      <AccessLevel role={role} />
    ),
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    ellipsis: true,
  },
  {
    dataIndex: 'actions',
    render: (_: unknown, user: IUser) => (
      <HiringTeamTableActions {...user} job={job} />
    ),
  },
]

export const companyMembersColumns = [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    ellipsis: true,
  },
  {
    title: 'Access Level',
    dataIndex: 'accessLevel',
    ellipsis: true,
    render: (_: unknown, { role }: { role: UsersRole }) => (
      <AccessLevel role={role} />
    ),
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    ellipsis: true,
  },
  {
    dataIndex: 'actions',
    render: (_: unknown, user: IAlgoliaUser) => (
      <InviteCompanyMember {...user} />
    ),
  },
]
