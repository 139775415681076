import React from 'react'
import { Link } from 'react-router-dom'

import user from '../assets/auth-user.svg'
import { ROUTES } from 'routes'

import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { CheckCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

import { ImageBlock, List, SignInText } from './styles'

const SignUpInfo = () => {
  const screens = useBreakpoint()

  if (screens.md) {
    return (
      <Col md={10}>
        <ImageBlock>
          <img src={user} alt="user" />
        </ImageBlock>

        <List>
          <li>
            <CheckCircleOutlined /> Post a job in minutes
          </li>
          <li>
            <CheckCircleOutlined />
            No credit card needed
          </li>
          <li>
            <CheckCircleOutlined />
            No long term contracts
          </li>
          <li>
            <CheckCircleOutlined />
            Access the UKs top job boards at a fraction of the cost
          </li>
        </List>

        <SignInText>
          Already have Jobheron account?
          <Link to={ROUTES.SIGN_IN}>
            Sign in
            <RightCircleOutlined
              style={{ fontSize: '14px', color: '#2688FC' }}
            />
          </Link>
        </SignInText>
      </Col>
    )
  }

  return null
}

export default SignUpInfo
