import React, { FC } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'

import StatusDot from './StatusDot/StatusDot'
import orderBy from 'lodash/orderBy'

import Checkbox from 'antd/lib/checkbox'

import { Count, List, Title } from './styles'

import { CandidateStatus } from 'types'

interface ICustomRefinementList {
  title: string
  showTitleCount?: boolean
  showCount?: boolean
  items: any
  refine: (value: unknown) => void
  orderedBy?: any
  maxWidth?: number
  attribute: string
}

const CustomRefinementList: FC<ICustomRefinementList> = ({
  title,
  showTitleCount,
  showCount,
  items,
  refine,
  orderedBy,
  maxWidth,
  attribute,
}) => {
  const orderedFilterOptions = orderedBy
    ? orderBy(items, item => orderedBy[item.label])
    : orderBy(items, 'label', 'asc')

  const isSomeRefined = items.some(
    ({ isRefined }: { isRefined: boolean }) => isRefined,
  )

  const allCount = items.reduce(
    (previousValue: number, { count }: { count: number }) => {
      return previousValue + count
    },
    0,
  )

  return (
    <List direction="vertical" size={10}>
      <Checkbox
        key={title}
        value={title}
        checked={!isSomeRefined}
        onChange={() => refine('')}
      >
        <Title> {title}</Title>

        {showTitleCount && <Count>({allCount})</Count>}
      </Checkbox>
      {orderedFilterOptions.map((item: any) => {
        return (
          <Checkbox
            key={item.value}
            checked={item.isRefined}
            value={item.value}
            onChange={() => refine(item.value)}
          >
            {attribute === 'status' && <StatusDot status={item.label} />}
            <Title maxWidth={maxWidth}>
              {item.label === CandidateStatus.AutoRejected
                ? 'Auto rejected'
                : item.label}
            </Title>

            {showCount && <Count>({item.count})</Count>}
          </Checkbox>
        )
      })}
    </List>
  )
}

const RefinementList = connectRefinementList(CustomRefinementList)

export default RefinementList
