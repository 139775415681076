import React from 'react'
import { useSelector } from 'react-redux'

import { isExternalRecruiterSelector } from 'store/viewer'

import { getFilterCount, getJobCandidatesStat } from 'helpers/candidateHelpers'

import NoCandidates from '../../../../../assets/icons/NoCandidates'
import { FilterProp, OnFilterProp } from '../DetailJob'
import CandidatesFilter from './CandidatesFilter/CandidatesFilter'
import FilterChips from './RegularFilter/FilterChips'
import { FilterAttribute, ItemsProps } from './RegularFilter/FilterList'
import FilterStages from './RegularFilter/FilterSlider'
import CandidatesTable from 'app/components/ResponsiveTables/CandidatesTable/CandidatesTable'
import EmptyBlock from 'app/components/UI/EmptyBlock/EmptyBlock'
import { orderBy } from 'lodash'

import { Spin } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import {
  CandidatesContainer,
  Container,
  EmptyBlockContainer,
  Main,
  SpinContainer,
} from './styles'

import {
  CandidateSourceLabels,
  CandidateStageLabels,
  CandidateStatusLabels,
  ICandidateList,
  RootState,
} from 'types'

interface CandidatesListProps {
  filters: FilterProp
  isLoading?: boolean
  candidates: ICandidateList
  onClearFilters: () => void
  onFilter: (prop: OnFilterProp) => void
  onPageChange: (pageNumber: number, total: number) => void
}

const CandidatesList = ({
  candidates,
  filters,
  isLoading: isListLoading,
  onClearFilters,
  onFilter,
  onPageChange,
}: CandidatesListProps) => {
  const screens = useBreakpoint()
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const { isLoading: isUpdatingStatus } = useSelector(
    (state: RootState) => state.candidates.updateCandidatesStatus,
  )
  const { isLoading: isUpdatingStage } = useSelector(
    (state: RootState) => state.candidates.updateCandidatesStage,
  )
  const { isLoading: isUpdatingCandidate } = useSelector(
    (state: RootState) => state.candidates.updateCandidate,
  )
  const { isLoading: isDeletingCandidate } = useSelector(
    (state: RootState) => state.candidates.deleteCandidate,
  )
  const { stages } = useSelector((state: RootState) => state.stages.fetchStages)
  const { data: candidateCountByKeyword } = useSelector(
    (state: RootState) => state.candidates.fetchCandidateCountByKeyword,
  )
  const { data: candidateCountByFilters } = useSelector(
    (state: RootState) => state.candidates.fetchCandidateCountByFilters,
  )

  // Retrieve Candidate Count by Stage from API for Sidebar Filters
  const stageData = stages.map(
    ({ name: key, order }) =>
      ({
        count: getJobCandidatesStat(candidateCountByKeyword.stageCount, key),
        isRefined: filters.stage.includes(key),
        label:
          key in CandidateStageLabels
            ? CandidateStageLabels[key as keyof typeof CandidateStageLabels]
            : key,
        value: key,
        order,
      } as ItemsProps),
  )
  const stageList = orderBy(stageData, 'order', 'asc')

  // Retrieve Candidate Count by Stage from API for Top Menu Filters
  const stageDetailedList = stageList.map(data => ({
    ...data,
    count: getJobCandidatesStat(candidateCountByFilters.stageCount, data.value),
  }))

  // Retrieve Candidate Count by Status from API for Sidebar Filters
  const statusList = candidateCountByKeyword.statusCount.map(
    ({ key, count }) =>
      ({
        count,
        isRefined: filters.status.includes(key),
        label:
          key in CandidateStatusLabels
            ? CandidateStatusLabels[key as keyof typeof CandidateStatusLabels]
            : key,
        value: key,
      } as ItemsProps),
  )

  // Retrieve Candidate Count by Source from API for Sidebar Filters
  const sourceList = candidateCountByKeyword.sourceCount.map(
    ({ key, count }) =>
      ({
        count,
        isRefined: filters.source.includes(key),
        label:
          key in CandidateSourceLabels
            ? CandidateSourceLabels[key as keyof typeof CandidateSourceLabels]
            : key,
        value: key,
      } as ItemsProps),
  )

  const filterChips = {
    ...filters,
    stage: filters.stage.map(key => ({
      value: key,
      label:
        key in CandidateStageLabels
          ? CandidateStageLabels[key as keyof typeof CandidateStageLabels]
          : key,
    })),
    status: filters.status.map(key => ({
      value: key,
      label:
        key in CandidateStatusLabels
          ? CandidateStatusLabels[key as keyof typeof CandidateStatusLabels]
          : key,
    })),
    source: filters.source.map(key => ({
      value: key,
      label:
        key in CandidateSourceLabels
          ? CandidateSourceLabels[key as keyof typeof CandidateSourceLabels]
          : key,
    })),
  }

  const filterCount = getFilterCount(filterChips)

  const isLoading =
    isListLoading ||
    isUpdatingStatus ||
    isUpdatingStage ||
    isUpdatingCandidate ||
    isDeletingCandidate

  return (
    <Container>
      {isLoading && (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      )}
      {screens.md && (
        <CandidatesFilter
          isCandidatesExist={!!candidates}
          onFilter={onFilter}
          sourceList={sourceList}
          stageList={stageList}
          statusList={statusList}
        />
      )}
      <Main>
        {screens.md && (
          <FilterChips
            filters={filterChips}
            onFilter={onFilter}
            onClearFilters={onClearFilters}
          />
        )}
        <FilterStages
          attribute={FilterAttribute.stage}
          items={stageDetailedList}
          onFilter={onFilter}
        />
        {!!candidates ? (
          <CandidatesContainer isExternalRecruiter={isExternalRecruiter}>
            <CandidatesTable
              data={candidates}
              hasFilters={!!filterCount}
              pageNumber={candidates.pageNumber + 1}
              pageSize={candidates.size}
              total={candidates.totalElements}
              onPageChange={onPageChange}
            />
          </CandidatesContainer>
        ) : (
          <EmptyBlockContainer>
            <EmptyBlock icon={<NoCandidates />} title="No Candidates, yet" />
          </EmptyBlockContainer>
        )}
      </Main>
    </Container>
  )
}

export default CandidatesList
