import { takeLatest } from 'redux-saga/effects'

import {
  approveJob,
  archiveJob,
  cloneJob,
  createJob,
  deleteJob,
  expireJob,
  fetchJob,
  fetchJobPreview,
  fetchJobsCounters,
  fetchRejectedCandidatesCount,
  promoteJob,
  publishJob,
  publishJobWithOutApproval,
  unArchiveJob,
  updateJob,
  updateJobForwardSettings,
} from './actions'
import {
  ensureApproveJob,
  ensureArchiveJob,
  ensureCloneJob,
  ensureCreateJob,
  ensureDeleteJob,
  ensureExpireJob,
  ensureFetchJob,
  ensureFetchJobPreview,
  ensureFetchJobsCounters,
  ensureFetchRejectedCandidatesCount,
  ensurePromoteJob,
  ensurePublishJob,
  ensurePublishJobWithOutApproval,
  ensureUnArchiveJob,
  ensureUpdateJob,
  ensureUpdateJobForwardSettings,
} from './workers'

export function* watchFetchJobPreview() {
  yield takeLatest(fetchJobPreview.TRIGGER, ensureFetchJobPreview)
}

export function* watchFetchJob() {
  yield takeLatest(fetchJob.TRIGGER, ensureFetchJob)
}

export function* watchFetchJobsCounters() {
  yield takeLatest(fetchJobsCounters.TRIGGER, ensureFetchJobsCounters)
}

export function* watchCreateJob() {
  yield takeLatest(createJob.TRIGGER, ensureCreateJob)
}

export function* watchUpdateJob() {
  yield takeLatest(updateJob.TRIGGER, ensureUpdateJob)
}

export function* watchApproveJob() {
  yield takeLatest(approveJob.TRIGGER, ensureApproveJob)
}

export function* watchPublishJob() {
  yield takeLatest(publishJob.TRIGGER, ensurePublishJob)
}

export function* watchDeleteJob() {
  yield takeLatest(deleteJob.TRIGGER, ensureDeleteJob)
}

export function* watchExpireJob() {
  yield takeLatest(expireJob.TRIGGER, ensureExpireJob)
}

export function* watchArchiveJob() {
  yield takeLatest(archiveJob.TRIGGER, ensureArchiveJob)
}

export function* watchUnArchiveJob() {
  yield takeLatest(unArchiveJob.TRIGGER, ensureUnArchiveJob)
}

export function* watchCloneJob() {
  yield takeLatest(cloneJob.TRIGGER, ensureCloneJob)
}

export function* watchPromoteJob() {
  yield takeLatest(promoteJob.TRIGGER, ensurePromoteJob)
}

export function* watchPublishJobWithOutApproval() {
  yield takeLatest(
    publishJobWithOutApproval.TRIGGER,
    ensurePublishJobWithOutApproval,
  )
}

export function* watchUpdateJobForwardSettings() {
  yield takeLatest(
    updateJobForwardSettings.TRIGGER,
    ensureUpdateJobForwardSettings,
  )
}

export function* watchFetchRejectedCandidatesCount() {
  yield takeLatest(
    fetchRejectedCandidatesCount.TRIGGER,
    ensureFetchRejectedCandidatesCount,
  )
}
