import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { signUpPromiseCreator } from 'store/viewer'

import { useQuery } from 'helpers/useQuery'

import { yupResolver } from '@hookform/resolvers/yup'

import InvitationView from './InvitationView'
import AuthContainer from 'app/components/Auth/AuthContainer/AuthContainer'
import useActionsRoutines from 'hooks/useActionsRoutines'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import { ROUTES } from 'routes'
import Auth from 'services/auth'
import { SignUpSchema, signUpSchemaWithOutCompany } from 'validation'

import { AuthStatus, RootState, UsersRole } from 'types'

interface INewViewer {
  firstName: string
  lastName: string
  email: string
  password: string
  companyName: string
  companyWebsite: string
  phoneNumber?: string
}

const Invitation = () => {
  const query = useQuery()
  const history = useHistory()

  const [isDisplaySuccessMessage, setIsDisplaySuccessMessage] = useState(false)
  const [formStep, setFormStep] = useState<0 | 1>(0)

  const viewer = useSelector((state: RootState) => state.viewer)
  const signUp = useActionsRoutines(signUpPromiseCreator)

  const token = query.get('token')
  const role = query.get('role')
  const email = query.get('email')
  const status = query.get('status') as AuthStatus

  const isExternalRecruiter = role === UsersRole.ExternalRecruiter

  const methods = useForm({
    resolver: yupResolver(
      isExternalRecruiter ? SignUpSchema : signUpSchemaWithOutCompany,
    ),
  })

  const socialQuery = `?token=${token}&role=${role}`

  const handleSignUpWithGoogle = () => {
    Auth.authorizeWithGoogle(socialQuery)
  }

  const handleSignUpWithLinkedIn = () => {
    Auth.authorizeWithLinkedin(socialQuery)
  }

  const handleGoToNextStep = async () => {
    const result = await methods.trigger([
      'firstName',
      'lastName',
      'email',
      'password',
    ])
    result && setFormStep(1)
  }

  const handleFormSubmit = async (newViewer: INewViewer) => {
    signUp({ viewer: pickBy({ token, role, ...newViewer }, identity) })
      .then(() => {
        if (email !== newViewer.email) {
          return setIsDisplaySuccessMessage(true)
        }
        return history.push(ROUTES.SIGN_IN)
      })
      .catch(({ errors }) => {
        setFormStep(0)
        methods.setError('email', {
          message: errors[0].message,
        })
      })
  }

  return (
    <AuthContainer title="Sign Up Invitation">
      <FormProvider {...methods}>
        <InvitationView
          status={status}
          formStep={formStep}
          viewer={viewer}
          isSuccess={isDisplaySuccessMessage}
          isExternalRecruiter={isExternalRecruiter}
          handleSignUpWithGoogle={handleSignUpWithGoogle}
          handleSignUpWithLinkedIn={handleSignUpWithLinkedIn}
          handleGoToNextStep={handleGoToNextStep}
          handleFormSubmit={handleFormSubmit}
        />
      </FormProvider>
    </AuthContainer>
  )
}

export default Invitation
