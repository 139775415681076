import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { isExternalRecruiterOrTeamMemberSelector } from 'store/viewer'

import JobTag from '../../JobTag/JobTag'
import BottomRowItems from '../components/BottomRowItems/BottomRowItems'
import Warning from 'assets/icons/Warning'

import Button from 'antd/lib/button'

import {
  ActionBlock,
  BottomLeftInfo,
  BottomRow,
  JobItem,
  Title,
  TitleBlock,
  TopRow,
  TopRowRight,
} from './styles'

import { IAlgoliaJob, TagComponent } from 'types'

const JobPending: FC<IAlgoliaJob> = ({
  title,
  status,
  company,
  isJHCampaign,
  referenceCode,
  relatedCampaign,
  statusExternal,
  ownerId,
  isExpired,
  isSSCampaign,
}) => {
  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )

  return (
    <JobItem>
      <TopRow>
        <TitleBlock>
          <Warning size={18} />
          <Title>{title}</Title>
        </TitleBlock>

        {!isExternalRecruiterOrTeamMember && (
          <TopRowRight>
            <ActionBlock>
              <Button>View job</Button>
            </ActionBlock>
          </TopRowRight>
        )}
      </TopRow>

      <BottomRow>
        <BottomLeftInfo>
          <JobTag
            status={status || statusExternal}
            tagComponent={TagComponent.JobItem}
            jobCreatorId={ownerId}
          />
        </BottomLeftInfo>

        <BottomRowItems
          isJHCampaign={isJHCampaign}
          relatedCampaign={relatedCampaign}
          referenceCode={referenceCode}
          company={company}
          status={status}
          isExpired={isExpired}
          isSSCampaign={isSSCampaign}
        />
      </BottomRow>
    </JobItem>
  )
}

export default JobPending
