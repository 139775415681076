import React from 'react'
import { Link } from 'react-router-dom'

import InviteTeamMember from 'app/components/Modals/InviteTeamMember/InviteTeamMember'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { ManageUsersActionsContainer } from './styles'

const ManageUsersActions = () => {
  return (
    <ManageUsersActionsContainer>
      <Button type="primary">
        <Link to={ROUTES.ADMIN_CREATE_USER}> Add user</Link>
      </Button>

      <InviteTeamMember />
    </ManageUsersActionsContainer>
  )
}

export default ManageUsersActions
