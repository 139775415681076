import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: auto;
    width: 100%;
  }

  body {
    font-family: 'Inter', sans-serif;
    background-color: #fff;
    min-width: 320px;
  }

  #root {
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  label {
    font-weight: 600;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  img {
    max-width: 100%;
  }

  .ant-btn {
    min-width: initial;
  }


  .ant-checkbox,
  .ant-space,
  .ant-radio {
    min-width: auto;
    min-height: auto;
  }


  .ant-form-item-explain-error {
    font-size: 12px;
  }

  .ant-btn-loading-icon > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }

  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 2px;
  }


  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;


  }

  .ant-table table {
    border: 1px solid #f0f0f0;
    border-bottom: none;
  }

  .ant-table-body > body {
    border-radius: 0;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 8px;
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
  }

  h1 {
    font-weight: 600;
    margin: 0;
  }

  .ant-tabs .ant-tabs-nav-list {
    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #d9d9d9;
    }
  }
 

  .ant-tabs .ant-tabs-nav {
    &:before {
      border-bottom: 0;
    }
  }

  .ant-tabs-extra-content {
    padding-left: 10px;
  }

  .ant-pagination-options {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 2px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 34px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 34px;
    }
  }

  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus, .ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
  .ant-tabs-tab{
    color:#262626;
  }

  .ant-form-item {
    &.ant-form-item-has-error {
      .quill {
        border-color: #ff4d4f;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
  text-shadow: unset;
  }

  .ant-tag {
    padding: 2px 10px;
    font-size: 13px;
    font-weight: 600;
    margin-right: 0;
  }

  .ant-tag-gold {
    color: #faad14;
    background: #fffbe6;
    border-color: #ffe58f;
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
    display: none;
  }

  .ant-modal-content{
  border-radius: 0;
  }

  .ant-modal-title {
    font-weight: 600;
    font-size: 20px;
  }

  .invite-member-job-dropdown {
    .ant-select-item {
      padding-left: 12px;
    }
    .ant-select-item-option-selected {
      .ant-select-item-option-state {
        display: none;
      }
    }
  }

  body .ant-message {
    top: auto !important;
    bottom: 8px;

    .ant-message-custom-content {
      display: flex;
      align-items: center;
    }
    .ant-btn {
      padding: 0 5px;
      height: 24px;
      min-height: auto;
      width: auto;
      min-width: auto;

      .anticon {
        margin: 0;
      }
    }

  }


  .ant-tabs .ant-tabs-nav-list:after {
    background: transparent;
  }

  .job-autocomplete-list {
    .ant-select-item-option-state {
      display: none;
    }
  }

  .job-menu-divider {
    margin: 0;
  }

  .ant-message-notice-content{
    padding: 0;
    background: transparent;
    box-shadow: none;
    & .anticon-info-circle{
      display: none;
    }
    &  .anticon.anticon-info-circle.ant-alert-icon{
      display: inline-block;
    }
  }

  .candidate-tooltip {
    text-transform: capitalize;
  }

  // override table
  .ant-table table .yellow-background,
  .ant-table table .yellow-background:hover td {
    background-color: #FFFBF2;
  }

  .ant-table-thead > tr > th {
    background: #fff;
    &::before{
      display: none;
    }
  }

  .row-item{
    cursor: pointer;

    &.selection-trigger-lg {
      .ant-checkbox-input {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 56px;
      }
    }
  }

  .row-item.table-row-light {
    background-color: #ffffff;
    font-weight: bold;
    &:hover td{
      background-color: #fafafa;
    }
    & .ant-table-cell-row-hover{
      background-color: #fafafa;
    }
  }
  .row-item.table-row-dark {
    background-color: #fafafa;
    &:hover td{
      background-color: #ffffff;
    }
   & .ant-table-cell-row-hover{
      background-color:  #ffffff;
    }
  }
 
.ant-table-tbody > tr.ant-table-row.table-row-dark:hover > td, .ant-table-tbody > tr.ant-table-row-dark > td.ant-table-cell-row-hover {
    background: #ffffff;
}
.ant-table-tbody > tr.ant-table-row.table-row-light:hover > td, .ant-table-tbody > tr.ant-table-row-light > td.ant-table-cell-row-hover {
    background: #fafafa;
}

  .ant-dropdown-menu {
    padding: 0;
    overflow: hidden;
  }

  .ant-alert-info {
    background: #262626;
    border: 1px solid #262626;
    color: #fff;

    .ant-alert-icon {
      margin-top: -3px;
    }

    .ant-alert-message {
      color: #fff;
    }

    .ant-alert-icon {
      color: #fff;
    }

    .ant-alert-close-icon {
      color: #FF773E;
    }

    .anticon.anticon-close {
      color: #FF773E;
      margin: 0;
    }

    .ant-alert-close-text {
      color: #FF773E;
      font-weight: bold;
      padding-left: 5px;
      font-size: 14px;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #fff;
  }

  .highlight-mention{
    background: #E6F7FF;
    color:#2688FC;
    border-radius: 2px;
    padding: 0;
  }

  .ant-drawer-bottom {
    .ant-drawer-content {
      border-radius: 8px 8px 0 0;
    }

    .ant-drawer-body {
      padding: 16px;
    }
  }

  .ant-menu-light .ant-menu-item-active {
    color: inherit;
  }

  .ant-alert-warning {
    background: #FFF2E8;
    border: none;
    .ant-alert-message {
      color: #FF773E;
      font-weight: 600;
    }
    .ant-alert-icon {
      color: #FF773E;
    }
  }
  .ant-tabs.ant-tabs-top{
    overflow: inherit;    
  }

  @keyframes bounce {
    0% { transform: translateY(0); }
    25% { transform: translateY(-3px); }
    75% { transform: translateY(3px); }
    100% { transform: translateY(0); }
  }
`
