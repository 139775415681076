import { ROUTES } from 'routes'

declare const webAuthType: {
  authorize: (options: { connection: string; redirect_uri: string }) => void
  logout: (options: { returnTo: string; client_id: string }) => void
  parseHash: (value: any) => any
  redirect: {
    loginWithCredentials: (value: any, callback: any) => any
  }
}

declare global {
  interface Window {
    auth0?: any
  }
}

class Auth {
  protected webAuth: typeof webAuthType | undefined

  init() {
    this.webAuth = new window.auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      responseType: 'token',
      redirectUri: `${window.location.origin}/auth/callback`,
      scope: 'openid profile read:timesheets create:timesheets',
    })
  }

  authorizeWithGoogle(socialQuery?: string) {
    this.webAuth?.authorize({
      connection: 'google-oauth2',
      redirect_uri: socialQuery
        ? `${window.location.origin}${ROUTES.AUTH_CALLBACK}${socialQuery}`
        : `${window.location.origin}${ROUTES.AUTH_CALLBACK}`,
    })
  }

  authorizeWithLinkedin(socialQuery?: string) {
    this.webAuth?.authorize({
      connection: 'linkedin',
      redirect_uri: socialQuery
        ? `${window.location.origin}${ROUTES.AUTH_CALLBACK}${socialQuery}`
        : `${window.location.origin}${ROUTES.AUTH_CALLBACK}`,
    })
  }

  parseHash(callback: (error: any, response: any) => void) {
    this.webAuth?.parseHash(async (error: any, response: any) =>
      callback(error, response),
    )
  }

  login(
    { email, password }: { email: string; password: string },
    callback: (err: any, res: any) => void,
  ) {
    this.webAuth?.redirect.loginWithCredentials(
      {
        connection: 'Username-Password-Authentication',
        username: email,
        password: password,
        responseType: 'token',
        scope: 'openid profile read:timesheets create:timesheets',
        redirect_uri: `${window.location.origin}${ROUTES.AUTH_CALLBACK}`,
      },
      (err: any, res: any) => callback(err, res),
    )
  }

  logout() {
    this.webAuth?.logout({
      returnTo: `${window.location.origin}${ROUTES.SIGN_IN}`,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    })
  }
}

const auth = new Auth()

export default auth
