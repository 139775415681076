interface generateAvatarNameProps {
  firstName?: string
  lastName?: string
  fullName?: string
}

export const generateAvatarName = ({
  firstName = '',
  lastName = '',
  fullName,
}: generateAvatarNameProps) => {
  if (fullName) {
    return fullName
      .split(' ')
      .splice(0, 2)
      .map(item => item.charAt(0))
      .join('')
  }

  return firstName?.trim().charAt(0) + lastName?.trim().charAt(0)
}
