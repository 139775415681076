import React from 'react'

import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'

import { UserStatus, UsersRole } from 'types'

export const columns = [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    ellipsis: true,
    width: 150,
  },

  {
    title: 'Access Level',
    dataIndex: 'accessLevel',
    width: 150,
    minWidth: 150,
    ellipsis: true,
    render: (
      _: unknown,
      {
        role,
        status,
        objectID,
        fullName,
      }: {
        role: UsersRole
        status: UserStatus
        objectID: string
        fullName: string
      },
    ) => (
      <AccessLevel
        role={role}
        status={status}
        userId={objectID}
        fullName={fullName}
      />
    ),
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    ellipsis: true,
    width: 150,
  },
]
