import styled from 'styled-components/macro'

import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'
import Tag from 'antd/lib/tag'

import { UsersRole } from 'types'

export const Container = styled.header`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
  width: 100%;
  background: #fff;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  height: 68px;

  @media (min-width: 576px) {
    transform: translateY(0);
  }
`

export const UserName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 600;
  margin-right: 5px;
  text-transform: uppercase;
  background: ${props =>
    props.role === UsersRole.JHSuperAdmin ? '#FFF2E8' : '#E6F7FF'};
  color: ${props =>
    props.role === UsersRole.JHSuperAdmin ? '#FF7A45' : '#2688FC'};
`

export const Main = styled.div`
  max-width: 1200px;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TopMenu = styled(Menu)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 0;

  .ant-badge {
    position: absolute;
    top: 3px;
    right: 3px;
  }

  li {
    display: inline-block;
    margin-left: 10px;
  }

  &.ant-menu {
    .ant-menu-overflow-item:not(.ant-menu-item-selected) {
      &:hover {
        &:after {
          display: none;
        }
      }
    }

    .ant-menu-submenu:not(.ant-menu-submenu-active) {
      &:hover {
        &:after {
          display: none;
        }
      }
    }

    .ant-menu-submenu.ant-menu-submenu-active {
      &:hover {
        &:after {
          display: block;
        }
      }
    }
  }

  .anticon-caret-down {
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -7px;
  }

  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    margin-left: 0;
  }
`

export const SubMenuWrapper = styled(Menu.SubMenu)`
  margin-right: 20px;
`

export const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
`

export const RightActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const MenuBlock = styled(Menu)`
  &.ant-dropdown-menu {
    width: 100%;
    max-width: 300px;
    padding-top: 16px;
    cursor: initial;
  }
`

export const MenuItem = styled(Menu.Item)`
  min-width: 224px;
  padding: 10px 16px;
`
export const MenuTop = styled.div`
  padding: 0 16px;
`

export const MenuName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const MenuCompany = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 17px;
  opacity: 0.5;
`

export const MenuTag = styled(Tag)`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  margin-bottom: 20px;
`

export const HelpButton = styled(Button)`
  margin-right: 16px;

  a {
    display: block;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.blue6};
    }
  }

  svg {
    font-size: 24px;
    fill: ${({ theme }) => theme.colors.gray7};
  }
`
