import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  approveJobPromiseCreator,
  publishJobPromiseCreator,
  publishJobWithOutApprovalPromiseCreator,
} from 'store/jobs'
import { getViewerSelector, isAdminSelector } from 'store/viewer'

import { checkIsViewerHasBalance } from 'helpers/creditsHelpers'

import IsNotVerifiedCompanyButton from './IsNotVerifiedCompanyButton/IsNotVerifiedCompanyButton'
import SelectCampaignTypeModal from 'app/components/Modals/SelectCampaignTypeModal/SelectCampaignTypeModal'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { JobActionsApproveItem } from './styles'

import { CreateJobTab, IJob, JobStatus, RootState, UserStatus } from 'types'

interface IApprovePublishJob {
  job: IJob
  activeTab: CreateJobTab
  isEditingJob: boolean
}

const ApprovePublishJob: FC<IApprovePublishJob> = ({
  job,
  activeTab,
  isEditingJob,
}) => {
  const history = useHistory()
  const isAdmin = useSelector(isAdminSelector)
  const viewer = useSelector(getViewerSelector)
  const approveJob = useActionsRoutines(approveJobPromiseCreator)
  const publishJob = useActionsRoutines(publishJobPromiseCreator)
  const publishJobWithOutApproval = useActionsRoutines(
    publishJobWithOutApprovalPromiseCreator,
  )
  const { balance } = useSelector(getViewerSelector)
  const { isLoading: isLoadingApproveJob } = useSelector(
    (state: RootState) => state.jobs.approveJob,
  )
  const { isLoading: isLoadingPublishJob } = useSelector(
    (state: RootState) => state.jobs.publishJob,
  )
  const { isLoading: isLoadingPublishWithOutApproval } = useSelector(
    (state: RootState) => state.jobs.publishJobWithOutApproval,
  )

  const [isOpenSelectCampaignModal, setIsOpenSelectCampaignModal] = useState(
    false,
  )

  const { status, needApproval, user } = job

  const userBalance = isAdmin ? user.balance : balance

  const isJobCreatorHasBalance = checkIsViewerHasBalance(userBalance)

  const canSubmitJob = viewer.id === job.user.id || isAdmin
  /**
   * Do not show action:
   * 1. is editing job mode
   * 2. active tab = SelectCampaign
   */
  if (
    isEditingJob ||
    activeTab === CreateJobTab.SelectCampaign ||
    !canSubmitJob
  ) {
    return null
  }

  const handlePublishWithOutApproval = async () => {
    try {
      await publishJobWithOutApproval({ id: job.id, userApproval: true })
      history.replace(`${ROUTES.JOB}/${job.id}`)
    } catch (err) {}
  }

  const handlePublishJob = async () => {
    try {
      const newJob = await publishJob({ id: job.id })
      const { id, status } = newJob.job
      setIsOpenSelectCampaignModal(false)
      if (status === JobStatus.PendingUserApproval) {
        if (isAdmin) {
          return history.replace(`${ROUTES.JOB}/${id}/preview`)
        }
        return history.replace(`${ROUTES.JOB}/${id}/preview?status=published`)
      }

      if (isAdmin) return history.replace(`${ROUTES.JOB}/${id}`)
      return history.replace(`${ROUTES.JOB}/${id}?status=published`)
    } catch (err) {
      console.log(err)
    }
  }

  const handleApproveJob = () => {
    approveJob({ advertId: job.id })
  }

  const isNotVerifiedCompany = user.companyStatus === UserStatus.NotVerified

  const isShowPublishWithOutApprovalButton =
    isAdmin &&
    needApproval &&
    !isNotVerifiedCompany &&
    (job.status === JobStatus.PendingUserApproval ||
      job.status === JobStatus.PendingAdminApproval)

  const handleOpenModal = () => {
    if (!isAdmin && !isJobCreatorHasBalance) {
      job.status !== JobStatus.NoCredits && handleApproveJob()
      return history.push(
        `${ROUTES.CHECKOUT}?redirectURL=${window.location.href}&advertId=${job.id}`,
      )
    }

    setIsOpenSelectCampaignModal(true)
  }

  const handleCloseModal = () => {
    isAdmin && job.status !== JobStatus.NoCredits && handleApproveJob()
    setIsOpenSelectCampaignModal(false)
  }

  if (
    isNotVerifiedCompany &&
    (status === JobStatus.PendingAdminApproval ||
      status === JobStatus.PendingUserApproval)
  )
    return (
      <IsNotVerifiedCompanyButton
        isAdmin={isAdmin}
        status={status}
        needApproval={needApproval}
        companyName={user.company}
      />
    )

  return (
    <>
      <JobActionsApproveItem>
        {(status === JobStatus.Draft || status === JobStatus.NoCredits) && (
          <Button
            type="primary"
            loading={isLoadingApproveJob && !isOpenSelectCampaignModal}
            onClick={() => handleOpenModal()}
          >
            Submit for approval
          </Button>
        )}

        {isAdmin && status === JobStatus.PendingAdminApproval && (
          <>
            {needApproval && (
              <Button
                type="primary"
                loading={isLoadingPublishJob && !isOpenSelectCampaignModal}
                onClick={() => handlePublishJob()}
              >
                Submit for user approval
              </Button>
            )}

            {!needApproval && (
              <Button
                type="primary"
                loading={isLoadingPublishJob && !isOpenSelectCampaignModal}
                onClick={() => handlePublishJob()}
              >
                Approve & Publish
              </Button>
            )}
          </>
        )}

        {!isAdmin && status === JobStatus.PendingUserApproval && (
          <Button
            type="primary"
            loading={isLoadingPublishJob && !isOpenSelectCampaignModal}
            onClick={() => handlePublishJob()}
          >
            Approve & Publish
          </Button>
        )}

        {isShowPublishWithOutApprovalButton && (
          <Button
            type="primary"
            loading={isLoadingPublishWithOutApproval}
            onClick={() => handlePublishWithOutApproval()}
          >
            Publish on behalf of the user
          </Button>
        )}

        <SelectCampaignTypeModal
          job={job}
          jobStatus={status}
          balance={userBalance}
          handleCloseModal={handleCloseModal}
          isOpenSelectCampaignModal={isOpenSelectCampaignModal}
        />
      </JobActionsApproveItem>
    </>
  )
}

export default ApprovePublishJob
