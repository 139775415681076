import { call, put } from 'redux-saga/effects'

import { ensureFetchUsersFromAlgolia } from 'store/users/workers'

import {
  completeAuth,
  fetchViewer,
  forgotPassword,
  getViewerBalance,
  logout,
  resendSignUp,
  resetPassword,
  showMessage,
  signUp,
  signUpCompany,
} from './actions'
import algolia from 'services/algolia'
import Api from 'services/api'
import Auth from 'services/auth'
import { getToken, removeToken, setToken } from 'services/localStorage'

import { IAddViewer, IResetPassword, IViewerCompany, UsersRole } from 'types'

export function* ensureNotVerifiedUsersExist(role: UsersRole) {
  let isNotVerifiedUsersExist = false
  if (role === UsersRole.CompanyAdmin) {
    const users = yield call(ensureFetchUsersFromAlgolia)
    isNotVerifiedUsersExist = users.nbHits > 0
  }
  yield put(showMessage({ isShowMessage: isNotVerifiedUsersExist }))
}

export function* ensureViewer() {
  try {
    const accessToken = getToken()
    if (!accessToken) {
      return yield put(fetchViewer.failure())
    }

    yield call(Api._setAccessTokenToAxios, { accessToken })
    const viewer = yield call(Api.auth.getViewer)
    yield algolia.init({ algoliaKeys: viewer.data.algoliaKeys })
    yield put(fetchViewer.success({ viewer: viewer.data }))
    yield call(ensureNotVerifiedUsersExist, viewer.data.role)
  } catch (err) {
    yield put(fetchViewer.failure())
  }
}

export function* ensureUpdateBalance() {
  try {
    const viewer = yield call(Api.auth.getViewer)
    yield put(getViewerBalance.success({ balance: viewer.data.balance }))
  } catch (err) {
    console.log(err)
  }
}

export function* ensureSignUp({
  payload,
}: {
  type: typeof signUp.TRIGGER
  payload: { viewer: IAddViewer }
}) {
  try {
    const { viewer } = payload
    yield call(Api.auth.signUp, { viewer })
    yield put(signUp.success())
  } catch (err) {
    const { errors } = err.response.data.errors
    yield put(signUp.failure({ errors }))
  }
}

export function* ensureResendSignUp({
  payload,
}: {
  type: typeof resendSignUp.TRIGGER
  payload: { email: string }
}) {
  try {
    const { email } = payload
    yield call(Api.auth.resendSignUp, { email })
    yield put(resendSignUp.success())
  } catch (err) {
    const { errors } = err.response.data.errors
    yield put(resendSignUp.failure({ errors }))
  }
}

export function* ensureSignUpCompany({
  payload,
}: {
  type: typeof signUp.TRIGGER
  payload: { viewerCompany: IViewerCompany }
}) {
  const { viewerCompany } = payload
  try {
    const accessToken = getToken()
    yield call(Api._setAccessTokenToAxios, { accessToken })
    yield call(Api.auth.singUpCompany, { viewerCompany })
    const viewer = yield call(Api.auth.getViewer)
    yield put(signUpCompany.success({ viewer: viewer.data }))
  } catch (err) {
    yield put(signUpCompany.failure())
  }
}

export function* ensureCompleteAuth({
  payload,
}: {
  type: typeof signUp.TRIGGER
  payload: { accessToken: string }
}) {
  try {
    const { accessToken } = payload
    yield call(setToken, { accessToken })
    yield call(Api._setAccessTokenToAxios, { accessToken })
    const viewer = yield call(Api.auth.getViewer)
    yield algolia.init({ algoliaKeys: viewer.data.algoliaKeys })
    yield put(completeAuth.success({ viewer: viewer.data }))
    yield call(ensureNotVerifiedUsersExist, viewer.data.role)
  } catch (err) {
    const { errors } = err?.response?.data?.errors
    yield put(completeAuth.failure({ errors }))
  }
}

export function* ensureLogout() {
  try {
    yield Auth.logout()
    yield call(removeToken)
    yield call(Api._removeAccessTokenFromAxios)
    yield put(logout.success())
  } catch (err) {
    yield put(fetchViewer.failure())
  }
}

export function* ensureForgotPassword({
  payload,
}: {
  type: typeof forgotPassword.TRIGGER
  payload: { email: string }
}) {
  try {
    const { email } = payload

    yield call(Api.auth.forgotPassword, { email })
    yield put(forgotPassword.success())
  } catch (err) {
    yield put(forgotPassword.failure())
  }
}

export function* ensureResetPassword({
  payload,
}: {
  type: typeof resetPassword.TRIGGER
  payload: IResetPassword
}) {
  try {
    yield call(Api.auth.resetPassword, payload)
    yield put(resetPassword.success())
  } catch (err) {
    const { errors } = err.response.data.errors
    yield put(resetPassword.failure({ errors }))
  }
}
