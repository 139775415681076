import styled from 'styled-components/macro'

import Button from 'antd/lib/button'

export const Container = styled.div`
  margin: 20px 0;
`

export const MobileContainer = styled.div`
  background: #ffffff;
`

export const UserItem = styled.div`
  border: 1px solid rgb(240, 240, 240);
  border-bottom: 0;
  padding: 15px 10px;

  &:last-child {
    border-bottom: 1px solid rgb(240, 240, 240);
  }

  a {
    color: ${({ theme }) => theme.colors.gray9};
    text-decoration: underline;
  }
`

export const TableContainer = styled.div`
  .ant-table-row {
    height: 57px;
  }
`

export const ActionButton = styled(Button)`
  color: ${({ theme, color }) => (color ? color : theme.colors.gray9)};
  &:hover {
    &:active {
      color: ${({ theme, color }) => (color ? color : theme.colors.gray9)};
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .ant-dropdown-menu-item {
    padding: 10px;
  }
`
