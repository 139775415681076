import styled from 'styled-components/macro'

import ACollapse from 'antd/lib/collapse'

export const Container = styled.div`
  width: 200px;
  min-width: 200px;
  background: #ffffff;
  border-right: 1px solid #d9d9d9;
  padding: 16px;
  padding-right: 0;
  display: ${({ isCandidatesExist }: { isCandidatesExist: boolean }) =>
    isCandidatesExist ? 'block' : 'none'};

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item {
    border: 0;
    white-space: nowrap;
  }

  .ant-collapse-content,
  .ant-collapse {
    background: #ffffff;
    border: 0;
  }

  .ant-checkbox-wrapper {
    font-weight: normal;

    &.ant-checkbox-wrapper-checked {
      font-weight: 600;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
  }

  .ant-collapse-header {
    .anticon {
      color: ${({ theme }) => theme.colors.gray7};
    }
  }
  @media (min-width: 576px) {
  }
`

export const Collapse = styled(ACollapse)`
  @media (min-width: 768px) {
    min-height: 150px;
    max-height: calc(100vh - 315px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`

export const FilterTitle = styled.div`
  font-weight: 600;
  margin-bottom: 10px;

  svg {
    margin-right: 15px;
  }
`
