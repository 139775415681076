import React, { FC } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Container } from './styles'

const modules = {
  toolbar: [
    [{ size: [] }],
    [{ align: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    // ['clean'],
  ],
}
interface IEditor {
  onChange: (value: string) => void
}

const Editor: FC<IEditor> = (props: any) => {
  return (
    <Container>
      <ReactQuill
        {...props}
        theme="snow"
        modules={modules}
        placeholder="Type job description here"
      />
    </Container>
  )
}

export default Editor
