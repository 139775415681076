import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { forwardCandidatesCSVPromiseCreator } from 'store/candidates'
import { isExternalRecruiterSelector } from 'store/viewer'

import { yupResolver } from '@hookform/resolvers/yup'

import FormItem from 'app/components/Forms/FormItem/FormItem'
import FormMultipleItems from 'app/components/Forms/FormMultipleItems/FormMultipleItems'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ForwardCVSchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'

import { ModalFooter } from './styles'

interface DownloadCSVModalProps {
  candidatesIdWithCv: number[]
  clearSelectionRows?: () => void
}

interface UseFormInputs {
  emailList: string[]
  comment?: string
}
const DownloadCSVModal: FC<DownloadCSVModalProps> = ({
  candidatesIdWithCv,
  clearSelectionRows,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const forwardCandidatesCSV = useActionsRoutines(
    forwardCandidatesCSVPromiseCreator,
  )
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    methods.reset()
    methods.setValue('emailList', [])
  }

  const methods = useForm({
    resolver: yupResolver(ForwardCVSchema),
    mode: 'onSubmit',
  })

  const handleFormSubmit = async (data: UseFormInputs) => {
    const { emailList, comment } = data

    try {
      await forwardCandidatesCSV({
        emailList,
        comment,
        ids: candidatesIdWithCv,
      })
    } catch (error) {
      // for now, we don't need to handle error
      console.log(error)
    }

    handleCloseModal()
    clearSelectionRows?.()
  }

  if (isExternalRecruiter) return null

  return (
    <>
      <Button disabled={!candidatesIdWithCv.length} onClick={handleOpenModal}>
        Download CSV
      </Button>

      <Modal
        width={550}
        footer={null}
        title="Download CSV"
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            onFinish={methods.handleSubmit(handleFormSubmit)}
          >
            <p>
              Please provide email address where candidates CSV data will be
              sent to.
            </p>

            <FormMultipleItems
              name="emailList"
              isRequired={true}
              placeholder="Email address"
            />
            <FormItem
              name="comment"
              component={Input.TextArea}
              componentProps={{ placeholder: 'Comment' }}
            />

            <ModalFooter>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={methods.formState.isSubmitting}
              >
                Send
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default DownloadCSVModal
