import styled from 'styled-components/macro'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'

export const JobForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2`
  font-weight: 600;
  background: #f0f0f0;
  margin: 16px 0 24px 0;
  padding: 6px 16px;
  font-size: 20px;

  &:first-child {
    margin-top: 0;
  }
`

export const Body = styled.div`
  padding-left: 16px;

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }
`

export const ColItem = styled(Col)`
  display: flex;
  max-height: 94px;
`

export const OptionItem = styled.div`
  display: flex;
  justify-content: space-between;
`
