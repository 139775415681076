import React, { FC } from 'react'

import WarningIcon from 'assets/icons/Warning'

import Tooltip from 'antd/lib/tooltip'

import { Container } from './styles'

interface IWarningTooltip {
  title: string
  icon?: React.ElementType
  placement?:
    | 'topLeft'
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined
}

const WarningTooltip: FC<IWarningTooltip> = ({
  icon: Icon = WarningIcon,
  title,
  placement = 'topLeft',
}) => {
  return (
    <Tooltip arrowPointAtCenter placement={placement} title={title}>
      <Container>
        <Icon />
      </Container>
    </Tooltip>
  )
}

export default WarningTooltip
