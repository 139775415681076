import styled from 'styled-components/macro'

import Button from 'antd/lib/button'

export const SearchBlock = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }

  & .ant-input-wrapper {
    min-width: 202px;
  }
`

export const JobSettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  .ant-btn-group {
    .ant-btn-icon-only {
      margin: 0;
    }
  }
`

export const MobileDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  h3 {
    flex: 1;
    margin: 0 10px 0 0;
    font-weight: 600;
  }
`

export const ButtonItem = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    min-width: 40px;
  }
`
