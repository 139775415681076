import React, { FC } from 'react'

import { numberWithCommas } from 'helpers/checkoutHelpers'

import {
  BundleItem,
  BundleItemRow,
  BundleTitle,
  BuyButton,
  Credits,
  Discount,
  Price,
  TotalCost,
} from './styles'

interface BundleTypes {
  credits: number
  saving: number
  price: number
  id: number
}

interface CampaignBundleProps {
  title: string
  types: BundleTypes[]
  handleBuyCredits: (packId: number, credits: number) => void
  loading: number | null
}

const CampaignBundle: FC<CampaignBundleProps> = ({
  title,
  types,
  handleBuyCredits,
  loading,
}) => {
  return (
    <div>
      <BundleTitle>{title} </BundleTitle>
      <BundleItem>
        {types.map(({ credits, price, id, saving }) => (
          <BundleItemRow key={id}>
            <Credits>{credits} Credits</Credits>
            <Discount>Save: £{numberWithCommas(saving)}</Discount>
            <TotalCost>
              Total cost = <Price> £{numberWithCommas(price)} </Price>
            </TotalCost>
            <BuyButton
              loading={loading === id}
              type="link"
              onClick={() => handleBuyCredits(id, 1)}
            >
              Buy now
            </BuyButton>
          </BundleItemRow>
        ))}
      </BundleItem>
    </div>
  )
}

export default CampaignBundle
