import React, { useMemo } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getRefreshSelector } from 'store/app/selectors'
import { getViewerSelector } from 'store/viewer'

import AssignCreditsForm from './AssignCreditsForm/AssignCreditsForm'
import CustomHits from './CustomHits'
import { columns } from './columns'
import { ALGOLIA_FILTER } from './constants'
import algoliasearch from 'algoliasearch/lite'
import CustomAlgoliaLoaderWrapper from 'app/components/Loaders/AlgoliaLoaderWrapper/AlgoliaLoaderWrapper'
import AlgoliaPagination from 'app/components/UI/AlgoliaPagination/AlgoliaPagination'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'

import {
  ColItem,
  Container,
  HeaderRow,
  TeamMembersContainer,
  TeamMembersTitle,
} from './styles'

const AssignCredits = () => {
  const history = useHistory()
  const viewer = useSelector(getViewerSelector)
  const refreshAlgoliaCache = useSelector(getRefreshSelector)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  return (
    <Container>
      <HeaderRow>
        <Col xs={24} md={14}>
          <BackButton title="Assign Jobheron Campaign Credits" />
        </Col>
        <ColItem xs={24} md={10}>
          <Button
            type="primary"
            onClick={() =>
              history.push(
                `${ROUTES.CHECKOUT}?redirectURL=${window.location.href}`,
              )
            }
          >
            Buy Credits
          </Button>
        </ColItem>
      </HeaderRow>
      <TeamMembersContainer>
        <TeamMembersTitle>Team Members</TeamMembersTitle>
        <InstantSearch
          searchClient={searchClient}
          indexName={viewer.algoliaKeys.users.listOfUsers}
          refresh={refreshAlgoliaCache}
        >
          <Index indexName={viewer.algoliaKeys.users.listOfUsers}>
            <Configure hitsPerPage={10} filters={ALGOLIA_FILTER} />
            <CustomAlgoliaLoaderWrapper>
              <CustomHits columns={columns} />
            </CustomAlgoliaLoaderWrapper>
            <AlgoliaPagination
              pageSize="10"
              setPageSize={() => {}}
              showSizeChanger={false}
              scrollToTop={false}
            />
          </Index>
        </InstantSearch>
      </TeamMembersContainer>
      <AssignCreditsForm />
    </Container>
  )
}

export default AssignCredits
