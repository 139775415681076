import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 200px);
  text-align: center;

  img {
    max-width: 140px;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
  }

  p {
    max-width: 580px;
    color: ${({ theme }) => theme.colors.gray7};
  }
`
