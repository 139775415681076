import React from 'react'

import Bulb from 'assets/icons/Bulb'

import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'

import { FullName, FullNameContainer } from './styles'

import { IHiringTeamMember, IJob } from 'types'

interface IHiringTeamTableFullName extends IHiringTeamMember {
  job: IJob
}

const HiringTeamTableFullName = ({
  fullName,
  userId,
  job,
}: IHiringTeamTableFullName) => {
  const isJobCreator = job.user.id === userId

  return (
    <FullNameContainer>
      <FullName>{fullName}</FullName>
      {isJobCreator && (
        <Tooltip placement="bottomLeft" title="Job’s Creator">
          <Button type="link">
            <Bulb />
          </Button>
        </Tooltip>
      )}
    </FullNameContainer>
  )
}

export default HiringTeamTableFullName
