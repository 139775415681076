import React, { FC, useEffect, useState } from 'react'
import { Prompt, useHistory, useLocation } from 'react-router-dom'

import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'

import { Body } from './styles'

interface IHistoryBlockModal {
  content?: any
  isBlocked: boolean
  isValid: boolean
  callback: (value: any) => void
}

const HistoryBlockModal: FC<IHistoryBlockModal> = ({
  content = '',
  isBlocked,
  isValid,
  callback,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const location = useLocation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState(location)
  const [shouldUnload, setShouldUnload] = useState(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
    setShouldUnload(false)
    callback(lastLocation)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const showModal = (nextLocation: any) => {
    openModal()
    setLastLocation(nextLocation)
  }

  const handleBlockedRoute = (nextLocation: any) => {
    if (!confirmedNavigation && isBlocked) {
      if (isValid) {
        callback(nextLocation)
        return false
      }

      showModal(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setIsModalOpen(false)
    setShouldUnload(false)
    setConfirmedNavigation(true)
  }

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function

      setShouldUnload(true)

      const searchParams =
        lastLocation.search && lastLocation.search.includes('redirectURL')
          ? ROUTES.JOBS
          : lastLocation.search
      history.push(`${lastLocation.pathname}${searchParams}`)
    }
  }, [confirmedNavigation, lastLocation, history])

  // Block non-react routes
  useEffect(() => {
    const unload = (event: any) => {
      if (isBlocked && !shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = content
      }
      if (shouldUnload) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', unload)

    return () => window.removeEventListener('beforeunload', unload)
  }, [isBlocked, content, shouldUnload])

  useEffect(() => {
    isBlocked && setConfirmedNavigation(false)
  }, [isBlocked])

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      {isModalOpen && (
        <Modal
          visible={isModalOpen}
          width={550}
          footer={null}
          onCancel={() => setIsModalOpen(false)}
        >
          <Body>
            <h2>Delete advert</h2>
            <p>You’ll lose all current progress.</p>
            <p>We can’t recover them once you delete.</p>

            <Space>
              <Button onClick={handleConfirmNavigationClick}>
                Yes, delete it
              </Button>
              <Button type="primary" onClick={closeModal}>
                Save draft
              </Button>
            </Space>
          </Body>
        </Modal>
      )}
    </>
  )
}

export default HistoryBlockModal
