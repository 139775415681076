import { all, fork } from 'redux-saga/effects'

import * as W from './watchers'

export function* watchViewerActions() {
  yield all([
    fork(W.watchCurrentViewer),
    fork(W.watchSignUp),
    fork(W.watchResendSignUp),
    fork(W.watchSignUpCompany),
    fork(W.watchCompleteAuth),
    fork(W.watchLogout),
    fork(W.watchForgotPassword),
    fork(W.watchResetPassword),
  ])
}
