import { all, fork } from 'redux-saga/effects'

import * as W from './watchers'

export function* watchStagesActions() {
  yield all([
    fork(W.watchFetchStages),
    fork(W.watchCreateStage),
    fork(W.watchUpdateStage),
    fork(W.watchDeleteStage),
    fork(W.watchReorderStage),
  ])
}
