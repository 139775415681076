import styled from 'styled-components/macro'

import Space from 'antd/lib/space'

interface ITitle {
  maxWidth?: number
}

export const List = styled(Space)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 16px 0;
  width: 100%;
  display: block;

  .ant-space-item {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-checkbox-wrapper {
    & > span {
      display: flex;
      align-items: center;
    }
  }
`

export const Count = styled.span`
  font-weight: 400;
  display: inline-block;
  padding-left: 5px;
  color: ${({ theme }) => theme.colors.gray7};
`

export const Title = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: unset;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: ${({ maxWidth }: ITitle) => maxWidth && `${maxWidth}px`};
  } ;
`
