import React, { FC } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import Input from 'antd/lib/input'

import { CloseOutlined, SearchOutlined } from '@ant-design/icons'

interface ICustomSearchBox {
  refine: (value: string) => void
  handleCloseMobileSearch: () => void
}

const CustomSearchBox: FC<ICustomSearchBox> = ({
  refine,
  handleCloseMobileSearch,
}) => (
  <Input
    prefix={<SearchOutlined />}
    addonAfter={
      <div onClick={handleCloseMobileSearch}>
        <CloseOutlined />
      </div>
    }
    placeholder="Search for a job..."
    onChange={event => refine(event.currentTarget.value)}
  />
)

const MobileSearchBox = connectSearchBox(CustomSearchBox)

export default MobileSearchBox
