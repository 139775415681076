import { createReducer } from '@reduxjs/toolkit'

import {
  hideSnackbar,
  showSnackbar,
  startRefreshAlgoliaCache,
  stopRefreshAlgoliaCache,
  updateLastPage,
} from './actions'

import { IAppState } from 'types'

export const APP_STATE: IAppState = {
  modals: {
    isShowInviteTeamMemberModal: false,
  },
  refreshAlgoliaCache: false,
  lastActivePage: {
    jobs: '',
    candidates: '',
  },
  appSnackbar: {
    isOpen: false,
    message: '',
  },
}

const viewerReducer = createReducer(APP_STATE, bulder => {
  bulder.addCase(startRefreshAlgoliaCache, state => {
    state.refreshAlgoliaCache = true
  })
  bulder.addCase(stopRefreshAlgoliaCache, state => {
    state.refreshAlgoliaCache = false
  })
  bulder.addCase(showSnackbar, (state, { payload }) => {
    state.appSnackbar.isOpen = true
    state.appSnackbar.message = payload.message
  })
  bulder.addCase(hideSnackbar, state => {
    state.appSnackbar.isOpen = false
  })
  bulder.addCase(updateLastPage, (state, { payload }) => {
    state.lastActivePage = {
      ...state.lastActivePage,
      [payload.type]: payload.path,
    }
  })
})

export default viewerReducer
