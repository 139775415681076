import styled from 'styled-components'

import Tag from 'antd/lib/tag'

export const TagItem = styled(Tag)`
  border: none;
  font-weight: 400;
  margin: 0 4px;
`
export const TagItemRejected = styled(TagItem)`
  background: #fff1f0;
  color: #ff7875;
`
