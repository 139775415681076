import format from 'date-fns/format'

export const formatDate = (
  date: Date | string,
  formatType = 'dd/MM/yy kk:mma',
) => {
  try {
    return format(new Date(date), formatType)
  } catch (err) {
    return ''
  }
}
