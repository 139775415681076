import React, { MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'

import CandidateStatusMenu from '../../UpdateCandidateStatus/CandidateStatusMenu'
import UpdateCandidateStageMenu from '../UpdateCandidateStageMenu'
import AddNoteModal from 'app/components/Modals/AddNoteModal/AddNoteModal'
import ForwardCVModal from 'app/components/Modals/ForwardCVModal/ForwardCVModal'

import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'

import {
  CloseOutlined,
  EllipsisOutlined,
  FileTextOutlined,
} from '@ant-design/icons'

import {
  CloseButton,
  DividerItem,
  MenuItem,
  MenuTitle,
  MobileDrawer,
} from './styles'

import { JobStatus, RootState } from 'types'

const CandidateMobileMenu = ({ candidate, isStatusVisible }: any) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const { isLoading } = useSelector(
    (state: RootState) => state.candidates.updateCandidatesStage,
  )

  const handleOpenMobileMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setMobileMenuOpen(true)
  }
  const handleCloseMobileMenu = () => {
    setMobileMenuOpen(false)
  }

  const { status, id, advert, company, cv } = candidate

  return (
    <>
      <Button
        loading={isLoading}
        icon={<EllipsisOutlined />}
        onClick={handleOpenMobileMenu}
      />
      <MobileDrawer
        placement="bottom"
        width="100%"
        height="88vh"
        onClose={handleCloseMobileMenu}
        visible={isMobileMenuOpen}
        closable={false}
      >
        <CloseButton
          onClick={() => {
            handleCloseMobileMenu()
          }}
          icon={<CloseOutlined />}
        />

        <Menu>
          {isStatusVisible && (
            <>
              <MenuTitle>Change status</MenuTitle>
              <span onClick={handleCloseMobileMenu}>
                <CandidateStatusMenu
                  isList={true}
                  status={status}
                  candidatesId={[id]}
                  jobId={String(advert.id)}
                />
              </span>
              <DividerItem />
            </>
          )}

          <>
            <MenuTitle>Move stage to:</MenuTitle>
            <UpdateCandidateStageMenu handleCloseMenu={handleCloseMobileMenu} />
          </>

          {isStatusVisible && <DividerItem />}

          {advert && (
            <MenuItem onClick={handleCloseMobileMenu}>
              <AddNoteModal
                buttonTitle="Add a note"
                buttonType="text"
                icon={<FileTextOutlined />}
                advertId={advert.id}
                companyId={company.id}
                candidateId={id}
              />
            </MenuItem>
          )}
          <MenuItem onClick={handleCloseMobileMenu}>
            <Menu>
              <ForwardCVModal
                candidateId={id}
                buttonTitle="Forward CV"
                buttonType="text"
                isHasCv={!!cv}
              />
            </Menu>
          </MenuItem>
          <DividerItem />
          <MenuItem onClick={handleCloseMobileMenu}>
            <Button icon={<CloseOutlined />} type="text">
              Cancel
            </Button>
          </MenuItem>
        </Menu>
      </MobileDrawer>
    </>
  )
}

export default CandidateMobileMenu
