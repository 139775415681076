import styled from 'styled-components'

import Comment from 'antd/lib/comment'
import Tag from 'antd/lib/tag'

export const Container = styled.div`
  padding: 20px 0;
  & .ant-comment:last-child .ant-comment-avatar:before {
    display: none;
  }
`

export const CommentWrapper = styled(Comment)`
  h2 {
    margin: 0;
    &:hover {
      color: ${({ theme }) => theme.colors.gray9};
    }
  }

  .ant-comment-content-author {
    display: flex;
    align-items: center;
  }
  .ant-comment-avatar {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      transform: translateX(-50%);
      width: 2px;
      background: #f0f0f0;
    }
  }

  .ant-comment-inner {
    padding: 0;
  }
  .ant-comment-avatar {
    padding-bottom: 30px;
  }
`

export const CommentInfo = styled.h2`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray9};
`

export const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e6f7ff;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.blue6};
  position: relative;
  text-transform: uppercase;
  svg {
    margin-left: 3px;
  }
`
export const UserName = styled.span`
  text-decoration: underline;
  margin-right: 4px;
`
export const CommentTime = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray7};
`

export const StageTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.gray8};
  font-weight: 600;
`
export const Content = styled.p`
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
`
export const SpinnerContainer = styled.div`
  text-align: center;
`

export const AgencyName = styled.span`
  color: ${({ theme }) => theme.colors.gray7};
`

export const UsernameWrapper = styled.span`
  display: flex;
  svg {
    margin-right: 6px;
  }
`
