import styled from 'styled-components'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Drawer from 'antd/lib/drawer'

export const MobileDrawer = styled(Drawer)`
  .ant-menu-item {
    display: flex;
    align-items: center;
    color: #262626;
  }
  .ant-menu {
    border: none;
  }
  & .ant-drawer-content .ant-drawer-body {
    padding: 24px 16px;
    position: relative;
  }
`

export const MenuItem = styled.div`
  min-height: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  svg + span {
    margin-left: 8px;
  }
  .ant-btn {
    font-weight: 400;
    padding: 8px 16px;
    text-align: left;
    width: 100%;
  }
`

export const MenuTitle = styled.h2`
  padding: 0 0 5px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DividerItem = styled(Divider)`
  margin: 15px 0;
`

export const CloseButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 40px;
  svg {
    width: 14px;
    height: 14px;
  }
`
