import React, { FC } from 'react'

import { OnFilterProp } from '../../DetailJob'
import FilterList, {
  FilterAttribute,
  ItemsProps,
} from '../RegularFilter/FilterList'
import SettingsIcon from 'assets/icons/SettingsIcon'

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import { Collapse, Container, FilterTitle } from './styles'

const { Panel } = Collapse

export interface CandidatesFilterProps {
  isCandidatesExist: boolean
  onFilter: (prop: OnFilterProp) => void
  sourceList: ItemsProps[]
  stageList: ItemsProps[]
  statusList: ItemsProps[]
}

const CandidatesFilter: FC<CandidatesFilterProps> = ({
  isCandidatesExist,
  onFilter,
  sourceList,
  stageList,
  statusList,
}) => {
  const limit = 20
  const maxWidth = 90

  return (
    <Container isCandidatesExist={isCandidatesExist}>
      <FilterTitle>
        <SettingsIcon /> Filter Candidates
      </FilterTitle>

      <Collapse
        defaultActiveKey={[FilterAttribute.status, FilterAttribute.source]}
        expandIcon={({ isActive }) =>
          isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
        }
      >
        <Panel header="Stage" key={FilterAttribute.stage}>
          <FilterList
            title="All stages"
            showTitleCount
            limit={limit}
            attribute={FilterAttribute.stage}
            showCount
            maxWidth={maxWidth}
            items={stageList}
            onFilter={onFilter}
          />
        </Panel>
        <Panel header="Status" key={FilterAttribute.status}>
          <FilterList
            title="All statuses"
            showTitleCount
            limit={limit}
            attribute={FilterAttribute.status}
            showCount
            maxWidth={maxWidth}
            items={statusList}
            onFilter={onFilter}
          />
        </Panel>
        <Panel header="Source" key={FilterAttribute.source}>
          <FilterList
            title="All sources"
            showTitleCount
            limit={limit}
            attribute={FilterAttribute.source}
            showCount
            maxWidth={maxWidth}
            items={sourceList}
            onFilter={onFilter}
          />
        </Panel>
      </Collapse>
    </Container>
  )
}

export default CandidatesFilter
