import React, { FC, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { showDeleteJobModal, showExpireJobModal } from 'store/jobs'
import {
  getViewerSelector,
  isExternalRecruiterSelector,
  isTeamMemberSelector,
} from 'store/viewer'

import { searchStateToUrl } from 'helpers/algoliaHelpers'
import { checkHighRoleAccessibility } from 'helpers/jobHelpers'

import CloneJob from '../../CloneJob/CloneJob'
import BottomRowItems from '../components/BottomRowItems/BottomRowItems'
import ArchiveJobModal from 'app/components/Modals/ArchiveJobModal/ArchiveJobModal'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'

import {
  ClearOutlined,
  CopyOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  UserOutlined,
} from '@ant-design/icons'

import {
  ActionBlock,
  BlueText,
  BottomLeftInfo,
  BottomRow,
  CandidateButton,
  CandidateButtonWithCandidates,
  DropdownButton,
  JobActivatedAt,
  LiveJobItem,
  MenuItem,
  Title,
  TitleBlock,
  TopRow,
  TopRowRight,
} from './styles'

import { ArchiveJobType, IJobStatuses, JobDetailTab } from 'types'

const JobLive: FC<IJobStatuses> = ({
  objectID,
  title,
  status,
  company,
  isJHCampaign,
  totalCandidates,
  totalNewCandidates,
  referenceCode,
  activatedAt,
  relatedCampaign,
  ownerId,
  isExpired,
  isSSCampaign,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const viewer = useSelector(getViewerSelector)
  const isHighRole = checkHighRoleAccessibility(ownerId, viewer)
  const isTeamMember = useSelector(isTeamMemberSelector)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)

  const handleDeleteJob = () => {
    dispatch(
      showDeleteJobModal({
        jobTitle: title,
        jobId: objectID,
        jobStatus: status,
      }),
    )
  }

  const handleExpireJob = () => {
    dispatch(
      showExpireJobModal({
        jobTitle: title,
        jobId: objectID,
      }),
    )
  }

  const menu = (
    <Menu>
      <MenuItem key="View job">
        <Link
          to={{
            pathname: `${ROUTES.JOB}/${objectID}`,
            search: `?tab=${JobDetailTab.JobDetails}`,
          }}
        >
          <Button icon={<EyeOutlined />} type="text">
            View job
          </Button>
        </Link>
      </MenuItem>
      {!isTeamMember && !isExternalRecruiter && (
        <MenuItem key="Copy job">
          <CloneJob
            title="Copy job"
            id={objectID}
            campaignType={isJHCampaign}
            icon={<CopyOutlined />}
          />
        </MenuItem>
      )}

      <MenuItem key="Hiring Team">
        <Link
          to={{
            pathname: `${ROUTES.JOB}/${objectID}`,
            search: `?tab=${JobDetailTab.HiringTeam}`,
          }}
        >
          <Button icon={<UserOutlined />} type="text">
            Hiring Team
          </Button>
        </Link>
      </MenuItem>

      <Divider className="job-menu-divider" />

      {isHighRole && !isExpired && (
        <MenuItem key="Expire job boards">
          <Button
            type="text"
            onClick={handleExpireJob}
            icon={<FieldTimeOutlined />}
          >
            Expire job boards
          </Button>
        </MenuItem>
      )}

      {isHighRole && (
        <>
          <MenuItem key="Archive job">
            <ArchiveJobModal
              type={ArchiveJobType.Archive}
              title={title}
              buttonTitle="Archive job"
              jobId={+objectID}
              icon={<ClearOutlined />}
            />
          </MenuItem>
          <MenuItem key="Delete job">
            <Button
              onClick={handleDeleteJob}
              icon={<DeleteOutlined />}
              type="text"
              block
            >
              Delete job
            </Button>
          </MenuItem>
        </>
      )}
    </Menu>
  )

  const handleOpenJobWithNewCandidates = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const searchState = {
      tab: 'Candidates',
      refinementList: {
        status: ['New'],
      },
      page: 1,
      configure: {
        hitsPerPage: 100,
        filters: `advertId: ${objectID}`,
      },
    }
    const location = {
      pathname: `/job/${objectID}`,
      search: `?tab=${JobDetailTab.Candidates}`,
    }

    history.push(searchStateToUrl(searchState, location))
  }

  const handleGoToAllCandidates = () => {
    history.push({
      pathname: `/job/${objectID}`,
      search: `?tab=${JobDetailTab.Candidates}`,
    })
  }

  return (
    <LiveJobItem id={status}>
      <TopRow>
        <TitleBlock>
          <Title>{title}</Title>
        </TitleBlock>

        <TopRowRight>
          <ActionBlock
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.preventDefault()
            }}
          >
            {totalNewCandidates > 0 ? (
              <CandidateButtonWithCandidates
                onClick={handleOpenJobWithNewCandidates}
              >
                New Candidates <BlueText>{totalNewCandidates}</BlueText>
              </CandidateButtonWithCandidates>
            ) : (
              <CandidateButton onClick={handleOpenJobWithNewCandidates}>
                New Candidates {totalNewCandidates}
              </CandidateButton>
            )}

            <CandidateButton onClick={handleGoToAllCandidates}>
              All Candidates {totalCandidates}
            </CandidateButton>

            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
              getPopupContainer={item => item}
            >
              <DropdownButton icon={<EllipsisOutlined />} />
            </Dropdown>
          </ActionBlock>
        </TopRowRight>
      </TopRow>

      <BottomRow>
        <BottomLeftInfo>
          <JobActivatedAt>
            Live for
            <span>{formatDistanceToNowStrict(new Date(activatedAt))}</span>
          </JobActivatedAt>
        </BottomLeftInfo>

        <BottomRowItems
          isJHCampaign={isJHCampaign}
          relatedCampaign={relatedCampaign}
          referenceCode={referenceCode}
          company={company}
          status={status}
          isExpired={isExpired}
          isSSCampaign={isSSCampaign}
        />
      </BottomRow>
    </LiveJobItem>
  )
}

export default JobLive
