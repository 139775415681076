import { campaigns } from 'constants/app'

import {
  CVForwardTypes,
  CreditType,
  IJobFrequencySetting,
  JobCampaign,
  JobTimeEngaging,
  UsersRole,
} from 'types'

export const jobTypes = [
  { title: 'Permanent', value: 'Permanent' },
  { title: 'Contract', value: 'Contract' },
  { title: 'Temporary', value: 'Temporary' },
]

export const cvForwardingFrequencyTypes = [
  {
    title: CVForwardTypes.Daily,
    value: IJobFrequencySetting.Daily,
  },
  {
    title: CVForwardTypes.AsCVCome,
    value: IJobFrequencySetting.Directly,
  },
]

export const jobCampaignTypes = [
  { title: 'Jobheron Campaign', value: JobCampaign.Jobheron },
  { title: 'Self Service Campaign', value: JobCampaign.SelfService },
]

export const jobTimeEngagements = [
  { title: 'Full-Time', value: JobTimeEngaging.FullTime },
  { title: 'Part-Time', value: JobTimeEngaging.PartTime },
]

export const jobTimeInterval = [
  { title: 'Annual', value: 'Annual' },
  { title: 'Weekly', value: 'Weekly' },
  { title: 'Daily', value: 'Daily' },
  { title: 'Hourly', value: 'Hourly' },
]

export const roleForCreateUser = [
  {
    title: 'Company Admin',
    value: UsersRole.CompanyAdmin,
  },
  {
    title: 'Recruitment Manager',
    value: UsersRole.RecruitmentManager,
  },
  {
    title: 'Hiring Manager',
    value: UsersRole.HiringManager,
  },
  {
    title: 'Team Member',
    value: UsersRole.TeamMember,
  },
  {
    title: 'External Recruiter',
    value: UsersRole.ExternalRecruiter,
  },
]

export const inviteRoles = [
  {
    title: 'Company Admin',
    description:
      'Full access to all features, can change billing and edit users access level.',
    value: UsersRole.CompanyAdmin,
  },
  {
    title: 'Recruitment Manager',
    description:
      'Can see all jobs, all candidates, invite and remove members to jobs, purchase job campaign credits. ',
    value: UsersRole.RecruitmentManager,
  },

  {
    title: 'Hiring Manager',
    description:
      'Can see only jobs and candidates they created or joined, can invite and remove members from joined jobs, purchase job campaign credits.',
    value: UsersRole.HiringManager,
  },
  {
    title: 'Team Member',
    description:
      'Can only see jobs they are invited to and only candidates within those jobs. ',
    value: UsersRole.TeamMember,
  },
  {
    title: 'External Recruiter',
    description:
      'Can only see jobs they have been invited to, and only those candidates they have submitted. ',
    value: UsersRole.ExternalRecruiter,
  },
]

export const jobCreditTypes = [
  {
    title: campaigns.advertiser,
    value: CreditType.Advertiser,
  },
  {
    title: campaigns.managed,
    value: CreditType.Managed,
  },
  {
    title: campaigns.hire,
    value: CreditType.Hire,
  },
]
