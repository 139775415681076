import React, { FC, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  addCandidatePromiseCreator,
  fetchCandidateCountByFiltersPromiseCreator,
  fetchCandidateCountByKeywordPromiseCreator,
  listCandidatesPromiseCreator,
  parseCandidateByERPromiseCreator,
  updateCandidatePromiseCreator,
} from 'store/candidates'
import {
  getViewerSelector,
  isAdminSelector,
  isExternalRecruiterOrTeamMemberSelector,
  isExternalRecruiterSelector,
} from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'
import { getStoredCandidateFilters } from 'helpers/candidateHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import FormItem from '../../Forms/FormItem/FormItem'
import AddCandidateSource from '../../UI/AddCandidateSource/AddCandidateSource'
import UploadFile from '../../UI/UploadFile/UploadFile'
import DeleteCandidateModal from '../DeleteCandidateModal/DeleteCandidateModal'
import useActionsRoutines from 'hooks/useActionsRoutines'
import {
  addCandidateByAdminSchema,
  addCandidateByExternalRecruiterSchema,
  addCandidateSchema,
  editCandidateSchema,
} from 'validation'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Space from 'antd/lib/space'

import { FooterButtons } from './styles'

import { ApiAddCandidate, CandidateSource, RootState, UsersRole } from 'types'

const { TextArea } = Input

interface IAddCandidate {
  jobId: string
  isOpenCandidateModal: boolean
  handleCloseCandidateModal: () => void
  candidate?: any
  isEdit?: boolean
}

const AddCandidateModal: FC<IAddCandidate> = ({
  isEdit,
  candidate = {},
  jobId,
  isOpenCandidateModal,
  handleCloseCandidateModal,
}) => {
  const viewer = useSelector(getViewerSelector)
  const isAdmin = useSelector(isAdminSelector)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const isTeamMemberOrExternalRecruiter = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )

  const listCandidatesAction = useActionsRoutines(listCandidatesPromiseCreator)
  const fetchCandidateCountByKeyword = useActionsRoutines(
    fetchCandidateCountByKeywordPromiseCreator,
  )
  const fetchCandidateCountByFilters = useActionsRoutines(
    fetchCandidateCountByFiltersPromiseCreator,
  )
  const addCandidate = useActionsRoutines(addCandidatePromiseCreator)
  const updateCandidate = useActionsRoutines(updateCandidatePromiseCreator)
  const parseCandidateByER = useActionsRoutines(
    parseCandidateByERPromiseCreator,
  )
  const candidateStore = useSelector((state: RootState) => state.candidates)

  const getYupSchema = (role: UsersRole, isEdit?: boolean) => {
    if (isEdit) {
      return editCandidateSchema
    }

    switch (role) {
      case UsersRole.JHSuperAdmin:
        return addCandidateByAdminSchema
      case UsersRole.ExternalRecruiter:
        return addCandidateByExternalRecruiterSchema
      default:
        return addCandidateSchema
    }
  }

  const candidateData = {
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    email: candidate.email,
    phoneNumber: candidate.phoneNumber,
    source: CandidateSource.JobheronDb,
    cv: '',
    note: '',
  }

  const methods = useForm({
    resolver: yupResolver(getYupSchema(viewer.role, isEdit)),
    defaultValues: { ...candidateData },
  })

  const {
    formState: { errors },
    setError,
    control,
    watch,
    setValue,
  } = methods

  const handleCloseModal = () => {
    handleCloseCandidateModal()
    methods.reset()
  }

  const currentCv = watch('cv')
  const candidateId = candidate.id || candidate.objectID
  const candidateName = `${candidate.firstName} ${candidate.lastName}`

  const handleOnChangeUploadCv = async ({ file }: any) => {
    try {
      if (isExternalRecruiter && !isEdit) {
        const candidateFormData = new FormData()
        candidateFormData.append('cv', file)
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
        } = await parseCandidateByER(candidateFormData)

        methods.reset({
          firstName,
          lastName,
          email,
          phoneNumber,
          cv: file,
        })
      }
      setValue('cv', file)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    methods.reset({ ...candidateData })
  }, [isOpenCandidateModal])

  const handleAddCandidate = (newCandidate: ApiAddCandidate) => {
    if (isEdit) {
      return updateCandidate({ candidateId, newCandidate, currentCv })
        .then(() => {
          fetchCandidateCountByKeyword(
            getStoredCandidateFilters({
              jobId,
              parseQs: true,
              isByKeywordOnly: true,
            }),
          )
          fetchCandidateCountByFilters(
            getStoredCandidateFilters({ jobId, parseQs: true }),
          )
          listCandidatesAction(
            getStoredCandidateFilters({ jobId, parseQs: true }),
          ).then(() => {
            handleCloseCandidateModal()
          })
        })
        .catch(errors =>
          setFormErrors({
            setError,
            defaultItem: 'email',
            errors,
          }),
        )
    }

    addCandidate({ jobId, newCandidate, currentCv })
      .then(() => {
        fetchCandidateCountByKeyword(
          getStoredCandidateFilters({
            jobId,
            parseQs: true,
            isByKeywordOnly: true,
          }),
        )
        fetchCandidateCountByFilters(
          getStoredCandidateFilters({ jobId, parseQs: true }),
        )
        listCandidatesAction(
          getStoredCandidateFilters({ jobId, parseQs: true }),
        ).then(() => {
          handleCloseModal()
        })
      })
      .catch(errors => {
        setFormErrors({
          setError,
          defaultItem: 'email',
          errors,
        })
      })
  }

  const uploadFileProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    fileList: currentCv ? [currentCv] : [],
    beforeUpload: () => false,
    onChange: handleOnChangeUploadCv,
  }

  const handleOnRemoveFile = () => {
    setValue('cv', '')
  }

  const renderModalActionTitle = () => {
    if (isEdit) return 'Save'
    if (isExternalRecruiter) return 'Submit candidate'
    return 'Add'
  }

  const isLoading =
    candidateStore.listCandidates.isLoading ||
    candidateStore.addCandidate.isLoading ||
    candidateStore.updateCandidate.isLoading

  return (
    <>
      <Modal
        width={600}
        footer={null}
        title={isEdit ? 'Edit candidate' : 'Add candidate manually'}
        visible={isOpenCandidateModal}
        onCancel={handleCloseModal}
      >
        <FormProvider {...methods}>
          {isExternalRecruiter && !isEdit && (
            <Form.Item
              hasFeedback={!!errors.cv}
              validateStatus={errors.cv ? 'error' : 'success'}
              help={errors.cv?.message}
            >
              <Controller
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    uploadFileProps={uploadFileProps}
                    onRemoveFile={handleOnRemoveFile}
                  />
                )}
                control={control}
                name="cv"
                defaultValue=""
              />
            </Form.Item>
          )}

          <Form layout="vertical">
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <FormItem
                  name="firstName"
                  label="First name"
                  component={Input}
                  defaultValue=""
                />
              </Col>

              <Col xs={24} md={12}>
                <FormItem
                  name="lastName"
                  label="Last name"
                  component={Input}
                  defaultValue=""
                />
              </Col>

              <Col xs={24} md={12}>
                <FormItem
                  name="email"
                  label="Email address"
                  component={Input}
                  defaultValue=""
                />
              </Col>

              <Col xs={24} md={12}>
                <FormItem
                  name="phoneNumber"
                  label="Phone number (optional)"
                  component={Input}
                  defaultValue=""
                />
              </Col>
            </Row>

            {isEdit && (
              <Form.Item
                hasFeedback={!!errors.cv}
                validateStatus={errors.cv ? 'error' : 'success'}
                help={errors.cv?.message}
              >
                <Controller
                  render={({ field }) => (
                    <UploadFile
                      {...field}
                      uploadFileProps={uploadFileProps}
                      onRemoveFile={handleOnRemoveFile}
                    />
                  )}
                  control={control}
                  name="cv"
                  defaultValue={undefined}
                />
              </Form.Item>
            )}

            {!isEdit && (
              <Form.Item
                validateStatus={errors.note ? 'error' : 'success'}
                help={errors.note?.message}
              >
                <Controller
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      rows={isExternalRecruiter ? 7 : 10}
                      placeholder={
                        isExternalRecruiter
                          ? 'Candidate interview notes: salary requirements, notice period plus other relevant information.'
                          : 'Add note about candidate'
                      }
                    />
                  )}
                  control={control}
                  name="note"
                  defaultValue=""
                />
              </Form.Item>
            )}

            {isAdmin && !isEdit && (
              <AddCandidateSource
                jobId={jobId}
                sourcedBy={candidate.sourcedBy}
              />
            )}
          </Form>
        </FormProvider>

        <FooterButtons>
          <Space>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={methods.handleSubmit(handleAddCandidate)}
              loading={isLoading}
            >
              {renderModalActionTitle()}
            </Button>
          </Space>

          {!isTeamMemberOrExternalRecruiter && isEdit && (
            <DeleteCandidateModal
              isButton
              jobId={jobId}
              candidateId={candidateId}
              buttonTitle="Delete Candidate"
              buttonType="dashed"
              candidateName={candidateName}
            />
          )}
        </FooterButtons>
      </Modal>
    </>
  )
}

export default AddCandidateModal
