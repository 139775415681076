import { takeLatest } from 'redux-saga/effects'

import { approveCompany, fetchCompanies } from './actions'
import { ensureApproveCompany, ensureFetchCompanies } from './workers'

export function* watchApproveCompany() {
  yield takeLatest(approveCompany.TRIGGER, ensureApproveCompany)
}

export function* watchFetchCompanies() {
  yield takeLatest(fetchCompanies.TRIGGER, ensureFetchCompanies)
}
