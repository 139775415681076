import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  fetchCandidateCountByFiltersPromiseCreator,
  fetchCandidateCountByKeywordPromiseCreator,
  listCandidatesPromiseCreator,
  updateCandidatesStagePromiseCreator,
} from 'store/candidates'
import { isExternalRecruiterSelector } from 'store/viewer'

import { getStoredCandidateFilters } from 'helpers/candidateHelpers'

import snackbar from 'app/components/Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'

import { RadioChangeEvent } from 'antd'
import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Space from 'antd/lib/space'

import { InfoCircleOutlined, OneToOneOutlined } from '@ant-design/icons'

import { RadioList, StageTitle } from './styles'

import { JobStatus, RootState, StageType } from 'types'

interface IUpdateCandidateStage {
  candidatesId: string[]
  currentStage?: any
  isButton?: boolean
  advertStatus: JobStatus
  clearSelectionRows?: () => void
  appliedFromLm?: boolean
}

const UpdateCandidateStage: FC<IUpdateCandidateStage> = ({
  candidatesId,
  currentStage,
  isButton,
  advertStatus,
  clearSelectionRows,
  appliedFromLm,
}) => {
  const [stageId, setStageId] = useState(currentStage?.id || currentStage)
  const [newStageName, setNewStageName] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const listCandidatesAction = useActionsRoutines(listCandidatesPromiseCreator)
  const fetchCandidateCountByKeyword = useActionsRoutines(
    fetchCandidateCountByKeywordPromiseCreator,
  )
  const fetchCandidateCountByFilters = useActionsRoutines(
    fetchCandidateCountByFiltersPromiseCreator,
  )
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)

  const { stages } = useSelector((state: RootState) => state.stages.fetchStages)

  const findNewStageName = (value: number) => {
    const stage = stages.find(stage => stage.id === value)?.name
    setNewStageName(stage ? stage : '')
  }
  const { isLoading: isListLoading } = useSelector(
    (state: RootState) => state.candidates.listCandidates,
  )
  const { isLoading: isUpdating } = useSelector(
    (state: RootState) => state.candidates.updateCandidatesStage,
  )
  const {
    data: { items: candidate },
  } = useSelector((state: RootState) => state.candidates.listCandidates)

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleChangeStage = (e: RadioChangeEvent) => {
    const { value } = e.target
    setStageId(value)
    findNewStageName(value)
  }

  const updateCandidatesStage = useActionsRoutines(
    updateCandidatesStagePromiseCreator,
  )

  const handleUpdateCandidatesStage = () => {
    const jobId = candidate.length ? candidate[0].advertId : undefined
    updateCandidatesStage({
      ids: candidatesId,
      stageId,
      stages,
    })
      .then(() => {
        fetchCandidateCountByKeyword(
          getStoredCandidateFilters({
            jobId,
            parseQs: true,
            isByKeywordOnly: true,
          }),
        )
        fetchCandidateCountByFilters(
          getStoredCandidateFilters({ jobId, parseQs: true }),
        )
        listCandidatesAction(
          getStoredCandidateFilters({ jobId, parseQs: true }),
        ).then(() => {
          clearSelectionRows && clearSelectionRows()
          snackbar({
            content: `Stage changed to "${newStageName}" ${
              candidatesId.length > 1
                ? `for ${candidatesId.length} candidates`
                : ''
            }`,
            showIcon: true,
            type: 'info',
            icon: InfoCircleOutlined,
            closeText: 'OK',
          })
          handleCloseModal()
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    currentStage && setStageId(currentStage.id)
  }, [currentStage])

  const filteredStages = appliedFromLm
    ? stages.filter(({ type }) => type !== StageType.Sourced)
    : stages.filter(({ type }) => type !== StageType.Applied)

  const isLoading = isListLoading || isUpdating

  if (isExternalRecruiter) return null

  return (
    <div>
      {isButton ? (
        <Button type="primary" onClick={handleOpenModal}>
          Change stage
        </Button>
      ) : (
        <Menu.Item
          key="Change stage"
          icon={<OneToOneOutlined />}
          onClick={handleOpenModal}
        >
          Change stage
        </Menu.Item>
      )}
      <Modal
        width={550}
        footer={null}
        title="Change stage"
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        <Radio.Group value={stageId} onChange={handleChangeStage}>
          <RadioList direction="vertical">
            {filteredStages.map(({ name, id }) => (
              <Radio key={name} value={id}>
                <StageTitle>{name}</StageTitle>
              </Radio>
            ))}
          </RadioList>
        </Radio.Group>

        <div>
          <Space>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={handleUpdateCandidatesStage}
            >
              Submit
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  )
}

export default UpdateCandidateStage
