import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { useQuery } from 'helpers'

import CompaniesToApprove from './CompaniesToApprove/CompaniesToApprove'
import HiringTeam from './HiringTeam/HiringTeam'
import ManageUsersActions from './ManageUsersActions/ManageUsersActions'
import ExternalRecruitersList from 'app/components/ExternalRecruiters/ExternalRecruitersList/ExternalRecruitersList'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import SettingsIcon from 'assets/icons/SettingsIcon'
import WarningIcon from 'assets/icons/Warning'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Tabs from 'antd/lib/tabs'

import { EllipsisOutlined } from '@ant-design/icons'

import { Header, HiringTeamTab, JobActions, RowItem } from './styles'

import { IAlgoliaUser, ManageUsersTabs, RootState } from 'types'

const { TabPane } = Tabs

interface ICounterState {
  companiesToVerify: number
  hiringTeam: number
  activeCompanies: number
  externalRecruiters: number
}

const defaultCounterState = {
  companiesToVerify: 0,
  hiringTeam: 0,
  activeCompanies: 0,
  externalRecruiters: 0,
}

const ManageUsers = () => {
  const query = useQuery()
  const history = useHistory()
  const screens = useBreakpoint()
  const location = useLocation()
  const defaultTabQuery = query.get('tab')

  const [
    isExistNotVerifiedCompanies,
    setIsExistNotVerifiedCompanies,
  ] = useState(false)
  const [counterState, setCounterState] = useState<ICounterState>(
    defaultCounterState,
  )

  const handleCheckIfExistNotVerifiedCompanies = ({
    hits,
    nbHits,
  }: {
    hits: IAlgoliaUser[]
    nbHits: number
  }) => {
    setCounterState({ ...counterState, companiesToVerify: nbHits })
    setIsExistNotVerifiedCompanies(!!hits.length)
  }

  const handleCheckExternalRecruitersCounter = ({
    nbHits,
  }: {
    nbHits: number
  }) => {
    setCounterState({ ...counterState, externalRecruiters: nbHits })
  }
  const handleChangeTabs = (activeKey: any) => {
    history.replace({ search: `?tab=${activeKey}` })
  }

  useEffect(() => {
    if (
      location.pathname === ROUTES.ADMIN_MANAGE_USERS &&
      location.search === ''
    ) {
      history.replace({ search: `?tab=${ManageUsersTabs.HiringTeam}` })
    }
  }, [])

  const { data: hiringTeamCountData } = useSelector(
    (state: RootState) => state.users.userCounters,
  )

  const { Blocked, NotVerified, Pending, Verified } = hiringTeamCountData || {}

  const hiringTeamAmount =
    (Blocked || 0) + (NotVerified || 0) + (Pending || 0) + (Verified || 0)
  return (
    <>
      <Header>
        <RowItem justify="space-between" align="middle">
          <Col xs={{ span: 16 }} sm={{ span: 10 }}>
            <BackButton title="Manage users" />
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 14 }}>
            {screens.lg && <ManageUsersActions />}
            {screens.xs && (
              <JobActions>
                <Button icon={<SettingsIcon />} />
                <Button icon={<EllipsisOutlined />} />
              </JobActions>
            )}
          </Col>
        </RowItem>
      </Header>

      <Tabs
        defaultActiveKey={defaultTabQuery || ManageUsersTabs.HiringTeam}
        onChange={handleChangeTabs}
        tabBarExtraContent={!screens.lg && <ManageUsersActions />}
      >
        <TabPane
          tab={
            <HiringTeamTab>
              {isExistNotVerifiedCompanies && <WarningIcon />}
              Companies to verify ({counterState.companiesToVerify})
            </HiringTeamTab>
          }
          key={ManageUsersTabs.CompaniesToVerify}
          forceRender
        >
          <CompaniesToApprove
            handleCheckIfExistNotVerifiedCompanies={
              handleCheckIfExistNotVerifiedCompanies
            }
          />
        </TabPane>
        <TabPane
          forceRender
          tab={
            <HiringTeamTab>
              {!!NotVerified && <WarningIcon />}
              Hiring Team ({hiringTeamAmount})
            </HiringTeamTab>
          }
          key={ManageUsersTabs.HiringTeam}
        >
          <HiringTeam
            hiringTeamCountData={hiringTeamCountData}
            hiringTeamAmount={hiringTeamAmount}
          />
        </TabPane>
        {/* <TabPane
          tab={`Active Companies (${counterState.activeCompanies})`}
          key={ManageUsersTabs.ActiveCompanies}
        >
          Active Companies
        </TabPane> */}
        <TabPane
          forceRender
          tab={`External Recruiters (${counterState.externalRecruiters})`}
          key={ManageUsersTabs.ExternalRecruiters}
        >
          <ExternalRecruitersList
            refresh={false}
            filters=""
            callback={handleCheckExternalRecruitersCounter}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default ManageUsers
