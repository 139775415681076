import styled from 'styled-components/macro'

export const Container = styled.div`
  display: ${({ isShowFilter }: { isShowFilter: boolean }) =>
    isShowFilter ? 'block' : 'none'};
`

export const DesktopContainer = styled.div`
  border: 1px solid #d9d9d9;
  background: #fafafa;
  border-radius: 8px;
  margin: 26px 0;
  height: 100%;
  max-height: 225px;

  .ant-col {
    border-right: 1px solid #d9d9d9;
    padding: 24px 0 0 24px;

    &:last-child {
      border-right: 0;
    }
  }

  .ant-checkbox-wrapper {
    font-weight: normal;
  }
`

export const JobFilterItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 170px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f0f0f0;
    border-radius: 4px;
  }
`

export const MobileContainer = styled.div`
  margin-bottom: 30px;

  .ant-collapse-content {
    border: none;
    background: #fafafa;
  }

  .ant-collapse {
    border: none;
    border-radius: 0;
    background: #fafafa;
  }

  .ant-collapse-header {
    font-weight: 600;
  }

  .ant-collapse-item {
    border: none;

    .ant-space {
      margin-top: 10px;
    }
  }

  .ant-collapse-content-active {
    border-bottom: 1px solid #d9d9d9;
  }
`

export const Title = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`
