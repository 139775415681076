import React, { FC } from 'react'

interface IPlus {
  size?: number
  color?: string
}

const Plus: FC<IPlus> = ({ size = 14, color = '#2688FC' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.90332C3.13438 0.90332 0 4.0377 0 7.90332C0 11.7689 3.13438 14.9033 7 14.9033C10.8656 14.9033 14 11.7689 14 7.90332C14 4.0377 10.8656 0.90332 7 0.90332ZM10 8.27832C10 8.34707 9.94375 8.40332 9.875 8.40332H7.5V10.7783C7.5 10.8471 7.44375 10.9033 7.375 10.9033H6.625C6.55625 10.9033 6.5 10.8471 6.5 10.7783V8.40332H4.125C4.05625 8.40332 4 8.34707 4 8.27832V7.52832C4 7.45957 4.05625 7.40332 4.125 7.40332H6.5V5.02832C6.5 4.95957 6.55625 4.90332 6.625 4.90332H7.375C7.44375 4.90332 7.5 4.95957 7.5 5.02832V7.40332H9.875C9.94375 7.40332 10 7.45957 10 7.52832V8.27832Z"
        fill={color}
      />
    </svg>
  )
}

export default Plus
