import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { isExternalRecruiterOrTeamMemberSelector } from 'store/viewer'

import TablesIcon from '../assets/Tables'
import { CREATE_JHCAMPAIGN_JOB_URL } from 'app/modules/Jobs/CreateJob/SelectCampaignType/SelectCampaignType'

import Button from 'antd/lib/button'

import {
  EmptyJobs,
  EmptyJobsIcon,
  EmptyJobsSubTitle,
  EmptyJobsTitle,
} from './styles'

const NoJobs = () => {
  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )

  return (
    <EmptyJobs>
      <EmptyJobsIcon>
        <TablesIcon />
      </EmptyJobsIcon>

      <EmptyJobsTitle>No jobs, yet</EmptyJobsTitle>
      {!isExternalRecruiterOrTeamMember && (
        <>
          <EmptyJobsSubTitle>
            Click create a job to get started
          </EmptyJobsSubTitle>
          <Link to={CREATE_JHCAMPAIGN_JOB_URL}>
            <Button type="primary" size="large">
              Create a job
            </Button>
          </Link>
        </>
      )}
    </EmptyJobs>
  )
}

export default NoJobs
