import styled from 'styled-components'

import Menu from 'antd/lib/menu'

export const MenuCredits = styled(Menu)`
  &.ant-dropdown-menu {
    margin-right: -10px;
    margin-top: 20px;
    padding: 16px 0;
    min-width: 215px;
    font-weight: 400;
  }
`

export const MenuCreditsTitle = styled.h3`
  cursor: default;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray7};
`

export const MenuCreditsContent = styled.div`
  padding: 0 16px 0 32px;

  @media screen and (min-width: 768px) {
    padding: 0 16px;
  }
`

export const MenuCreditsRow = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin: 8px 0;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MenuCreditsRowBold = styled(MenuCreditsRow)`
  font-weight: 600;
  margin-bottom: 16px;
`
