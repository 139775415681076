import { createReducer } from '@reduxjs/toolkit'

import { getCheckoutInfo, purchaseCredits, transferCredits } from './actions'

import { ICreditsState } from 'types'

export const CREDITS_STATE: ICreditsState = {
  creditsPurchase: {
    isLoading: false,
  },
  getCheckoutInfo: {
    isLoading: false,
    fetchCheckoutInfo: undefined,
  },
  transferCredits: {
    isLoading: false,
  },
}

const creditsReducer = createReducer(CREDITS_STATE, {
  [transferCredits.TRIGGER]: state => {
    state.transferCredits.isLoading = true
  },
  [transferCredits.FAILURE]: state => {
    state.transferCredits.isLoading = false
  },
  [transferCredits.SUCCESS]: state => {
    state.transferCredits.isLoading = false
  },
  [purchaseCredits.TRIGGER]: state => {
    state.creditsPurchase.isLoading = true
  },
  [purchaseCredits.FAILURE]: state => {
    state.creditsPurchase.isLoading = false
  },
  [purchaseCredits.SUCCESS]: state => {
    state.creditsPurchase.isLoading = false
  },

  [getCheckoutInfo.TRIGGER]: state => {
    state.getCheckoutInfo.isLoading = true
  },
  [getCheckoutInfo.FAILURE]: state => {
    state.getCheckoutInfo.isLoading = false
  },
  [getCheckoutInfo.SUCCESS]: (state, { payload }) => {
    state.getCheckoutInfo.isLoading = false
    state.getCheckoutInfo.fetchCheckoutInfo = payload
  },
})

export default creditsReducer
