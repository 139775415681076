export enum UsersRole {
  TeamMember = 'TeamMember',
  ExternalRecruiter = 'ExternalRecruiter',
  HiringManager = 'HiringManager',
  RecruitmentManager = 'RecruitmentManager',
  CompanyAdmin = 'CompanyAdmin',
  JHSuperAdmin = 'JHSuperAdmin',
}

export interface ICreateUser {
  firstName: string
  lastName: string
  email: string
  password: string
  role: UsersRole
  companyName?: string
  companyWebsite?: string
  companyId?: number
}

export enum CompanyExistStatus {
  Existing = 'Existing',
  New = 'New',
}

export interface IApproveUser {
  userId: string
  role: UsersRole
  companyId: string
}
export interface ApiDeleteUser {
  userDeleteId: string
  userAcceptId: string
}

export interface IUpdateUserCompany extends IUpdateUserCompanyForm {
  id: number
}

export interface MoveUserToAnotherCompany {
  id: string
  companyId: string
}

export interface IUpdateUserCompanyForm {
  name: string
  website: string
}

export enum CompanyStatus {
  NotVerified = 'NotVerified',
  Verified = 'Verified',
}

export interface ApiUpdateUser {
  firstName: string
  lastName: string
  role?: UsersRole
  phoneNumber: number
  status: UserStatus
  advertiser: number
  managed: number
  hire: number
  email: string
}

export enum UserStatus {
  NotFinishedSignUp = 'NotFinishedSignUp',
  NotVerified = 'NotVerified',
  Pending = 'Pending',
  Verified = 'Verified',
  Blocked = 'Blocked',
}

export interface IInvite {
  company: string
  invitedAt: string
  invitedById: string
  status: UserStatus
}

export interface IAlgoliaUser {
  key: string
  company: string
  companyId: number
  credits: number
  email: string
  fullName: string
  invitedBy: IInvite[]
  objectID: string
  role: UsersRole
  signUpDate: Date
  blockedDate: Date
  signUpMethod: string
  status: UserStatus
  companyStatus: CompanyStatus
  userId: string
  balance?: { advertiser: number; managed: number; hire: number }
}

export interface UserCompany {
  id: number
  name: string
  status: CompanyStatus
  website: string[]
}

export interface IUser {
  balance: { advertiser: number; managed: number; hire: number }
  company: UserCompany
  credits: number
  email: string
  firstName: string
  id: string
  lastName: string
  fullName: string
  phoneNumber: string
  role: UsersRole
  signUpDate: string
  signUpMethod: string
  status: UserStatus
  companyId: number
  invitedToCompanies: UserCompany[]
  isLastCompanyAdmin: boolean
  isLastUser: boolean
  ownAdverts: boolean
}

export interface IUserCounterData {
  Blocked?: number
  NotVerified?: number
  Pending?: number
  Verified?: number
}

export interface IUserState {
  fetchUser: { isLoading: boolean; user: IUser | null }
  updateUser: { isLoading: boolean }
  deleteUser: { isLoading: boolean }
  updateUserCompany: { isLoading: boolean }
  moveUserToAnotherCompany: { isLoading: boolean }
  userData: { hits: IAlgoliaUser[] }
  approveUser: {
    isLoading: boolean
  }
  fetchCompanyUsers: { isLoading: boolean; hits: IAlgoliaUser[] }
  changeUserRole: {
    isLoading: boolean
  }
  userCounters: {
    isLoading: boolean
    data: null | IUserCounterData
  }
  createUser: {
    isLoading: boolean
  }
  refresh: boolean
}

export enum ManageUsersTabs {
  CompaniesToVerify = 'CompaniesToVerify',
  HiringTeam = 'HiringTeam',
  ActiveCompanies = 'ActiveCompanies',
  ExternalRecruiters = 'ExternalRecruiters',
}

export enum UserInvites {
  HiringTeam = 'HiringTeam',
  Company = 'Company',
}

export enum UserSignUpMethods {
  Email = 'Email',
  Google = 'Google',
  Linkedin = 'Linkedin',
}
