import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  span {
    margin-right: 5px;
  }
`

export const Name = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
