import styled from 'styled-components/macro'

export const SelectJobsContainer = styled.div`
  margin-left: 25px;
`
export const SelectCompanyContainer = styled.div`
  margin: 0 0 15px 25px;
`

export const RadioContainer = styled.div`
  .ant-radio-group {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select-selection-item-remove {
    display: none;
  }
`

export const InputContainer = styled.div`
  .ant-form-item {
    width: calc(100% - 68px);
  }
  .ant-input {
    border-radius: 8px 0 0 8px;
  }
  .ant-input-group.ant-input-group-compact {
    display: flex;
  }
`

export const Header = styled.h2`
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
`

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 12px 0;
`

export const Description = styled.p`
  margin: 32px 0;
`

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  font-size: 16px;
  width: 100%;

  .ant-radio-wrapper {
    align-items: center;
  }

  .ant-row {
    margin: 0;
  }

  h3 {
    margin: 0;
    font-weight: 600;
  }
  span {
    color: ${({ theme }) => theme.colors.gray7};
    font-weight: normal;
  }

  .ant-radio-group {
    width: 100%;
  }
`

export const ModalBody = styled.div`
  .ant-form {
    margin-top: 10px;
  }
`

export const AlertContainer = styled.div`
  margin-bottom: 10px;
`
