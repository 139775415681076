import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Space from 'antd/lib/space'

import { Container } from './styles'

import { AuthStatus } from 'types'

interface IAuthStatusComponent {
  title: string
  img: string
  text: string
  status: AuthStatus
  isInvitation?: boolean
  handleResendSRequest?: () => void
}

const AuthStatusComponent: FC<IAuthStatusComponent> = ({
  title,
  img,
  text,
  status,
  isInvitation,
  handleResendSRequest,
}) => {
  return (
    <Container>
      <img src={img} alt={title} />
      <h2>{title}</h2>
      <p>{text}</p>

      {isInvitation && (
        <Space>
          <Button>
            <Link to={ROUTES.SIGN_IN}>Sign in</Link>
          </Button>
          <Button type="primary">
            <Link to={ROUTES.SIGN_UP}>Sign up</Link>
          </Button>
        </Space>
      )}

      {(status === AuthStatus.ExpiredToken ||
        status === AuthStatus.NeedToBeVerified ||
        status === AuthStatus.InvalidToken) &&
        !isInvitation && (
          <Space>
            <Button>
              <Link to={ROUTES.SIGN_IN}>Go back</Link>
            </Button>
            <Button type="primary" onClick={handleResendSRequest}>
              Request link resend
            </Button>
          </Space>
        )}
    </Container>
  )
}

export default AuthStatusComponent
