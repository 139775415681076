export interface IDiscountPerCredit {
  from: number
  to: number
  savePerCredit: number
}

export interface ICampaignCreditType {
  id: number
  name: CreditType
  description: null | string
  credits: number
  price: number
  priceWithVat: number
  pricePerCredit: number
  vat: number
  saving: number
  discount: number
  discountPreCredit: IDiscountPerCredit[]
}

export interface ICreditsState {
  creditsPurchase: {
    isLoading: boolean
  }
  getCheckoutInfo: {
    isLoading: boolean
    fetchCheckoutInfo: undefined | ICampaignCreditType[]
  }
  transferCredits: {
    isLoading: boolean
  }
}

export interface IPurchaseCredits {
  packId: number
  credits: number
  advertId?: number
  successUrl?: string
  cancelUrl?: string
}

export interface ITransferCredits {
  fromUserId: string
  toUserId: string
  advertiser: number
  managed: number
  hire: number
}

export enum CreditType {
  Advertiser = 'Advertiser',
  Managed = 'Managed',
  Hire = 'Hire',
}

export interface UserBalance {
  managed: number
  advertiser: number
  hire: number
}

export interface CampaignBundles {
  title: string
  types: ICampaignCreditType[]
}

export interface IJobheronCampaigns {
  name: string
  value: string
  popular: boolean
  prevName: string | null
  advantages: {
    text: string
    tooltip?: string
  }[]
}
