import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { hideSnackbar } from '../../store/app'
import { hideMessage, isAdminSelector } from 'store/viewer'

import { useQuery } from 'helpers/useQuery'

import DeleteJob from '../../app/components/Modals/DeleteJob/DeleteJob'
import ExpireJob from '../../app/components/Modals/ExpireJob/ExpireJob'
import { ROUTES } from '../../routes'
import Header from 'app/components/Header/Header'
import snackbar from 'app/components/Snackbar/Snackbar'

import Button from 'antd/lib/button'
import message from 'antd/lib/message'

import { Body, Container } from './styles'

import { CreateJobTab, ManageUsersTabs, RootState } from 'types'

interface IMain {
  children: React.ReactNode
}

const Main: FC<IMain> = ({ children }) => {
  let snackbarTimer: number
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const query = useQuery()
  const activeTab = query.get('tab')
  const { showMessage } = useSelector((state: RootState) => state.viewer)
  const { appSnackbar } = useSelector((state: RootState) => state.app)
  const isJHAdmin = useSelector(isAdminSelector)

  const handleCloseMessage = () => {
    dispatch(hideMessage())
  }

  const handleHideSnackbar = () => {
    dispatch(hideSnackbar())
    message.destroy()
  }

  const handleRedirectToUsers = () => {
    handleCloseMessage()
    const redirectLink = isJHAdmin
      ? `${ROUTES.ADMIN_MANAGE_USERS}?tab=${ManageUsersTabs.HiringTeam}`
      : `${ROUTES.SETTING_ACCOUNT_MEMBERS}`
    history.push(redirectLink)
  }

  useEffect(() => {
    return () => {
      clearTimeout(snackbarTimer)
    }
  }, [])

  useEffect(() => {
    if (appSnackbar.isOpen) {
      snackbar({
        content: appSnackbar.message,
        showIcon: true,
        duration: 0,
        type: 'info',
        onClose: handleHideSnackbar,
      })

      snackbarTimer = window.setTimeout(() => {
        handleHideSnackbar()
      }, 5000)
    }
  }, [appSnackbar.isOpen])

  // TODO try to combine 2 snackbars
  useEffect(() => {
    if (showMessage) {
      snackbar({
        content: 'You have new users to approve',
        showIcon: true,
        type: 'warning',
        onClose: handleCloseMessage,
        action: (
          <Button type="link" onClick={handleRedirectToUsers}>
            Review
          </Button>
        ),
      })
    }

    return () => {
      message.destroy()
    }
  }, [showMessage])

  const isJobsPage =
    location.pathname === ROUTES.JOBS ||
    activeTab === CreateJobTab.SelectCampaign

  // TODO Need find a better way to check the candidate page
  const isCandidatePage = location.pathname.includes('candidate')

  return (
    <>
      <Header />

      {isCandidatePage ? (
        <>{children}</>
      ) : (
        <Body isJobsPageOrCreateJobPage={isJobsPage}>
          <Container>{children}</Container>
        </Body>
      )}

      <DeleteJob />
      <ExpireJob />
    </>
  )
}

export default Main
