import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { isExternalRecruiterOrTeamMemberSelector } from 'store/viewer'

import { formatDate } from 'helpers/formatDate'

import DownloadCv from '../../../Candidate/DownloadCV/DownloadCV'
import EditCandidate from '../../../Candidate/EditCandidate/EditCandidate'
import ChangeCandidateStatusModal from '../../../Modals/ChangeCandidateStatusModal/ChangeCandidateStatusModal'
import DeleteCandidateModal from '../../../Modals/DeleteCandidateModal/DeleteCandidateModal'
import ForwardCVModal from '../../../Modals/ForwardCVModal/ForwardCVModal'
import UpdateCandidateStage from '../../../Modals/UpdateCandidateStage/UpdateCandidateStage'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Drawer from 'antd/lib/drawer'
import Dropdown from 'antd/lib/dropdown'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import {
  CloseOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'

import {
  CloseBtn,
  Container,
  DrawerHeader,
  DrawerHeaderTitle,
  MenuContainer,
} from './styles'

import { IAlgoliaCandidate, RootState } from 'types'

interface ICandidateAction {
  candidate: IAlgoliaCandidate
  setRows?: (values: string[]) => void
}

const Actions: FC<ICandidateAction> = ({ candidate, setRows }) => {
  const screens = useBreakpoint()
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )
  const { status: jobStatus, id } = useSelector(
    (state: RootState) => state.jobs.fetchJob.job!,
  )

  const {
    objectID,
    applied,
    hasCv,
    firstName = '',
    lastName = '',
    advertId,
    status,
    stage,
  } = candidate

  const menu = (
    <MenuContainer>
      <div onClick={e => e.stopPropagation()}>
        <DownloadCv
          isShowIcon
          id={Number(objectID)}
          isHasCv={hasCv}
          fullName={`${firstName} ${lastName}`}
        />

        <ForwardCVModal
          candidateId={+objectID}
          buttonTitle="Forward CV"
          isHasCv={hasCv}
        />

        <Divider className="job-menu-divider" />

        <UpdateCandidateStage
          currentStage={stage}
          candidatesId={[objectID]}
          advertStatus={jobStatus}
        />

        <ChangeCandidateStatusModal
          candidatesId={[objectID]}
          currentStatus={status}
          advert={{ id: String(id), status: jobStatus }}
        />

        <Divider className="job-menu-divider" />

        <EditCandidate jobId={String(advertId)} candidate={candidate} />

        {!isExternalRecruiterOrTeamMember && (
          <DeleteCandidateModal
            jobId={String(advertId)}
            candidateId={objectID}
            buttonTitle="Delete Candidate"
            buttonType="link"
            candidateName={`${firstName} ${lastName}`}
            icon={<DeleteOutlined />}
          />
        )}
      </div>
    </MenuContainer>
  )

  return (
    <Container>
      {formatDate(applied, 'dd/MM/yy')}

      {screens.xs && (
        <>
          <Button
            type="text"
            icon={<EllipsisOutlined />}
            onClick={e => {
              e.preventDefault()
              setIsOpenMobileMenu(true)
            }}
          />
          <Drawer
            placement="bottom"
            width="100%"
            height="auto"
            closable={false}
            visible={isOpenMobileMenu}
            onClose={e => {
              e.preventDefault()
              setIsOpenMobileMenu(false)
            }}
          >
            <DrawerHeader>
              <DrawerHeaderTitle>{`${candidate.firstName} ${candidate.lastName}`}</DrawerHeaderTitle>
              <Button
                onClick={e => {
                  e.preventDefault()
                  setIsOpenMobileMenu(false)
                }}
              >
                <CloseOutlined />
              </Button>
            </DrawerHeader>

            <div onClick={() => setIsOpenMobileMenu(false)}>
              {menu}

              <Divider className="job-menu-divider" />
              <CloseBtn
                icon={<CloseOutlined />}
                type="text"
                onClick={e => {
                  e.stopPropagation()
                  setIsOpenMobileMenu(false)
                }}
              >
                Cancel
              </CloseBtn>
            </div>
          </Drawer>
        </>
      )}

      {!screens.xs && (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Button
            type="text"
            icon={<EllipsisOutlined />}
            onClick={e => {
              e.stopPropagation()
              !!setRows && setRows([])
            }}
          />
        </Dropdown>
      )}
    </Container>
  )
}

export default Actions
