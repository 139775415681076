import React from 'react'
import { Link } from 'react-router-dom'

import CreditsItems from './CreditsItems'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { MenuCredits, MenuCreditsContent, MenuCreditsTitle } from './styles'

const CreditsMenu = () => {
  return (
    <MenuCredits>
      <MenuCreditsContent>
        <MenuCreditsTitle>Jobheron Campaign Credits</MenuCreditsTitle>
      </MenuCreditsContent>
      <CreditsItems />
      <MenuCreditsContent>
        <Button size="large" type="primary" block>
          <Link to={`${ROUTES.CHECKOUT}?redirectURL=${window.location.href}`}>
            Buy Credits
          </Link>
        </Button>
      </MenuCreditsContent>
    </MenuCredits>
  )
}

export default CreditsMenu
