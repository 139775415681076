import React from 'react'
import { useSelector } from 'react-redux'

import { isAdminSelector } from 'store/viewer'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { InfoBlock, Text } from './styles'

const CreateJobInfo = () => {
  const screens = useBreakpoint()
  const isJHAdmin = useSelector(isAdminSelector)

  if (screens.md) {
    return (
      <InfoBlock isJHAdmin={isJHAdmin}>
        <h2>General tips</h2>

        <Text bottom={150}>Keep your job title clear and simple.</Text>

        <Text bottom={360}>
          Paste your job advert/spec here. <br />
          Ads with 700 words or less work best! <br />
          You should include:
          <ul>
            <li>
              A description of your organization and the reasons why an
              applicant would want to work for you.
            </li>
            <li>The job’s main duties and responsibilities.</li>
            <li>
              Details of the essential and desirable experience you’re seeking.
            </li>
            <li>
              The working hours, benefits, working location and any travel
              requirements.
            </li>
          </ul>
        </Text>
        <Text bottom={65}>
          For commission based roles list the OTE here to help attract the best
          talent. Also mention benefits like gym membership, flexi-time, on-site
          parking, private healthcare etc.
        </Text>
        <Text bottom={110}>
          Is this job part or full-time? Temporary or permanent? Let applicants
          know, so there are no surprises later on.
        </Text>

        <Text bottom={105}>
          Jobs with a listed salary receive 25% more applicants than those
          without. A salary range helps applicants decide if they are the right
          level for the job.
        </Text>

        <Text bottom={120}>
          Some applicants don't mind a commute. Others want to work near their
          home. So, let them know where you are.
        </Text>

        <Text bottom={20}>
          Automatically filter applications by searching their CVs for specific
          keywords. CVs that do not contain at least one of your keywords will
          be marked as Rejected
        </Text>

        <Text>
          We may need to edit your advert to ensure it meets with our editorial
          guidelines and best practices. If you’d like to have final approval
          once these changes are made, toggle this to "I want to approve
          changes". Once published, an advert can not be edited.
        </Text>
      </InfoBlock>
    )
  }

  return null
}

export default CreateJobInfo
