import { ROUTES, allSettingsRoutes, settingsMenu } from '../routes'
import _ from 'lodash'

import { UserStatus, UsersRole } from '../types'

interface IBaseSettings {
  status: UserStatus
  role: UsersRole
}

interface ScrollTo {
  id: string
  yOffset?: number
}

export const getViewerSettingsMenu = ({ status, role }: IBaseSettings) => {
  if (status === UserStatus.NotVerified) {
    return settingsMenu.filter(({ group }) => group === 'Personal')
  }

  return _.flatMapDepth(settingsMenu, ({ group, children }) => {
    return {
      group,
      children: children.filter(({ permissions }) =>
        permissions.includes(role),
      ),
    }
  })
}

export const getViewerSettingsRoutes = ({ status, role }: IBaseSettings) => {
  if (status === UserStatus.NotVerified) {
    return allSettingsRoutes.filter(
      ({ path }) => path === ROUTES.SETTING_PROFILE,
    )
  }

  return allSettingsRoutes.filter(({ permissions }) =>
    permissions.includes(role),
  )
}

export const camelCaseToString = (text: string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const titleCaseToWords = (text: string) =>
  text.split(/(?=[A-Z])/).join(' ')

export const scrollTo = ({ id, yOffset }: ScrollTo) => {
  const customOffset = yOffset || -80
  const section = document.getElementById(id)
  if (section) {
    const y =
      section.getBoundingClientRect().top + window.pageYOffset + customOffset
    window.scrollTo({ top: y })
  }
}
