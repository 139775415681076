import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { fetchUserPromiseCreator, updateUserPromiseCreator } from 'store/users'
import {
  isAdminOrCompanyAdminSelector,
  isAdminSelector,
  isCompanyAdminSelector,
} from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'
import { checkIsViewerHasBalance } from 'helpers/creditsHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import snackbar from '../../components/Snackbar/Snackbar'
import UpdateUserCompany from './UpdateUserCompany'
import UpdateUserCredits from './UpdateUserCredits'
import { userStatuses } from './constants'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import DeleteUserModal from 'app/components/Modals/DeleteUserModal/DeleteUserModal'
import Status from 'app/components/ResponsiveTables/UserTable/Status/Status'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import { roleForCreateUser } from 'app/modules/Jobs/constants'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'
import updateUserSchema from 'validation/updateUserSchema'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'

import { SpinContainer } from '../Jobs/DetailJob/styles'
import { Actions, ColItem, GroupContainer, RowHeader, Title } from './styles'

import {
  ApiUpdateUser,
  IError,
  RootState,
  UserSignUpMethods,
  UserStatus,
  UsersRole,
} from 'types'

const { Option } = Select

const UpdateUser = () => {
  const history = useHistory()
  const { userId } = useParams<{ userId: string }>()

  const fetchUser = useActionsRoutines(fetchUserPromiseCreator)
  const updateUser = useActionsRoutines(updateUserPromiseCreator)
  const isAdminOrCompanyAdmin = useSelector(isAdminOrCompanyAdminSelector)
  const isAdmin = useSelector(isAdminSelector)
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)

  const { user, isLoading: isFetchingUser } = useSelector(
    (state: RootState) => state.users.fetchUser,
  )
  const isUserHasBalance =
    user?.balance && checkIsViewerHasBalance(user.balance)

  const { isLoading } = useSelector(
    (state: RootState) => state.users.updateUser,
  )

  const methods = useForm({
    resolver: yupResolver(updateUserSchema),
    mode: 'onSubmit',
  })

  const { errors } = methods.formState

  useEffect(() => {
    fetchUser({ userId })
  }, [])

  const handleEditUser = async ({
    firstName,
    lastName,
    role,
    status,
    phoneNumber,
    advertiser,
    managed,
    hire,
    email,
  }: ApiUpdateUser) => {
    try {
      await updateUser({
        id: userId,
        firstName,
        lastName,
        role,
        status,
        phoneNumber,
        advertiser,
        managed,
        hire,
        email,
      })
      snackbar({
        content: 'Profile updated',
        showIcon: true,
        type: 'info',
        closeText: 'OK',
      })
    } catch (errors) {
      setFormErrors({
        setError: methods.setError,
        defaultItem: 'role',
        errors: errors as IError[],
      })
    }
  }

  useEffect(() => {
    if (user) {
      methods.reset({
        ...user,
        advertiser: String(user.balance.advertiser),
        managed: user.balance.managed,
        hire: user.balance.hire,
        companyName: user.company.name,
        companyWebsite: user.company.website[0],
        companyStatus: user.company.status,
      })
    }
  }, [user])

  if (isFetchingUser || !user) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    )
  }

  const canEdit =
    !isAdminOrCompanyAdmin ||
    (user.role === UsersRole.ExternalRecruiter && !isAdmin)

  const handleRedirect = () => {
    const link = isAdmin
      ? ROUTES.ADMIN_MANAGE_USERS
      : ROUTES.SETTING_ACCOUNT_MEMBERS
    history.push(link)
  }

  const canEmailEdit = isAdmin && user.signUpMethod === UserSignUpMethods.Email

  return (
    <div>
      <Helmet>
        <title>Edit user</title>
      </Helmet>
      <RowHeader>
        <Col span={12}>
          <BackButton title={user.fullName} onBack={handleRedirect} />
        </Col>
        {(isAdmin ||
          (isCompanyAdmin && user.role !== UsersRole.ExternalRecruiter)) && (
          <ColItem span={12}>
            <Button type="default" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={methods.handleSubmit(handleEditUser)}
            >
              Save changes
            </Button>
          </ColItem>
        )}
      </RowHeader>
      <FormProvider {...methods}>
        <Form layout="vertical" onFinish={methods.handleSubmit(handleEditUser)}>
          <GroupContainer>
            <Title>User details</Title>
            <Row gutter={24} justify="start">
              <Col xs={24} md={6}>
                <FormItem
                  name="firstName"
                  label="First name"
                  component={Input}
                  defaultValue=""
                  componentProps={{
                    disabled: canEdit,
                  }}
                />
              </Col>

              <Col xs={24} md={6}>
                <FormItem
                  name="lastName"
                  label="Last name"
                  component={Input}
                  defaultValue=""
                  componentProps={{ disabled: canEdit }}
                />
              </Col>

              <Col xs={24} md={6}>
                <FormItem
                  name="signUpMethod"
                  label="Sign up method"
                  component={Input}
                  defaultValue=""
                  componentProps={{ disabled: true }}
                />
              </Col>

              <Col xs={24} md={6}>
                <FormItem
                  name="email"
                  label="Email address"
                  component={Input}
                  defaultValue=""
                  componentProps={{ disabled: !canEmailEdit }}
                />
              </Col>

              <Col xs={24} md={6}>
                <FormItem
                  name="phoneNumber"
                  label="Phone number"
                  component={Input}
                  defaultValue=""
                  componentProps={{ disabled: canEdit }}
                />
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  hasFeedback={!!errors.role}
                  validateStatus={errors.role ? 'error' : 'success'}
                  help={errors.role && errors.role?.message}
                  label="Access Level"
                >
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={field.onChange}
                        disabled={
                          user.role === UsersRole.ExternalRecruiter ||
                          user?.status !== UserStatus.Verified ||
                          !isAdminOrCompanyAdmin
                        }
                      >
                        {roleForCreateUser.map(({ value, title }) => (
                          <Option
                            key={value}
                            value={value}
                            hidden={
                              value === UsersRole.ExternalRecruiter ||
                              (value === UsersRole.TeamMember &&
                                isUserHasBalance)
                            }
                          >
                            {title}
                          </Option>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="role"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  hasFeedback={!!errors.status}
                  validateStatus={errors.status ? 'error' : 'success'}
                  help={errors.status && errors.status?.message}
                  label="Status"
                >
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={field.onChange}
                        disabled={user.status === UserStatus.Verified}
                      >
                        {userStatuses.map(({ value }) => (
                          <Option key={value} value={value}>
                            <Status status={value} />
                          </Option>
                        ))}
                      </Select>
                    )}
                    control={methods.control}
                    name="status"
                  />
                </Form.Item>
              </Col>
              {isAdminOrCompanyAdmin && (
                <Col xs={24} md={6}>
                  <Actions>
                    <DeleteUserModal user={user} />
                  </Actions>
                </Col>
              )}
            </Row>
          </GroupContainer>

          {isAdminOrCompanyAdmin && <UpdateUserCredits user={user} />}

          <UpdateUserCompany user={user} />
        </Form>
      </FormProvider>
    </div>
  )
}

export default UpdateUser
