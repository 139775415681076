import styled from 'styled-components'

export const Dot = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
`
export const ShortlistedDot = styled(Dot)`
  background: #13c2c2;
`
export const MaybeDot = styled(Dot)`
  background: #ffa940;
`
export const RejectedDot = styled(Dot)`
  background: #ff7875;
`
export const AutoRejectedDot = styled(Dot)`
  background: #ff7a45;
`
export const NewDot = styled(Dot)`
  background: #40a9ff;
`
export const ViewedDot = styled(Dot)`
  background: #bfbfbf;
`
