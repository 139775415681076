import React, { FC, useMemo } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import { getRefreshSelector } from 'store/app/selectors'
import { getViewerSelector } from 'store/viewer'

import AlgoliaLoaderWrapper from '../../../../components/Loaders/AlgoliaLoaderWrapper/AlgoliaLoaderWrapper'
import CompaniesToVerifyTable from '../../../../components/ResponsiveTables/CompaniesToVerifyTable/CompaniesToVerifyTable'
import algoliasearch from 'algoliasearch/lite'
import AccessLevelInfo from 'app/components/UI/AccessLevelInfo/AccessLevelInfo'
import AlgoliaPagination from 'app/components/UI/AlgoliaPagination/AlgoliaPagination'
import AlgoliaSearch from 'app/components/UI/AlgoliaSearch/AlgoliaSearch'
import useSearchToQuery from 'hooks/useSearchToQuery'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import { Container, Header, RightAction } from './styles'

import { RootState } from 'types'

interface ICompaniesToApprove {
  handleCheckIfExistNotVerifiedCompanies: (value: any) => void
}

const CompaniesToApprove: FC<ICompaniesToApprove> = ({
  handleCheckIfExistNotVerifiedCompanies,
}) => {
  const viewer = useSelector(getViewerSelector)
  const refreshAlgoliaCache = useSelector(getRefreshSelector)
  const companiesToApprove = useSelector(
    (state: RootState) => state.companiesToApprove,
  )
  const { onSearchStateChange, searchState } = useSearchToQuery({})

  const indexName = viewer.algoliaKeys.users.companiesToApprove
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      searchState={searchState}
      refresh={refreshAlgoliaCache}
      onSearchStateChange={onSearchStateChange}
    >
      <Container>
        <Header>
          <Row align="middle">
            <Col md={10} lg={12}>
              <AccessLevelInfo />
            </Col>
            <Col md={14} lg={12}>
              <RightAction>
                <AlgoliaSearch />
              </RightAction>
            </Col>
          </Row>
        </Header>

        <Index indexName={indexName}>
          <Configure distinct />
          <AlgoliaLoaderWrapper
            callback={handleCheckIfExistNotVerifiedCompanies}
          >
            <CompaniesToVerifyTable
              indexName={indexName}
              searchClient={searchClient}
              refreshAlgoliaCache={refreshAlgoliaCache}
              isLoading={companiesToApprove.approveCompany.isLoading}
            />
          </AlgoliaLoaderWrapper>
          <AlgoliaPagination />
        </Index>
      </Container>
    </InstantSearch>
  )
}

export default CompaniesToApprove
