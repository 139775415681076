import styled from 'styled-components'

import Card from 'antd/lib/card'
import Col from 'antd/lib/col'
import InputNumber from 'antd/lib/input-number'

export const CardContainer = styled.div`
  margin-bottom: 16px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

export const ColItem = styled(Col)`
  display: flex;
  justify-content: flex-end;
`
export const CardBox = styled(Card)`
  min-height: 474px;
  padding: 16px;
  .ant-card-body {
    padding: 0;
  }
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f8f8f8;
  padding: 16px;
  margin-bottom: 23px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`
export const CardBuyItem = styled.div`
  display: flex;
  margin-bottom: 24px;
  .ant-btn {
    flex: 1;
    margin-left: 8px;
  }
`
export const CardCount = styled(InputNumber)`
  width: 64px;
  .ant-input-number-handler-wrap {
    opacity: 1;
  }
`
export const CardList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
    svg {
      fill: ${({ theme }) => theme.colors.blue6};
      margin-right: 10px;
    }
    .anticon-info-circle {
      svg {
        fill: #8c8c8c;
      }
    }
  }
  li:last-child {
    margin-bottom: 0;
  }
`
export const Discount = styled.span`
  margin-left: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.orange6};
`
export const PopularItem = styled.span`
  background: #fff7e6;
  border-radius: 6px 6px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.orange6};
  padding: 1px 8px;
`
export const PopularItemWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const CardPrice = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`
export const AdditionalBlock = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.blue6};
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.colors.blue6};
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
`
