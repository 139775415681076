import styled from 'styled-components'

import Comment from 'antd/lib/comment'

export const Container = styled.div`
  padding: 20px 0;
`

export const CommentWrapper = styled(Comment)`
  h2 {
    margin: 0;
    &:hover {
      color: ${({ theme }) => theme.colors.gray9};
    }
  }
  p {
    padding-bottom: 15px;
  }
  .ant-comment-content-author {
    display: flex;
    align-items: center;
  }

  .ant-comment-inner {
    padding: 0;
  }
  .ant-comment-avatar {
    padding-bottom: 30px;
  }
`

export const CommentInfo = styled.h2`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray9};
`

export const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e6f7ff;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.blue6};
  position: relative;
  text-transform: uppercase;
`

export const CommentTime = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray7};
`
export const NoNotes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: 50vh;
`

export const SpinnerContainer = styled.div`
  text-align: center;
`
