import styled from 'styled-components/macro'

import Input from 'antd/lib/input'
import Tag from 'antd/lib/tag'

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  min-height: 40px;
  .ant-tag {
    display: inline-flex;
    align-items: center;
    margin: 7px 5px;
    padding: 0 10px;

    .ant-btn {
      padding: 0;
      margin: 0;
      height: auto;
      min-height: auto;
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 5px;
    }
  }
`
export const EmailTag = styled(Tag)`
  background: #f0f0f0;
  border: 1px solid ${({ theme }) => theme.colors.gray6};
  &.ant-tag .ant-btn {
    margin-left: 8px;
    svg {
      margin: 0;
    }
  }
`

export const AddNewEmail = styled(Tag)`
  border: none;
  cursor: pointer;
`

export const InputContainer = styled.div`
  display: flex;
  min-width: 200px;
  margin: 4px 5px;

  .ant-input-group-addon {
    padding: 0;
  }

  .ant-input {
    padding: 0 7px;
    background: #f0f0f0;
    border: 1px solid ${({ theme }) => theme.colors.gray6};
    &:focus {
      border-color: ${({ theme }) => theme.colors.gray6};
      box-shadow: unset;
    }
  }

  .ant-btn {
    padding: 0 5px;
    margin: 0;
    height: auto;
    width: auto;
    min-height: auto;
    display: inline-flex;
    align-items: center;
  }
`

export const InputWithPlaceholder = styled(Input)`
  border: none;
  &:focus {
    border: none;
    box-shadow: none;
  }
`
export const InputContainerActions = styled.div`
  display: flex;
  align-items: center;
`
