import { createAction } from '@reduxjs/toolkit'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

import { IAlgoliaUser } from 'types'

export const clearCandidate = createAction('candidates/clearCandidate')
export const clearCandidateTimeline = createAction(
  'candidates/clearCandidateTimeline',
)
export const clearCandidateNotes = createAction(
  'candidates/clearCandidateNotes',
)

export const storeCandidates = createAction<{ hits: IAlgoliaUser[] }>(
  'candidates/storeCandidates',
)

export const fetchCandidate = createRoutine('candidates/fetchCandidate')
export const fetchCandidatePromiseCreator = promisifyRoutine(fetchCandidate)

export const listCandidates = createRoutine('candidates/listCandidates')
export const listCandidatesPromiseCreator = promisifyRoutine(listCandidates)

export const fetchCandidateCountByKeyword = createRoutine(
  'candidates/fetchCandidateCountByKeyword',
)
export const fetchCandidateCountByKeywordPromiseCreator = promisifyRoutine(
  fetchCandidateCountByKeyword,
)

export const fetchCandidateCountByFilters = createRoutine(
  'candidates/fetchCandidateCountByFilters',
)
export const fetchCandidateCountByFiltersPromiseCreator = promisifyRoutine(
  fetchCandidateCountByFilters,
)

export const addCandidate = createRoutine('candidates/addCandidate')
export const addCandidatePromiseCreator = promisifyRoutine(addCandidate)

export const updateCandidate = createRoutine('candidates/updateCandidate')
export const updateCandidatePromiseCreator = promisifyRoutine(updateCandidate)

export const addCandidates = createRoutine('candidates/addCandidates')
export const addCandidatesPromiseCreator = promisifyRoutine(addCandidates)

export const downloadCv = createRoutine('candidate/downloadCv')
export const downloadCvPromiseCreator = promisifyRoutine(downloadCv)

export const parseCandidateByER = createRoutine('candidates/parseCandidateByER')
export const parseCandidateByERPromiseCreator = promisifyRoutine(
  parseCandidateByER,
)

export const deleteCandidate = createRoutine('candidate/deleteCandidate')
export const deleteCandidatePromiseCreator = promisifyRoutine(deleteCandidate)

export const fetchCandidateAssociatedMembers = createRoutine(
  'candidates/fetchCandidateAssociatedMembers',
)
export const fetchCandidateAssociatedMembersPromiseCreator = promisifyRoutine(
  fetchCandidateAssociatedMembers,
)

export const updateCandidatesStatus = createRoutine(
  'candidates/updateCandidatesStatus',
)
export const updateCandidatesStatusPromiseCreator = promisifyRoutine(
  updateCandidatesStatus,
)

export const updateCandidatesStage = createRoutine(
  'candidates/updateCandidatesStage',
)
export const updateCandidatesStagePromiseCreator = promisifyRoutine(
  updateCandidatesStage,
)

export const postCandidateNote = createRoutine('candidates/postCandidateNote')
export const postCandidateNotePromiseCreator = promisifyRoutine(
  postCandidateNote,
)

export const fetchCandidateTimeline = createRoutine(
  'candidates/fetchCandidateTimeline',
)
export const fetchCandidateTimelinePromiseCreator = promisifyRoutine(
  fetchCandidateTimeline,
)

export const fetchCandidateNotes = createRoutine(
  'candidates/fetchCandidateNotes',
)
export const fetchCandidateNotesPromiseCreator = promisifyRoutine(
  fetchCandidateNotes,
)

export const forwardCandidates = createRoutine('candidates/forwardCandidates')
export const forwardCandidatesPromiseCreator = promisifyRoutine(
  forwardCandidates,
)

export const forwardCandidatesCSV = createRoutine(
  'candidates/forwardCandidatesCSV',
)

export const forwardCandidatesCSVPromiseCreator = promisifyRoutine(
  forwardCandidatesCSV,
)
