import styled from 'styled-components/macro'

import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import Menu from 'antd/lib/menu'
import Row from 'antd/lib/row'

import { UsersRole } from 'types'

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
  width: 100%;
  background: #fff;
`

export const MenuButton = styled(Button)``

export const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
`

export const UserName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 600;
  margin-right: 16px;
  background: ${props =>
    props.role === UsersRole.JHSuperAdmin ? '#FFF2E8' : '#E6F7FF'};
  color: ${props =>
    props.role === UsersRole.JHSuperAdmin ? '#FF7A45' : '#2688FC'};
`

export const UserTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
`

export const MobileDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    overflow-x: hidden;
  }
`

export const CreditBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: auto;
  svg {
    position: absolute;
  }
`

export const CreditBlockText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  z-index: 2;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`

export const SubMenuItem = styled(Menu.SubMenu)`
  svg {
    transition: all 0.3s;
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.blue6};
    }
  }
  .ant-menu.ant-menu-sub {
    background: #fff;
  }
  .ant-menu-item-group {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:last-child {
      border-bottom: 0;
    }
  }

  .ant-menu-item-group-title {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray9};
    padding-left: 24px;
  }
  .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 50px;
  }
`

export const RowItem = styled(Row)`
  padding: 25px 24px 64px;
  .ant-btn {
    margin-bottom: 24px;
  }
  .ant-btn::last-child {
    margin-bottom: 0;
  }
`
