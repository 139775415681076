import styled from 'styled-components/macro'

export const Body = styled.div`
  margin-top: 24px;
`

export const StageItem = styled.div`
  height: 100px;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.gray9};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  h3 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    overflow: hidden;
    max-height: 32px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    display: block;
    display: -webkit-box;
  }
`
export const StageIcon = styled.div`
  font-size: 21px;
  color: ${({ theme }) => theme.colors.gray6};
`
