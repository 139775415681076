import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { isExternalRecruiterSelector } from 'store/viewer'

import AddCandidateModal from 'app/components/Modals/AddCandidateModal/AddCandidateModal'
import AddCandidatesModal from 'app/components/Modals/AddCandidatesModal/AddCandidatesModal'

import Button from 'antd/lib/button'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'

import { CaretDownOutlined } from '@ant-design/icons'

import { MenuContainer } from './styles'

const AddCandidate = ({
  jobId,
  companyId,
}: {
  jobId: string
  companyId: string
}) => {
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const [isOpenCandidatesModal, setIsOpenCandidatesModal] = useState(false)
  const [isOpenCandidateModal, setIsOpenCandidateModal] = useState(false)

  const handleOpenCandidatesModal = () => {
    setIsOpenCandidatesModal(true)
  }

  const handleCloseCandidatesModal = () => {
    setIsOpenCandidatesModal(false)
  }

  const handleOpenCandidateModal = () => {
    setIsOpenCandidateModal(true)
  }

  const handleCloseCandidateModal = () => {
    setIsOpenCandidateModal(false)
  }

  const menu = (
    <MenuContainer>
      <Menu.Item onClick={handleOpenCandidatesModal}>
        <h2>Upload CVs</h2>
        <p>Upload CVs directly from your desktop.</p>
      </Menu.Item>

      <Menu.Item onClick={handleOpenCandidateModal}>
        <h2>Enter Details Manually</h2>
        <p>Create a candidate profile based on information you have.</p>
      </Menu.Item>
    </MenuContainer>
  )

  return (
    <>
      {isExternalRecruiter ? (
        <Button type="primary" onClick={handleOpenCandidateModal}>
          Submit candidate
        </Button>
      ) : (
        <Dropdown.Button
          overlay={menu}
          type="primary"
          icon={<CaretDownOutlined />}
          trigger={['click']}
          onClick={handleOpenCandidatesModal}
        >
          Add Candidate
        </Dropdown.Button>
      )}

      <AddCandidatesModal
        jobId={jobId}
        candidate={{ companyId }}
        isOpenCandidatesModal={isOpenCandidatesModal}
        handleCloseCandidatesModal={handleCloseCandidatesModal}
      />
      <AddCandidateModal
        jobId={jobId}
        candidate={{ companyId }}
        isOpenCandidateModal={isOpenCandidateModal}
        handleCloseCandidateModal={handleCloseCandidateModal}
      />
    </>
  )
}

export default AddCandidate
