import React from 'react'

import Spin from 'antd/lib/spin'

import { LoadingOutlined } from '@ant-design/icons'

import { Container } from './styles'

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />

const PageLoader = () => {
  return (
    <Container>
      <Spin indicator={antIcon} />
    </Container>
  )
}

export default PageLoader
