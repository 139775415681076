import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { updateCandidatesStagePromiseCreator } from '../../../../store/candidates'

import useActionsRoutines from '../../../../hooks/useActionsRoutines'
import snackbar from '../../Snackbar/Snackbar'
import UpdateCandidateStageMenu from './UpdateCandidateStageMenu'

import Dropdown from 'antd/lib/dropdown'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { NextStageContainer, Title } from '../CandidateActions/styles'

import { RootState, StageType } from 'types'

const UpdateCandidateStage = () => {
  const screens = useBreakpoint()
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const updateCandidatesStage = useActionsRoutines(
    updateCandidatesStagePromiseCreator,
  )

  const { candidate } = useSelector(
    (state: RootState) => state.candidates.fetchCandidate,
  )

  const { stages } = useSelector((state: RootState) => state.stages.fetchStages)

  const { isLoading } = useSelector(
    (state: RootState) => state.candidates.updateCandidatesStage,
  )

  const handleCloseStageDropDown = () => {
    setIsOpenDropdown(false)
  }

  const filteredStages = stages.filter(
    ({ type }) => type !== StageType.Sourced && type !== StageType.Applied,
  )

  //TODO fix stage view in redux

  const currentStage = filteredStages.findIndex(
    ({ id }) => id === candidate!.stage.id,
  )
  const nextStage = filteredStages[currentStage + 1]

  const handleMovetoNextStage = async () => {
    if (!nextStage) return setIsOpenDropdown(true)
    try {
      updateCandidatesStage({
        ids: [candidate!.id],
        stageId: nextStage.id,
        stages,
      }).then(() => {
        snackbar({
          content: `Stage changed to "${nextStage.name}"`,
          showIcon: true,
          type: 'info',
          icon: InfoCircleOutlined,
          closeText: 'OK',
        })
      })
    } catch (err) {
      console.log(err)
    }
  }

  if (!screens.md) {
    return null
  }

  return (
    <NextStageContainer>
      <Dropdown.Button
        type="primary"
        overlay={
          <UpdateCandidateStageMenu
            handleCloseMenu={handleCloseStageDropDown}
          />
        }
        trigger={['click']}
        icon={<CaretDownOutlined />}
        visible={isOpenDropdown}
        loading={isLoading}
        onVisibleChange={e => setIsOpenDropdown(e)}
        onClick={handleMovetoNextStage}
      >
        <Title>
          {nextStage ? `Move to ${nextStage.name}` : 'Update stage'}
        </Title>
      </Dropdown.Button>
    </NextStageContainer>
  )
}

export default UpdateCandidateStage
