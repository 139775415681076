import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { searchStateToUrl } from 'helpers'

import Warning from 'assets/icons/Warning'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'

import { ButtonWrapper, Text, Title } from './styles'

import { ManageUsersTabs } from 'types'

interface NotVerifiedCompanyModalProps {
  companyName: string
  icon?: any
  buttonTitle: string
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
}

const NotVerifiedCompanyModal: FC<NotVerifiedCompanyModalProps> = ({
  companyName,
  buttonTitle,
  icon,
  buttonType = 'primary',
}) => {
  const history = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenNotVerifiedCompanies = () => {
    const searchState = {
      tab: 'CompaniesToVerify',
      query: companyName,
    }
    const location = {
      pathname: `${ROUTES.ADMIN_MANAGE_USERS}`,
      search: `?tab=${ManageUsersTabs.CompaniesToVerify}`,
    }

    history.push(searchStateToUrl(searchState, location))
  }
  return (
    <>
      <Button icon={icon && icon} onClick={handleOpenModal} type={buttonType}>
        {buttonTitle}
      </Button>
      <Modal
        title={
          <Title>
            <Warning size={18} />
            {companyName} not verified
          </Title>
        }
        width={550}
        visible={isOpenModal}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Text>
          Please note that job was created and submited for approval by a user
          of the unverified company. Go to “Companies to Approve” to approve
          company in order to proceed.
        </Text>
        <ButtonWrapper>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button type="primary" onClick={handleOpenNotVerifiedCompanies}>
            Go to Companies to Approve
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default NotVerifiedCompanyModal
