import * as Yup from 'yup'

import { password } from './baseSchema'

const resetPasswordSchema = Yup.object({
  password,
  verifyPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
})

export default resetPasswordSchema
