import styled from 'styled-components'

import Tag from 'antd/lib/tag'

export const TagItem = styled(Tag)`
  display: flex;
  align-items: center;
  border: none;
  padding: 2px 8px;
  border-radius: 8px;
  font-weight: 600;
  svg {
    margin-right: 5px;
  }
`
export const TagItemRed = styled(TagItem)`
  color: ${({ theme }) => theme.colors.red4};
  background: #fff1f0;
`
export const TagItemMaybe = styled(TagItem)`
  color: ${({ theme }) => theme.colors.orange6};
  background: #fffbe6;
`

export const TagItemShortlisted = styled(TagItem)`
  &.ant-tag {
    border: 1px solid #b5f5ec;
  }
`
export const TagItemViewed = styled(TagItem)`
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
`
