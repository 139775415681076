import React, { FC } from 'react'

import {
  AutoRejectedDot,
  MaybeDot,
  NewDot,
  RejectedDot,
  ShortlistedDot,
  ViewedDot,
} from './styles'

import { CandidateStatus } from 'types'

interface StatusDotProps {
  status: CandidateStatus
}

const StatusDot: FC<StatusDotProps> = ({ status }) => {
  switch (status) {
    case CandidateStatus.Shortlisted:
      return <ShortlistedDot />
    case CandidateStatus.Maybe:
      return <MaybeDot />
    case CandidateStatus.Rejected:
      return <RejectedDot />
    case CandidateStatus.AutoRejected:
      return <AutoRejectedDot />
    case CandidateStatus.New:
      return <NewDot />
    case CandidateStatus.Viewed:
      return <ViewedDot />

    default:
      return <AutoRejectedDot />
  }
}

export default StatusDot
