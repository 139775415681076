import styled from 'styled-components/macro'

import Modal from 'antd/lib/modal'

export const ModalFooter = styled.div`
  text-align: left;
  margin-top: 30px;

  .ant-btn {
    margin-right: 10px;
  }
`
export const ModalItem = styled(Modal)`
  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }
`
