import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { isExternalRecruiterOrTeamMemberSelector } from 'store/viewer'

import FilterButton from './FilterButton'
import CurrentRefinements from 'app/components/Jobs/JobsList/CurrentRefinements/CurrentRefinements'
import MobileSearchBox from 'app/components/Jobs/JobsList/MobileSearchBox/SearchBox'
import AlgoliaSearch from 'app/components/UI/AlgoliaSearch/AlgoliaSearch'
import { CREATE_JHCAMPAIGN_JOB_URL } from 'app/modules/Jobs/CreateJob/SelectCampaignType/SelectCampaignType'

import Button from 'antd/lib/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { SearchOutlined } from '@ant-design/icons'

import {
  Header,
  HeaderLeft,
  HeaderTop,
  JobActions,
  MobileHeader,
} from './styles'

interface IJobListHeader {
  isJobsExist: boolean
  isOpenMobileSearch: boolean
  isShowFilter: boolean
  setIsShowFilter: (value: boolean) => void
  setIsOpenMobileSearch: (value: boolean) => void
}

const JobListHeader: FC<IJobListHeader> = ({
  isJobsExist,
  isOpenMobileSearch,
  isShowFilter,
  setIsShowFilter,
  setIsOpenMobileSearch,
}) => {
  const screens = useBreakpoint()

  const isTeamMemberOrExternalRecruiter = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )

  const handleCloseFilter = () => {
    setIsShowFilter(false)
  }

  const handleShowFilter = () => {
    setIsShowFilter(true)
  }

  const handleOpenMobileSearch = () => {
    setIsOpenMobileSearch(true)
  }

  const handleCloseMobileSearch = () => {
    setIsOpenMobileSearch(false)
  }

  if (!isJobsExist) {
    return <h1>Jobs</h1>
  }

  return (
    <>
      {isOpenMobileSearch && (
        <MobileHeader>
          <MobileSearchBox handleCloseMobileSearch={handleCloseMobileSearch} />
          <FilterButton
            isShowFilter={isShowFilter}
            handleShowFilter={handleShowFilter}
            handleCloseFilter={handleCloseFilter}
          />
        </MobileHeader>
      )}

      {!isOpenMobileSearch && (
        <Header>
          <HeaderTop>
            <HeaderLeft>
              <h1>Jobs</h1>
            </HeaderLeft>
            <div>
              <JobActions>
                {!screens.md && !isOpenMobileSearch && (
                  <Button
                    icon={<SearchOutlined onClick={handleOpenMobileSearch} />}
                  />
                )}

                {screens.md && (
                  <AlgoliaSearch placeholder="Search for a job..." />
                )}

                <FilterButton
                  isShowFilter={isShowFilter}
                  handleShowFilter={handleShowFilter}
                  handleCloseFilter={handleCloseFilter}
                />

                {!isTeamMemberOrExternalRecruiter && (
                  <Button type="primary">
                    <Link to={CREATE_JHCAMPAIGN_JOB_URL}>+ New job</Link>
                  </Button>
                )}
              </JobActions>
            </div>
          </HeaderTop>
          <CurrentRefinements />
        </Header>
      )}
    </>
  )
}

export default JobListHeader
