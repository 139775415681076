import styled from 'styled-components'

export const ModalFooter = styled.div`
  text-align: left;
  margin-top: 30px;

  .ant-btn {
    margin-right: 10px;
  }
`
