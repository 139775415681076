import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { approveJobPromiseCreator } from 'store/jobs'
import { isAdminSelector } from 'store/viewer'

import { creditTypes } from '../../../modules/Checkout/constants'
import AddCreditsButton from 'app/components/Modals/SelectCampaignTypeModal/AddCreditsButton/AddCreditsButton'
import CreditIcon from 'app/components/Modals/SelectCampaignTypeModal/assets/CreditIcon'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import Spin from 'antd/lib/spin'

import {
  RowItem,
  RowItemText,
  Title,
} from 'app/components/Modals/SelectCampaignTypeModal/styles'

import { CreditType, RootState } from 'types'

const SelectCampaignTypeModal = ({
  balance,
  job,
  handleCloseModal,
  isOpenSelectCampaignModal,
}: any) => {
  const history = useHistory()
  const isAdmin = useSelector(isAdminSelector)
  const approveJob = useActionsRoutines(approveJobPromiseCreator)

  const { isLoading: isApproveJobLoading } = useSelector(
    (state: RootState) => state.jobs.approveJob,
  )

  const handleApproveJob = ({ creditType }: { creditType: CreditType }) => {
    approveJob({ advertId: job.id, creditType }).then(() => {
      handleCloseModal()
      if (isAdmin) return history.replace(`${ROUTES.JOB}/${job.id}/preview`)
      history.replace(`${ROUTES.JOB}/${job.id}/preview?status=approved`)
    })
  }

  return (
    <div>
      <Modal
        width={400}
        footer={null}
        title={
          <Title>
            <CreditIcon /> Select campaign type
          </Title>
        }
        onCancel={handleCloseModal}
        visible={isOpenSelectCampaignModal}
      >
        <Spin spinning={isApproveJobLoading}>
          {creditTypes.map(({ title, creditType, value }) => {
            const currentBalance = balance[value]

            return (
              <RowItem key={creditType}>
                <RowItemText>
                  {title} ({currentBalance} credits)
                </RowItemText>
                {!currentBalance && <AddCreditsButton job={job} />}

                {!!currentBalance && (
                  <Button
                    type="link"
                    onClick={() => handleApproveJob({ creditType })}
                  >
                    Select
                  </Button>
                )}
              </RowItem>
            )
          })}
        </Spin>
      </Modal>
    </div>
  )
}

export default SelectCampaignTypeModal
