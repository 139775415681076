import React, { FC, useState } from 'react'
import { useHistory } from 'react-router'

import { cloneJobPromiseCreator } from 'store/jobs'

import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { JobCampaign } from 'types'

interface CloneJobProps {
  title: string
  id: string
  campaignType: JobCampaign | boolean
  icon?: React.ReactNode
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
}

const CloneJob: FC<CloneJobProps> = ({
  title,
  campaignType,
  id,
  icon,
  buttonType = 'text',
}) => {
  const jobCampaignType = () => {
    if (typeof campaignType === 'boolean') {
      return campaignType ? JobCampaign.Jobheron : JobCampaign.SelfService
    }

    return campaignType
  }

  const cloneJob = useActionsRoutines(cloneJobPromiseCreator)
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()
  const handleCloneJob = () => {
    setLoading(true)
    cloneJob({ id, campaignType: jobCampaignType() })
      .then(jobId => {
        history.push(`${ROUTES.JOB}/${jobId}/preview`)
      })
      .finally(() => setLoading(false))
  }
  return (
    <Button
      onClick={handleCloneJob}
      loading={isLoading}
      icon={icon}
      type={buttonType}
    >
      {title}
    </Button>
  )
}

export default CloneJob
