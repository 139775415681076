import * as Yup from 'yup'

const addStageSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, 'Has to be a valid stage')
    .max(100, 'Has to be a valid stage')
    .required('Has to be a valid stage'),
})

export default addStageSchema
