import styled from 'styled-components/macro'

interface IStageItem {
  isRefined?: boolean
  isDashed?: boolean
  count?: number
}

export const StageItem = styled.div`
  height: 86px;
  padding: 12px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.gray9};
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ isRefined }: IStageItem) =>
    isRefined ? '#E6F7FF' : '#fff'};
  border: 1px solid
    ${({ isRefined }: IStageItem) => (isRefined ? '#2688FC' : '#d9d9d9')};
`

export const StageCount = styled.div`
  font-weight: 600;
  line-height: 16px;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 24px;
  }
`

export const StageItemTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  max-height: 32px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  display: block;
  display: -webkit-box;
`
export const SpinContainer = styled.div`
  height: 86px;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
