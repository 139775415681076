import React from 'react'

import Button from 'antd/lib/button'
import Carousel from 'antd/lib/carousel'

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'

import { Container } from './styles'

interface IStageArrow {
  className?: string
  onClick?: () => void
}

interface ISlider {
  children: React.ReactNode
}

const StageNextArrow = ({ className, onClick }: IStageArrow) => (
  <Button
    icon={<ArrowRightOutlined />}
    className={className}
    onClick={onClick}
  />
)

const StagePrevArrow = ({ className, onClick }: IStageArrow) => (
  <Button
    icon={<ArrowLeftOutlined />}
    className={className}
    onClick={onClick}
  />
)

const Slider = ({ children }: ISlider) => {
  const carouselSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 6.5,
    slidesToScroll: 1,
    nextArrow: <StageNextArrow />,
    prevArrow: <StagePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <Container>
      <Carousel {...carouselSettings}>{children}</Carousel>
    </Container>
  )
}

export default Slider
