import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { formatDate } from 'helpers/formatDate'

import FullName from '../UserTable/FullName/FullName'
import Actions from './Actions'
import { externalRecruitersColumns } from './columns'

import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Row from 'antd/lib/row'
import Table from 'antd/lib/table'

import { Container, MobileContainer, TableContainer, UserItem } from './styles'

import { IAlgoliaUser, RootState } from 'types'

interface IExternalRecruitersTable {
  hits: any
}

const ExternalRecruitersTable: FC<IExternalRecruitersTable> = ({ hits }) => {
  const screens = useBreakpoint()

  const { isLoading } = useSelector(
    (state: RootState) => state.hiringTeam.inviteCompanyMember,
  )

  if (!hits.length) return null

  return (
    <Container>
      {!screens.lg ? (
        <>
          <MobileContainer>
            {hits.map((user: IAlgoliaUser) => {
              const { email, key } = user
              return (
                <UserItem key={key}>
                  <Row justify="space-between" align="middle" gutter={[10, 20]}>
                    <Col xs={12} md={6}>
                      <FullName {...user} />
                    </Col>

                    <Col xs={12} md={8}>
                      {email}
                    </Col>

                    <Col xs={12} md={6}>
                      <div>{formatDate(user.signUpDate)}</div>
                    </Col>

                    <Col xs={12} md={4}>
                      <Actions {...user} />
                    </Col>
                  </Row>
                </UserItem>
              )
            })}
          </MobileContainer>
        </>
      ) : (
        <TableContainer>
          <Table
            loading={isLoading}
            columns={externalRecruitersColumns}
            dataSource={hits}
            pagination={false}
          />
        </TableContainer>
      )}
    </Container>
  )
}

export default ExternalRecruitersTable
