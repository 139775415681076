import React, { FC } from 'react'

import Tag from 'antd/lib/tag'

import { InvitationStatus } from 'types'

interface IStatus {
  invitationStatus: InvitationStatus
}

const HiringTeamTableStatus: FC<IStatus> = ({ invitationStatus }) => {
  if (invitationStatus === InvitationStatus.Accepted)
    return <Tag color="cyan">Joined</Tag>

  if (invitationStatus === InvitationStatus.Pending)
    return <Tag color="blue">Pending...</Tag>

  return null
}

export default HiringTeamTableStatus
