import styled from 'styled-components'

import Modal from 'antd/lib/modal'

export const ModalItem = styled(Modal)``
export const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  svg {
    margin-right: 8px;
  }
`
export const RowItem = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`

export const RowItemText = styled.div`
  min-width: 140px;
`
