import DoneImg from './assets/almost-done.svg'
import CheckYourEmail from './assets/check-your-email.png'
import ExpiredImg from './assets/expired.svg'
import SuccessImg from './assets/success.svg'
import VerifyEmail from './assets/verify-email.png'

import { AuthStatus } from 'types'

//TODO: change text for sign up InvalidToken, ExpiredToken

export const signUpStatuses = [
  {
    status: AuthStatus.CheckYourEmail,
    title: 'Please check your inbox',
    text: 'Click on the received link to activate your account.',
    img: CheckYourEmail,
  },
  {
    status: AuthStatus.TokenAlreadyUsed,
    title: 'Email is already verified.',
    text: 'This link is no longer valid.',
    img: SuccessImg,
  },
  {
    status: AuthStatus.ExpiredToken,
    title: 'You need to be verified',
    text:
      'Before proceeding with forgot password procedure you need to verify your email. Check your inbox or request link resend.',
    img: VerifyEmail,
  },
  {
    status: AuthStatus.InvalidToken,
    title: 'You need to be verified',
    text:
      'Before proceeding with forgot password procedure you need to verify your email. Check your inbox or request link resend.',
    img: VerifyEmail,
  },
]

export const forgotPasswordStatuses = [
  {
    status: AuthStatus.CheckYourEmail,
    title: 'Please check your inbox',
    text:
      'If your email was found in our database, we’ve sent you a Password Reset link.',
    img: CheckYourEmail,
  },
  {
    status: AuthStatus.Success,
    title: 'Password reset successfully!',
    text: 'You can sign in now.',
    img: DoneImg,
  },
  {
    status: AuthStatus.NeedToBeVerified,
    title: 'You need to be verified',
    text:
      'Before proceeding with forgot password procedure you need to verify your email. Check your inbox or request link resend.',
    img: VerifyEmail,
  },
  {
    status: AuthStatus.InvalidToken,
    title: 'Token not valid',
    text: 'Please request another token.',
    img: ExpiredImg,
  },

  {
    status: AuthStatus.ExpiredToken,
    title: 'Token expired',
    text: 'Please request another token.',
    img: ExpiredImg,
  },
  {
    status: AuthStatus.TokenAlreadyUsed,
    title: 'Token already used',
    text: 'This link is no longer valid.',
    img: SuccessImg,
  },
]

export const invitationStatuses = [
  {
    status: AuthStatus.CheckYourEmail,
    title: 'Please check your inbox',
    text: 'Click on the received link to activate your account.',
    img: CheckYourEmail,
  },
  {
    status: AuthStatus.TokenAlreadyUsed,
    title: 'Invitation already used',
    text: 'This link is no longer valid.',
    img: SuccessImg,
  },
  {
    status: AuthStatus.ExpiredToken,
    title: 'Invitation expired',
    text: 'Please request another invitation.',
    img: ExpiredImg,
  },
  {
    status: AuthStatus.InvalidToken,
    title: 'Invitation expired',
    text: 'Please request another invitation.',
    img: ExpiredImg,
  },
]
