import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

type SHeaderRow = {
  scrollDirection: string
}
export const Container = styled.div`
  .ant-tabs-nav {
    margin: 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-tabs-extra-content {
    .ant-btn {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
`

export const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7% 0;
`

export const HeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderContainer = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    height: ${({ scrollDirection }: SHeaderRow) =>
      scrollDirection === 'up' ? '100px' : 'auto'};

    ${HeaderRow} {
      ${({ scrollDirection }: SHeaderRow) =>
        scrollDirection === 'up' &&
        `
    padding: 10px;
    position: fixed;
    top: 68px;
    background: #fff;
    z-index: 2;
    left: 0;
    right: 0;
  `}
    }
  }
`

export const ColItem = styled(Col)`
  margin-bottom: 16px;
  @media (min-width: 750px) {
    margin-bottom: 0;
  }
`
export const CandidatesRightBlock = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`

export const StyledLink = styled(Link)`
  display: none;
  @media (min-width: 750px) {
    display: block;
    color: ${({ theme }) => theme.colors.gray9};
    &:hover {
      color: ${({ theme }) => theme.colors.blue4};
    }
  }
`
