import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { updateLastPage } from 'store/app'
import { getRefreshSelector } from 'store/app/selectors'
import { fetchJobsCounterPromiseCreators, getJobsSelector } from 'store/jobs'
import { getViewerSelector, isExternalRecruiterSelector } from 'store/viewer'

import { createURL } from 'helpers/algoliaHelpers'

import JobListHeader from './JobListHeader/JobListHeader'
import JobListTabs from './JobListTabs/JobListTabs'
import algoliasearch from 'algoliasearch/lite'
import Hits from 'app/components/Jobs/JobsList/Hits/Hits'
import JobFilter from 'app/components/Jobs/JobsList/JobFilter/JobFilter'
import NotVerified from 'app/components/Jobs/assets/NotVerified'
import AlgoliaPagination from 'app/components/UI/AlgoliaPagination/AlgoliaPagination'
import EmptyBlock from 'app/components/UI/EmptyBlock/EmptyBlock'
import useActionsRoutines from 'hooks/useActionsRoutines'
import useSearchToQuery from 'hooks/useSearchToQuery'
import { ROUTES } from 'routes'

import Spin from 'antd/lib/spin'

import { Body, LinkItem, NotVerifiedBlock, SpinnerWrapper } from './styles'

import { UserStatus } from 'types'

const JobsList = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [isOpenMobileSearch, setIsOpenMobileSearch] = useState(false)
  const refreshAlgoliaCache = useSelector(getRefreshSelector)

  const [hitsPerPage, setHistPerPage] = useState(10)
  const viewer = useSelector(getViewerSelector)
  const jobs = useSelector(getJobsSelector)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const fetchJobsCounters = useActionsRoutines(fetchJobsCounterPromiseCreators)

  const { onSearchStateChange, searchState } = useSearchToQuery({})

  const jobsIndexName = isExternalRecruiter
    ? viewer.algoliaKeys.adverts.listOfAdvertsExternalRecruiter
    : viewer.algoliaKeys.adverts.listOfAdverts

  const jobStatusFilter = isExternalRecruiter
    ? 'statusFilterExternal'
    : 'statusFilter'

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.adverts.token,
      ),
    [],
  )

  const handleUpdateHitsPerPage = useCallback(
    newHitsPerPage => {
      setHistPerPage(newHitsPerPage)
    },
    [hitsPerPage],
  )

  useEffect(() => {
    fetchJobsCounters()
  }, [])

  useEffect(() => {
    dispatch(
      updateLastPage({
        path: `${location.pathname}${location.search}`,
        type: 'jobs',
      }),
    )
  }, [location])

  const isJobsExist = !!jobs?.counters
  const { status, company } = viewer

  if (jobs.fetchJobs.isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (status === UserStatus.NotVerified) {
    return (
      <NotVerifiedBlock>
        <EmptyBlock
          title="Account pending verification"
          message={`Your account is pending  ${company.name} Admin approval`}
          icon={<NotVerified />}
        >
          <LinkItem
            target="_blank"
            rel="noreferrer"
            href={ROUTES.JOBHERON_HELP_CENTER}
          >
            Find out more here.
          </LinkItem>
        </EmptyBlock>
      </NotVerifiedBlock>
    )
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={jobsIndexName}
      refresh={refreshAlgoliaCache}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <>
        <JobListHeader
          isJobsExist={isJobsExist}
          isOpenMobileSearch={isOpenMobileSearch}
          isShowFilter={isShowFilter}
          setIsShowFilter={setIsShowFilter}
          setIsOpenMobileSearch={setIsOpenMobileSearch}
        />
        <Body>
          <JobFilter hitsPerPage={hitsPerPage} isShowFilter={isShowFilter} />
          {isShowFilter ? (
            <>
              <Hits />
              <Configure hitsPerPage={hitsPerPage} />
              <AlgoliaPagination
                pageSize={hitsPerPage}
                setPageSize={handleUpdateHitsPerPage}
              />
            </>
          ) : (
            <JobListTabs
              jobsIndexName={jobsIndexName}
              jobStatusFilter={jobStatusFilter}
              searchState={searchState}
            />
          )}
        </Body>
      </>
    </InstantSearch>
  )
}

export default JobsList
