import React from 'react'
import { useSelector } from 'react-redux'

import { getViewerSelector } from 'store/viewer'

import { totalBalance } from 'helpers/creditsHelpers'

import { campaigns } from 'constants/app'

import Menu from 'antd/lib/menu'

import {
  MenuCreditsContent,
  MenuCreditsRow,
  MenuCreditsRowBold,
} from './styles'

const CreditsItems = () => {
  const { balance } = useSelector(getViewerSelector)
  const { advertiser, managed, hire } = balance
  const balanceAmount = totalBalance(balance)
  return (
    <MenuCreditsContent>
      <MenuCreditsRow>
        <div>{campaigns.advertiser}</div>
        <div>{advertiser}</div>
      </MenuCreditsRow>
      <MenuCreditsRow>
        <div>{campaigns.managed}</div>
        <div>{managed}</div>
      </MenuCreditsRow>
      <MenuCreditsRow>
        <div>{campaigns.hire}</div>
        <div>{hire}</div>
      </MenuCreditsRow>
      <Menu.Divider />
      <MenuCreditsRowBold>
        <div>Total</div>
        <div>{balanceAmount}</div>
      </MenuCreditsRowBold>
    </MenuCreditsContent>
  )
}

export default CreditsItems
