import { createReducer } from '@reduxjs/toolkit'

import {
  approveJob,
  archiveJob,
  clearJob,
  createJob,
  deleteJob,
  expireJob,
  fetchJob,
  fetchJobPreview,
  fetchJobsCounters,
  fetchRejectedCandidatesCount,
  hideDeleteJobModal,
  hideExpireJobModal,
  promoteJob,
  publishJob,
  publishJobWithOutApproval,
  showDeleteJobModal,
  showExpireJobModal,
  unArchiveJob,
  updateJob,
  updateJobForwardSettings,
} from './actions'

import { IJob, IJobsState } from 'types'

export const JOBS_STATE: IJobsState = {
  fetchJob: { isLoading: false, job: undefined },
  fetchJobPreview: { isLoading: false, job: undefined },
  fetchJobs: { isLoading: false, error: null, isError: false },
  createJob: { isLoading: false, errors: null },
  updateJobForwardSettings: { isLoading: false },
  publishJob: { isLoading: false },
  publishJobWithOutApproval: { isLoading: false },
  approveJob: { isLoading: false },
  archiveJob: { isLoading: false },
  unArchiveJob: { isLoading: false },
  updateJob: { isLoading: false },
  promoteJob: { isLoading: false },
  fetchRejectedCandidates: [],
  deleteJob: {
    isLoading: false,
    isOpenDeleteJobModal: false,
    jobId: null,
    jobTitle: null,
    jobStatus: null,
  },
  expireJob: {
    isLoading: false,
    isOpenExpireJobModal: false,
    jobId: undefined,
    jobTitle: undefined,
  },
  counters: null,
}

const jobsReducer = createReducer(JOBS_STATE, {
  [fetchJobsCounters.TRIGGER]: state => {
    state.fetchJobs.isLoading = true
  },
  [fetchJobsCounters.SUCCESS]: (state, { payload }) => {
    state.counters =
      payload.counters.statusFilterExternal || payload.counters.statusFilter
    state.fetchJobs.isLoading = false
  },
  [fetchJobsCounters.FAILURE]: state => {
    state.counters = null
    state.fetchJobs.isLoading = false
  },

  [fetchRejectedCandidatesCount.SUCCESS]: (state, { payload }) => {
    state.fetchRejectedCandidates = payload
  },

  [fetchJobPreview.TRIGGER]: state => {
    state.fetchJobPreview.isLoading = true
  },
  [fetchJobPreview.SUCCESS]: (state, { payload }) => {
    state.fetchJobPreview.isLoading = false
    state.fetchJobPreview.job = payload.job
  },
  [fetchJobPreview.FAILURE]: state => {
    state.fetchJobPreview.isLoading = false
  },

  [fetchJob.TRIGGER]: state => {
    state.fetchJob.isLoading = true
  },
  [fetchJob.SUCCESS]: (state, { payload }) => {
    state.fetchJob.isLoading = false
    state.fetchJob.job = payload.job
  },
  [fetchJob.FAILURE]: state => {
    state.fetchJob.isLoading = false
  },

  [createJob.TRIGGER]: state => {
    state.createJob.isLoading = true
    state.createJob.errors = null
  },
  [createJob.SUCCESS]: state => {
    state.createJob.isLoading = false
    state.createJob.errors = null
  },
  [createJob.FAILURE]: (state, { payload }) => {
    state.createJob.isLoading = false
    state.createJob.errors = payload.errors
  },

  [updateJob.TRIGGER]: state => {
    state.updateJob.isLoading = true
  },
  [updateJob.SUCCESS]: (state, { payload }) => {
    state.updateJob.isLoading = false
    if (state.fetchJob.job) {
      state.fetchJob.job = {
        ...state.fetchJob.job,
        ...payload.job,
      }
    }

    if (state.fetchJobPreview.job) {
      state.fetchJobPreview.job = {
        ...state.fetchJobPreview.job,
        ...payload.job,
      }
    }
  },
  [updateJob.FAILURE]: state => {
    state.updateJob.isLoading = false
  },

  [approveJob.TRIGGER]: state => {
    state.approveJob.isLoading = true
  },
  [approveJob.SUCCESS]: (state, { payload }) => {
    state.approveJob.isLoading = false

    state.fetchJobPreview.job = {
      ...state.fetchJobPreview.job,
      status: payload.job.status,
    } as IJob
  },
  [approveJob.FAILURE]: state => {
    state.approveJob.isLoading = false
  },

  [publishJob.TRIGGER]: state => {
    state.publishJob.isLoading = true
  },
  [publishJob.SUCCESS]: (state, { payload }) => {
    state.publishJob.isLoading = false
    state.fetchJobPreview.job = {
      ...state.fetchJobPreview.job,
      status: payload.job.status,
    } as IJob
  },
  [publishJob.FAILURE]: state => {
    state.publishJob.isLoading = false
  },

  [publishJobWithOutApproval.TRIGGER]: state => {
    state.publishJobWithOutApproval.isLoading = true
  },
  [publishJobWithOutApproval.SUCCESS]: state => {
    state.publishJobWithOutApproval.isLoading = false
  },
  [publishJobWithOutApproval.FAILURE]: state => {
    state.publishJobWithOutApproval.isLoading = false
  },

  [updateJobForwardSettings.TRIGGER]: state => {
    state.updateJobForwardSettings.isLoading = true
  },
  [updateJobForwardSettings.SUCCESS]: (state, { payload }) => {
    state.updateJobForwardSettings.isLoading = false
    if (state.fetchJob.job) {
      state.fetchJob.job.forwardTo = payload.emailList
      state.fetchJob.job.cvForwardingFrequency = payload.frequency
    }
  },
  [updateJobForwardSettings.FAILURE]: state => {
    state.updateJobForwardSettings.isLoading = false
  },

  [promoteJob.TRIGGER]: state => {
    state.promoteJob.isLoading = true
  },
  [promoteJob.SUCCESS]: state => {
    state.promoteJob.isLoading = false
  },
  [promoteJob.FAILURE]: state => {
    state.promoteJob.isLoading = false
  },

  [String(showDeleteJobModal)]: (state, { payload }) => {
    state.deleteJob.isOpenDeleteJobModal = true
    state.deleteJob.jobId = payload.jobId
    state.deleteJob.jobTitle = payload.jobTitle
    state.deleteJob.jobStatus = payload.jobStatus
  },
  [String(hideDeleteJobModal)]: state => {
    state.deleteJob.isOpenDeleteJobModal = false
    state.deleteJob.jobStatus = null
  },

  [deleteJob.TRIGGER]: state => {
    state.deleteJob.isLoading = true
  },
  [deleteJob.SUCCESS]: state => {
    state.deleteJob.isLoading = false
    state.deleteJob.jobId = null
    state.deleteJob.jobTitle = null
    state.deleteJob.jobStatus = null
  },
  [deleteJob.FAILURE]: state => {
    state.deleteJob.isLoading = false
  },

  [String(showExpireJobModal)]: (state, { payload }) => {
    state.expireJob.isOpenExpireJobModal = true
    state.expireJob.jobId = payload.jobId
    state.expireJob.jobTitle = payload.jobTitle
  },
  [String(hideExpireJobModal)]: state => {
    state.expireJob.isOpenExpireJobModal = false
  },

  [expireJob.TRIGGER]: state => {
    state.expireJob.isLoading = true
  },
  [expireJob.SUCCESS]: (state, { payload }) => {
    state.expireJob.isLoading = false
    if (!!state.fetchJob.job) {
      state.fetchJob.job = {
        ...state.fetchJob.job,
        isExpired: payload.isExpired,
      } as IJob
    }
  },
  [expireJob.FAILURE]: state => {
    state.expireJob.isLoading = false
  },
  [archiveJob.TRIGGER]: state => {
    state.archiveJob.isLoading = true
  },
  [archiveJob.FAILURE]: state => {
    state.archiveJob.isLoading = false
  },
  [archiveJob.SUCCESS]: (state, { payload }) => {
    state.archiveJob.isLoading = false
    if (!!state.fetchJob.job) {
      state.fetchJob.job = {
        ...state.fetchJob.job,
        status: payload.status,
        isExpired: payload.isExpired,
      } as IJob
    }
  },
  [unArchiveJob.TRIGGER]: state => {
    state.unArchiveJob.isLoading = true
  },
  [unArchiveJob.FAILURE]: state => {
    state.unArchiveJob.isLoading = false
  },
  [unArchiveJob.SUCCESS]: state => {
    state.unArchiveJob.isLoading = false
  },

  [String(clearJob)]: state => {
    state.fetchJob.job = undefined
    state.fetchJobPreview.job = undefined
  },
})

export default jobsReducer
