import React, { FC, ReactNode } from 'react'

import { Container } from './styles'

interface IEmptyBlock {
  icon: React.ReactNode
  title: string
  message?: string
  children?: ReactNode
}

const EmptyBlock: FC<IEmptyBlock> = ({ icon, title, message, children }) => {
  return (
    <Container>
      {icon}
      <h2>{title}</h2>
      {message && <p>{message}</p>}

      {children && children}
    </Container>
  )
}

export default EmptyBlock
