import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;

  .ant-btn {
    margin-right: 20px;
  }
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`
