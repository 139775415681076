import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import Form from 'antd/lib/form'

interface IFormItem {
  name: any
  label?: string
  help?: string
  componentProps?: any
  component: any
  defaultValue?: string
}

const FormItem: FC<IFormItem> = ({
  defaultValue = '',
  name,
  label,
  help,
  componentProps,
  component: Component,
  children,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  return (
    <Form.Item
      hasFeedback={!!errors[name]}
      validateStatus={errors && errors[name] ? 'error' : 'success'}
      help={help ? help : errors[name]?.message}
      label={label}
    >
      <Controller
        render={({ field }) => (
          <Component
            {...field}
            allowClear
            {...componentProps}
            onChange={field.onChange}
          >
            {children}
          </Component>
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
    </Form.Item>
  )
}

export default FormItem
