import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { expireJobPromiseCreator, hideExpireJobModal } from 'store/jobs'

import snackbar from 'app/components/Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'

import Button from 'antd/lib/button'
import message from 'antd/lib/message'

import { ModalFooter, ModalItem } from './styles'

import { RootState } from 'types'

const ExpireJob = () => {
  const dispatch = useDispatch()
  const expireJob = useActionsRoutines(expireJobPromiseCreator)
  const { jobTitle, jobId, isOpenExpireJobModal, isLoading } = useSelector(
    (state: RootState) => state.jobs.expireJob,
  )

  const handleExpireJob = ({ undo = false }) => {
    expireJob({ id: jobId, undo }).then(() => {
      handleCloseModal()
      if (undo) return
      snackbar({
        content: 'Job has been expired.',
        undoFunc: handleUndoExpireJob,
        showIcon: true,
        type: 'info',
        closable: true,
      })
    })
  }

  const handleUndoExpireJob = () => {
    handleExpireJob({ undo: true })
    handleHideMessage()
  }

  const handleCloseModal = () => {
    dispatch(hideExpireJobModal())
  }

  const handleHideMessage = () => {
    message.destroy()
  }

  return (
    <ModalItem
      width={550}
      footer={null}
      title={`Expire ${jobTitle} job`}
      visible={isOpenExpireJobModal}
      onCancel={handleCloseModal}
    >
      <ul>
        <li>Your job will be expired on any job boards it is live on.</li>
        <li>It cannot be made live again without additional cost.</li>
      </ul>
      <ModalFooter>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button
          type="primary"
          loading={isLoading}
          onClick={() => handleExpireJob({ undo: false })}
        >
          Expire
        </Button>
      </ModalFooter>
    </ModalItem>
  )
}

export default ExpireJob
