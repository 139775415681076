/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from '@reduxjs/toolkit'

import appReducer from 'store/app/reducer'
import candidatesReducer from 'store/candidates/reducer'
import companiesToApproveReducer from 'store/companyToApprove/reducer'
import creditsReducer from 'store/credits/reducer'
import hiringTeamReducer from 'store/hiringTeam/reducer'
import jobsReducer from 'store/jobs/reducer'
import usersReducer from 'store/users/reducer'
import viewerReducer from 'store/viewer/reducer'

import stagesReducer from './stages/reducer'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer() {
  return combineReducers({
    app: appReducer,
    viewer: viewerReducer,
    users: usersReducer,
    jobs: jobsReducer,
    hiringTeam: hiringTeamReducer,
    companiesToApprove: companiesToApproveReducer,
    candidates: candidatesReducer,
    credits: creditsReducer,
    stages: stagesReducer,
  })
}
