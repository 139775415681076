import styled from 'styled-components'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

export const Container = styled.div`
  min-width: 1024px;
`

export const HeaderRow = styled(Row)`
  margin-bottom: 36px;
`

export const ColItem = styled(Col)`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`

export const TeamMembersContainer = styled.div`
  margin-bottom: 24px;
`
export const TeamMembersTitle = styled.h1`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const TableWrapper = styled.div`
  min-height: 471px;
`
