import { UserBalance } from 'types/credits'

export const checkIsViewerHasBalance = (balance: UserBalance) => {
  const balanceStatus = !!Object.values(balance).some(item => item > 0)
  return balanceStatus
}

export const totalBalance = (balance: UserBalance) => {
  const { advertiser, hire, managed } = balance
  return advertiser + hire + managed
}
