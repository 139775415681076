import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { completeAuthPromiseCreator } from 'store/viewer'

import { useQuery } from 'helpers/useQuery'

import PageLoader from 'app/components/Loaders/PageLoader/PageLoader'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'
import Auth from 'services/auth'

const Callback = () => {
  const query = useQuery()
  const token = query.get('token')
  const role = query.get('role')
  const history = useHistory()
  const completeAuth = useActionsRoutines(completeAuthPromiseCreator)

  useEffect(() => {
    Auth.parseHash(async (error, response) => {
      if (error || !response) {
        if (
          error.errorDescription ===
          'Please verify your email before logging in.'
        ) {
          return history.push({
            pathname: ROUTES.SIGN_IN,
            state: { message: error.errorDescription },
          })
        }

        return history.push(ROUTES.SIGN_IN)
      }
      if (response) {
        completeAuth({ accessToken: response.accessToken })
          .then(() => history.push(ROUTES.JOBS))
          .catch(({ errors }) => {
            if (errors[0].message === "User haven't finished sign up") {
              if (role && token) {
                return history.push(
                  `${ROUTES.SIGN_UP_COMPANY}?token=${token}&role=${role}`,
                )
              }
              return history.push(ROUTES.SIGN_UP_COMPANY)
            }
          })
      }
    })
  }, [])

  return <PageLoader />
}

export default Callback
