import React, { FC } from 'react'

import { isValidCvSize } from 'helpers/candidateHelpers'

import { allowedUploadTypes } from '../../Modals/constants'

import Button from 'antd/lib/button'
import Upload from 'antd/lib/upload'

import {
  CloudUploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'

import {
  DraggerContainer,
  FileItem,
  FileItemError,
  FileItemRight,
} from './styles'

const { Dragger } = Upload

interface IUploadFile {
  uploadFileProps: any
  onRemoveFile: (file: any) => void
}

const UploadFile: FC<IUploadFile> = ({ uploadFileProps, onRemoveFile }) => {
  return (
    <DraggerContainer>
      <Dragger
        {...uploadFileProps}
        itemRender={(originNode, file) => {
          if (!isValidCvSize(file.size)) {
            return (
              <FileItemError>
                <ExclamationCircleOutlined />
                <div>{file.name}</div>
                <FileItemRight>
                  CV must be smaller than 50MB - removed
                </FileItemRight>
              </FileItemError>
            )
          }

          if (!allowedUploadTypes.some(type => file.name.endsWith(type))) {
            return (
              <FileItemError>
                <ExclamationCircleOutlined />
                <div>{file.name}</div>

                <FileItemRight>Unsupported file type - removed</FileItemRight>
              </FileItemError>
            )
          }

          return (
            <FileItem>
              <PaperClipOutlined />
              <div>{file.name}</div>
              <FileItemRight>
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => onRemoveFile(file)}
                />
              </FileItemRight>
            </FileItem>
          )
        }}
      >
        <CloudUploadOutlined />
        <h2>
          <span>Click</span> or drag and drop to add candidate CVs
        </h2>
        <p>
          Upload up to 50 candidates to your job at a time <br />
          Files supported: pdf, doc, docx
        </p>
      </Dragger>
    </DraggerContainer>
  )
}

export default UploadFile
