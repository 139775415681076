import React from 'react'

import Signal from 'assets/icons/Signal'

import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons'

import {
  MaybeButton,
  RejectButton,
  SuccessButton,
} from './UpdateCandidateStatus/styles'

import {
  CandidateSource,
  CandidateStatus,
  ICandidateSource,
  ICandidateStatus,
} from 'types'

export const candidateSources: ICandidateSource[] = [
  { title: 'Sourced', value: CandidateSource.Sourced },
  { title: 'Jobheron Application', value: CandidateSource.JobheronApp },
  { title: 'JH CV search', value: CandidateSource.JobheronDb },
  {
    title: 'External Recruiter',
    value: CandidateSource.ExternalRecruiter,
  },
]

export const candidateStatuses: ICandidateStatus[] = [
  { title: 'Shortlisted', value: CandidateStatus.Shortlisted },
  { title: 'Maybe', value: CandidateStatus.Maybe },
  { title: 'Rejected', value: CandidateStatus.Rejected },
  { title: 'New', value: CandidateStatus.New },
  { title: 'Viewed', value: CandidateStatus.Viewed },
]

export const candidateStatusesWithIcon = [
  {
    title: 'Shortlisted',
    value: CandidateStatus.Shortlisted,
    icon: <CheckOutlined />,
  },

  {
    title: 'Maybe',
    value: CandidateStatus.Maybe,
    icon: <QuestionCircleOutlined />,
  },
  {
    title: 'Reject',
    value: CandidateStatus.Rejected,
    icon: <CloseCircleOutlined />,
  },
  {
    title: 'New',
    value: CandidateStatus.New,
    icon: <Signal />,
  },
  {
    title: 'Viewed',
    value: CandidateStatus.Viewed,
    icon: <EyeOutlined />,
  },
]

export const candidateStatusButtons = [
  {
    Component: SuccessButton,
    statusName: CandidateStatus.Shortlisted,
    icon: <CheckOutlined />,
  },
  {
    Component: MaybeButton,
    statusName: CandidateStatus.Maybe,
    icon: <QuestionOutlined />,
  },
  {
    Component: RejectButton,
    statusName: CandidateStatus.Rejected,
    icon: <CloseOutlined />,
  },
]
