import styled from 'styled-components/macro'

export const ImageBlock = styled.div`
  margin-bottom: 40px;
  img {
    width: 275px;
    height: 300px;
  }
`

export const SignInText = styled.p`
  font-weight: 600;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.gray7};
  font-size: 14px;
  line-height: 20px;

  a {
    padding-left: 10px;
    color: ${({ theme }) => theme.colors.blue6};
    display: inline-flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }
`

export const List = styled.ul`
  list-style-type: none;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray9};
    &:last-child {
      margin-bottom: 40px;
    }

    svg {
      margin-right: 10px;
      font-size: 18px;
      fill: ${({ theme }) => theme.colors.blue6};
    }
  }
`
