import * as React from 'react'

function CreditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={28}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2.488a4 4 0 014 0l8.702 5.024a4 4 0 012 3.464v10.048a4 4 0 01-2 3.464l-8.701 5.024a4 4 0 01-4 0l-8.702-5.024a4 4 0 01-2-3.464V10.976a4 4 0 012-3.464L12 2.488z"
        fill="#FFC53D"
        stroke="#FFC53D"
        strokeWidth={2}
      />
    </svg>
  )
}

export default CreditIcon
