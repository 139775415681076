import React, { FC } from 'react'

import { campaigns } from 'constants/app'

import Tooltip from 'antd/lib/tooltip'

import { TooltipItem, TooltipRow } from './styles'

import { UserBalance } from 'types'

interface BalanceColumnProps {
  balance: UserBalance
}

const BalanceColumn: FC<BalanceColumnProps> = ({ balance }) => {
  const { advertiser, hire, managed } = balance

  return (
    <Tooltip
      placement="bottomLeft"
      title={
        <TooltipItem>
          <TooltipRow>
            <span>{campaigns.advertiser}</span>
            <span>{advertiser}</span>
          </TooltipRow>
          <TooltipRow>
            <span>{campaigns.managed}</span>
            <span>{managed}</span>
          </TooltipRow>
          <TooltipRow>
            <span>{campaigns.hire}</span>
            <span>{hire}</span>
          </TooltipRow>
        </TooltipItem>
      }
    >
      <span>
        {advertiser} • {managed} • {hire}
      </span>
    </Tooltip>
  )
}

export default BalanceColumn
