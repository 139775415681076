import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { isAdminSelector } from 'store/viewer'

import InviteCompanyMember from '../../Jobs/JobHiringTeam/InviteCompanyMember'
import DeleteUserModal from '../../Modals/DeleteUserModal/DeleteUserModal'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'

import { EditOutlined, EllipsisOutlined } from '@ant-design/icons'

import { Wrapper } from './styles'

import { IAlgoliaUser } from 'types'

const Actions = (user: IAlgoliaUser) => {
  const { objectID, role } = user
  const { jobId } = useParams<{ jobId: string }>()
  const isJHAdmin = useSelector(isAdminSelector)

  if (jobId) {
    return <InviteCompanyMember objectID={objectID} role={role} />
  }

  const menu = (
    <Menu>
      <Menu.Item key="Edit user" icon={<EditOutlined />}>
        <Link to={`${ROUTES.USERS}/${user.objectID}`}>Edit user</Link>
      </Menu.Item>
      <DeleteUserModal isMenuItem user={user} />
    </Menu>
  )

  if (isJHAdmin) {
    return (
      <Wrapper>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
          getPopupContainer={item => item}
        >
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      </Wrapper>
    )
  }
  return null
}

export default Actions
