import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { updateCandidatesStagePromiseCreator } from 'store/candidates'

import snackbar from '../../Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'

import Button from 'antd/lib/button'

import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

import {
  ButtonItem,
  MenuContainer,
  MenuItem,
  MenuItemCurrent,
  TagItem,
} from '../CandidateActions/styles'

import { IStage, RootState, StageType } from 'types'

interface IUpdateCandidateStageMenu {
  handleCloseMenu?: () => void
}

const UpdateCandidateStageMenu: FC<IUpdateCandidateStageMenu> = ({
  handleCloseMenu,
}) => {
  const { stages } = useSelector((state: RootState) => state.stages.fetchStages)
  const { candidate } = useSelector(
    (state: RootState) => state.candidates.fetchCandidate,
  )

  const updateCandidatesStage = useActionsRoutines(
    updateCandidatesStagePromiseCreator,
  )

  const handleUpdateCandidatesStage = async ({ id, name }: IStage) => {
    try {
      handleCloseMenu && handleCloseMenu()
      await updateCandidatesStage({
        ids: [candidate!.id],
        stageId: id,
        stages,
      })
      snackbar({
        content: `Stage changed to "${name}"`,
        showIcon: true,
        type: 'info',
        icon: InfoCircleOutlined,
        closeText: 'OK',
      })
    } catch (err) {
      console.log(err)
    }
  }

  const filteredStage = candidate!.appliedFromLm
    ? stages.filter(({ type }) => type !== StageType.Sourced)
    : stages.filter(({ type }) => type !== StageType.Applied)

  return (
    <MenuContainer>
      {filteredStage.map(stage => (
        <div key={stage.id}>
          {stage.name === candidate!.stage.name ? (
            <MenuItemCurrent>
              <Button type="text" disabled>
                <TagItem>
                  {stage.name}
                  <CheckCircleOutlined />
                </TagItem>
              </Button>
            </MenuItemCurrent>
          ) : (
            <MenuItem onClick={() => handleUpdateCandidatesStage(stage)}>
              <ButtonItem type="text">{stage.name}</ButtonItem>
            </MenuItem>
          )}
        </div>
      ))}
    </MenuContainer>
  )
}

export default UpdateCandidateStageMenu
