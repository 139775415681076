import { IJob } from './jobs'
import { IUser } from './user'

export enum InvitationStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
}

export interface IUpdateHT {
  id: number
  userId: string
  companyId?: string
}

export interface IHiringTeamMember extends IUser {
  userId: string
  invitationStatus: InvitationStatus
}

export interface IHiringTeamJob extends IJob {
  user: {
    id: string
    fullName: string
    email: string
    company: string
  }
}

export interface IHiringTeamState {
  fetch: { isLoading: boolean }
  inviteMember: { isLoading: boolean }
  inviteCompanyMember: { isLoading: boolean }
  deleteMember: { isLoading: boolean }
  job: IHiringTeamJob | null
  refresh: boolean
}
