import styled from 'styled-components/macro'

export const CampaignBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CampaignBlockTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
`

export const CampaignBlockText = styled.div`
  margin-bottom: 32px;
`

export const CampaignRow = styled.div`
  margin: 10px;
  max-width: 100%;

  @media (min-width: 768px) {
    display: flex;
  }
`

export const CampaignHeader = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
  }
`

export const CampaignItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  max-width: 100%;
  margin-bottom: 30px;
  position: relative;
  background: #fff;

  .ant-btn {
    min-width: 230px;
  }

  @media (min-width: 768px) {
    padding: 70px 70px 24px 70px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    margin: 0 12px;

    &:hover {
      border-color: #f0f0f0;
    }
  }
`

export const CampaignItemImageContainer = styled.div`
  margin-bottom: 50px;
  height: 140px;
`

export const ComingSoonContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`

export const CampaignItemTitle = styled.h3`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;

  .ant-tag {
    margin: 0 0 0 10px;
  }
`

export const CampaignSubtitle = styled.p`
  margin-bottom: 13px;
`

export const CampaignSubtitleGray = styled(CampaignSubtitle)`
  opacity: 0.5;
`

export const CampaignItemTitleGray = styled(CampaignItemTitle)`
  color: ${({ theme }) => theme.colors.gray7};

  @media (min-width: 768px) {
    margin-bottom: 42px;
  }
`

export const CampaignItemList = styled.ul`
  color: ${({ theme }) => theme.colors.gray7};
  padding: 0;
  margin: 0 0 28px 0;
`
