import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { clearCandidate, fetchCandidatePromiseCreator } from 'store/candidates'
import { isExternalRecruiterSelector } from 'store/viewer'

import { getFullName } from 'helpers/candidateHelpers'
import { useQuery } from 'helpers/useQuery'

import CandidateHeader from './CandidateHeader'
import CandidateActions from 'app/components/Candidate/CandidateActions/CandidateActions'
import CandidateNotes from 'app/components/Candidate/CandidateNotes/CandidateNotes'
import ActionsSideBar from 'app/components/Candidate/CandidateSideBar/ActionsSideBar/ActionsSideBar'
import CandidateSideBar from 'app/components/Candidate/CandidateSideBar/CandidateSideBar'
import CandidateSummary from 'app/components/Candidate/CandidateSummary/CandidateSummary'
import CandidateTimeline from 'app/components/Candidate/CandidateTimeline/CandidateTimeline'
import useActionsRoutines from 'hooks/useActionsRoutines'

import { Spin } from 'antd'
import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Row from 'antd/lib/row'
import Tabs from 'antd/lib/tabs'

import { SpinContainer } from '../Jobs/PreviewJob/styles'
import { Container, NotesDot, TabWrapper, TabsItem } from './styles'

import { CandidateTabs, RootState } from 'types'

const { TabPane } = Tabs

const Candidate = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const history = useHistory()
  const screens = useBreakpoint()
  const { candidateId } = useParams<{ candidateId: string }>()
  const fetchCandidate = useActionsRoutines(fetchCandidatePromiseCreator)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)

  const defaultTab = screens.lg ? CandidateTabs.Summary : CandidateTabs.CV
  const defaultTabQuery = query.get('tab') || defaultTab
  const [activeTab, setActiveTab] = useState(defaultTabQuery as CandidateTabs)

  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey as CandidateTabs)
    history.replace({ search: `?tab=${activeKey}` })
  }

  const { candidate, isLoading } = useSelector(
    (state: RootState) => state.candidates.fetchCandidate,
  )
  useEffect(() => {
    setActiveTab(defaultTabQuery as CandidateTabs)
  }, [defaultTabQuery])

  useEffect(() => {
    if (
      screens.lg &&
      (activeTab === CandidateTabs.CV || activeTab === CandidateTabs.Details)
    ) {
      setActiveTab(CandidateTabs.Summary)
      history.replace({ search: `?tab=${CandidateTabs.Summary}` })
    }
    if (!screens.lg && activeTab === CandidateTabs.Summary) {
      setActiveTab(CandidateTabs.CV)
      history.replace({ search: `?tab=${CandidateTabs.CV}` })
    }
  }, [screens.lg])

  useEffect(() => {
    fetchCandidate({ candidateId })
      .then(() => {})
      .catch(err => {
        console.log(err)
      })

    return () => {
      dispatch(clearCandidate())
    }
  }, [candidateId])

  if (!candidate || isLoading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    )
  }

  const {
    cv,
    nextCandidate,
    previousCandidate,
    noteExist,
    advert: { status: jobStatus },
  } = candidate

  const fullName = getFullName(candidate)

  return (
    <>
      <CandidateHeader candidate={candidate} />

      <Container>
        <Row gutter={20}>
          <Col span={24} lg={{ span: 18 }}>
            <TabsItem
              defaultActiveKey={
                screens.lg ? CandidateTabs.Summary : CandidateTabs.CV
              }
              activeKey={activeTab}
              onChange={handleChangeTabs}
              tabBarExtraContent={!screens.xl && <ActionsSideBar />}
            >
              {screens.lg ? (
                <TabPane tab={CandidateTabs.CV} key={CandidateTabs.Summary}>
                  <CandidateSummary
                    fullName={fullName}
                    cv={cv}
                    nextCandidate={nextCandidate}
                    previousCandidate={previousCandidate}
                    candidateId={candidateId}
                    jobStatus={jobStatus}
                  />
                </TabPane>
              ) : (
                <>
                  <TabPane tab={CandidateTabs.CV} key={CandidateTabs.CV}>
                    {!screens.md && <CandidateActions candidate={candidate} />}
                    <CandidateSummary
                      fullName={fullName}
                      cv={cv}
                      nextCandidate={nextCandidate}
                      previousCandidate={previousCandidate}
                      candidateId={candidateId}
                      jobStatus={jobStatus}
                    />
                  </TabPane>
                  <TabPane
                    tab={CandidateTabs.Details}
                    key={CandidateTabs.Details}
                  >
                    {!screens.md && <CandidateActions candidate={candidate} />}
                    <CandidateSideBar candidate={candidate} />
                  </TabPane>
                </>
              )}
              <TabPane
                tab={
                  <TabWrapper>
                    {CandidateTabs.Notes} {noteExist && <NotesDot />}
                  </TabWrapper>
                }
                key={CandidateTabs.Notes}
              >
                <CandidateNotes />
              </TabPane>
              {!isExternalRecruiter && (
                <TabPane
                  tab={CandidateTabs.Timeline}
                  key={CandidateTabs.Timeline}
                >
                  <CandidateTimeline />
                </TabPane>
              )}
            </TabsItem>
          </Col>
          {screens.lg && (
            <Col span={24} lg={{ span: 6 }}>
              <CandidateSideBar candidate={candidate} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default Candidate
