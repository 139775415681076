import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import publishedImg from '../assets/published.svg'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Space from 'antd/lib/space'

import { Confirmation } from '../styles'

const JobPublished: FC<{ jobId: string }> = ({ jobId }) => {
  return (
    <Confirmation>
      <img
        src={publishedImg}
        alt="Your advert is on it’s way to our editorial team."
      />
      <h2>Your advert is now published!</h2>
      <p>
        Keep an eye on your inbox for an email from us. We’ll let you know once
        we have reviewed your advert. You can check your advert’s status at any
        time on you dashboard.
      </p>

      <Space>
        <Button>
          <Link to={ROUTES.JOBS}>See all jobs</Link>
        </Button>
        <Button type="primary">
          <Link to={`${ROUTES.JOB}/${jobId}`}>View job</Link>
        </Button>
      </Space>
    </Confirmation>
  )
}

export default JobPublished
