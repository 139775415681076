import { createAction } from '@reduxjs/toolkit'

export const updateLastPage = createAction<{
  path: string
  type: 'jobs' | 'candidates'
}>('app/updateLastPage')

export const showSnackbar = createAction<{ message: string }>(
  'app/showSnackbar',
)

export const hideSnackbar = createAction('app/hideSnackbar')

export const startRefreshAlgoliaCache = createAction(
  'app/startRefreshAlgoliaCache',
)
export const stopRefreshAlgoliaCache = createAction(
  'app/stopRefreshAlgoliaCache',
)
