import React from 'react'

import { FileTextOutlined } from '@ant-design/icons'

import { UsersRole } from '../types'

export const ROUTES = Object.freeze({
  DASHBOARD: '/',
  JOBS: '/jobs',
  JOB: '/job',
  JOB_EDIT: '/edit',
  JOB_HIRING_TEAM: '/hiring-team',
  JOB_CREATE: '/job/create',
  JOB_SELECT_CAMPAIGN_TYPE: '/select-campaign-type',
  JOB_ADD_BOARD: '/add-board',
  COMPANY: '/company',
  USERS: '/users',

  CANDIDATE: '/candidate',
  ASSIGN_CREDITS: '/assign-credits',

  CHECKOUT: '/checkout',

  INBOX: '/inbox',
  CANDIDATES: '/candidates',
  INTERVIEWS: '/interviews',

  SETTINGS: '/settings',
  SETTING_PROFILE: '/settings/profile',

  SETTING_COMPANY_INFO: '/settings/company-info',
  SETTING_ACCOUNT_MEMBERS: '/settings/account-members',
  SETTING_JOBS_STAGES: '/settings/jobs-stages',
  SETTING_JOBS_STAGES_EDIT: '/settings/jobs-stages/edit',
  SETTING_GDPR: '/settings/gdpr',
  SETTING_ASSIGN_CREDITS: '/settings/assign-credits',

  SIGN_UP: '/auth/sign-up',
  SIGN_UP_COMPANY: '/auth/sign-up-company',
  SIGN_IN: '/auth/sign-in',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  AUTH_CALLBACK: '/auth/callback',

  ADMIN_MANAGE_USERS: '/admin/manage-users',
  ADMIN_CANDIDATES: '/admin/candidates',
  ADMIN_CREATE_USER: '/admin/create-user',

  JOBHERON_TERMS_AND_CONDITIONS: 'https://jobheron.co.uk/terms',
  JOBHERON_HELP_CENTER: 'https://jobheron.tawk.help',
  JOBHERON_CONTACT_US: 'https://jobheron.co.uk/contact',
})

export const adminMenu = [
  {
    title: 'Jobs',
    link: ROUTES.JOBS,
  },
  {
    title: 'Manage users',
    link: ROUTES.ADMIN_MANAGE_USERS,
  },
]

export const userMenu = [
  {
    title: 'Jobs',
    link: ROUTES.JOBS,
    icon: <FileTextOutlined />,
    enableSubMenu: false,
  },
]

export const settingsMenu = [
  {
    group: 'Personal',
    children: [
      {
        title: 'Profile',
        link: ROUTES.SETTING_PROFILE,
        permissions: [
          UsersRole.TeamMember,
          UsersRole.ExternalRecruiter,
          UsersRole.HiringManager,
          UsersRole.RecruitmentManager,
          UsersRole.CompanyAdmin,
        ],
      },
    ],
  },
  {
    group: 'Organisation',
    children: [
      // {
      //   title: 'Company details',
      //   link: ROUTES.SETTING_COMPANY_INFO,
      //   permissions: [
      //     UsersRole.TeamMember,
      //     UsersRole.HiringManager,
      //     UsersRole.RecruitmentManager,
      //     UsersRole.CompanyAdmin,
      //   ],
      // },
      {
        title: 'Account Members',
        link: ROUTES.SETTING_ACCOUNT_MEMBERS,
        permissions: [
          UsersRole.TeamMember,
          UsersRole.HiringManager,
          UsersRole.RecruitmentManager,
          UsersRole.CompanyAdmin,
        ],
      },
      {
        title: 'Job Stages',
        link: ROUTES.SETTING_JOBS_STAGES,
        permissions: [UsersRole.CompanyAdmin],
      },
      {
        title: 'GDPR',
        link: ROUTES.SETTING_GDPR,
        permissions: [
          UsersRole.TeamMember,
          UsersRole.HiringManager,
          UsersRole.RecruitmentManager,
          UsersRole.CompanyAdmin,
        ],
      },
      {
        title: 'Assign credits',
        link: ROUTES.SETTING_ASSIGN_CREDITS,
        permissions: [UsersRole.CompanyAdmin],
      },
    ],
  },
]
