import styled from 'styled-components'

import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'

export const ButtonWrapper = styled.div`
  text-align: right;
`
export const FormWrapper = styled.div`
  & .ant-input {
    text-align: center;
  }
`

export const Column = styled(Col)`
  .ant-col.ant-form-item-label {
    text-align: center;
  }
`
export const RowItem = styled(Row)`
  margin-bottom: 24px;
`

export const InputItem = styled(Input)`
  .ant-col.ant-form-item-label {
    text-align: center;
  }
`
