import { UserBalance } from './credits'
import { CompanyStatus, IAlgoliaUser } from './user'

import { CreditType, IError } from 'types'

export enum JobStatus {
  Draft = 'Draft',
  NoCredits = 'NoCredits',
  PendingAdminApproval = 'PendingAdminApproval',
  PendingUserApproval = 'PendingUserApproval',
  Live = 'Live',
  ReadOnly = 'ReadOnly',
  Archived = 'Archived',
}

export enum TagComponent {
  JobItem = 'JobItem',
  JobDetails = 'JobDetails',
}

export enum JobStatusFilter {
  Draft = 'Draft',
  Pending = 'Pending',
  Live = 'Live',
  Archived = 'Archived',
}

export enum JobCampaign {
  SelfService = 'SelfService',
  Jobheron = 'Jobheron',
}

export enum JobTimeInterval {
  Annual = 'Annual',
  Weekly = 'Weekly',
  Daily = 'Daily',
  Hourly = 'Hourly',
}

export enum JobType {
  Permanent = 'Permanent',
  Contract = 'Contract',
  Temporary = 'Temporary',
}

export enum JobTimeEngaging {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export interface ICreateJob {
  title: string
  description: string
  benefits: string
  type: JobType
  timeEngaging: JobTimeEngaging
  salaryBottom: number
  salaryTop: number
  showSalary: boolean
  needApproval: boolean
  preferKw: string
  postCode: string[]
}

export interface IAlgoliaJob {
  activatedAt: Date
  company: {
    id: number
    name: string
    status: CompanyStatus
  }
  createdAt: Date
  expiresAt: Date
  isArchived: boolean
  isFeature: boolean
  isExpired: boolean
  isJHCampaign: boolean
  isPostedOnCareerPage: boolean
  isPostedOnOtherBoards: boolean
  isSSCampaign: boolean
  objectID: string
  ownerId: string
  referenceCode: string
  status: JobStatus
  title: string
  totalCandidates: number
  totalInPipeline: number
  totalInterviews: number
  totalNewCandidates: number
  statusFilter: JobStatusFilter
  statusExternal: JobStatusFilter
  statusFilterExternal: JobStatusFilter
  relatedCampaign?: {
    referenceCode: string
  }
}

export interface IJobStatuses extends IAlgoliaJob {
  isAdmin: boolean
}

export enum JobPage {
  DetailJob = 'detailJob',
  PreviewJob = 'previewJob',
}

export interface IJobBoards {
  isExpired: boolean
  name: string
  numberOfCandidates: number
}
export interface IDetailJob {
  id: number
  status: JobStatus
  campaignName?: string
  relatedCampaignId?: number | null
  department?: string | null
  companyId: number
  isExpired: boolean
  expiresAt?: Date
  activatedAt: Date
  totalCandidates: number
  totalNewCandidates: number
  totalInPipeline: number
  totalInterviews: number
  isPostedOnCareerPage: boolean
  isPostedOnOtherBoards: boolean
  jobBoards?: IJobBoards[]
  user: {
    id: string
    fullName: string
    email: string
    company: string
    companyStatus: string
  }
  referenceCode: string
  isFeature: boolean
  campaignType: JobCampaign
  title: string
  description: string
  benefits: string | null
  salaryTop: number
  salaryBottom: number
  showSalary: boolean
  type: JobType
  timeEngaging: JobTimeEngaging
  timeInterval: JobTimeInterval
  postCode: string
  remote: boolean
  preferKw: string[]
  needApproval: boolean
  location: string
  creditType: CreditType | null
}

export interface IJob {
  company: {
    id: number
    name: string
  }
  id: number
  isFeature: boolean
  forwardTo: string[]
  cvForwardingFrequency: IJobFrequencySetting
  isExpired: boolean
  isJHCampaign: boolean
  isPostedOnCareerPage: boolean
  isPostedOnOtherBoards: boolean
  isSSCampaign: boolean
  ownerId: string
  status: JobStatus
  title: string
  totalCandidates: number
  totalInPipeline: number
  totalInterviews: number
  description: string
  totalNewCandidates: number
  referenceCode: string
  salaryTop: number
  salaryBottom: number
  timeInterval: JobTimeInterval
  timeEngaging: JobTimeEngaging
  needApproval: boolean
  campaignType: JobCampaign
  postCode: string
  preferKw: string[]
  benefits: string
  type: JobType
  remote: boolean
  activatedAt: Date
  createdAt: Date
  showSalary: boolean
  userId: string
  location: string
  user: any
  companyId: number
  department?: string
  balance?: null | UserBalance
  creditType: CreditType | null
}

export interface IJobsState {
  fetchJob: { isLoading: boolean; job?: IJob }
  fetchJobPreview: { isLoading: boolean; job?: IJob }
  fetchJobs: { isLoading: boolean; error: any; isError: boolean }
  updateJobForwardSettings: { isLoading: boolean }
  createJob: { isLoading: boolean; errors: IError[] | null }
  updateJob: { isLoading: boolean }
  publishJob: { isLoading: boolean }
  publishJobWithOutApproval: { isLoading: boolean }
  approveJob: { isLoading: boolean }
  archiveJob: { isLoading: boolean }
  unArchiveJob: { isLoading: boolean }
  promoteJob: { isLoading: boolean }
  fetchRejectedCandidates: IAlgoliaUser[]
  deleteJob: {
    isLoading: boolean
    isOpenDeleteJobModal: boolean
    jobId: string | null
    jobTitle: string | null
    jobStatus: string | null
  }
  expireJob: {
    isLoading: boolean
    isOpenExpireJobModal: boolean
    jobId?: string
    jobTitle?: string
  }
  counters: null | {
    Pending: number
    Live: number
    Draft: number
    Archived: number
  }
}

export interface IApproveJob {
  advertId: number
  creditType: CreditType
}

export interface ExpireJob {
  id: string
  undo: boolean
}
export interface ArchiveJob {
  id: string
  undo: boolean
}

export enum CreateJobTab {
  SelectCampaign = 'SelectCampaign',
  JobDetails = 'JobDetails',
  HiringTeam = 'HiringTeam',
}

export enum JobsListTab {
  All = 'All',
  Pending = 'Pending',
  Live = 'Live',
  Draft = 'Draft',
  Archived = 'Archived',
}

export interface ApiPublishJob {
  id: number
  userApproval?: boolean
  creditType?: CreditType
}

export enum JobDetailTab {
  Candidates = 'Candidates',
  Pipeline = 'Pipeline',
  Activities = 'Activities',
  Overview = 'Overview',
  HiringTeam = 'HiringTeam',
  JobDetails = 'JobDetails',
}

export enum IJobFrequencySetting {
  Directly = 'Directly',
  Daily = 'Daily',
}
export interface IJobForwardSettings {
  id: number
  enable: boolean
  frequency: IJobFrequencySetting
  emailList: string[]
}
