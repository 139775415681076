import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  fetchCandidateAssociatedMembersPromiseCreator,
  postCandidateNotePromiseCreator,
} from 'store/candidates'
import { isExternalRecruiterSelector } from 'store/viewer'

import { yupResolver } from '@hookform/resolvers/yup'

import useActionsRoutines from 'hooks/useActionsRoutines'
import { OptionProps } from 'rc-mentions/lib/Option'
import { AddNoteSchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Mentions from 'antd/lib/mentions'
import Modal from 'antd/lib/modal'

import {
  HighlighterBlock,
  InputWrapper,
  ModalContent,
  ModalFooter,
} from './styles'

import { IAlgoliaUser, InvitationStatus, RootState, UserStatus } from 'types'

interface Inputs {
  note: string
}

const { Option } = Mentions

interface AddNoteModalProps {
  icon?: React.ReactNode
  buttonTitle: string
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
  candidateId: string
  companyId: number
  advertId: string
}
interface IUserMentions {
  key: string
  value: string
}

const AddNoteModal: FC<AddNoteModalProps> = ({
  icon,
  buttonType = 'default',
  buttonTitle,
  advertId,
  companyId,
  candidateId,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const highlighterRef = useRef<HTMLHeadingElement>(null)
  const inputRef = useRef<HTMLHeadingElement>(null)
  const [associatedMembers, setAssociatedMembers] = useState<IAlgoliaUser[]>([])
  const getCandidateAssociatedMembers = useActionsRoutines(
    fetchCandidateAssociatedMembersPromiseCreator,
  )
  const postCandidateNote = useActionsRoutines(postCandidateNotePromiseCreator)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const [userMentions, setUserMentions] = useState<IUserMentions[]>([])
  const [inputText, setInputText] = useState<string>('')

  const { isLoading } = useSelector(
    (state: RootState) => state.candidates.postCandidateNote,
  )

  useEffect(() => {
    if (isOpenModal) {
      fetchAssociatedMembers()
    }
  }, [isOpenModal])

  if (isOpenModal) {
    inputRef.current?.focus()
  }

  const fetchAssociatedMembers = async () => {
    const filters = isExternalRecruiter
      ? `advertId: ${advertId} AND invitationStatus: ${InvitationStatus.Accepted}`
      : `companyId:${companyId} AND (role:CompanyAdmin OR role:RecruitmentManager OR acceptedTeams:${advertId}) AND status: ${UserStatus.Verified}`
    const response = await getCandidateAssociatedMembers({
      options: {
        filters,
        hitsPerPage: 100,
      },
      isExternalRecruiter,
    })
    setAssociatedMembers(response)
  }

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddNoteSchema),
    mode: 'onSubmit',
  })

  const handleOpenModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    setInputText('')
    reset()
  }

  const handleFormSubmit: SubmitHandler<Inputs> = data => {
    const markup = highlighterRef?.current?.innerHTML

    const users = userMentions
      .filter(({ value }: { value: string }) => data.note.includes(value))
      .map(item => item.key)
    postCandidateNote({
      id: candidateId,
      text: markup,

      users: !!users.length ? users : undefined,
    })
    handleCloseModal()
    setValue('note', '')
    setInputText('')
  }

  const handleSelectMentions = (option: OptionProps, prefix: string) => {
    setUserMentions([
      ...userMentions,
      {
        value: `${prefix}${option.value}`,
        key: option.key,
      },
    ] as IUserMentions[])
  }
  const highlightedUserNames = userMentions.map(item => item.value)

  return (
    <>
      <Button type={buttonType} onClick={handleOpenModal} icon={icon}>
        {buttonTitle}
      </Button>
      <Modal
        width={550}
        footer={null}
        title="Add a note"
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        <ModalContent>
          <Form onFinish={handleSubmit(handleFormSubmit)}>
            <InputWrapper>
              <Form.Item
                validateStatus={errors && errors.note ? 'error' : 'success'}
                help={errors.note?.message}
                rules={[{ required: true }]}
                name="note"
              >
                <Controller
                  render={({ field }) => (
                    <Mentions
                      autoSize={true}
                      {...field}
                      onChange={value => {
                        setInputText(value)
                        field.onChange(value)
                      }}
                      value={inputText}
                      onSelect={(option, prefix) =>
                        handleSelectMentions(option, prefix)
                      }
                      placeholder="Write your note here... &#10;Send your note to a colleague by typing @theirname"
                      name="note"
                      ref={inputRef}
                    >
                      {!!associatedMembers.length &&
                        associatedMembers.map(member => (
                          <Option
                            value={member.fullName}
                            key={
                              isExternalRecruiter
                                ? member.userId
                                : member.objectID
                            }
                          >
                            {member.fullName}
                          </Option>
                        ))}
                    </Mentions>
                  )}
                  control={control}
                  name="note"
                />
              </Form.Item>
              <HighlighterBlock ref={highlighterRef}>
                <Highlighter
                  highlightClassName="highlight-mention"
                  searchWords={highlightedUserNames}
                  autoEscape={true}
                  textToHighlight={inputText}
                />
              </HighlighterBlock>
            </InputWrapper>
            <ModalFooter>
              <Button onClick={handleCloseModal}>Delete</Button>
              <Button
                type="primary"
                onClick={handleSubmit(handleFormSubmit)}
                loading={isLoading}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddNoteModal
