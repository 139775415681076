import styled from 'styled-components/macro'

export const Body = styled.main`
  height: 100%;
  min-height: 100vh;
  background-color: ${({
    isJobsPageOrCreateJobPage,
  }: {
    isJobsPageOrCreateJobPage: boolean
  }) => (isJobsPageOrCreateJobPage ? '#FAFAFA' : '#fff')};
  padding-top: 75px;
`

export const Container = styled.div`
  max-width: 1260px;
  margin: auto;
  padding: 10px;
  height: 100%;

  @media (min-width: 1024px) {
    padding: 25px 30px 30px;
  }
`
