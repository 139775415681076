import styled from 'styled-components/macro'

export const Container = styled.div`
  background: #ffffff;

  .ant-pagination {
    justify-content: flex-end;
    display: flex;
    margin-top: 20px;
  }
`

export const UserItem = styled.div`
  border-bottom: 1px solid rgb(240, 240, 240);
  padding: 0 10px;

  .ant-col {
    margin: 10px 0;
    padding: 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ant-btn {
    padding: 0;
    font-weight: 600;
  }

  a {
    color: ${({ theme }) => theme.colors.gray9};
    text-decoration: underline;
  }
`
