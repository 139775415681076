import styled from 'styled-components'

export const CampaignItem = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 600;
  margin-left: 0;
  font-size: 12px;

  @media (min-width: 768px) {
    margin-left: 30px;
    justify-content: flex-end;
  }

  svg {
    margin-right: 5px;
  }
`

export const CampaignItemExpired = styled(CampaignItem)`
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.gray7};
  font-weight: 400;
`
