import React, { FC, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  createStagePromiseCreator,
  updateStagePromiseCreator,
} from 'store/stages'

import { setFormErrors } from 'helpers/apiHelpers'
import { renderStageIcon } from 'helpers/stagesHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import { stageTypes } from '../../constants'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import snackbar from 'app/components/Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { AddStageSchema } from 'validation'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Space from 'antd/lib/space'
import Spin from 'antd/lib/spin'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { ColItem, Container } from './styles'

import { ApiCreateStage, IStage, RootState, StageType } from 'types'

const { Option } = Select

interface IAddEditStage {
  isShowAddStage: boolean
  setIsShowAddStage: (value: boolean) => void
  setCurrentStage: (value: IStage | null) => void
  currentStage: IStage | null
}

const AddEditStage: FC<IAddEditStage> = ({
  isShowAddStage,
  setIsShowAddStage,
  currentStage,
  setCurrentStage,
}) => {
  const updateStage = useActionsRoutines(updateStagePromiseCreator)
  const createStage = useActionsRoutines(createStagePromiseCreator)

  const { isLoading } = useSelector(
    (state: RootState) => state.stages.createStage,
  )
  const methods = useForm({
    resolver: yupResolver(AddStageSchema),
  })

  const { setValue, control, handleSubmit, reset, setError } = methods

  useEffect(() => {
    if (!currentStage) return

    setValue('name', currentStage.name)
    setValue('type', currentStage.type)
  }, [currentStage, isShowAddStage])

  const handleCloseIsAddStage = () => {
    setIsShowAddStage(false)
    reset({ type: StageType.PhoneScreen })
    setCurrentStage(null)
  }

  const handleAddEditStage = async ({ name, type }: ApiCreateStage) => {
    try {
      if (currentStage) {
        await updateStage({
          id: currentStage.id,
          order: currentStage.order,
          name,
          type,
        })

        return handleCloseIsAddStage()
      }

      await createStage({ name, type })
      methods.reset({ type: StageType.PhoneScreen })
      snackbar({
        content: `${name} stage added`,
        showIcon: true,
        type: 'info',
        closeText: 'OK',
      })
      handleCloseIsAddStage()
    } catch ({ errors }) {
      setFormErrors({
        setError,
        defaultItem: 'name',
        errors,
      })
    }
  }

  return (
    <Container>
      {isShowAddStage && (
        <Spin spinning={isLoading}>
          <FormProvider {...methods}>
            <Form layout="vertical">
              <Row align="middle" gutter={12}>
                <ColItem>
                  <FormItem
                    name="name"
                    label="Stage name"
                    component={Input}
                    componentProps={{ placeholder: 'Enter stage name' }}
                  />
                </ColItem>

                <ColItem>
                  <Form.Item label="Stage type">
                    <Controller
                      render={({ field }) => (
                        <Select {...field}>
                          {stageTypes.map(({ name, type }) => (
                            <Option key={type} value={type}>
                              <Space>
                                <div>{renderStageIcon(type)}</div>
                                <div>{name}</div>
                              </Space>
                            </Option>
                          ))}
                        </Select>
                      )}
                      control={control}
                      name="type"
                      defaultValue={StageType.PhoneScreen}
                    />
                  </Form.Item>
                </ColItem>

                <Col>
                  <Space>
                    <Button onClick={handleCloseIsAddStage}>
                      <CloseOutlined />
                    </Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit(handleAddEditStage)}
                    >
                      <CheckOutlined />
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </Spin>
      )}
    </Container>
  )
}

export default AddEditStage
