import { takeLatest } from 'redux-saga/effects'

import {
  deleteMember,
  getHiringTeamCounters,
  inviteCompanyMember,
  inviteMember,
} from './actions'
import {
  ensureDeleteMember,
  ensureGetHiringTeamCounters,
  ensureInviteCompanyMember,
  ensureInviteMember,
} from './workers'

export function* watchInviteCompanyMember() {
  yield takeLatest(inviteCompanyMember.TRIGGER, ensureInviteCompanyMember)
}

export function* watchDeleteMember() {
  yield takeLatest(deleteMember.TRIGGER, ensureDeleteMember)
}

export function* watchInviteMember() {
  yield takeLatest(inviteMember.TRIGGER, ensureInviteMember)
}
export function* watchGetHiringTeamCounters() {
  yield takeLatest(getHiringTeamCounters.TRIGGER, ensureGetHiringTeamCounters)
}
