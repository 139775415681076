import styled from 'styled-components/macro'

import Alert from 'antd/lib/alert'
import Space from 'antd/lib/space'

export const InfoAlert = styled(Alert)`
  margin-bottom: 20px;
`

export const Footer = styled(Space)`
  display: flex;
  justify-content: flex-end;
`
