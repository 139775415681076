import styled from 'styled-components/macro'

export const Body = styled.div`
  margin-bottom: 50px;

  .ant-tabs-nav {
    margin-top: 5px;
  }
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const NotVerifiedBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 37px 28px 26px;
  max-width: 476px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
`

export const LinkItem = styled.a`
  font-size: 14px;
  line-height: 22px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue6};
`
