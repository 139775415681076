import React, { FC } from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import { candidateSources } from 'app/components/Candidate/constanst'
import CloseIcon from 'assets/icons/Close'
import uniqBy from 'lodash/uniqBy'

import Button from 'antd/lib/button'
import Tag from 'antd/lib/tag'

import { CloseOutlined } from '@ant-design/icons'

import { ClearFilters, Container, Tags } from './styles'

import { CandidateSource } from 'types'

interface ICustomCurrentRefinements {
  items: any
  refine: (value: unknown) => void
  padding?: string
}

const CustomCurrentRefinements: FC<ICustomCurrentRefinements> = ({
  items,
  refine,
  padding,
}) => {
  if (!items.length) return null

  const uniqueCurrentRefinement = uniqBy(items, 'label')

  return (
    <Container padding={padding}>
      <Tags>
        {uniqueCurrentRefinement.map((item: any, index: number) => {
          if (item.attribute === 'isFeature') {
            return (
              <Tag key={index}>
                Starred only
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    refine(item.value)
                  }}
                />
              </Tag>
            )
          }

          return (
            <span key={item.value}>
              {item.items ? (
                <>
                  {item.items.map((nested: any) => {
                    const itemIndex = candidateSources.findIndex(
                      ({ value }: { value: CandidateSource }) =>
                        value === nested.label,
                    )
                    if (itemIndex !== -1) {
                      return (
                        <Tag key={nested.value}>
                          {candidateSources[itemIndex].title}
                          <Button
                            type="text"
                            icon={<CloseIcon color="#8C8C8C" />}
                            onClick={() => {
                              refine(nested.value)
                            }}
                          />
                        </Tag>
                      )
                    }
                    return (
                      <Tag key={nested.value}>
                        {nested.label}
                        <Button
                          type="text"
                          icon={<CloseIcon color="#8C8C8C" />}
                          onClick={() => {
                            refine(nested.value)
                          }}
                        />
                      </Tag>
                    )
                  })}
                </>
              ) : (
                <Tag key={item.label}>
                  {item.label}
                  <Button
                    type="text"
                    icon={<CloseIcon color="#8C8C8C" />}
                    onClick={() => {
                      refine(item.value)
                    }}
                  />
                </Tag>
              )}
            </span>
          )
        })}
        {!!items.length && (
          <ClearFilters type="link" onClick={() => refine(items)}>
            Clear all filters
            <CloseIcon color="#2688FC" />
          </ClearFilters>
        )}
      </Tags>
    </Container>
  )
}

const CurrentRefinements = connectCurrentRefinements(CustomCurrentRefinements)

export default CurrentRefinements
