import React, { FC, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { inviteMemberPromiseCreator } from 'store/hiringTeam'
import { getViewerSelector, isAdminSelector } from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import AccessLevelInfo from '../../UI/AccessLevelInfo/AccessLevelInfo'
import SearchCompaniesAutocomplete from './SearchCompaniesAutocomplete/SearchCompaniesAutocomplete'
import SearchJobAutocomplete from './SearchJobAutocomplete/SearchJobAutocomplete'
import { inviteRoles } from 'app/modules/Jobs/constants'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { inviteMemberAdminSchema, inviteMemberSchema } from 'validation'

import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Space from 'antd/lib/space'

import {
  AlertContainer,
  Description,
  Header,
  InputContainer,
  ModalBody,
  RadioContainer,
  RadioItem,
  SelectCompanyContainer,
  SelectJobsContainer,
  Title,
} from './styles'

import { IInviteMember, JobStatus, RootState, UsersRole } from 'types'

interface IInviteTeamMember {
  title?: string
  type?: 'primary' | 'default' | 'text' | 'link' | 'ghost' | 'dashed'
  jobId?: number
  companyId?: number
  icon?: React.ReactNode
  jobStatus?: JobStatus
}

const InviteTeamMember: FC<IInviteTeamMember> = ({
  title = 'Invite a new Member',
  type = 'primary',
  icon,
  jobId,
  companyId,
  jobStatus,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentInviteEmail, setCurrentInviteEmail] = useState('')
  const [currentInviteCompany, setCurrentInviteCompany] = useState('')
  const [isMemberInvited, setIsUserInvited] = useState(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState<null | string>(
    null,
  )

  const isAdmin = useSelector(isAdminSelector)
  const viewer = useSelector(getViewerSelector)
  const hiringTeam = useSelector((state: RootState) => state.hiringTeam)
  const inviteMember = useActionsRoutines(inviteMemberPromiseCreator)

  const methods = useForm({
    resolver: yupResolver(
      isAdmin ? inviteMemberAdminSchema : inviteMemberSchema,
    ),
    defaultValues: {
      email: '',
      role: '',
      adverts: [],
      companyId,
    },
  })

  const userId = viewer.id

  const handleInviteMember = ({
    email,
    role,
    adverts = [],
    companyId,
  }: IInviteMember) => {
    setCurrentInviteEmail(email)

    const allAdverts = [...adverts, jobId].filter(Boolean)

    inviteMember({
      userId,
      email,
      role,
      adverts: allAdverts.length ? allAdverts : undefined,
      companyId,
    })
      .then(() => setIsUserInvited(true))
      .catch(({ errors }) =>
        setFormErrors({
          setError: methods.setError,
          defaultItem: 'email',
          errors,
        }),
      )
  }

  const handleResetModal = () => {
    setIsUserInvited(false)
    methods.reset()
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsUserInvited(false)
    setSelectedCompanyId(null)
    methods.reset()
    setIsOpenModal(false)
  }

  const errors = methods.formState.errors

  const selectedRole = methods.watch('role')
  const selectedAdverts = methods.watch('adverts')
  const selectedCompany = methods.watch('companyId')

  useEffect(() => {
    methods.setValue('adverts', [])
  }, [selectedRole])

  return (
    <>
      <Button type={type} onClick={handleOpenModal} icon={icon}>
        {title}
      </Button>

      <Modal
        width={550}
        footer={null}
        maskClosable={false}
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        {isMemberInvited ? (
          <div>
            <Header>
              <Title>
                {selectedRole === UsersRole.ExternalRecruiter
                  ? 'New external recruiter invited'
                  : 'New member invited'}
              </Title>
            </Header>
            <Description>
              <span>
                Invite sent to <strong>{currentInviteEmail}. </strong>
              </span>
              {isAdmin && !jobId && (
                <span>
                  User assigned to <strong>{currentInviteCompany}</strong>
                </span>
              )}
            </Description>

            <Space>
              <Button onClick={handleResetModal}>Invite another</Button>
              <Button type="primary" onClick={handleCloseModal}>
                I’m done
              </Button>
            </Space>
          </div>
        ) : (
          <ModalBody>
            <Header>
              <Title>Invite a new Member</Title>
            </Header>
            {jobStatus === JobStatus.Archived && (
              <AlertContainer>
                <Alert
                  type="warning"
                  showIcon
                  message="You are in the middle of inviting user to archived job."
                />
              </AlertContainer>
            )}
            <AccessLevelInfo />
            <FormProvider {...methods}>
              <Form layout="vertical">
                <InputContainer>
                  <Input.Group compact>
                    <Form.Item
                      validateStatus={
                        errors.email || errors.role ? 'error' : 'success'
                      }
                      help={errors.email?.message || errors.role?.message}
                    >
                      <Controller
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder="Invite by email address..."
                          />
                        )}
                        control={methods.control}
                        name="email"
                        defaultValue=""
                      />
                    </Form.Item>

                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={hiringTeam.inviteMember.isLoading}
                      onClick={methods.handleSubmit(handleInviteMember)}
                    >
                      Invite
                    </Button>
                  </Input.Group>
                </InputContainer>

                <RadioContainer>
                  <Form.Item>
                    <Controller
                      render={({ field }) => (
                        <Radio.Group {...field}>
                          {inviteRoles.map(({ title, value, description }) => (
                            <RadioItem key={value}>
                              <Radio value={value}>
                                <h3>{title}</h3>
                                <span>{description}</span>
                              </Radio>
                            </RadioItem>
                          ))}

                          {isAdmin && !jobId && (
                            <SelectCompanyContainer>
                              <Form.Item
                                label="Select Member’s existing company"
                                validateStatus={
                                  errors.companyId ? 'error' : 'success'
                                }
                                help={errors.companyId?.message}
                              >
                                <Controller
                                  render={({ field }) => (
                                    <SearchCompaniesAutocomplete
                                      {...field}
                                      isShowMembersQuantity
                                      selectedCompanyId={selectedCompanyId}
                                      selectedAdverts={selectedAdverts}
                                      placeholder="Select or search for a company..."
                                      setCurrentInviteCompany={
                                        setCurrentInviteCompany
                                      }
                                    />
                                  )}
                                  control={methods.control}
                                  name="companyId"
                                />
                              </Form.Item>
                            </SelectCompanyContainer>
                          )}

                          {selectedRole === UsersRole.ExternalRecruiter &&
                            !jobId && (
                              <SelectJobsContainer>
                                <Form.Item label="Select jobs (optional)">
                                  <Controller
                                    render={({ field }) => (
                                      <SearchJobAutocomplete
                                        {...field}
                                        selectedAdverts={selectedAdverts}
                                        selectedCompany={selectedCompany}
                                        selectedCompanyId={selectedCompanyId}
                                        setSelectedCompanyId={
                                          setSelectedCompanyId
                                        }
                                      />
                                    )}
                                    control={methods.control}
                                    name="adverts"
                                    defaultValue={[]}
                                  />
                                </Form.Item>
                              </SelectJobsContainer>
                            )}
                        </Radio.Group>
                      )}
                      control={methods.control}
                      name="role"
                      defaultValue=""
                    />
                  </Form.Item>
                </RadioContainer>
              </Form>
            </FormProvider>
          </ModalBody>
        )}
      </Modal>
    </>
  )
}

export default InviteTeamMember
