import * as Yup from 'yup'

export const createJobSchema = Yup.object().shape(
  {
    title: Yup.string()
      .trim()
      .required('Has to be between 3 and 200 characters')
      .min(3, 'Has to be between 3 and 200 characters')
      .max(200, 'Has to be between 3 and 200 characters'),
    description: Yup.string().test(
      'editorLength',
      'Has to be between 50 and 6000 characters',
      function (html?: string) {
        const string =
          html?.replace(/<\/?[^>]+(>|$)/g, '').replace(/&amp;/g, '&') || ''
        if (string.length < 50) return false
        if (string.length > 6000) return false
        return !!html
      },
    ),

    benefits: Yup.lazy(val =>
      !!val
        ? Yup.string()
            .min(3, 'Has to be between 3 and 75 characters')
            .max(75, 'Has to be between 3 and 75 characters')
        : Yup.string().nullable(),
    ),
    type: Yup.string().required('Required'),
    timeEngaging: Yup.string().required('Has to be a valid Time Engagement'),
    salaryBottom: Yup.number()
      .required('Has to be equal or greater than salary to')
      .transform(value => (isNaN(value) ? undefined : value))
      .when('salaryTop', {
        is: (salaryTop: any) => +salaryTop,
        then: Yup.number()

          .typeError('Has to be equal or less than salary to')
          .required('Has to be equal or less than salary to')
          .positive('Has to be equal or less than salary to')
          .max(Yup.ref('salaryTop'), 'Has to be equal or less than salary to'),
      }),

    salaryTop: Yup.number()
      .required('Has to be equal or greater than salary from')
      .transform(value => (isNaN(value) ? undefined : value))
      .when('salaryBottom', {
        is: (salaryBottom: any) => +salaryBottom,
        then: Yup.number()
          .typeError('Has to be equal or greater than salary from')
          .required('Has to be equal or greater than salary from')
          .positive('Has to be equal or greater than salary from')
          .min(
            Yup.ref('salaryBottom'),
            'Has to be equal or greater than salary from',
          ),
      }),

    showSalary: Yup.boolean(),
    timeInterval: Yup.string().required('Has to be a valid Time Interval'),
    location: Yup.string().required('Has to be a valid location'),
    postCode: Yup.string()
      .trim()
      .required('Has to be a valid postcode')
      .min(3, 'Has to be a valid postcode')
      .max(8, 'Has to be a valid postcode'),
    remote: Yup.boolean(),
    preferKw: Yup.array(),
    needApproval: Yup.boolean(),
  },
  [['salaryTop', 'salaryBottom']],
)

export const createJobAdminSchema = createJobSchema.shape({
  userId: Yup.string().required('Has to be a valid user'),
})
