import styled from 'styled-components/macro'

import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'

export const MenuWrapper = styled(Menu)`
  overflow: hidden;
`

export const JobItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 16px 16px 80px 16px;
  border: 2px dashed #d9d9d9;
  color: ${({ theme }) => theme.colors.gray9};
  min-height: 112px;
  background: #ffffff;
  &:hover {
    background: rgba(230, 247, 255, 0.5);
    border: 2px dashed #40a9ff;
    color: ${({ theme }) => theme.colors.gray9};
  }

  .ant-btn {
    padding: 8px 10px;
  }
  .ant-btn {
    font-weight: 600;
  }
  @media (min-width: 768px) {
    padding: 16px;
    .ant-btn {
      padding: 8px 10px;
    }
  }
`

export const ArchivedJobItem = styled(JobItem)`
  background: ${({ theme }) => theme.colors.gray3};
  border: 1px solid #d9d9d9;
  &:hover {
    background: ${({ theme }) => theme.colors.gray3};
    border: 1px solid #2688fc;
  }
`

export const LiveJobItem = styled(JobItem)`
  border: 1px solid #d9d9d9;
  &:hover {
    border: 1px solid #2688fc;
  }
`
export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  svg {
    margin-right: 10px;
    min-width: 20px;
  }
  @media (min-width: 768px) {
    flex: 1;
    max-width: 40%;
  }
`

export const Title = styled.h2`
  font-weight: 600;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  a {
    color: ${({ theme }) => theme.colors.gray9};
    &:hover {
      text-decoration: underline;
    }
  }
`

export const ActionBlock = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  .ant-btn:not(:last-child) {
    margin-right: 10px;
  }
  @media (min-width: 768px) {
    position: static;
  }
`

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const TopRowRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const BottomRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  div {
    flex: 1;
  }
`

export const BottomLeftInfo = styled.div`
  display: flex;
  margin-top: 16px;
  white-space: nowrap;
  @media (min-width: 768px) {
    margin-top: 0;
  }
  .ant-tag-magenta {
    color: #ff7875;
    background: #fff1f0;
    border: 1px solid #ffccc7;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .ant-tag-default {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    color: ${({ theme }) => theme.colors.gray7};
  }
`

export const JobCreatedAt = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray7};
  span {
    font-weight: normal;
    padding-left: 5px;
    color: ${({ theme }) => theme.colors.gray9};
  }
`

export const JobActivatedAt = styled.div`
  color: ${({ theme }) => theme.colors.gray7};
  span {
    font-weight: 600;
    padding-left: 5px;
    color: ${({ theme }) => theme.colors.gray9};
  }
`

export const MenuButtonRed = styled(Button)`
  color: ${({ theme }) => theme.colors.red5};
  &&:hover {
    color: ${({ theme }) => theme.colors.red5};
  }
`

export const MenuItem = styled(Menu.Item)`
  &.ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0;
  }
  .ant-btn {
    border-radius: 0;
    text-align: left;
    width: 100%;
    padding: 13px 27px;
    min-height: 50px;
    font-weight: 400;

    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
`

export const DropdownButton = styled(Button)`
  position: absolute;
  bottom: 50px;
  right: 0;

  @media (min-width: 768px) {
    position: static;
  }
`

export const CandidateButton = styled(Button)`
  font-size: 12px;
  @media (min-width: 576px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
`

export const BlueText = styled.span`
  color: ${({ theme }) => theme.colors.blue6};
  margin-left: 4px;
`

export const CandidateButtonWithCandidates = styled(CandidateButton)`
  background: rgba(230, 247, 255, 0.7);
`
