import styled from 'styled-components/macro'

import Row from 'antd/lib/row'

export const Header = styled.div`
  margin-top: 12px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
  }
`

export const JobActions = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    margin-left: 10px;
  }

  @media (min-width: 768px) {
    .ant-btn {
      margin-left: 0;
    }
  }

  .ant-input-search {
    max-width: 250px;
    margin-right: 10px;
  }
`
export const RowItem = styled(Row)`
  width: 100%;
`

export const HiringTeamTab = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`
