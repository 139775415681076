import { all, fork } from 'redux-saga/effects'

import * as W from './watchers'

export function* watchCreditsActions() {
  yield all([
    fork(W.watchPurchaseCredits),
    fork(W.watchTransferCredits),
    fork(W.watchGetCheckoutInfo),
  ])
}
