import React, { FC, useState } from 'react'

import { numberWithCommas } from 'helpers/checkoutHelpers'

import { jobheronCampaigns } from 'app/modules/Checkout/constants'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Tooltip from 'antd/lib/tooltip'

import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons'

import {
  AdditionalBlock,
  CardBox,
  CardBuyItem,
  CardContainer,
  CardCount,
  CardHeader,
  CardList,
  CardPrice,
  Discount,
  PopularItem,
  PopularItemWrapper,
} from './styles'

import { IDiscountPerCredit } from 'types'

interface CampaignAdvantages {
  text: string
  tooltip?: string
}
interface CardItemProps {
  discountPreCredit: IDiscountPerCredit[]
  id: number
  loading: number | null
  name: string
  credits: number
  price: number
  handleBuyCredits: (packId: number, credits: number) => void
}

const CardItem: FC<CardItemProps> = ({
  discountPreCredit,
  name,
  credits,
  id,
  loading,
  price,
  handleBuyCredits,
}) => {
  const [count, setCount] = useState<number>(1)

  const findCampaignIndex = jobheronCampaigns.findIndex(
    campaign => campaign.value === name,
  )
  const {
    name: campaignName,
    popular,
    advantages,
    prevName,
  } = jobheronCampaigns[findCampaignIndex]

  const findDiscount = (countNumber: number) => {
    const discountIndex = discountPreCredit.findIndex(
      ({ from, to }) => count >= from && count <= to,
    )

    if (discountIndex > -1) {
      return discountPreCredit[discountIndex].savePerCredit * countNumber
    } else {
      return 0
    }
  }

  const discount = findDiscount(count)

  const totalPrice = count > 0 ? price * count - discount : price
  const discountExist = discount > 0 && discountPreCredit

  return (
    <CardContainer>
      <PopularItemWrapper>
        <PopularItem> {popular && 'MOST POPULAR'} </PopularItem>
      </PopularItemWrapper>

      <CardBox>
        <CardHeader>
          <h2>{campaignName}</h2>
          <CardPrice>
            £{numberWithCommas(totalPrice)}
            {discountExist && (
              <Discount>Save: £{numberWithCommas(discount)}</Discount>
            )}
          </CardPrice>
        </CardHeader>
        <CardBuyItem>
          <CardCount
            min={1}
            max={999}
            value={count}
            onChange={value => setCount(+value)}
            step="1"
          />
          <Button
            loading={id === loading}
            type="primary"
            onClick={() => handleBuyCredits(id, credits * count)}
          >
            Buy now
          </Button>
        </CardBuyItem>
        {prevName && (
          <AdditionalBlock>
            <div>Everything in {prevName}</div>
            <Divider plain>Plus</Divider>
          </AdditionalBlock>
        )}
        <CardList>
          {advantages.map(({ text, tooltip }: CampaignAdvantages) => (
            <li key={text}>
              <CheckOutlined /> {text}{' '}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <InfoCircleOutlined />
                </Tooltip>
              )}
            </li>
          ))}
        </CardList>
      </CardBox>
    </CardContainer>
  )
}

export default CardItem
