import React, { FC } from 'react'

import { getFilterCount } from 'helpers/candidateHelpers'

import { OnFilterProp } from '../../DetailJob'
import { FilterAttribute } from './FilterList'
import CloseIcon from 'assets/icons/Close'

import Button from 'antd/lib/button'
import Tag from 'antd/lib/tag'

import {
  ClearFilters,
  Container,
  Tags,
} from 'app/components/Jobs/JobsList/CurrentRefinements/styles'

interface FilterChipsProps {
  filters: {
    [key: string]: {
      value: string
      label: string
    }[]
  }
  onClearFilters: () => void
  onFilter: (prop: OnFilterProp) => void
}

const FilterChips: FC<FilterChipsProps> = ({
  filters,
  onClearFilters,
  onFilter,
}) => {
  const items = Object.entries(filters)
  const filterCount = getFilterCount(filters)

  if (!filterCount) return null

  return (
    <Container padding="10px 16px">
      <Tags>
        {items.map(([key, values]) =>
          values.map(({ value, label }: { value: string; label: string }) => (
            <Tag key={`chip-${key}-${value}`}>
              {label}
              <Button
                type="text"
                icon={<CloseIcon color="#8C8C8C" />}
                onClick={() => {
                  onFilter({ key: key as FilterAttribute, value })
                }}
              />
            </Tag>
          )),
        )}
        {!!filterCount && (
          <ClearFilters type="link" onClick={() => onClearFilters()}>
            Clear all filters
            <CloseIcon color="#2688FC" />
          </ClearFilters>
        )}
      </Tags>
    </Container>
  )
}

export default FilterChips
