import React, { FC, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { showDeleteJobModal } from 'store/jobs'
import {
  getViewerSelector,
  isExternalRecruiterSelector,
  isTeamMemberSelector,
} from 'store/viewer'

import { checkHighRoleAccessibility } from 'helpers/jobHelpers'

import CloneJob from '../../CloneJob/CloneJob'
import BottomRowItems from '../components/BottomRowItems/BottomRowItems'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Dropdown from 'antd/lib/dropdown'

import {
  CopyOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SelectOutlined,
  UserOutlined,
} from '@ant-design/icons'

import {
  ActionBlock,
  ArchivedJobItem,
  BottomLeftInfo,
  BottomRow,
  JobActivatedAt,
  MenuButtonRed,
  MenuItem,
  MenuWrapper,
  Title,
  TitleBlock,
  TopRow,
  TopRowRight,
} from './styles'

import { IJobStatuses, JobDetailTab } from 'types'

const JobArchived: FC<IJobStatuses> = ({
  objectID,
  title,
  status,
  company,
  isJHCampaign,
  referenceCode,
  relatedCampaign,
  expiresAt,
  ownerId,
  isExpired,
  isSSCampaign,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isTeamMember = useSelector(isTeamMemberSelector)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)

  const handleDeleteJob = () => {
    dispatch(
      showDeleteJobModal({
        jobTitle: title,
        jobId: objectID,
        jobStatus: status,
      }),
    )
  }

  const viewer = useSelector(getViewerSelector)
  const isHighRole = checkHighRoleAccessibility(ownerId, viewer)

  const menu = (
    <MenuWrapper>
      <MenuItem key="View Candidates">
        <Button
          icon={<UserOutlined />}
          type="text"
          onClick={() =>
            history.push(
              `${ROUTES.JOB}/${objectID}?tab=${JobDetailTab.Candidates}`,
            )
          }
        >
          View Candidates
        </Button>
      </MenuItem>

      {!isTeamMember && !isExternalRecruiter && (
        <MenuItem>
          <CloneJob
            title="Copy job"
            id={objectID}
            campaignType={isJHCampaign}
            buttonType="text"
            icon={<CopyOutlined />}
          />
        </MenuItem>
      )}

      <MenuItem>
        <Button
          icon={<SelectOutlined />}
          type="text"
          onClick={() =>
            history.push(
              `${ROUTES.JOB}/${objectID}?tab=${JobDetailTab.JobDetails}`,
            )
          }
        >
          View job
        </Button>
      </MenuItem>

      {isHighRole && (
        <MenuItem>
          <MenuButtonRed
            icon={<DeleteOutlined />}
            type="text"
            block
            onClick={handleDeleteJob}
          >
            Delete
          </MenuButtonRed>
        </MenuItem>
      )}
    </MenuWrapper>
  )

  return (
    <ArchivedJobItem>
      <TopRow>
        <TitleBlock>
          <Title>{title}</Title>
        </TitleBlock>

        <TopRowRight>
          <ActionBlock
            onClick={(e: MouseEvent<HTMLDivElement>) => e.preventDefault()}
          >
            {!isTeamMember && !isExternalRecruiter && (
              <CloneJob
                title="Copy job"
                id={objectID}
                campaignType={isJHCampaign}
                buttonType="default"
              />
            )}

            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          </ActionBlock>
        </TopRowRight>
      </TopRow>

      <BottomRow>
        <BottomLeftInfo>
          <JobActivatedAt>
            Expired
            <span>{formatDistanceToNowStrict(new Date(expiresAt))} ago</span>
          </JobActivatedAt>
        </BottomLeftInfo>

        <BottomRowItems
          isJHCampaign={isJHCampaign}
          isSSCampaign={isSSCampaign}
          relatedCampaign={relatedCampaign}
          referenceCode={referenceCode}
          company={company}
          status={status}
          isExpired={isExpired}
        />
      </BottomRow>
    </ArchivedJobItem>
  )
}

export default JobArchived
