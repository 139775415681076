import styled from 'styled-components/macro'

import Button from 'antd/lib/button'

export const HiringTeamContainer = styled.div`
  margin: 30px 0 110px;

  .ant-table-row {
    height: 57px;
  }

  .ant-btn {
    font-weight: 600;
  }

  .ant-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

export const HiringTeamList = styled.div`
  margin-bottom: 50px;

  .ant-table-wrapper .ant-table table {
    border: 4px solid #e6f7ff;
  }
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 16px 0;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const TabsContainer = styled.div`
  & .ant-tabs .ant-tabs-nav {
    border-bottom: none;
  }
  .ant-tabs-extra-content {
    padding-left: 0;
    position: absolute;
  }
  .tablist {
    position: relative;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray7};
  margin: 16px 0;
`

export const InviteButtonContainer = styled.div`
  text-align: right;
`

export const InviteButton = styled(Button)`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.blue6};
  @media (min-width: 576px) {
    margin-left: auto;
  }

  svg {
    margin-right: 10px;
  }
`

export const ActionButton = styled(Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  svg {
    margin-right: 5px;
  }
  color: ${props => props.color};

  &:hover {
    color: ${props => props.color};
  }
`
export const HiringTeamTableActionsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const HiringTeamTableActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-btn {
    color: ${({ theme }) => theme.colors.blue6};

    svg {
      margin-right: 10px;
    }
  }
`
export const ButtonGray = styled(Button)`
  &.ant-btn {
    color: ${({ theme }) => theme.colors.gray7};
  }
`

export const FullNameContainer = styled.div`
  display: flex;
  align-items: center;
  & a.TitleLink {
    border-bottom: none;
  }
  .ant-btn-link {
    padding: 0 10px;
    display: flex;
    align-items: center;
    height: auto;
  }
`

export const FullName = styled.h3`
  margin: 0;
  font-size: 14px;
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
`
