import React, { FC, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { deleteUserPromiseCreator } from 'store/users'
import {
  getViewerSelector,
  isAdminSelector,
  isCompanyAdminSelector,
} from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'
import { checkIsViewerHasBalance } from 'helpers/creditsHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import AlgoliaAutocomplete from '../../UI/AlgoliaAutocomplete/AlgoliaAutocomplete'
import algoliasearch from 'algoliasearch/lite'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'
import { deleteUserSchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'

import { DeleteOutlined } from '@ant-design/icons'

import { Footer, InfoAlert } from './styles'

import {
  IError,
  ManageUsersTabs,
  OptionsType,
  RootState,
  UserStatus,
  UsersRole,
} from 'types'

interface IDeleteUser {
  isMenuItem?: boolean
  user: any
}

const DELETE = 'DELETE'

const DeleteUser: FC<IDeleteUser> = ({ isMenuItem, user }) => {
  const location = useLocation()
  const history = useHistory()
  const viewer = useSelector(getViewerSelector)
  const isJHAdmin = useSelector(isAdminSelector)
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const deleteUser = useActionsRoutines(deleteUserPromiseCreator)
  const { isLoading } = useSelector(
    (state: RootState) => state.users.deleteUser,
  )

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  const { isLastCompanyAdmin, ownAdverts, balance } = user
  const companyId = user.companyId || user.company.id
  const userId = user.objectID || user.id
  const isUserHasBalance = checkIsViewerHasBalance(balance)

  const deleteUserWithTransfer = isUserHasBalance || ownAdverts
  const deleteUserSchemeCondition =
    !isLastCompanyAdmin && deleteUserWithTransfer

  const methods = useForm({
    resolver: deleteUserSchemeCondition && yupResolver(deleteUserSchema),
  })
  const {
    setValue,
    reset,
    control,
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods

  const currentConfirmWord = watch('confirm')

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
    reset({ userAcceptId: null })
  }

  const handleGoToManageUsers = () => {
    if (location.pathname !== ROUTES.ADMIN_MANAGE_USERS && isJHAdmin) {
      history.push(
        `${ROUTES.ADMIN_MANAGE_USERS}?tab=${ManageUsersTabs.HiringTeam}`,
      )
    }
    if (
      location.pathname !== ROUTES.SETTING_ACCOUNT_MEMBERS &&
      isCompanyAdmin
    ) {
      history.push(`${ROUTES.SETTING_ACCOUNT_MEMBERS}`)
    }
    handleCloseModal()
  }

  const handleDeleteUser = async ({
    userAcceptId,
  }: {
    userAcceptId: string
  }) => {
    try {
      await deleteUser({
        userDeleteId: userId,
        userAcceptId: userAcceptId ?? undefined,
      })
      handleGoToManageUsers()
    } catch ({ errors }) {
      setFormErrors({
        setError,
        defaultItem: 'confirm',
        errors: errors as IError[],
      })
    }
  }

  return (
    <>
      {isMenuItem && (
        <Menu.Item
          danger
          key="Delete user"
          onClick={handleOpenModal}
          icon={<DeleteOutlined />}
        >
          Delete user
        </Menu.Item>
      )}
      {!isMenuItem && (
        <Button onClick={handleOpenModal} danger>
          Delete user
        </Button>
      )}

      {isOpenModal && (
        <Modal
          width={550}
          footer={null}
          title="Delete user?"
          visible={isOpenModal}
          onCancel={handleCloseModal}
        >
          {isLastCompanyAdmin && (
            <FormProvider {...methods}>
              <Form layout="vertical">
                <InfoAlert
                  message={
                    'Any job data and credits associated with this account will be deleted and cannot be recovered.'
                  }
                  type="error"
                  showIcon
                />
                <p>
                  To confirm please type in <strong>DELETE</strong> below
                </p>

                <FormItem
                  name="confirm"
                  component={Input}
                  componentProps={{ placeholder: DELETE, autocomplete: 'off' }}
                />
              </Form>
            </FormProvider>
          )}

          {!isLastCompanyAdmin && (
            <FormProvider {...methods}>
              <Form layout="vertical">
                {deleteUserWithTransfer && (
                  <>
                    <p>
                      Before deleting you can select a team member where job
                      data and campaign credits will be transfered to.
                    </p>

                    <Form.Item
                      validateStatus={errors.userAcceptId ? 'error' : 'success'}
                      help={errors.userAcceptId?.message}
                    >
                      <Controller
                        render={({ field }) => (
                          <AlgoliaAutocomplete
                            {...field}
                            isGroupByCompany
                            searchClient={searchClient}
                            handleOnSelect={value =>
                              setValue('userAcceptId', value)
                            }
                            filters={`companyId: ${companyId} AND status: ${UserStatus.Verified} AND NOT objectID: ${userId} AND NOT role: ${UsersRole.TeamMember}`}
                            indexName={viewer.algoliaKeys.users.listOfUsers}
                            placeholder="New team member"
                            optionsType={OptionsType.UsersWithRole}
                          />
                        )}
                        control={control}
                        name="userAcceptId"
                      />
                    </Form.Item>

                    <InfoAlert
                      message={
                        'Please note, once deleted user’s data CANNOT be retrieved.'
                      }
                      type="error"
                      showIcon
                    />
                  </>
                )}

                {!deleteUserWithTransfer && (
                  <p>Selected user has no jobs or credits associated.</p>
                )}

                <p>
                  To confirm please type in <strong>DELETE</strong> below
                </p>

                <FormItem
                  name="confirm"
                  component={Input}
                  componentProps={{ placeholder: DELETE, autocomplete: 'off' }}
                />
              </Form>
            </FormProvider>
          )}

          <Footer>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              danger
              type="primary"
              loading={isLoading}
              disabled={currentConfirmWord !== DELETE}
              onClick={handleSubmit(handleDeleteUser)}
            >
              Delete user
            </Button>
          </Footer>
        </Modal>
      )}
    </>
  )
}

export default DeleteUser
