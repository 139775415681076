import { MouseEvent } from 'react'

import { JobDetailTab, JobStatus, UsersRole } from 'types'

type CheckIfCanRemoveFromJob = {
  viewerRole: UsersRole
  viewerId: string
  jobCreatorId: string
  jobUserId: string
  invitedBy: string
}

type CheckIfCanInviteToLiveArchiveJob = {
  jobStatus?: JobStatus
  activeTab: string
  userRole: UsersRole
}

export const checkHighRoleAccessibility = (
  jobId: string,
  viewer: { role: UsersRole; id: string },
) => {
  return (
    viewer.role === UsersRole.JHSuperAdmin ||
    viewer.role === UsersRole.CompanyAdmin ||
    viewer.role === UsersRole.RecruitmentManager ||
    (viewer.role === UsersRole.HiringManager && jobId === viewer.id)
  )
}

export const checkIfCanRemoveFromJob = ({
  viewerRole,
  viewerId,
  jobCreatorId,
  jobUserId,
  invitedBy,
}: CheckIfCanRemoveFromJob) => {
  if (viewerId === jobUserId) return false

  if (jobCreatorId === jobUserId) return false

  if (viewerRole === UsersRole.TeamMember && viewerId !== invitedBy)
    return false

  if (viewerRole === UsersRole.ExternalRecruiter) return false

  if (viewerRole === UsersRole.HiringManager) {
    if (jobCreatorId !== viewerId && viewerId !== invitedBy) {
      return false
    }
  }

  return true
}

export const checkIfCanInviteToLiveArchiveJob = ({
  jobStatus,
  activeTab,
  userRole,
}: CheckIfCanInviteToLiveArchiveJob) => {
  if (activeTab === JobDetailTab.HiringTeam) {
    if (jobStatus === JobStatus.Live) {
      if (
        userRole === UsersRole.JHSuperAdmin ||
        userRole === UsersRole.CompanyAdmin ||
        userRole === UsersRole.RecruitmentManager ||
        userRole === UsersRole.HiringManager
      ) {
        return true
      }
    }

    if (jobStatus === JobStatus.Archived) {
      if (
        userRole === UsersRole.JHSuperAdmin ||
        userRole === UsersRole.CompanyAdmin ||
        userRole === UsersRole.RecruitmentManager
      ) {
        return true
      }
    }
  }

  return false
}

export const comingSoon = (event?: MouseEvent<HTMLElement>) => {
  event && event.preventDefault()
  alert('Coming soon')
}

export const replaceCharacters = (value: string) => {
  return value.replace(/[^\d.]/g, '')
}

export const firstLetterToLowerCase = (item: string): string => {
  return item.charAt(0).toLowerCase() + item.slice(1)
}
