import React, { FC, MouseEvent, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { updateJobForwardSettingsPromiseCreator } from 'store/jobs'

import { yupResolver } from '@hookform/resolvers/yup'

import { cVForwardTypes } from '../constants'
import FormMultipleItems from 'app/components/Forms/FormMultipleItems/FormMultipleItems'
import { cvForwardingFrequencyTypes } from 'app/modules/Jobs/constants'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { MultipleForwardCVSchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Space from 'antd/lib/space'

import {
  FormItem,
  ModalFooter,
  RadioGroupItem,
  RadioTitle,
  Text,
} from './styles'

import { CVForwardTypes, IJobFrequencySetting } from 'types'

interface CvForwardSettingsProps {
  jobId: number
  icon?: React.ReactNode
  buttonTitle: string
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
  cvForwardingFrequency: IJobFrequencySetting
  forwardTo: any
}

interface UseFormInputs {
  emailList: string[]
  cvForwardType: CVForwardTypes
}
const CvForwardSettings: FC<CvForwardSettingsProps> = ({
  icon,
  buttonType = 'text',
  jobId,
  buttonTitle,
  forwardTo,
  cvForwardingFrequency,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const updateJobForwardSettings = useActionsRoutines(
    updateJobForwardSettingsPromiseCreator,
  )

  const checkForwardType = (type: IJobFrequencySetting) => {
    return cvForwardingFrequencyTypes.find(item => item.value === type)?.title!
  }

  const cvForwardType = checkForwardType(cvForwardingFrequency)

  const methods = useForm({
    resolver: yupResolver(MultipleForwardCVSchema),
  })

  const { setValue } = methods

  const handleOpenModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setValue('emailList', forwardTo)
    setValue('cvForwardType', cvForwardType)
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    reset()
  }

  const handleFormSubmit = (data: UseFormInputs) => {
    const { emailList, cvForwardType } = data
    const frequency = cvForwardingFrequencyTypes.find(
      item => item.title === cvForwardType,
    )?.value

    updateJobForwardSettings({
      frequency,
      enable: !!emailList.length,
      emailList,
      id: jobId,
    })
      .then(() => {
        setIsOpenModal(false)
      })
      .catch(() => {
        handleCloseModal()
      })
  }

  const {
    formState: { errors },
    control,
    reset,
  } = methods

  return (
    <>
      <Button type={buttonType} onClick={handleOpenModal} icon={icon}>
        {buttonTitle}
      </Button>
      <Modal
        width={550}
        footer={null}
        title="CV forwarding"
        visible={isOpenModal}
        onCancel={handleCloseModal}
        destroyOnClose={true}
      >
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            onFinish={methods.handleSubmit(handleFormSubmit)}
          >
            <Text>
              CV forwarding enables to send upcoming CVs to provided email
              address.
            </Text>
            <RadioTitle> Frequency</RadioTitle>

            <FormItem
              validateStatus={errors.cvForwardType ? 'error' : 'success'}
              help={errors.cvForwardType?.message}
            >
              <Controller
                render={({ field }) => (
                  <RadioGroupItem {...field} defaultValue={cvForwardType}>
                    <Space direction="vertical">
                      {cVForwardTypes.map(({ title, value }) => (
                        <Radio value={value} key={value}>
                          {title}
                        </Radio>
                      ))}
                    </Space>
                  </RadioGroupItem>
                )}
                name="cvForwardType"
                control={control}
              />
            </FormItem>

            <FormMultipleItems
              name="emailList"
              isRequired={true}
              placeholder="name@website.com"
            />

            <ModalFooter>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button
                type="primary"
                onClick={methods.handleSubmit(handleFormSubmit)}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default CvForwardSettings
