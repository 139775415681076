import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { urlToSearchState } from 'helpers'

import CurrentRefinements from '../../JobsList/CurrentRefinements/CurrentRefinements'
import RefinementList from '../../JobsList/RefinementList/RefinementList'
import ClearFilterButton from './ClearFilterButton/ClearFilterButton'
import { candidateSources } from 'app/components/Candidate/constanst'
import {
  stagesOrder,
  statusOrder,
} from 'app/modules/Jobs/DetailJob/CandidatesList/CandidatesFilter/constanst'
import SettingsIcon from 'assets/icons/SettingsIcon'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'

import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
} from '@ant-design/icons'

import {
  ButtonGroup,
  CollapseItem,
  CollapsePanel,
  FilterActiveDot,
  FilterButton,
  Header,
  HeaderInfo,
  MobileDrawer,
  Title,
} from './styles'

import { CandidateSource } from 'types'

const CandidateFilterMenu = () => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
  const [isFilterActive, setIsFilterActive] = useState(false)
  const location = useLocation()
  const handleCloseMobileMenu = () => {
    setIsOpenMobileMenu(false)
  }
  const handleOpenMobileMenu = () => {
    setIsOpenMobileMenu(true)
  }

  const searchState = urlToSearchState(location)

  const algoliaActiveFilters = searchState.refinementList

  useEffect(() => {
    if (algoliaActiveFilters) {
      const isFilterStateExist = Object.values(algoliaActiveFilters).some(
        item => item !== '',
      )

      setIsFilterActive(isFilterStateExist)
    }
  }, [algoliaActiveFilters])

  return (
    <>
      <FilterButton onClick={handleOpenMobileMenu}>
        <SettingsIcon />
        {isFilterActive && <FilterActiveDot />}
      </FilterButton>

      <MobileDrawer
        key="bottom"
        placement="bottom"
        closable={false}
        height="calc(100% - 66px)"
        onClose={handleCloseMobileMenu}
        visible={isOpenMobileMenu}
      >
        <Header>
          <HeaderInfo>
            <Title> Filter Candidates</Title>
            <Button onClick={handleCloseMobileMenu} icon={<CloseOutlined />} />
          </HeaderInfo>

          <CurrentRefinements padding={'10px 0'} />
        </Header>

        <CollapseItem
          expandIconPosition="right"
          defaultActiveKey={['statuses', 'source']}
          expandIcon={({ isActive }) =>
            isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
          }
        >
          <CollapsePanel header="Stage" key="stage">
            <RefinementList
              title="All stages"
              limit={20}
              attribute="stage"
              showCount
              orderedBy={stagesOrder}
            />
            <Divider />
          </CollapsePanel>
          <CollapsePanel header="Status" key="statuses">
            <RefinementList
              title="All statuses"
              limit={20}
              attribute="status"
              showCount
              orderedBy={statusOrder}
            />
            <Divider />
          </CollapsePanel>
          <CollapsePanel header="Source" key="source">
            <RefinementList
              title="All sources"
              limit={20}
              attribute="source"
              showCount
              transformItems={items => {
                return items.map((item: any) => {
                  const itemIndex = candidateSources.findIndex(
                    ({ value }: { value: CandidateSource }) =>
                      value === item.label,
                  )
                  if (itemIndex !== -1) {
                    return {
                      ...item,
                      label: candidateSources[itemIndex].title,
                    }
                  }
                  return item
                })
              }}
            />
            <Divider />
          </CollapsePanel>
        </CollapseItem>
        {isOpenMobileMenu && (
          <ButtonGroup>
            <ClearFilterButton />
            <Button type="primary" onClick={handleCloseMobileMenu}>
              Apply filters
            </Button>
          </ButtonGroup>
        )}
      </MobileDrawer>
    </>
  )
}

export default CandidateFilterMenu
