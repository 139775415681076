import * as React from 'react'

function SSCampaignGrayImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={172}
      height={147}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.5}>
        <path
          d="M153.685 73.442c0 15.914-5.328 30.56-14.163 42.39-4.907 6.337-10.797 11.97-17.528 16.336-10.937 7.182-23.978 11.407-38 11.407-38.562 0-69.691-31.405-69.691-69.992 0-38.727 31.27-69.991 69.69-69.991 14.023 0 27.064 4.225 38.001 11.266 6.731 4.366 12.621 9.999 17.528 16.336 8.975 11.689 14.163 26.335 14.163 42.248zM26.76 18.356a3.524 3.524 0 002.251-4.436 3.502 3.502 0 00-4.417-2.26 3.524 3.524 0 00-2.25 4.436 3.502 3.502 0 004.417 2.26zM34.413 6.87a2.396 2.396 0 001.53-3.018 2.382 2.382 0 00-3.004-1.536 2.396 2.396 0 00-1.53 3.016 2.382 2.382 0 003.004 1.537zM2.384 82.737a2.389 2.389 0 002.384-2.394 2.389 2.389 0 00-2.384-2.394A2.389 2.389 0 000 80.343a2.389 2.389 0 002.384 2.394zM168.067 53.048a5.367 5.367 0 00.338-7.56 5.312 5.312 0 00-7.528-.341 5.368 5.368 0 00-.339 7.56 5.314 5.314 0 007.529.34zM167.006 29.645a2.249 2.249 0 002.244-2.253 2.249 2.249 0 00-2.244-2.253 2.248 2.248 0 00-2.243 2.253 2.248 2.248 0 002.243 2.253z"
          fill="#EAEEF9"
        />
        <path
          d="M15.565 135.548h144.43"
          stroke="#EBF0F6"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M131.951 14.436v116.747c0 2.112-1.683 3.943-3.927 3.943H42.067a3.928 3.928 0 01-3.926-3.943V14.436c0-2.112 1.823-3.802 3.926-3.802h86.098a3.779 3.779 0 013.786 3.802z"
          fill="#CED7E2"
        />
        <path
          d="M131.95 22.885v108.297c0 2.113-1.682 3.943-3.926 3.943H49.499l-6.871-6.9 78.104-107.311 6.731-2.535 4.487 4.506z"
          fill="#BCC4CF"
        />
        <path
          d="M128.445 20.21H43.329v108.438c0 .845.701 1.408 1.402 1.408h83.714V20.21z"
          fill="#A6AFBA"
        />
        <path
          d="M126.341 18.238H43.89a1.26 1.26 0 00-1.262 1.268v107.17c0 .704.56 1.267 1.262 1.267h82.451c.702 0 1.262-.563 1.262-1.267V19.646c.141-.704-.42-1.408-1.262-1.408z"
          fill="#E3EAF2"
        />
        <g filter="url(#prefix__filter0_d_5419:139465)">
          <path
            d="M127.604 18.379s-.141 6.478-2.104 47.459v.845c-2.384 40.277-38.14 48.726-42.488 49.149-1.963.141-5.749.422-12.62.563-8.413.282-21.314.423-41.366.704-.7 0-1.121-.704-.841-1.267C42.207 87.807 42.348 18.52 42.348 18.52h85.256v-.141z"
            fill="#fff"
          />
        </g>
        <path
          d="M112.319 39.081H56.65c-.42 0-.84-.422-.84-.844 0-.423.42-.845.84-.845h55.669c.421 0 .842.422.842.845 0 .422-.421.844-.842.844zM112.039 44.15H89.884c-.42 0-.841-.422-.841-.844 0-.423.42-.845.84-.845h22.156c.421 0 .841.422.841.845 0 .422-.42.845-.841.845z"
          fill="#CED7E2"
        />
        <path
          d="M83.714 44.15H56.23c-.42 0-.841-.422-.841-.844 0-.423.42-.845.841-.845h27.484c.42 0 .841.422.841.845 0 .422-.28.845-.841.845z"
          fill="#E3EAF2"
        />
        <path
          d="M75.3 49.08H55.95c-.421 0-.842-.423-.842-.845 0-.423.42-.845.841-.845H75.44c.421 0 .842.422.842.845 0 .422-.421.845-.982.845zM110.496 61.614H79.647c-.42 0-.841-.423-.841-.845 0-.423.42-.845.841-.845h30.849c.421 0 .842.422.842.845 0 .422-.421.845-.842.845z"
          fill="#CED7E2"
        />
        <path
          d="M73.337 61.614h-18.23c-.42 0-.84-.423-.84-.845 0-.423.42-.845.84-.845h18.23c.42 0 .841.422.841.845 0 .422-.42.845-.841.845z"
          fill="#E3EAF2"
        />
        <path
          d="M110.216 66.543h-13.04c-.421 0-.842-.423-.842-.846 0-.422.42-.844.841-.844h13.041c.421 0 .841.422.841.845 0 .422-.42.845-.841.845z"
          fill="#CED7E2"
        />
        <path
          d="M90.024 66.543H54.407c-.42 0-.842-.423-.842-.846 0-.422.421-.844.842-.844h35.617c.42 0 .84.422.84.845 0 .422-.42.845-.84.845zM87.22 71.613H53.705c-.42 0-.841-.423-.841-.845 0-.423.42-.845.841-.845H87.22c.421 0 .842.422.842.845 0 .422-.42.845-.842.845zM107.271 84.428H70.813c-.42 0-.841-.422-.841-.845 0-.422.42-.845.841-.845h36.458c.421 0 .841.423.841.845 0 .423-.42.845-.841.845z"
          fill="#E3EAF2"
        />
        <path
          d="M65.064 84.428H51.462c-.42 0-.84-.422-.84-.845 0-.422.42-.845.84-.845h13.602c.42 0 .842.423.842.845 0 .423-.281.845-.842.845zM106.851 89.498H80.208c-.42 0-.841-.423-.841-.845 0-.423.42-.845.841-.845h26.783c.42 0 .841.422.841.845 0 .422-.421.845-.981.845z"
          fill="#CED7E2"
        />
        <path
          d="M75.16 89.498H50.9c-.42 0-.84-.423-.84-.845 0-.423.42-.845.84-.845h24.26c.42 0 .84.422.84.845 0 .422-.42.845-.84.845zM66.746 94.426H49.78c-.42 0-.841-.422-.841-.845 0-.422.42-.845.841-.845h16.967c.421 0 .842.423.842.845 0 .423-.421.845-.842.845z"
          fill="#E3EAF2"
        />
        <path
          d="M125.36 66.684c-2.383 40.276-38.14 48.726-42.487 49.149-1.964.14-5.75.422-12.62.563C88.06 108.087 97.735 93.3 97.455 81.752c8.694.564 23.557-.14 27.904-15.068z"
          fill="#EAEEF9"
        />
        <path
          d="M104.186 10.352H90.725c0-.282.14-.423.14-.704 0-2.676-2.244-4.93-4.908-4.93s-4.908 2.254-4.908 4.93c0 .281 0 .563.14.704H67.728c-.701 0-1.402.563-1.402 1.408v4.366c0 3.239 2.664 5.915 5.89 5.915h27.483c3.225 0 5.889-2.676 5.889-5.915V11.76c0-.845-.701-1.408-1.402-1.408z"
          fill="#BAC6D5"
        />
        <g filter="url(#prefix__filter1_d_5419:139465)">
          <path
            d="M103.345 9.507H89.883c0-.282.14-.422.14-.704 0-2.676-2.243-4.929-4.907-4.929-2.665 0-4.908 2.253-4.908 4.929 0 .282 0 .563.14.704H66.887c-.701 0-1.403.563-1.403 1.408v4.366c0 3.24 2.665 5.915 5.89 5.915h27.484c3.225 0 5.889-2.676 5.889-5.915v-4.366c0-.845-.701-1.408-1.402-1.408z"
            fill="url(#prefix__paint0_linear_5419:139465)"
          />
        </g>
        <path
          d="M85.116 11.056a2.248 2.248 0 002.244-2.253 2.248 2.248 0 00-2.244-2.253 2.248 2.248 0 00-2.243 2.253 2.248 2.248 0 002.243 2.253z"
          fill="#EAEEF9"
        />
        <g filter="url(#prefix__filter2_d_5419:139465)">
          <path
            d="M151.321 109.932l-.42 2.957c0 .141-.141.282-.141.422 0 .141-.14.282-.14.564-.421 1.971-1.122 3.802-1.963 5.492-2.524 4.788-6.31 8.591-10.938 10.985l-1.682.845c-1.403.563-2.665.986-4.207 1.267-.421.141-.701.141-1.122.281a24.18 24.18 0 01-9.535-.14c-13.602-2.816-22.296-16.195-19.491-29.856 2.804-13.66 16.126-22.391 29.727-19.575 4.768.986 9.255 3.38 12.62 6.9 5.329 5.212 7.853 12.535 7.292 19.858z"
            fill="url(#prefix__paint1_linear_5419:139465)"
          />
        </g>
        <path
          d="M151.301 110.058c0 .986-.28 1.972-.42 2.958 0 .14 0 .281-.14.422 0 .141 0 .282-.141.563-.42 1.972-1.121 3.803-1.963 5.493-2.524 4.788-6.31 8.59-10.937 10.984l-1.683.845-17.247-10.562 3.505-12.111 4.768-6.901 10.657-.845 13.601 9.154z"
          fill="url(#prefix__paint2_linear_5419:139465)"
        />
        <path
          d="M132.932 108.509v-.281s0-.141.14-.282c0-.141 0-.141.141-.282.14-.281.28-.704.42-1.126.141-.141.561-1.127.561-1.268l-8.133-4.506c-.14 0-.28.422-.42.704-.281.281-.421.704-.561.704 0 0-.421.422-.421.563l-.28.282c-.281.282-.702.704-1.683 1.127-1.402.704-2.524.281-2.945.422l-.28.141c-.14 0-.281.141-.281.282-.42.422-.28.422-.561 15.068 0 .423.561.563.842.282l4.767-8.168c.281-.423.701-.564 1.122-.282.421.282.561.704.281 1.127l-5.048 8.168c-.281.422.14.845.56.563l4.207-2.535.14-.141 4.347-2.675c3.366-2.113 3.927-2.254 4.067-2.958.14-.563-.281-.845-.701-1.971-.141-.282-.561-1.69-.281-2.958zm-4.487 1.972c-.701 1.126-2.103 1.549-3.225.845-1.122-.704-1.543-2.113-.841-3.239.701-1.127 2.103-1.549 3.225-.845 1.121.704 1.542 2.112.841 3.239z"
          fill="url(#prefix__paint3_linear_5419:139465)"
        />
        <path
          d="M134.334 105.129c-.14.141-.561 1.127-.561 1.268-.14.422-.42.845-.42 1.126l-8.273-5.21c.14-.141.28-.423.56-.705.141-.281.421-.704.421-.704l8.273 4.225z"
          fill="#D9E1EA"
        />
        <path
          d="M133.633 106.819l-8.273-5.21c-.701-.423-.841-1.409-.421-1.972l2.244-3.661c.421-.705 1.402-.845 1.963-.423l8.273 5.211c.701.422.842 1.408.421 1.971l-2.244 3.662c-.42.704-1.402.845-1.963.422z"
          fill="url(#prefix__paint4_linear_5419:139465)"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear_5419:139465"
          x1={65.397}
          y1={12.525}
          x2={104.745}
          y2={12.525}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFD4E3" />
          <stop offset={1} stopColor="#CBD1E2" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_5419:139465"
          x1={101.044}
          y1={107.872}
          x2={151.396}
          y2={107.872}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAEEF9" />
          <stop offset={1} stopColor="#CBD1E2" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_5419:139465"
          x1={128.809}
          y1={109.139}
          x2={141.564}
          y2={121.838}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C9DB" />
          <stop offset={1} stopColor="#CCD0E6" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_5419:139465"
          x1={128.734}
          y1={116.965}
          x2={121.477}
          y2={104.223}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.011} stopColor="#DBE2F8" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear_5419:139465"
          x1={132.209}
          y1={107.841}
          x2={130.959}
          y2={98.045}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.011} stopColor="#D4D9E8" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <filter
          id="prefix__filter0_d_5419:139465"
          x={24.097}
          y={18.379}
          width={107.506}
          height={107.721}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5419:139465"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5419:139465"
            result="shape"
          />
        </filter>
        <filter
          id="prefix__filter1_d_5419:139465"
          x={57.484}
          y={0.874}
          width={55.263}
          height={33.322}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0.707552 0 0 0 0 0.754779 0 0 0 0 0.8125 0 0 0 0.4 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5419:139465"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5419:139465"
            result="shape"
          />
        </filter>
        <filter
          id="prefix__filter2_d_5419:139465"
          x={93.156}
          y={79.647}
          width={66.242}
          height={66.506}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0.743056 0 0 0 0 0.761713 0 0 0 0 0.833333 0 0 0 0.22 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5419:139465"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5419:139465"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default SSCampaignGrayImage
