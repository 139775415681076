import React, { FC, useState } from 'react'
import { Configure, Index } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { getJobsSelector } from 'store/jobs'

import { searchStateToUrl, useQuery } from 'helpers'

import Hits from 'app/components/Jobs/JobsList/Hits/Hits'
import NoJobs from 'app/components/Jobs/NoJobs/NoJobs'
import AlgoliaLoaderWrapper from 'app/components/Loaders/AlgoliaLoaderWrapper/AlgoliaLoaderWrapper'
import AlgoliaPagination from 'app/components/UI/AlgoliaPagination/AlgoliaPagination'

import Tabs from 'antd/lib/tabs'

import { JobStatusFilter, JobsListTab } from 'types'

const { TabPane } = Tabs

interface IJobListTabs {
  jobStatusFilter: string
  jobsIndexName: string
  searchState: any
}

const JobListTabs: FC<IJobListTabs> = ({
  jobStatusFilter,
  jobsIndexName,
  searchState,
}) => {
  const query = useQuery()
  const history = useHistory()
  const location = useLocation()

  const defaultTabQuery = query.get('tab') || JobsListTab.All
  const [activeTab, setActiveTab] = useState(defaultTabQuery as JobsListTab)
  const [hitsPerPage, setHistPerPage] = useState(10)

  const jobs = useSelector(getJobsSelector)

  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey as JobsListTab)

    history.replace(
      searchStateToUrl({ ...searchState, tab: activeKey }, location),
    )
  }

  const { Pending, Live, Draft, Archived } = jobs?.counters || {}

  const isJobsExist = !!jobs?.counters

  const handleUpdateHitsPerPage = (newHitsPerPage: number) => {
    setHistPerPage(newHitsPerPage)
  }

  if (!isJobsExist) return <NoJobs />

  return (
    <Tabs
      activeKey={activeTab}
      onChange={handleChangeTabs}
      destroyInactiveTabPane
    >
      <TabPane tab="All jobs" key={JobsListTab.All}>
        <Index indexName={jobsIndexName}>
          <AlgoliaLoaderWrapper>
            <Configure hitsPerPage={hitsPerPage} />
            <Hits />
            <AlgoliaPagination
              pageSize={hitsPerPage}
              setPageSize={handleUpdateHitsPerPage}
            />
          </AlgoliaLoaderWrapper>
        </Index>
      </TabPane>

      {Pending && (
        <TabPane tab={`Pending jobs (${Pending})`} key={JobsListTab.Pending}>
          <Index indexName={jobsIndexName}>
            <AlgoliaLoaderWrapper>
              <Configure
                filters={`${jobStatusFilter}: ${JobStatusFilter.Pending}`}
                hitsPerPage={hitsPerPage}
              />
              <Hits />
              <AlgoliaPagination
                pageSize={hitsPerPage}
                setPageSize={handleUpdateHitsPerPage}
              />
            </AlgoliaLoaderWrapper>
          </Index>
        </TabPane>
      )}

      {Live && (
        <TabPane tab={`Live jobs (${Live})`} key={JobsListTab.Live}>
          <Index indexName={jobsIndexName}>
            <AlgoliaLoaderWrapper>
              <Configure
                filters={`${jobStatusFilter}: ${JobStatusFilter.Live}`}
                hitsPerPage={hitsPerPage}
              />
              <Hits />
              <AlgoliaPagination
                pageSize={hitsPerPage}
                setPageSize={handleUpdateHitsPerPage}
              />
            </AlgoliaLoaderWrapper>
          </Index>
        </TabPane>
      )}

      {Draft && (
        <TabPane tab={`Draft jobs (${Draft})`} key={JobsListTab.Draft}>
          <Index indexName={jobsIndexName}>
            <AlgoliaLoaderWrapper>
              <Configure
                filters={`${jobStatusFilter}: ${JobStatusFilter.Draft}`}
                hitsPerPage={hitsPerPage}
              />
              <Hits />
              <AlgoliaPagination
                pageSize={hitsPerPage}
                setPageSize={handleUpdateHitsPerPage}
              />
            </AlgoliaLoaderWrapper>
          </Index>
        </TabPane>
      )}

      {Archived && (
        <TabPane tab={`Archived jobs (${Archived})`} key={JobsListTab.Archived}>
          <Index indexName={jobsIndexName}>
            <AlgoliaLoaderWrapper>
              <Configure
                filters={`${jobStatusFilter}: ${JobStatusFilter.Archived}`}
                hitsPerPage={hitsPerPage}
              />
              <Hits />
              <AlgoliaPagination
                pageSize={hitsPerPage}
                setPageSize={handleUpdateHitsPerPage}
              />
            </AlgoliaLoaderWrapper>
          </Index>
        </TabPane>
      )}
    </Tabs>
  )
}

export default JobListTabs
