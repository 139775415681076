import { parseQsDuplicateKeys } from './apiHelpers'
import { pick } from 'lodash'

import {
  CandidateSortCriteria,
  IApiCandidate,
  ICandidate,
  ICandidateSimplified,
  SortOrder,
} from 'types'

export const getCVFormat = (type: string) => {
  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return '.docx'
    case 'application/msword':
      return '.doc'
    case 'application/pdf':
      return '.pdf'
    default:
      return ''
  }
}

export const isValidCvSize = (size?: number) => {
  if (!size) return false
  return size / 1024 / 1024 < 50
}

export const capitalizeFirstLetter = (string: string) => {
  return string.trim().charAt(0).toUpperCase() + string.trim().slice(1)
}

export const getFullName = ({ firstName, lastName }: ICandidate) =>
  [firstName, lastName].join(' ')

// Flatten candidate data from API response
export const simplifyCandidateData = (data: IApiCandidate) =>
  ({
    advertId: Number(data.advert.id),
    applied: data.applied,
    companyId: data.company.id,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    key: String(data.id),
    objectID: String(data.id),
    hasCv: !!data.cv,
    source: data.source,
    stage: data.stage.type,
    stageName: data.stage.name,
    status: data.status,
    phoneNumber: data.phoneNumber,
    appliedFromLm: data.appliedFromLm,
  } as ICandidateSimplified)

export const getJobCandidatesStat = (
  data: { key: string; count: number }[],
  key: string,
) => data.find(stat => stat.key.toLowerCase() === key.toLowerCase())?.count || 0

export const getStoredCandidateFilters = ({
  jobId,
  isByKeywordOnly,
  parseQs,
}: {
  jobId: string | number | undefined
  isByKeywordOnly?: boolean
  parseQs?: boolean
}) => {
  const qs = sessionStorage.getItem('candidateSearch') || ''
  const storedParams = Object.fromEntries(new URLSearchParams(qs))

  const allParams = {
    advertId: Number(jobId),
    candidateStageTypes: storedParams?.stage
      ? storedParams.stage.split(',')
      : undefined,
    candidateStatuses: storedParams?.status
      ? storedParams.status.split(',')
      : undefined,
    candidateSources: storedParams?.source
      ? storedParams.source.split(',')
      : undefined,
    pageSize: storedParams?.limit || undefined,
    pageNumber: storedParams?.page ? Number(storedParams.page) - 1 : undefined,
    searchString: storedParams?.query || undefined,
    sortOrder: SortOrder.DESC,
    sortingCriteria: CandidateSortCriteria.APPLIED,
  }

  const params = isByKeywordOnly
    ? pick(allParams, ['advertId', 'searchString'])
    : allParams

  if (parseQs) {
    return parseQsDuplicateKeys(params)
  }

  return params
}

export const getFilterCount = (data: { [key: string]: any[] }) => {
  const allFilters = Object.entries(data)
  return allFilters.reduce((previousValue: number, [key, values]: any) => {
    return previousValue + values.length
  }, 0)
}
