import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  Configure,
  Index,
  InstantSearch,
  connectHits,
} from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import { getHiringTeamCountersPromiseCreator } from 'store/hiringTeam'
import {
  getViewerSelector,
  isAdminSelector,
  isExternalRecruiterOrTeamMemberSelector,
} from 'store/viewer'

import ExternalRecruitersList from '../../ExternalRecruiters/ExternalRecruitersList/ExternalRecruitersList'
import AlgoliaPagination from '../../UI/AlgoliaPagination/AlgoliaPagination'
import { companyMembersColumns, hiringTeamMembersColumns } from './columns'
import algoliasearch from 'algoliasearch/lite'
import HiringTeamCompanyMembersTable from 'app/components/ResponsiveTables/HiringTeamCompanyMembersTable/HiringTeamCompanyMembersTable'
import HiringTeamTable from 'app/components/ResponsiveTables/HiringTeamTable/HiringTeamTable'
import useActionsRoutines from 'hooks/useActionsRoutines'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Tabs from 'antd/lib/tabs'

import {
  Description,
  HiringTeamContainer,
  HiringTeamList,
  TabsContainer,
  Title,
} from './styles'

import { IJob, JobStatus, RootState, UserStatus } from 'types'

const { TabPane } = Tabs

interface IHiringTeam {
  isPreviewPage?: boolean
  job: IJob
}

const JobMembersHits = ({ hits, job }: { hits: any; job: IJob }) => {
  return (
    <HiringTeamTable
      job={job}
      hits={hits}
      columns={hiringTeamMembersColumns(job)}
    />
  )
}

const JobMembersHitsCustomHits = connectHits(JobMembersHits)

const UsersHits = ({ hits }: { hits: any }) => {
  return (
    <HiringTeamCompanyMembersTable
      hits={hits}
      columns={companyMembersColumns}
    />
  )
}

const CustomUserHits = connectHits(UsersHits)

const HiringTeam: FC<IHiringTeam> = ({ isPreviewPage, job }) => {
  const screens = useBreakpoint()
  const [pageSize, setPageSize] = useState(10)
  const viewer = useSelector(getViewerSelector)
  const isAdmin = useSelector(isAdminSelector)
  const isTeamMemberOrExternalRecruiter = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )

  const getHiringTeamCounters = useActionsRoutines(
    getHiringTeamCountersPromiseCreator,
  )
  const { refresh } = useSelector((state: RootState) => state.hiringTeam)

  const [isShowExternalRecruiterTab, setIsShowExternalRecruiterTab] = useState(
    false,
  )
  const [isShowCompanyMembersTab, setIsShowCompanyMembersTab] = useState(false)

  const companyId = isAdmin ? job.companyId : viewer.companyId

  useEffect(() => {
    refreshHiringTeamCounters()
  }, [])

  useEffect(() => {
    refresh && refreshHiringTeamCounters()
  }, [refresh])

  const refreshHiringTeamCounters = async () => {
    const { isExistER, isExistCM } = await getHiringTeamCounters({
      companyMembersFilters: `companyId: ${companyId} AND NOT memberOfTeams: ${job.id} AND status: ${UserStatus.Verified}`,
      externalRecruiterFilters: `visibleToCompanies: ${companyId} AND NOT memberOfTeams: ${job.id} AND status: ${UserStatus.Verified}`,
    })

    setIsShowCompanyMembersTab(isExistCM)
    setIsShowExternalRecruiterTab(isExistER)
  }

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.teamMembers.token,
      ),
    [],
  )

  const searchClient2 = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  const OperationsSlot = {
    left: <Title>Invite existing Members</Title>,
  }

  return (
    <HiringTeamContainer>
      <HiringTeamList>
        {isPreviewPage && <Title>{job?.title} Hiring Team</Title>}

        <InstantSearch
          searchClient={searchClient}
          indexName={viewer.algoliaKeys.teamMembers.indexName}
          refresh={refresh}
        >
          <Index indexName={viewer.algoliaKeys.teamMembers.indexName}>
            <Configure hitsPerPage={10} filters={`advertId: ${job.id}`} />
            <JobMembersHitsCustomHits job={job} />
            <AlgoliaPagination pageSize="10" setPageSize={() => {}} />
          </Index>
        </InstantSearch>
      </HiringTeamList>

      {(isShowCompanyMembersTab || isShowExternalRecruiterTab) &&
        !isTeamMemberOrExternalRecruiter &&
        job.status !== JobStatus.Archived && (
          <>
            {!screens.md && <Title>Invite existing Members</Title>}
            <TabsContainer>
              <Tabs
                defaultActiveKey="1"
                centered={screens.md}
                tabBarExtraContent={screens.md && OperationsSlot}
              >
                {isShowCompanyMembersTab && (
                  <TabPane tab="Hiring Team" key="1">
                    <Description>{job.user.company} Hiring Team </Description>

                    <InstantSearch
                      searchClient={searchClient2}
                      indexName={viewer.algoliaKeys.users.listOfUsers}
                      refresh={refresh}
                    >
                      <Configure
                        hitsPerPage={pageSize}
                        filters={`companyId: ${companyId} AND NOT memberOfTeams: ${job.id} AND status: ${UserStatus.Verified}`}
                      />
                      <CustomUserHits />
                      <AlgoliaPagination
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                      />
                    </InstantSearch>
                  </TabPane>
                )}

                {isShowExternalRecruiterTab && (
                  <TabPane tab="External Recruiters" key="2">
                    <ExternalRecruitersList
                      refresh={refresh}
                      filters={`visibleToCompanies: ${companyId} AND NOT memberOfTeams: ${job.id} AND status: ${UserStatus.Verified}`}
                    />
                  </TabPane>
                )}
              </Tabs>
            </TabsContainer>
          </>
        )}
    </HiringTeamContainer>
  )
}

export default HiringTeam
