import styled from 'styled-components/macro'

import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'

export const ModalItem = styled(Modal)`
  & .ant-modal-body {
    padding-bottom: 0;
  }
  & .ant-modal-footer {
    border-top: none;
    padding: 10px 24px;
  }
  & .ant-modal-content {
    padding-bottom: 22px;
  }
  & .ant-alert {
    border: none;
    padding: 9px 16px;

    margin-bottom: 32px;
    ul {
      margin: 0 0 0 20px;
      padding: 0;
    }
    li {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.orange6};
    }
  }
`
export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  svg {
    margin-right: 8px;
  }
`

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.gray9};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 32px;
`
export const BoldText = styled.span`
  font-weight: 700;
`
export const InputElement = styled(Input)`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  &:placeholder {
    color: ${({ theme }) => theme.colors.gray7};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
`
export const ButtonsRightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`
