import styled from 'styled-components/macro'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const Container = styled.div`
  .ant-tabs-nav {
    margin-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
  }
`

export const JobActionsContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  flex-wrap: wrap;
  .ant-btn {
    font-weight: 600;
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    position: static;
    padding: 0;

    .ant-btn {
      min-width: initial;
    }
  }
`

export const EditJobContainer = styled.div`
  display: flex;
  margin-bottom: 100px;

  .ant-select-selection-item {
    font-weight: 600;
  }
`

export const Main = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    flex: 1;
    margin-right: 32px;
    width: calc(100% - 352px);
  }
`

export const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7% 0;
`

export const Confirmation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 130px);
  max-width: 720px;
  margin: auto;
  text-align: center;
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 20px;
    line-height: 1.2em;
  }
  p {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.gray7};
  }

  .ant-btn {
    font-weight: 600;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 30px;
    }
  }
`
