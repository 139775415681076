import { createSelector } from '@reduxjs/toolkit'

import { RootState, UsersRole } from 'types'

const viewer = (state: RootState) => state.viewer
export const getViewerSelector = createSelector(viewer, r => r.viewer)

export const isAdminSelector = createSelector(
  viewer,
  r => r.viewer.role === UsersRole.JHSuperAdmin,
)

export const isCompanyAdminSelector = createSelector(
  viewer,
  r => r.viewer.role === UsersRole.CompanyAdmin,
)

export const isAdminOrCompanyAdminSelector = createSelector(
  viewer,
  r =>
    r.viewer.role === UsersRole.JHSuperAdmin ||
    r.viewer.role === UsersRole.CompanyAdmin,
)

export const isExternalRecruiterOrTeamMemberSelector = createSelector(
  viewer,
  r =>
    r.viewer.role === UsersRole.ExternalRecruiter ||
    r.viewer.role === UsersRole.TeamMember,
)

export const isExternalRecruiterSelector = createSelector(
  viewer,
  r => r.viewer.role === UsersRole.ExternalRecruiter,
)

export const isTeamMemberSelector = createSelector(
  viewer,
  r => r.viewer.role === UsersRole.TeamMember,
)

export const isRecruitmentManagerSelector = createSelector(
  viewer,
  r => r.viewer.role === UsersRole.RecruitmentManager,
)

export const isHiringManagerSelector = createSelector(
  viewer,
  r => r.viewer.role === UsersRole.HiringManager,
)
