import React, { FC } from 'react'

import { OnFilterProp } from '../../DetailJob'
import StatusDot from 'app/components/Jobs/JobsList/RefinementList/StatusDot/StatusDot'

import Checkbox from 'antd/lib/checkbox'

import {
  Count,
  List,
  Title,
} from 'app/components/Jobs/JobsList/RefinementList/styles'

export enum FilterAttribute {
  source = 'source',
  stage = 'stage',
  status = 'status',
}

export enum ApiFilterAttribute {
  source = 'candidateSources',
  stage = 'candidateStageTypes',
  status = 'candidateStatuses',
}

export interface ItemsProps {
  count: number
  isRefined: boolean
  label: string
  value: string
  order?: number
}

interface IFilterList {
  attribute: FilterAttribute
  items: ItemsProps[]
  limit: number
  maxWidth?: number
  onFilter: (prop: OnFilterProp) => void
  showCount?: boolean
  showTitleCount?: boolean
  title: string
}

/**
 * This is similar to RefinementList component
 * but retrieves list from passed items prop instead from an Algolia HOC response
 */
const FilterList: FC<IFilterList> = ({
  attribute,
  items,
  maxWidth,
  onFilter,
  showCount,
  showTitleCount,
  title,
}) => {
  const isSomeRefined = items.some(
    ({ isRefined }: { isRefined: boolean }) => isRefined,
  )

  const allCount = items.reduce(
    (previousValue: number, { count }: { count: number }) => {
      return previousValue + count
    },
    0,
  )

  const allValue = items.map(item => item.value)

  return (
    <List direction="vertical" size={10}>
      <Checkbox
        key={title}
        value={title}
        checked={!isSomeRefined}
        onChange={() => onFilter({ key: attribute, value: allValue })}
      >
        <Title> {title}</Title>

        {showTitleCount && <Count>({allCount})</Count>}
      </Checkbox>
      {items.map((item: any) => {
        if (!item.count) {
          return <React.Fragment key={item.value}></React.Fragment>
        }

        return (
          <Checkbox
            key={item.value}
            checked={item.isRefined}
            value={item.value}
            onChange={() => onFilter({ key: attribute, value: item.value })}
          >
            {attribute === FilterAttribute.status && (
              <StatusDot status={item.label} />
            )}
            <Title title={item.label} maxWidth={maxWidth}>
              {item.label}
            </Title>

            {showCount && <Count>({item.count})</Count>}
          </Checkbox>
        )
      })}
    </List>
  )
}

export default FilterList
