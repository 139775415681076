import React from 'react'

const Kanban = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.875 0.5H8.125V13.5H5.875V0.5ZM10.6875 0.5H12.9375V13.5H10.6875V0.5ZM1.0625 0.5H3.3125V13.5H1.0625V0.5Z"
        stroke="#262626"
      />
    </svg>
  )
}

export default Kanban
