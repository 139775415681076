import React from 'react'

import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'

import { Header } from './styles'

const GDPR = () => {
  return (
    <div>
      <Header>
        <BackButton title="GDPR" />
      </Header>

      <p>
        Jobheron is GDPR compliant. All candidates are automatically removed
        from the system after 9 months.
      </p>
    </div>
  )
}

export default GDPR
