import React, { FC } from 'react'

import NotVerifiedCompanyModal from 'app/components/Modals/NotVerifiedCompanyModal/NotVerifiedCompanyModal'

import Button from 'antd/lib/button'
import Space from 'antd/lib/space'
import Tooltip from 'antd/lib/tooltip'

import { JobStatus } from 'types'

interface IsNotVerifiedCompanyButtonProps {
  status: JobStatus
  isAdmin: boolean
  needApproval: boolean
  companyName: string
}

const IsNotVerifiedCompanyButton: FC<IsNotVerifiedCompanyButtonProps> = ({
  status,
  isAdmin,
  needApproval,
  companyName,
}) => {
  if (isAdmin && needApproval && status === JobStatus.PendingAdminApproval) {
    return (
      <Space>
        <NotVerifiedCompanyModal
          buttonTitle="Submit for user approval"
          companyName={companyName}
        />
        <NotVerifiedCompanyModal
          buttonTitle="Publish on behalf of the user"
          companyName={companyName}
        />
      </Space>
    )
  }
  if (isAdmin) {
    return (
      <NotVerifiedCompanyModal
        buttonTitle="Approve & Publish"
        companyName={companyName}
      />
    )
  }
  return (
    <Tooltip title="Pending company verification">
      <Button type="primary" disabled>
        "Approve & Publish"
      </Button>
    </Tooltip>
  )
}

export default IsNotVerifiedCompanyButton
