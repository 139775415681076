import styled from 'styled-components/macro'

export const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.4);
`

export const Container = styled.div`
  position: relative;
  border-top: #f0f0f0;
  display: flex;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  max-width: 100%;
  flex: 1;
`

export const CandidatesContainer = styled.div`
  .ant-table-tbody > tr:hover,
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #f3fbff;
  }

  .ant-tag {
    border: none;
  }
  ${({ isExternalRecruiter }: { isExternalRecruiter: boolean }) => {
    if (isExternalRecruiter) {
      return `
      .ant-table-thead > tr > th:first-child,
      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
      padding-left: 20px;
      }`
    }
  }}
`
export const EmptyBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10%;
`
