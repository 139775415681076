import styled from 'styled-components/macro'

import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-btn {
    margin-left: 10px;
  }
`

export const MenuContainer = styled(Menu)`
  border: 0;
  width: 100%;
  &.ant-menu-vertical .ant-menu-item {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    padding: 14px 12px;
    .anticon {
      font-size: 14px;
    }
  }
  @media (min-width: 576px) {
    width: 275px;
  }
`

export const CloseBtn = styled(Button)`
  padding: 10px 0 0 0;
`

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const DrawerHeaderTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  margin: 0 16px 0 0;
`
