import React from 'react'

import { ROUTES } from 'routes'

import { AccessLevelsText, LinkItem } from './styles'

const AccessLevelInfo = () => {
  return (
    <AccessLevelsText>
      Learn more about access levels{' '}
      <LinkItem
        target="_blank"
        rel="noreferrer"
        href={`${ROUTES.JOBHERON_HELP_CENTER}/article/team-user-roles-access-levels`}
      >
        here.
      </LinkItem>
    </AccessLevelsText>
  )
}

export default AccessLevelInfo
