import { campaigns } from 'constants/app'

import { CreditType, IJobheronCampaigns } from 'types'

export const jobheronCampaigns: IJobheronCampaigns[] = [
  {
    name: campaigns.advertiser,
    value: 'Advertiser',
    popular: false,
    prevName: null,
    advantages: [
      {
        text: 'Account Manager',
      },
      {
        text: 'Job Ad Written',
      },
      {
        text: 'Premium Job Boards',
      },
      {
        text: '500+ Niche / Free Boards',
      },
      {
        text: 'Applicant Tracking System',
      },
      {
        text: 'Electronic CV Filtering',
      },
      {
        text: 'Human CV Screening (Optional)',
        tooltip:
          'Optional Manual CV Filtering saving you hours, by our expert recruitment team.',
      },
    ],
  },
  {
    name: campaigns.managed,
    value: 'Managed',
    popular: true,
    prevName: campaigns.advertiser,
    advantages: [
      {
        text: 'CV database search',
        tooltip:
          'We carry out a detailed search for you on all the main UK CV ​Databases and send you a shortlist of relevant talent.',
      },
      {
        text: 'Branded Ads',
      },
    ],
  },
  {
    name: campaigns.hire,
    value: 'Hire',
    popular: false,
    prevName: campaigns.managed,
    advantages: [
      {
        text: 'Telephone Screening',
        tooltip: 'We carry out full telephone screening for you.',
      },
      {
        text: 'Interviews Arranged',
      },
      {
        text: 'All you have to do is Hire!',
      },
    ],
  },
]

export enum CreditTypeValue {
  advertiser = 'advertiser',
  managed = 'managed',
  hire = 'hire',
}

export const creditTypes = [
  {
    title: campaigns.advertiser,
    creditType: CreditType.Advertiser,
    value: CreditTypeValue.advertiser,
    packId: 1,
  },
  {
    title: campaigns.managed,
    creditType: CreditType.Managed,
    value: CreditTypeValue.managed,
    packId: 2,
  },
  {
    title: campaigns.hire,
    creditType: CreditType.Hire,
    value: CreditTypeValue.hire,
    packId: 3,
  },
]

export const campaignTypes = ['Advertiser', 'Managed', 'Hire']
