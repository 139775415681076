import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  a {
    color: ${({ theme }) => theme.colors.gray9};
    text-decoration: underline;
  }
`
