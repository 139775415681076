import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import snackbar from 'app/components/Snackbar/Snackbar'
import Close from 'assets/icons/Close'
import Success from 'assets/icons/Success'

import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import Tag from 'antd/lib/tag'
import Tooltip from 'antd/lib/tooltip'

import { PlusOutlined } from '@ant-design/icons'

import {
  AddNewTag,
  Container,
  InputContainer,
  InputContainerActions,
} from './styles'

const AddJobKeywords = (props: any) => {
  const {
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext()

  const addTagInput = useRef<Input>(null)
  const [inputValue, setInputValue] = useState('')
  const defaultTags = props.value ? props.value : []
  const [tags, setTags] = useState<string[]>(defaultTags)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const showInput = () => {
    setIsInputVisible(true)
  }

  useEffect(() => {
    isInputVisible && addTagInput.current?.focus()
  }, [isInputVisible])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value.length > 255) {
      setError('preferKw', {
        message: 'Keyword should be between 1 and 255 characters',
      })
    } else {
      clearErrors('preferKw')
    }
    setInputValue(value)
  }

  const handleUndoTag = (tag: string) => {
    // TODO I need filter tags because here i have previous state !!
    const filteredTags = tags.filter(item => item !== tag)

    const newTags = [...filteredTags, tag]
    setTags(newTags)
    props.onChange(newTags)
  }

  const handleInputConfirm = () => {
    let newTags = tags

    if (inputValue.trim().length && tags.indexOf(inputValue) === -1) {
      newTags = [...tags, inputValue.trim()]
    }
    setTags(newTags)
    setIsInputVisible(false)
    setInputValue('')
    props.onChange(newTags)
  }

  const handleClose = (tag: string) => {
    const newTags = tags.filter(item => item !== tag)
    setTags(newTags)
    props.onChange(newTags)
    snackbar({
      content: 'Keyword has been deleted.',
      undoFunc: () => handleUndoTag(tag),
      showIcon: true,
      type: 'info',
    })
  }

  const handleHideAddTagInput = () => {
    setIsInputVisible(false)
    setInputValue('')
  }

  return (
    <Container>
      {tags.map((tag: string) => {
        const isLongTag = tag.length > 20

        const tagElem = (
          <Tag
            key={tag}
            closable={false}
            icon={
              <Button type="text" onClick={() => handleClose(tag)}>
                <Close color="#8C8C8C" />
              </Button>
            }
          >
            <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
          </Tag>
        )

        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}

      {isInputVisible && (
        <InputContainer>
          <Input
            type="text"
            ref={addTagInput}
            value={inputValue}
            onChange={handleInputChange}
            onPressEnter={handleInputConfirm}
            addonAfter={
              <InputContainerActions>
                <Button
                  type="text"
                  icon={<Close />}
                  onClick={handleHideAddTagInput}
                />
                <Button
                  type="text"
                  onClick={handleInputConfirm}
                  disabled={!!errors.preferKw}
                  icon={<Success color="#13C2C2" />}
                />
              </InputContainerActions>
            }
          />
        </InputContainer>
      )}
      {!isInputVisible && (
        <AddNewTag onClick={showInput} color="blue">
          <PlusOutlined /> Add keyword
        </AddNewTag>
      )}
    </Container>
  )
}

export default AddJobKeywords
