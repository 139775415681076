import styled from 'styled-components'

export interface IRejectedCandidates {
  isRejectedActive: boolean
}

export const RejectedCandidates = styled.div<IRejectedCandidates>`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray9};
  text-decoration: ${({ isRejectedActive }) =>
    isRejectedActive ? 'underline' : 'none'};
  span {
    font-weight: 600;
`
