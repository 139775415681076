import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Redirect } from 'react-router-dom'

import { invitationStatuses } from '../../../components/Auth/constants'
import AuthStatusComponent from 'app/components/Auth/AuthStatusComponent/AuthStatusComponent'
import SignUpInfo from 'app/components/Auth/SignUpInfo/SignUpInfo'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import SignInWithGoogle from 'app/components/SignInWithGoogle/SignInWithGoogle'
import SignInWithLinkedIn from 'app/components/SignInWithLinkedIn/SignInWithLinkedIn'
import { ROUTES } from 'routes'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'

import {
  BottomText,
  Description,
  FormBlock,
  FullWidthButton,
  NextButton,
  SignDivider,
  SocialContainer,
  Title,
} from './styles'

import { AuthStatus, IViewerState } from 'types'

interface IInvitation {
  isSuccess: boolean
  isExternalRecruiter: boolean
  handleSignUpWithGoogle: () => void
  handleSignUpWithLinkedIn: () => void
  handleGoToNextStep: () => void
  handleFormSubmit: (viewer: any) => Promise<void>
  formStep: 0 | 1
  viewer: IViewerState
  status: AuthStatus
}

const Invitation: FC<IInvitation> = ({
  status,
  isSuccess,
  isExternalRecruiter,
  handleSignUpWithGoogle,
  handleSignUpWithLinkedIn,
  handleGoToNextStep,
  formStep,
  viewer,
  handleFormSubmit,
}) => {
  const { handleSubmit } = useFormContext()

  const currentAuthStatus = invitationStatuses.find(
    item => item.status === status,
  )!

  if (
    status === AuthStatus.Success ||
    status === AuthStatus.Accepted ||
    status === AuthStatus.Rejected
  ) {
    return <Redirect to={ROUTES.SIGN_IN} />
  }

  if (isSuccess) {
    return <AuthStatusComponent {...invitationStatuses[0]} />
  }

  if (status === AuthStatus.TokenAlreadyUsed) {
    return <AuthStatusComponent {...currentAuthStatus} isInvitation />
  }

  if (
    status === AuthStatus.ExpiredToken ||
    status === AuthStatus.InvalidToken
  ) {
    return <AuthStatusComponent {...currentAuthStatus} isInvitation />
  }

  if (isExternalRecruiter) {
    return (
      <Row>
        <SignUpInfo />

        <Col xs={24} md={14}>
          <Form layout="vertical" onFinish={handleSubmit(handleFormSubmit)}>
            {formStep === 0 && (
              <FormBlock>
                <Title>Sign up for free</Title>
                <Description>
                  Access the UK’s top job boards at a fraction of the cost{' '}
                  <br />- no credit card needed
                </Description>

                <Row gutter={24}>
                  <Col xs={24} md={12}>
                    <FormItem
                      name="firstName"
                      label="First name"
                      component={Input}
                    />
                  </Col>

                  <Col xs={24} md={12}>
                    <FormItem
                      name="lastName"
                      label="Last name"
                      component={Input}
                    />
                  </Col>
                </Row>

                <FormItem name="email" label="Work email" component={Input} />

                <FormItem
                  name="password"
                  label="Password"
                  help="At least 8 characters, one uppercase letter, one lowercase letter, one digit."
                  component={Input.Password}
                />

                <Form.Item>
                  <NextButton type="primary" onClick={handleGoToNextStep}>
                    Next
                  </NextButton>
                </Form.Item>

                <SignDivider plain>or</SignDivider>

                <SocialContainer>
                  <Row gutter={24}>
                    <Col className="gutter-row" xs={24} md={12}>
                      <SignInWithGoogle
                        title="Sign up with Google"
                        onClick={handleSignUpWithGoogle}
                      />
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                      <SignInWithLinkedIn
                        title="Sign up with LinkedIn"
                        onClick={handleSignUpWithLinkedIn}
                      />
                    </Col>
                  </Row>
                </SocialContainer>
              </FormBlock>
            )}

            {formStep === 1 && (
              <FormBlock>
                <Title>You’re nearly there.</Title>

                <FormItem
                  name="companyName"
                  label="Company name"
                  component={Input}
                />

                <FormItem
                  name="companyWebsite"
                  label="Company website"
                  component={Input}
                  componentProps={{
                    placeholder: 'i.e. www.yourcompany.com',
                  }}
                />

                <FormItem
                  name="phoneNumber"
                  label="Phone number (optional)"
                  component={Input}
                  componentProps={{
                    prefix: '+44',
                  }}
                />

                <Form.Item>
                  <FullWidthButton
                    type="primary"
                    htmlType="submit"
                    loading={viewer.signUp.isLoading}
                  >
                    Get started!
                  </FullWidthButton>
                </Form.Item>
              </FormBlock>
            )}
          </Form>

          <BottomText>
            <p>By signing up, you agree to our </p>
            <a
              target="_blank"
              rel="noreferrer"
              href={ROUTES.JOBHERON_TERMS_AND_CONDITIONS}
            >
              Terms of use
            </a>
          </BottomText>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <SignUpInfo />

      <Col xs={24} md={14}>
        <Form layout="vertical" onFinish={handleSubmit(handleFormSubmit)}>
          <FormBlock>
            <Title>Sign up for free</Title>
            <Description>
              Access the UK’s top job boards at a fraction of the cost <br />-
              no credit card needed
            </Description>

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <FormItem
                  name="firstName"
                  label="First name"
                  component={Input}
                />
              </Col>

              <Col xs={24} md={12}>
                <FormItem name="lastName" label="Last name" component={Input} />
              </Col>
            </Row>

            <FormItem name="email" label="Work email" component={Input} />

            <FormItem
              name="password"
              label="Password"
              help="At least 8 characters, one uppercase letter, one lowercase letter, one digit."
              component={Input.Password}
            />

            <Form.Item>
              <NextButton
                type="primary"
                onClick={handleSubmit(handleFormSubmit)}
                loading={viewer.signUp.isLoading}
              >
                Get started!
              </NextButton>
            </Form.Item>

            <SignDivider plain>or</SignDivider>

            <SocialContainer>
              <Row gutter={24}>
                <Col className="gutter-row" xs={24} md={12}>
                  <SignInWithGoogle
                    title="Sign up with Google"
                    onClick={handleSignUpWithGoogle}
                  />
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <SignInWithLinkedIn
                    title="Sign up with LinkedIn"
                    onClick={handleSignUpWithLinkedIn}
                  />
                </Col>
              </Row>
            </SocialContainer>
          </FormBlock>
        </Form>

        <BottomText>
          <p>By signing up, you agree to our </p>
          <a
            target="_blank"
            rel="noreferrer"
            href={ROUTES.JOBHERON_TERMS_AND_CONDITIONS}
          >
            Terms of use
          </a>
        </BottomText>
      </Col>
    </Row>
  )
}

export default Invitation
