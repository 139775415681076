import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { renderStageIcon } from 'helpers/stagesHelpers'

import Button from 'antd/lib/button'
import Space from 'antd/lib/space'

import {
  DisabledStageItem,
  StageBody,
  StageContainer,
  StageDraggable,
  StageIcon,
} from './styles'

import { IStage } from 'types'

interface IStageItem {
  index: number
  stage: IStage
  handleUpdateStage: (stage: IStage) => void
  handleOpenDeleteStageModal: (stage: IStage) => void
}

const StageItem = ({
  index,
  stage,
  handleUpdateStage,
  handleOpenDeleteStageModal,
}: IStageItem) => {
  if (!stage.isEditable) {
    return (
      <div>
        <Space>
          <DisabledStageItem>
            <StageIcon>{renderStageIcon(stage.type)}</StageIcon>
            <div>{stage.name}</div>
          </DisabledStageItem>
        </Space>
      </div>
    )
  }

  return (
    <Draggable index={index} draggableId={stage.draggableId}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StageContainer>
            <StageDraggable />
            <StageBody>
              <StageIcon>{renderStageIcon(stage.type)}</StageIcon>
              <div>{stage.name}</div>
              <Button type="link" onClick={() => handleUpdateStage(stage)}>
                Edit
              </Button>
            </StageBody>
            <Button
              danger
              type="text"
              onClick={() => handleOpenDeleteStageModal(stage)}
            >
              Delete
            </Button>
          </StageContainer>
        </div>
      )}
    </Draggable>
  )
}

export default StageItem
