import styled from 'styled-components'

import Row from 'antd/lib/row'

export const Header = styled(Row)`
  margin-bottom: 20px;
`

export const InfoBlock = styled(Row)`
  margin-bottom: 20px;
`
