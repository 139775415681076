import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearCandidateNotes,
  fetchCandidateNotesPromiseCreator,
} from 'store/candidates'

import { formatDate } from 'helpers/formatDate'
import { generateAvatarName } from 'helpers/generateAvatarName'

import Notes from '../assets/Notes'
import AddNoteModal from 'app/components/Modals/AddNoteModal/AddNoteModal'
import EmptyBlock from 'app/components/UI/EmptyBlock/EmptyBlock'
import Loader from 'app/components/UI/Loader/Loader'
import differenceInMonths from 'date-fns/differenceInMonths'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import useActionsRoutines from 'hooks/useActionsRoutines'

import {
  CommentInfo,
  CommentTime,
  CommentWrapper,
  Container,
  NoNotes,
  UserAvatar,
} from './styles'

import { JobStatus, RootState } from 'types'

const today = new Date()

const CandidateNotes = () => {
  const [isLoading, setIsLoading] = useState(true)
  const fetchNotes = useActionsRoutines(fetchCandidateNotesPromiseCreator)
  const dispatch = useDispatch()
  const { candidateNotes } = useSelector((state: RootState) => state.candidates)
  const {
    id: candidateId,
    company: { id: companyId },
    advert: { id: advertId, status: jobStatus },
  } = useSelector(
    (state: RootState) => state.candidates.fetchCandidate.candidate!,
  )
  const {
    items: notes,
    totalPages,
    pageNumber: pageNumberFromState,
  } = candidateNotes

  useEffect(() => {
    fetchNotes({ pageNumber: 0 }).finally(() => setIsLoading(false))
    return () => {
      dispatch(clearCandidateNotes())
    }
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (notes && !notes.length) {
    return (
      <NoNotes>
        <EmptyBlock
          title="No notes added"
          message='To create a note please click "Add note" button below.'
          icon={<Notes />}
        >
          <AddNoteModal
            buttonTitle="Add note"
            buttonType="primary"
            advertId={String(advertId)}
            companyId={companyId}
            candidateId={String(candidateId)}
          />
        </EmptyBlock>
      </NoNotes>
    )
  }

  const hasMorePages = totalPages !== pageNumberFromState + 1

  return (
    <Container>
      <InfiniteScroll
        dataLength={notes.length}
        next={() => fetchNotes({ pageNumber: pageNumberFromState + 1 })}
        hasMore={hasMorePages}
        loader={<Loader />}
      >
        {notes &&
          notes.map(data => {
            const { user, id, text, createdAt } = data

            return (
              <CommentWrapper
                key={id}
                author={<CommentInfo>{user.fullName}</CommentInfo>}
                avatar={
                  <UserAvatar>
                    {generateAvatarName({
                      fullName: user.fullName,
                    })}
                  </UserAvatar>
                }
                content={<p dangerouslySetInnerHTML={{ __html: text }}></p>}
                datetime={
                  <CommentTime>
                    {differenceInMonths(today, createdAt) >= 1
                      ? `${formatDate(createdAt, 'dd MMM, kk:mma')}`
                      : `${formatDate(
                          createdAt,
                          'dd MMM, kk:mma',
                        )} (${formatDistanceToNowStrict(new Date(createdAt), {
                          addSuffix: true,
                        })}) `}
                  </CommentTime>
                }
              />
            )
          })}
      </InfiniteScroll>
    </Container>
  )
}

export default CandidateNotes
