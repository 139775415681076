import UpdateUser from '../app/modules/UpdateUser/UpdateUser'
import { ROUTES } from './constants'
import AdminCandidates from 'app/modules/Admin/Candidates/Candidates'
import CreateUser from 'app/modules/Admin/CreateUser/CreateUser'
import ManageUsers from 'app/modules/Admin/ManageUsers/ManageUsers'
import AssignCredits from 'app/modules/AssignCredits/AssignCredits'
import Candidate from 'app/modules/Candidate/Candidate'
import Checkout from 'app/modules/Checkout/Checkout'
import Home from 'app/modules/Home'
import CreateJob from 'app/modules/Jobs/CreateJob/CreateJob'
import JobDetail from 'app/modules/Jobs/DetailJob/DetailJob'
import JobList from 'app/modules/Jobs/JobList/JobList'
import PreviewJob from 'app/modules/Jobs/PreviewJob/PreviewJob'
import Settings from 'app/modules/Settings'

import { UsersRole } from 'types'

export const allRoutes = [
  {
    exact: true,
    path: ROUTES.DASHBOARD,
    component: Home,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.HiringManager,
      UsersRole.CompanyAdmin,
      UsersRole.RecruitmentManager,
      UsersRole.ExternalRecruiter,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    exact: true,
    path: ROUTES.JOBS,
    component: JobList,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.ExternalRecruiter,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    exact: true,
    path: ROUTES.JOB_CREATE,
    component: CreateJob,
    permissions: [
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    exact: true,
    path: `${ROUTES.JOB}/:jobId/preview`,
    component: PreviewJob,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.ExternalRecruiter,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    exact: true,
    path: `${ROUTES.JOB}/:jobId/edit`,
    component: PreviewJob,
    permissions: [
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    exact: true,
    path: `${ROUTES.JOB}/:jobId`,
    component: JobDetail,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.ExternalRecruiter,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    path: ROUTES.CHECKOUT,
    component: Checkout,
    permissions: [
      UsersRole.HiringManager,
      UsersRole.CompanyAdmin,
      UsersRole.RecruitmentManager,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    path: `${ROUTES.CANDIDATE}/:candidateId`,
    component: Candidate,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.ExternalRecruiter,
      UsersRole.HiringManager,
      UsersRole.RecruitmentManager,
      UsersRole.CompanyAdmin,
      UsersRole.JHSuperAdmin,
    ],
  },
  {
    path: ROUTES.SETTING_ASSIGN_CREDITS,
    component: AssignCredits,
    permissions: [UsersRole.CompanyAdmin],
  },
  {
    path: ROUTES.SETTINGS,
    component: Settings,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.HiringManager,
      UsersRole.CompanyAdmin,
      UsersRole.RecruitmentManager,
      UsersRole.ExternalRecruiter,
    ],
  },
  {
    path: ROUTES.ADMIN_CANDIDATES,
    component: AdminCandidates,
    permissions: [UsersRole.JHSuperAdmin],
  },
  {
    path: ROUTES.ADMIN_MANAGE_USERS,
    component: ManageUsers,
    permissions: [UsersRole.JHSuperAdmin],
  },
  {
    path: ROUTES.ADMIN_CREATE_USER,
    component: CreateUser,
    permissions: [UsersRole.JHSuperAdmin],
  },
  {
    path: `${ROUTES.USERS}/:userId`,
    component: UpdateUser,
    permissions: [
      UsersRole.TeamMember,
      UsersRole.HiringManager,
      UsersRole.CompanyAdmin,
      UsersRole.RecruitmentManager,
      UsersRole.JHSuperAdmin,
    ],
  },
]
