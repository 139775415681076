import styled from 'styled-components/macro'

export const Container = styled.div`
  background: #fafafa;
  width: 100%;
  padding: 16px 0;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
  @media (min-width: 576px) {
    padding: 16px;
  }

  .ant-carousel .slick-arrow {
    width: 40px;
    height: 40px;
    padding: 6.4px 0;
    font-size: 16px;
    background: #fff;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
      0 6px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    vertical-align: -1px;
    border: 1px solid #d9d9d9;
    margin-top: -20px;
    z-index: 1;

    &.slick-disabled {
      display: none;
    }

    svg {
      color: ${({ theme }) => theme.colors.gray9};
    }
  }
  .slick-list {
    margin: 0 -8px;
  }
  .slick-slide > div {
    padding: 0 8px;
  }

  .ant-carousel .slick-prev {
    left: 5px;
  }

  .ant-carousel .slick-next {
    right: 5px;
  }

  .slick-slide {
    width: 100%;
  }
`
