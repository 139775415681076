import * as Yup from 'yup'

import { CandidateSource } from '../types'

export const addCandidatesSchema = Yup.object().shape({
  cvs: Yup.mixed().required('File is required'),
})

export const addCandidatesByAdminSchema = addCandidatesSchema.shape({
  source: Yup.string().required('Select source in order to add Candidate.'),
  userId: Yup.mixed().when('source', {
    is: (value: CandidateSource) => value === CandidateSource.Sourced,
    then: Yup.string().nullable().required('Has to be a valid user'),
    otherwise: Yup.mixed().notRequired(),
  }),
})
