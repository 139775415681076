import * as React from 'react'

function CreditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={33}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 2.988a4 4 0 014 0l8.702 5.024a4 4 0 012 3.464v10.048a4 4 0 01-2 3.464L18 30.012a4 4 0 01-4 0l-8.702-5.024a4 4 0 01-2-3.464V11.476a4 4 0 012-3.464L14 2.988z"
        stroke="#FFC53D"
        strokeWidth={2}
      />
    </svg>
  )
}

export default CreditIcon
