import styled from 'styled-components'

export const ModalFooter = styled.div`
  text-align: left;
  margin-top: 30px;

  .ant-btn {
    margin-right: 10px;
  }
`

export const ModalContent = styled.div`
  .ant-form-item-explain {
    margin-top: 5px;
  }
  & .ant-form {
    textarea {
      resize: none;
      height: 280px;
    }
  }
  .ant-form-item-has-error {
    .ant-mentions {
      border: 1px solid ${({ theme }) => theme.colors.red5};
    }
    .ant-mentions:focus,
    .ant-mentions-focused {
      box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
    }
  }
`
export const InputWrapper = styled.div`
  position: relative;
  white-space: pre-line;
  .rc-textarea {
    position: relative;
    background: transparent;
    color: transparent;
    z-index: 2;
    caret-color: #262626;
    min-height: 280px;
  }
`
export const HighlighterBlock = styled.div`
  position: absolute;
  right: 12px;
  bottom: 9px;
  top: 9px;
  left: 12px;
  z-index: 0;
`
