import React, { FC, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import debounce from 'lodash/debounce'
import algolia from 'services/algolia'

import Select from 'antd/lib/select'

import { OptionItem, OptionItemCandidates, OptionItemTitle } from './styles'

const { Option } = Select

interface ISearchCompaniesAutocomplete {
  setCurrentInviteCompany?: (value: string) => void
  selectedAdverts: string[]
  selectedCompanyId: null | string
  isShowMembersQuantity?: boolean
  placeholder: string
}

interface ICompany {
  name: string
  objectID: string
  status: string
  numberOfMembers: number
}

const SearchCompaniesAutocomplete: FC<ISearchCompaniesAutocomplete> = ({
  setCurrentInviteCompany,
  selectedAdverts,
  selectedCompanyId,
  isShowMembersQuantity,
  placeholder,
  ...rest
}) => {
  const { setValue } = useFormContext()

  const [companiesList, setCompaniesList] = useState<ICompany[]>([])

  const filters = useMemo(() => {
    if (selectedCompanyId) {
      return `objectID: ${selectedCompanyId}`
    }
    return ''
  }, [selectedCompanyId])

  const handleSearchUsers = async (query: string) => {
    const companies = await algolia.getCompanies({
      query,
      options: { filters, hitsPerPage: 100 },
    })
    setCompaniesList((companies.hits as unknown) as ICompany[])
    if (selectedCompanyId) {
      setValue('companyId', String(selectedCompanyId))
      handleSelectCompany(String(selectedCompanyId))
    } else {
      setValue('companyId', null)
    }
  }

  const handleSelectCompany = (companyId: string) => {
    const selectedCompany = companiesList.find(
      ({ objectID }) => objectID === companyId,
    )
    selectedCompany &&
      setCurrentInviteCompany &&
      setCurrentInviteCompany(selectedCompany?.name)
  }

  useEffect(() => {
    handleSearchUsers('')
  }, [])

  useEffect(() => {
    handleSearchUsers('')
  }, [selectedCompanyId])

  return (
    <Select
      {...rest}
      showSearch={true}
      filterOption={false}
      onSearch={debounce(handleSearchUsers, 400)}
      placeholder={placeholder}
      onSelect={(companyId: string) => handleSelectCompany(companyId)}
    >
      {companiesList.map(({ name, objectID, numberOfMembers }) => (
        <Option key={objectID} value={objectID}>
          <OptionItem>
            <OptionItemTitle>{name}</OptionItemTitle>
            {isShowMembersQuantity && (
              <OptionItemCandidates>
                {numberOfMembers} Members
              </OptionItemCandidates>
            )}
          </OptionItem>
        </Option>
      ))}
    </Select>
  )
}

export default SearchCompaniesAutocomplete
