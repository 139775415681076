import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'

import {
  deleteStagePromiseCreator,
  fetchStagesPromiseCreator,
  reorderStagePromiseCreator,
} from 'store/stages'
import { getViewerSelector } from 'store/viewer'

import AddEditStage from '../AddEditStage/AddEditStage'
import StagesList from './StageList'
import DeleteStageModal from 'app/components/Modals/DeleteStageModal/DeleteStageModal'
import snackbar from 'app/components/Snackbar/Snackbar'
import Loader from 'app/components/UI/Loader/Loader'
import useActionsRoutines from 'hooks/useActionsRoutines'

import { AddButton } from '../AddEditStage/styles'
import { Container } from './styles'

import { IStage, RootState } from 'types'

const reorder = (list: IStage[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const MAX_STAGES_COUNT = 10

const Stages = () => {
  const viewer = useSelector(getViewerSelector)
  const fetchStages = useActionsRoutines(fetchStagesPromiseCreator)
  const reorderStage = useActionsRoutines(reorderStagePromiseCreator)

  const [isShowAddStage, setIsShowAddStage] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [currentStage, setCurrentStage] = useState<IStage | null>(null)

  const deleteStage = useActionsRoutines(deleteStagePromiseCreator)

  const { isLoading, stages } = useSelector(
    (state: RootState) => state.stages.fetchStages,
  )

  const stagesWithDraggableId = stages.map((stage: IStage) => ({
    ...stage,
    draggableId: `id-${stage.id}`,
  }))

  useEffect(() => {
    !stagesWithDraggableId.length &&
      fetchStages({ companyId: viewer.companyId })
  }, [])

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const reorderedStages = reorder(
      stagesWithDraggableId,
      result.source.index,
      result.destination.index,
    )

    const draggableStage = stagesWithDraggableId.find(
      ({ draggableId }) => draggableId === result.draggableId,
    )

    if (!draggableStage) return

    const requestedStage = {
      id: draggableStage.id,
      name: draggableStage.name,
      type: draggableStage.type,
      order: result.destination.index,
    }

    await reorderStage({ requestedStage, reorderedStages })

    snackbar({
      content: `${draggableStage?.name} stage reordered`,
      showIcon: true,
      type: 'info',
      closeText: 'OK',
    })
  }

  const handleOpenDeleteStageModal = async (stage: IStage) => {
    if (stage.id === currentStage?.id && isShowAddStage) {
      setIsShowAddStage(false)
      setCurrentStage(null)
    }
    if (!stage.hasCandidates) {
      const { id, name } = stage
      await deleteStage({ id })
      snackbar({
        content: `${name} deleted`,
        showIcon: true,
        type: 'info',
        closeText: 'OK',
      })
      return
    }

    setIsOpenDeleteModal(true)
    setCurrentStage(stage)
  }

  const handleUpdateStage = (stage: IStage) => {
    setIsShowAddStage(true)
    setCurrentStage({ ...stage })
  }

  const handleCloseDeleteStageModal = () => {
    setIsOpenDeleteModal(false)
    setCurrentStage(null)
    setIsShowAddStage(false)
  }

  const handleOpenIsAddStage = () => {
    setIsShowAddStage(true)
  }

  if (isLoading) return <Loader />

  return (
    <>
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <StagesList
                  stages={stagesWithDraggableId}
                  handleUpdateStage={handleUpdateStage}
                  handleOpenDeleteStageModal={handleOpenDeleteStageModal}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>

      {stagesWithDraggableId.length < MAX_STAGES_COUNT && !isShowAddStage && (
        <AddButton type="primary" onClick={handleOpenIsAddStage}>
          + Add new stage
        </AddButton>
      )}

      {isShowAddStage && (
        <AddEditStage
          currentStage={currentStage}
          isShowAddStage={isShowAddStage}
          setCurrentStage={setCurrentStage}
          setIsShowAddStage={setIsShowAddStage}
        />
      )}

      {currentStage && (
        <DeleteStageModal
          currentStage={currentStage}
          stages={stagesWithDraggableId}
          isOpenDeleteModal={isOpenDeleteModal}
          handleCloseDeleteStageModal={handleCloseDeleteStageModal}
        />
      )}
    </>
  )
}

export default Stages
