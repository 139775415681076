import React from 'react'

import Stages from './Stages/Stages'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import { ROUTES } from 'routes'

import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'

import { Header, InfoBlock } from './styles'

const EditJobsStages = () => {
  return (
    <>
      <Header>
        <BackButton title="Edit job stages" />
      </Header>

      <InfoBlock>
        <Alert
          message="Changing stages affects all jobs."
          type="warning"
          showIcon
        />
        <Button type="link">
          <a
            target="_blank"
            rel="noreferrer"
            href={`${ROUTES.JOBHERON_HELP_CENTER}/article/job-stages-how-to-edit-them`}
          >
            Learn more
          </a>
        </Button>
      </InfoBlock>

      <Stages />
    </>
  )
}

export default EditJobsStages
