import styled from 'styled-components'

import Tag from 'antd/lib/tag'

export const StatusTag = styled(Tag)`
  &.ant-tag {
    font-size: 11px;
    border: none;
    margin-right: 8px;
    border-radius: 6px;
  }
`
export const AutoRejectedStatusTag = styled(StatusTag)`
  color: #ff4d4f;
  background: #fff1f0;
`
