import React, { FC } from 'react'

import {
  TagItem,
  TagItemMaybe,
  TagItemRed,
  TagItemShortlisted,
  TagItemViewed,
} from './styles'

import { CandidateStatus } from 'types'

interface ActiveStatusItemProps {
  status: CandidateStatus
  title: string
  icon: React.ReactNode
}

const ActiveStatusItem: FC<ActiveStatusItemProps> = ({
  status,
  title,
  icon,
}) => {
  switch (status) {
    case CandidateStatus.Maybe:
      return (
        <TagItemMaybe>
          {icon} {title}
        </TagItemMaybe>
      )
    case CandidateStatus.New:
      return <TagItem color="blue">{icon} New</TagItem>
    case CandidateStatus.Rejected:
      return <TagItemRed>{icon} Rejected</TagItemRed>
    case CandidateStatus.Shortlisted:
      return (
        <TagItemShortlisted color="cyan">{icon} Shortlist</TagItemShortlisted>
      )
    case CandidateStatus.Viewed:
      return <TagItemViewed>{icon} Viewed</TagItemViewed>
    default:
      return null
  }
}

export default ActiveStatusItem
