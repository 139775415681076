import * as Yup from 'yup'

import {
  companyName,
  companyWebsite,
  email,
  firstName,
  lastName,
  password,
  phoneNumber,
} from './baseSchema'

const SignInSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  password,
  companyName: companyName.required('Please enter company name'),
  companyWebsite: companyWebsite.required('Please enter correct url'),
  phoneNumber,
})

export default SignInSchema
