import { createAction } from '@reduxjs/toolkit'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const showDeleteJobModal = createAction<{
  jobTitle?: string
  jobId?: string
  jobStatus?: string
}>('jobs/showDeleteJobModal')

export const hideDeleteJobModal = createAction('jobs/hideDeleteJobModal')

export const showExpireJobModal = createAction<{
  jobTitle?: string
  jobId?: string
}>('jobs/showExpireJobModal')

export const hideExpireJobModal = createAction('jobs/hideExpireJobModal')
export const clearJob = createAction('jobs/clearJob')

export const fetchJobPreview = createRoutine('jobs/fetchJobPreview')
export const fetchJobPreviewPromiseCreator = promisifyRoutine(fetchJobPreview)

export const updateJobForwardSettings = createRoutine(
  'jobs/updateJobForwardSettings',
)
export const updateJobForwardSettingsPromiseCreator = promisifyRoutine(
  updateJobForwardSettings,
)

export const fetchJob = createRoutine('jobs/fetchJob')
export const fetchJobPromiseCreator = promisifyRoutine(fetchJob)

export const fetchJobsCounters = createRoutine('jobs/fetchJobsCounters')
export const fetchJobsCounterPromiseCreators = promisifyRoutine(
  fetchJobsCounters,
)

export const createJob = createRoutine('jobs/createJob')
export const createJobPromiseCreator = promisifyRoutine(createJob)

export const updateJob = createRoutine('jobs/updateJob')
export const updateJobPromiseCreator = promisifyRoutine(updateJob)

export const approveJob = createRoutine('jobs/approveJob')
export const approveJobPromiseCreator = promisifyRoutine(approveJob)

export const publishJob = createRoutine('jobs/publishJob')
export const publishJobPromiseCreator = promisifyRoutine(publishJob)

export const publishJobWithOutApproval = createRoutine(
  'jobs/publishJobWithOutApproval',
)
export const publishJobWithOutApprovalPromiseCreator = promisifyRoutine(
  publishJobWithOutApproval,
)

export const deleteJob = createRoutine('jobs/deleteJob')
export const approveDeleteJObPromiseCreator = promisifyRoutine(deleteJob)

export const expireJob = createRoutine('jobs/expireJob')
export const expireJobPromiseCreator = promisifyRoutine(expireJob)

export const archiveJob = createRoutine('jobs/archiveJob')
export const archiveJobPromiseCreator = promisifyRoutine(archiveJob)

export const unArchiveJob = createRoutine('jobs/unArchiveJob')
export const unArchiveJobPromiseCreator = promisifyRoutine(unArchiveJob)

export const cloneJob = createRoutine('jobs/cloneJob')
export const cloneJobPromiseCreator = promisifyRoutine(cloneJob)

export const promoteJob = createRoutine('jobs/promoteJob')
export const promoteJobPromiseCreator = promisifyRoutine(promoteJob)

export const fetchRejectedCandidatesCount = createRoutine(
  'jobs/fetchRejectedCandidatesCount',
)
export const fetchRejectedCandidatesCountPromiseCreator = promisifyRoutine(
  fetchRejectedCandidatesCount,
)
