import styled from 'styled-components/macro'

import Button from 'antd/lib/button'

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ActionButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${props => props.color};
  margin-right: 15px;

  svg {
    margin-right: 5px;
  }
  &:focus,
  &:hover {
    color: ${props => props.color};
  }
`
