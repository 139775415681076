import styled from 'styled-components'

export const EmptyJobs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
export const EmptyJobsIcon = styled.div`
  margin-bottom: 40px;
`
export const EmptyJobsTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`
export const EmptyJobsSubTitle = styled.h3`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.gray7};
  text-align: center;
`
