import * as React from 'react'

function Notes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={154}
      height={182}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M133.124 70.944c0 12.519-4.213 24.043-11.199 33.35-3.769 4.982-8.537 9.297-13.858 12.738C99.418 122.679 89.107 126 78.02 126c-30.492 0-55.106-24.699-55.106-55.056 0-30.455 24.614-55.154 55.106-55.154 11.087 0 21.398 3.32 30.047 8.86 5.321 3.43 9.978 7.864 13.858 12.846 7.096 9.306 11.199 20.94 11.199 33.448z"
        fill="#F3F6FF"
      />
      <path
        opacity={0.5}
        d="M27.419 23.79c1.617-.517 2.502-2.262 1.976-3.883a3.07 3.07 0 00-3.88-1.969c-1.618.518-2.502 2.262-1.977 3.883a3.08 3.08 0 003.88 1.97zM34.04 13.785a1.937 1.937 0 001.243-2.442 1.94 1.94 0 00-2.443-1.25 1.943 1.943 0 00-1.245 2.443 1.933 1.933 0 002.445 1.25z"
        fill="#D7E1EA"
      />
      <path
        d="M11.64 78.373c1.07 0 1.938-.877 1.938-1.947 0-1.069-.868-1.935-1.939-1.935-1.071 0-1.939.866-1.939 1.935 0 1.07.868 1.947 1.94 1.947z"
        fill="url(#prefix__paint0_linear_5846:168635)"
      />
      <path
        d="M138.206 45.554a4.333 4.333 0 00.276-6.123 4.34 4.34 0 00-6.123-.28 4.333 4.333 0 00-.276 6.121 4.319 4.319 0 006.123.282z"
        fill="#EAEEF9"
      />
      <path
        d="M135.388 25.422a1.83 1.83 0 000-3.657 1.828 1.828 0 000 3.657z"
        fill="#FFC069"
      />
      <g filter="url(#prefix__filter0_d_5846:168635)">
        <path
          d="M107.595 20.391H46.389a6.123 6.123 0 00-6.122 6.123v88.525a6.123 6.123 0 006.122 6.123h61.206a6.123 6.123 0 006.123-6.123V26.514a6.123 6.123 0 00-6.123-6.123z"
          fill="#000"
        />
      </g>
      <path
        d="M107.595 20.391H46.389a6.123 6.123 0 00-6.122 6.123v88.525a6.123 6.123 0 006.122 6.123h61.206a6.123 6.123 0 006.123-6.123V26.514a6.123 6.123 0 00-6.123-6.123z"
        fill="#fff"
      />
      <path
        d="M58.524 38.777h-6.418c-.385 0-.771-.409-.771-.817v-.816c0-.409.386-.816.771-.816h6.418c.384 0 .77.407.77.816v.816c0 .408-.386.817-.77.817zM80.4 38.777H64.334c-.376 0-.753-.409-.753-.817v-.816c0-.409.377-.816.754-.816H80.4c.377 0 .755.407.755.816v.816c0 .408-.378.817-.755.817zM58.524 51.023h-6.418c-.385 0-.771-.409-.771-.818v-.816c0-.408.386-.816.771-.816h6.418c.384 0 .77.408.77.816v.816c0 .409-.386.818-.77.818zM74.4 51.023H64.334c-.376 0-.753-.409-.753-.818v-.816c0-.408.377-.816.754-.816H74.4c.377 0 .755.408.755.816v.816c0 .409-.378.818-.755.818zM7.251 114.682c2.799.888 11.429-.566 16.95-4.268 4.353 5.562 5.986 7.016 7.541 7.177-2.41 2.493-10.418 6.525-14.928 1.284-5.287 3.466-9.563-4.193-9.563-4.193zM127.258 103.964c2.964.155 10.998-3.426 15.366-8.245 5.615 4.118 7.487 5.056 9.125 4.819-1.794 2.952-7.254 9.411-14.976 9.876-5.615-3.262-9.515-6.45-9.515-6.45z"
        fill="#EAEEF9"
      />
      <path
        d="M72.022 72.667c-.228-1.598-.342-2.746-1.483-3.083-1.825-.462-2.394 1.935-4.105 3.759-.57.573-1.37 1.035-2.054 1.485-.227.113-.684.349-.798-.112-.456-1.373 0-.687 1.94-2.848.912-1.035 1.481-2.284 1.254-3.77-.115-.912-.684-1.71-1.597-2.06-.913-.337-1.825-.224-2.51.575-1.596 1.947-1.14 3.083-1.254 5.93a.622.622 0 01-.343.575 13.507 13.507 0 01-5.245 1.71v-.112c1.026-1.373 3.079-3.77 3.535-7.878.228-1.598.228-3.073-.57-4.558-1.483-2.746-5.475-2.398-7.3.911-1.825 3.534-1.482 9.015 1.027 12.099-.57.45-1.026.911-1.597 1.249-.342.236-.456.799-.228 1.148.228.45.912.562 1.368.225 1.826-1.486 1.71-1.373 1.94-1.486.341-.112.684 0 1.026 0 1.255 0 2.053-.112 2.965-.225.457-.112 3.536-1.26 3.65-1.26 0 0 0 .123.115.236.342 1.25 1.026 1.71 2.395 1.598 1.026-.124 1.939-.574 2.737-1.26.912-.912 2.395-2.735 3.194-3.77.227.798.227 1.485.456 2.171.455 1.261 1.71 1.486 2.51.462.91-1.035 1.253-2.397 2.28-2.971a.862.862 0 00.57-.8c0-.686-.684-1.023-1.255-.798-1.254.574-1.939 1.71-2.623 2.858zm-18.02 1.598c-.913-1.147-1.255-2.397-1.483-3.77-.457-2.06-.228-4.108.57-6.043.457-1.036 1.027-1.835 2.167-2.06 1.14-.225 1.94 0 2.167 1.137.912 3.883-.685 7.653-3.422 10.736zm9.58-5.942c.684-1.362 1.482-.112 1.369.462-.229.686-.57 1.249-.913 1.823-.227.337-.456.574-.798.911-.114-1.136-.114-2.16.342-3.196z"
        fill="url(#prefix__paint1_linear_5846:168635)"
      />
      <path
        d="M116.732 32.04c-1.597 2.284-3.194 4.445-4.563 6.842-1.939 3.196-2.965 2.285-7.869 3.77l4.334 1.024c.114 0 .114 0 .228.113.228.225.342.574.114.799-.684 1.024-1.368 2.06-2.052 3.084a34.36 34.36 0 01-21.1 13.122c-1.141.225-2.168 1.024-2.624 2.06-1.027 2.048-1.94 4.107-2.966 6.156-.342.686-1.026.911-1.71.574-.685-.35-.913-1.025-.571-1.711.57-1.26 1.141-2.51 1.71-3.658.458-1.024.344-2.048 0-3.083-.569-1.823-1.026-3.647-1.14-5.582 0-1.835.343-3.77.799-5.594.799-2.97 1.939-5.818 3.65-8.328l.114-.124c.114 0 .342 0 .342.237.114.45.228.799.456 1.361.228-1.136.57-2.273.913-3.42.113-.451.456-.913.798-1.374 1.825-1.823 3.878-3.421 6.16-4.67h.113c.115 0 .228.112.228.225v2.172c.913-1.373 1.94-2.51 3.08-3.77.341-.338.798-.687 1.254-.8 6.387-2.284 13.344-2.622 19.846-.799 1.14.226 1.14.462.456 1.373z"
        fill="url(#prefix__paint2_linear_5846:168635)"
      />
      <path
        opacity={0.5}
        d="M104.869 37.171c-12.887 4.795-19.73 15.407-25.206 26.932.457-1.024.342-2.048 0-3.084-.227-.686-.342-1.25-.57-1.936.114 0 .114-.112.228-.112 5.589-10.613 12.546-19.74 24.637-24.31.684-.224 1.368.113 1.596.8.228.799-.114 1.485-.685 1.71z"
        fill="url(#prefix__paint3_linear_5846:168635)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_5846:168635"
          x1={9.74}
          y1={76.466}
          x2={13.583}
          y2={76.466}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6EB0FF" />
          <stop offset={1} stopColor="#2688FC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_5846:168635"
          x1={56.684}
          y1={62.181}
          x2={64.294}
          y2={79.388}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6EDF5" />
          <stop offset={1} stopColor="#DDE3EA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_5846:168635"
          x1={87.575}
          y1={33.462}
          x2={109.033}
          y2={66.297}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6EDF5" />
          <stop offset={1} stopColor="#DDE3EA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_5846:168635"
          x1={100.301}
          y1={34.592}
          x2={82.867}
          y2={53.034}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F4F8" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <filter
          id="prefix__filter0_d_5846:168635"
          x={0.267}
          y={0.391}
          width={153.451}
          height={180.771}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={20} />
          <feGaussianBlur stdDeviation={20} />
          <feColorMatrix values="0 0 0 0 0.396078 0 0 0 0 0.478431 0 0 0 0 0.576471 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5846:168635"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_5846:168635"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Notes
