import styled from 'styled-components'

import Button from 'antd/lib/button'
import Collapse from 'antd/lib/collapse'
import Drawer from 'antd/lib/drawer'

export const FilterButton = styled(Button)`
  position: relative;
  &.ant-btn {
    min-width: 40px;
  }
  &:hover svg {
    stroke: ${({ theme }) => theme.colors.blue6};
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`
export const MobileDrawer = styled(Drawer)`
  .ant-drawer-content .ant-drawer-body {
    padding: 0;
    background-color: #fafafa;
  }
  .ant-drawer-mask {
    background-color: transparent;
  }

  &.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: unset;
  }
`
export const Header = styled.div`
  padding: 12px 8px 16px;
  background: #fff;
  .ant-btn.ant-btn-link {
    display: none;
  }
  .ant-tag {
    padding: 5px 10px;
  }
`

export const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`

export const CollapseItem = styled(Collapse)`
  padding: 0;
  border-radius: unset;
  border: unset;
  margin-bottom: 70px;
  .ant-collapse-item {
    border-bottom: unset;
  }
  .ant-collapse-content {
    border-top: unset;
    background-color: transparent;
  }
`
export const CollapsePanel = styled(Collapse.Panel)`
  .ant-checkbox-wrapper {
    font-weight: 400;
    &.ant-checkbox-wrapper-checked {
      font-weight: 600;
    }
  }
  .ant-collapse-header {
    font-weight: 600;
  }
  .ant-divider {
    margin-bottom: 0;
  }
`
export const ButtonGroup = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 16px 18px;
  width: 100%;
  left: 0;
  right: 0;
  gap: 18px;
  .ant-btn {
    flex-grow: 1;
  }
`

export const FilterActiveDot = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  background: ${({ theme }) => theme.colors.orange6};
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
`
