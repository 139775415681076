import { createReducer } from '@reduxjs/toolkit'

import {
  approveUser,
  changeUserRole,
  createUser,
  deleteUser,
  fetchCompanyUsers,
  fetchUser,
  fetchUsersCounter,
  moveUserToAnotherCompany,
  startRefreshUserAlgoliaCache,
  stopRefreshUserAlgoliaCache,
  updateUser,
  updateUserCompany,
} from './actions'

import { IUserState } from 'types'

export const USERS_STATE: IUserState = {
  fetchUser: { isLoading: false, user: null },
  updateUser: { isLoading: false },
  deleteUser: { isLoading: false },
  updateUserCompany: { isLoading: false },
  moveUserToAnotherCompany: { isLoading: false },
  userData: { hits: [] },
  fetchCompanyUsers: { hits: [], isLoading: false },
  approveUser: { isLoading: false },
  changeUserRole: { isLoading: false },
  userCounters: { isLoading: false, data: null },
  refresh: false,
  createUser: { isLoading: false },
}

const usersReducer = createReducer(USERS_STATE, {
  [fetchCompanyUsers.TRIGGER]: state => {
    state.fetchCompanyUsers.isLoading = true
  },
  [fetchCompanyUsers.SUCCESS]: (state, { payload }) => {
    state.fetchCompanyUsers.isLoading = false
    state.fetchCompanyUsers.hits = payload
  },
  [fetchCompanyUsers.FAILURE]: state => {
    state.fetchCompanyUsers.isLoading = false
  },

  [fetchUser.TRIGGER]: state => {
    state.fetchUser.isLoading = true
  },
  [fetchUser.SUCCESS]: (state, { payload }) => {
    state.fetchUser.isLoading = false
    state.fetchUser.user = payload.user
  },
  [fetchUser.FAILURE]: state => {
    state.fetchUser.isLoading = false
  },

  [updateUser.TRIGGER]: state => {
    state.updateUser.isLoading = true
  },
  [updateUser.SUCCESS]: (state, { payload }) => {
    state.updateUser.isLoading = false
    state.fetchUser.user = payload.user
  },
  [updateUser.FAILURE]: state => {
    state.updateUser.isLoading = false
  },

  [deleteUser.TRIGGER]: state => {
    state.deleteUser.isLoading = true
  },
  [deleteUser.SUCCESS]: state => {
    state.deleteUser.isLoading = false
  },
  [deleteUser.FAILURE]: state => {
    state.deleteUser.isLoading = false
  },

  [updateUserCompany.TRIGGER]: state => {
    state.updateUserCompany.isLoading = true
  },
  [updateUserCompany.SUCCESS]: (state, { payload }) => {
    state.updateUserCompany.isLoading = false
    if (state.fetchUser.user) {
      state.fetchUser.user.company = {
        ...state.fetchUser.user.company,
        ...payload.userCompany,
      }
    }
  },
  [updateUserCompany.FAILURE]: state => {
    state.updateUserCompany.isLoading = false
  },

  [moveUserToAnotherCompany.TRIGGER]: state => {
    state.moveUserToAnotherCompany.isLoading = true
  },
  [moveUserToAnotherCompany.SUCCESS]: (state, { payload }) => {
    state.moveUserToAnotherCompany.isLoading = false
    if (state.fetchUser.user) {
      state.fetchUser.user.company = {
        ...state.fetchUser.user.company,
        ...payload.user.company,
      }
    }
  },
  [moveUserToAnotherCompany.FAILURE]: state => {
    state.moveUserToAnotherCompany.isLoading = false
  },

  [createUser.TRIGGER]: state => {
    state.createUser.isLoading = true
  },
  [createUser.SUCCESS]: state => {
    state.createUser.isLoading = false
  },
  [createUser.FAILURE]: state => {
    state.createUser.isLoading = false
  },

  [approveUser.TRIGGER]: state => {
    state.approveUser.isLoading = true
  },
  [approveUser.FAILURE]: state => {
    state.approveUser.isLoading = false
  },
  [approveUser.SUCCESS]: state => {
    state.approveUser.isLoading = false
  },
  [changeUserRole.TRIGGER]: state => {
    state.changeUserRole.isLoading = true
  },
  [changeUserRole.FAILURE]: state => {
    state.changeUserRole.isLoading = false
  },
  [changeUserRole.SUCCESS]: state => {
    state.changeUserRole.isLoading = false
  },
  [fetchUsersCounter.TRIGGER]: state => {
    state.userCounters.isLoading = true
  },
  [fetchUsersCounter.SUCCESS]: (state, { payload }) => {
    const { counters } = payload
    state.userCounters.data = counters
    state.userCounters.isLoading = false
  },
  [fetchUsersCounter.FAILURE]: state => {
    state.userCounters.data = null
    state.userCounters.isLoading = false
  },
  [String(startRefreshUserAlgoliaCache)]: state => {
    state.refresh = true
  },
  [String(stopRefreshUserAlgoliaCache)]: state => {
    state.refresh = false
  },
})

export default usersReducer
