import styled from 'styled-components/macro'

import { Tag } from 'antd'

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;

  .ant-tag {
    display: inline-flex;
    align-items: center;
    margin: 10px 5px;

    .ant-btn {
      padding: 0;
      margin: 0;
      height: auto;
      min-height: auto;
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 5px;
    }
  }
`

export const AddNewTag = styled(Tag)`
  border: none;
`

export const InputContainerActions = styled.div`
  display: flex;
  align-items: center;
`

export const InputContainer = styled.div`
  max-width: 300px;
  min-width: 200px;
  margin: 10px 5px;

  .ant-input-group-addon {
    padding: 0;
  }

  .ant-input {
    padding: 3px 7px;
  }

  .ant-btn {
    padding: 0 5px;
    margin: 0;
    height: auto;
    width: auto;
    min-height: auto;
    display: inline-flex;
    align-items: center;
  }
`
