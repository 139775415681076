import React, { FC } from 'react'

import { getFullName } from 'helpers/candidateHelpers'

import WarningTooltip from 'app/components/UI/WarningTooltip/WarningTooltip'

import { Container, Name } from './styles'

import { IAlgoliaCandidate, ICandidate } from 'types'

const FullName: FC<IAlgoliaCandidate> = ({
  firstName = '',
  lastName = '',
  email,
}) => {
  const name = getFullName({ firstName, lastName } as ICandidate)
  return (
    <Container>
      <Name>{name}</Name>
      {!email && (
        <WarningTooltip title="No email details" placement="bottomLeft" />
      )}
    </Container>
  )
}

export default FullName
