import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { approveJobPromiseCreator } from 'store/jobs'
import { isAdminSelector } from 'store/viewer'

import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { IJob, JobStatus } from 'types'

interface AddCreditsButtonProps {
  job: IJob
}

const AddCreditsButton: FC<AddCreditsButtonProps> = ({ job }) => {
  const history = useHistory()
  const isAdmin = useSelector(isAdminSelector)
  const approveJob = useActionsRoutines(approveJobPromiseCreator)

  const handleChangeStatusAndGoToCheckout = async () => {
    if (job.status !== JobStatus.NoCredits) {
      await approveJob({ advertId: job.id })
    }

    if (isAdmin) {
      return history.push(`${ROUTES.USERS}/${job.user.id}`)
    }

    history.push(
      `${ROUTES.CHECKOUT}?redirectURL=${window.location.href}&advertId=${job.id}`,
    )
  }

  if (isAdmin) {
    return (
      <Button type="link" onClick={handleChangeStatusAndGoToCheckout}>
        Assign credits
      </Button>
    )
  }

  return (
    <Button type="link" onClick={handleChangeStatusAndGoToCheckout}>
      Buy now
    </Button>
  )
}

export default AddCreditsButton
