import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { searchStateToUrl, urlToSearchState } from 'helpers'

const DEBOUNCE_TIME = 700

const useSearchToQuery = ({
  setItem,
  stopSearch,
}: {
  setItem?: string
  stopSearch?: boolean
}): any => {
  const history = useHistory()
  const location = useLocation()
  const [searchState, setSearchState] = useState(urlToSearchState(location))

  const setStateId = useRef<number>()
  const onSearchStateChange = (nextSearchState: any) => {
    clearTimeout(setStateId.current)

    if (stopSearch) return

    setStateId.current = window.setTimeout(() => {
      history.replace(
        searchStateToUrl(nextSearchState, location),
        nextSearchState,
      )
      if (setItem) {
        sessionStorage.setItem(setItem, window.location.search)
      }
    }, DEBOUNCE_TIME)
    setSearchState(nextSearchState)
  }

  useEffect(() => {
    const nextSearchState = urlToSearchState(location)

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState)
    }
  }, [location])

  return { onSearchStateChange, searchState, setSearchState }
}

export default useSearchToQuery
