import { all, fork } from 'redux-saga/effects'

import * as W from './watchers'

export function* watchJobsActions() {
  yield all([
    fork(W.watchFetchJobPreview),
    fork(W.watchFetchJob),
    fork(W.watchFetchJobsCounters),
    fork(W.watchCreateJob),
    fork(W.watchUpdateJob),
    fork(W.watchApproveJob),
    fork(W.watchPublishJob),
    fork(W.watchDeleteJob),
    fork(W.watchExpireJob),
    fork(W.watchArchiveJob),
    fork(W.watchUnArchiveJob),
    fork(W.watchCloneJob),
    fork(W.watchPromoteJob),
    fork(W.watchPublishJobWithOutApproval),
    fork(W.watchUpdateJobForwardSettings),
    fork(W.watchFetchRejectedCandidatesCount),
  ])
}
