import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Route, Switch, useLocation } from 'react-router-dom'

import {
  getViewerSelector,
  isExternalRecruiterOrTeamMemberSelector,
} from 'store/viewer'

import {
  getViewerSettingsMenu,
  getViewerSettingsRoutes,
} from 'helpers/viewerHelpers'

import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Menu from 'antd/lib/menu'

import { BuyCreditsButton, Container, MenuContainer, Wrapper } from './styles'

const Settings = () => {
  const location = useLocation()
  const screens = useBreakpoint()

  const viewer = useSelector(getViewerSelector)
  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )
  const viewerSettingsMenu = getViewerSettingsMenu({
    status: viewer.status,
    role: viewer.role,
  })

  const viewerSettingsRoutes = getViewerSettingsRoutes({
    status: viewer.status,
    role: viewer.role,
  })

  return (
    <>
      <Container>
        {screens.md && (
          <>
            <MenuContainer>
              <Menu
                style={{ width: 225, minWidth: 225 }}
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                {viewerSettingsMenu.map(({ group, children }) => (
                  <div key={group}>
                    {!!children.length && (
                      <Menu.ItemGroup key={group} title={group}>
                        {children.map(({ title, link }) => (
                          <Menu.Item key={link}>
                            <Link to={link}>{title}</Link>
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                    )}
                  </div>
                ))}
                {!isExternalRecruiterOrTeamMember && (
                  <BuyCreditsButton>
                    <Menu.Item key="buy credits">
                      <Button type="primary">
                        <Link
                          to={`${ROUTES.CHECKOUT}?redirectURL=${window.location.href}`}
                        >
                          Buy credits
                        </Link>
                      </Button>
                    </Menu.Item>
                  </BuyCreditsButton>
                )}
              </Menu>
            </MenuContainer>
          </>
        )}

        <Switch>
          {viewerSettingsRoutes.map(({ path, component: Component }) => (
            <Route exact path={path} key={path}>
              <Wrapper>
                <Component />
              </Wrapper>
            </Route>
          ))}
        </Switch>
      </Container>
    </>
  )
}

export default Settings
