import React, { FC } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { deleteStagePromiseCreator } from 'store/stages'

import { renderStageIcon } from 'helpers/stagesHelpers'

import snackbar from '../../Snackbar/Snackbar'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import useActionsRoutines from 'hooks/useActionsRoutines'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Space from 'antd/lib/space'

import { IStage, RootState } from 'types'

const { Option } = Select

interface IDeleteStageModal {
  currentStage: IStage
  stages: IStage[]
  isOpenDeleteModal: boolean
  handleCloseDeleteStageModal: () => void
}

const confirmWord = 'DELETE'

const DeleteStageModal: FC<IDeleteStageModal> = ({
  currentStage,
  stages,
  isOpenDeleteModal,
  handleCloseDeleteStageModal,
}) => {
  const deleteStage = useActionsRoutines(deleteStagePromiseCreator)
  const { isLoading } = useSelector(
    (state: RootState) => state.stages.deleteStage,
  )

  const methods = useForm()
  const { name, id } = currentStage
  const { control, watch } = methods

  const currentConfirmWord = watch('confirm')

  const handleOnCloseModal = () => {
    methods.reset()
    handleCloseDeleteStageModal()
  }

  const handleDeleteStage = async ({ stageId }: { stageId: number }) => {
    await deleteStage({ id, stageId })
    handleOnCloseModal()
    snackbar({
      content: `${name} deleted`,
      showIcon: true,
      type: 'info',
      closeText: 'OK',
    })
  }

  return (
    <>
      <Modal
        width={600}
        footer={null}
        title={`Delete ${name} stage`}
        visible={isOpenDeleteModal}
        onCancel={handleOnCloseModal}
      >
        <FormProvider {...methods}>
          <p>
            Technical Assessment stage has some current or past candidate
            activity.
            <br />
            <strong>
              Please select and move candidates into a different stage before
              deleting this one.
            </strong>
          </p>

          <Form layout="vertical">
            {currentStage.hasCandidates && (
              <Form.Item label="Stage type">
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select stage to move candidates to"
                    >
                      {stages.map(({ name, id, type }) => (
                        <Option
                          key={id}
                          value={id}
                          disabled={id === currentStage.id}
                        >
                          <Space>
                            <div>{renderStageIcon(type)}</div>
                            <div>{name}</div>
                          </Space>
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name="stageId"
                />
              </Form.Item>
            )}

            <p>To confirm deletion please type DELETE below</p>

            <FormItem
              name="confirm"
              component={Input}
              componentProps={{ placeholder: confirmWord }}
            />
            <Space>
              <Button onClick={handleOnCloseModal}>Cancel</Button>
              <Button
                danger
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={currentConfirmWord !== confirmWord}
                onClick={methods.handleSubmit(handleDeleteStage)}
              >
                Delete stage
              </Button>
            </Space>
          </Form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default DeleteStageModal
