import React, { FC, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import Input from 'antd/lib/input'

const { Search } = Input

interface ICustomSearchBox {
  refine: (value: string) => void
  placeholder?: string
  currentRefinement?: string
}

export const DEBOUNCE_TIME = 400
export const defaultPlaceholder = 'Search for anything...'

const CustomSearchBox: FC<ICustomSearchBox> = ({
  refine,
  currentRefinement,
  placeholder = defaultPlaceholder,
}) => {
  const setStateId = React.useRef<number>()
  const [search, setSearch] = useState(currentRefinement)

  const handleOnChangeDebounced = (event: any) => {
    const value = event.currentTarget.value
    clearTimeout(setStateId.current)
    setStateId.current = window.setTimeout(() => refine(value), DEBOUNCE_TIME)
    setSearch(value)
  }

  return (
    <Search
      allowClear
      value={search}
      placeholder={placeholder}
      onChange={handleOnChangeDebounced}
    />
  )
}

const AlgoliaSearch = connectSearchBox(CustomSearchBox)

export default AlgoliaSearch
