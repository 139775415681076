import { createReducer } from '@reduxjs/toolkit'

import { approveCompany, fetchCompanies } from './actions'

import { ICompaniesToApproveState } from 'types'

export const COMPANY_TO_APPROVE_STATE: ICompaniesToApproveState = {
  fetchCompanies: { isLoading: false },
  approveCompany: { isLoading: false },
  companies: { hits: [], total: 0 },
}

const companiesToApproveReducer = createReducer(COMPANY_TO_APPROVE_STATE, {
  [fetchCompanies.TRIGGER]: state => {
    state.fetchCompanies.isLoading = true
  },
  [fetchCompanies.FAILURE]: state => {
    state.fetchCompanies.isLoading = false
  },
  [fetchCompanies.SUCCESS]: (state, { payload }) => {
    state.fetchCompanies.isLoading = false
    state.companies = payload.companies
  },

  [approveCompany.TRIGGER]: state => {
    state.approveCompany.isLoading = true
  },
  [approveCompany.FAILURE]: state => {
    state.approveCompany.isLoading = false
  },
  [approveCompany.SUCCESS]: state => {
    state.approveCompany.isLoading = false
  },
})

export default companiesToApproveReducer
