import algoliasearch from 'algoliasearch/lite'

import { CandidateStatus, IApiFetchCandidateAssociatedMembers } from 'types'

type AlgoliaFilters = {
  query: string
  options: any
}

type AlgoliaKeys = {
  users: {
    token: string
    listOfUsers: string
    companiesToApprove: string
    externalRecruiter: string
  }
  teamMembers: {
    token: string
    indexName: string
  }
  adverts: {
    token: string
    listOfAdverts: string
  }
  companies: {
    token: string
    indexName: string
  }
  candidates: {
    token: string
    indexName: string
  }
}

class Algolia {
  private keys: AlgoliaKeys

  constructor() {
    this.keys = {
      users: {
        token: '',
        listOfUsers: '',
        companiesToApprove: '',
        externalRecruiter: '',
      },
      teamMembers: {
        token: '',
        indexName: '',
      },
      adverts: {
        token: '',
        listOfAdverts: '',
      },
      companies: {
        token: '',
        indexName: '',
      },
      candidates: {
        token: '',
        indexName: '',
      },
    }
  }

  init({ algoliaKeys }: { algoliaKeys: AlgoliaKeys }) {
    this.keys = algoliaKeys
  }

  async getUsers({ query, options }: AlgoliaFilters) {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.users.token,
    )
    const index = client.initIndex(this.keys.users.listOfUsers)
    return index.search(query, { ...options })
  }

  async getUsersFacets() {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.users.token,
    )
    const index = client.initIndex(this.keys.users.listOfUsers)
    return index.search('', { facets: ['status'] })
  }

  async getCompaniesToApprove({ query, options }: AlgoliaFilters) {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.users.token,
    )
    const index = client.initIndex(this.keys.users.companiesToApprove)
    return index.search(query, { ...options })
  }

  async getJobs({ query, options }: AlgoliaFilters) {
    const jobsClient = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.adverts.token,
    )
    const index = jobsClient.initIndex(this.keys.adverts.listOfAdverts)
    return index.search(query, { ...options })
  }

  async getCompanies({ query, options }: AlgoliaFilters) {
    const companiesClient = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.companies.token,
    )
    const index = companiesClient.initIndex(this.keys.companies.indexName)

    return index.search(query, { ...options })
  }

  async getJobRejectedCandidatesCount({ advertId }: { advertId: string }) {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.candidates.token,
    )
    const index = client.initIndex(this.keys.candidates.indexName)
    return index.search('', {
      hitsPerPage: 500,
      filters: `advertId: ${advertId} AND (status: ${CandidateStatus.Rejected} OR status:${CandidateStatus.AutoRejected})`,
    })
  }

  async getJobsFacets({
    jobsIndexName,
    jobStatusFilter,
  }: {
    jobsIndexName: string
    jobStatusFilter: string
  }) {
    const jobsClient = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.adverts.token,
    )
    const index = jobsClient.initIndex(jobsIndexName)

    return index.search('', { facets: [jobStatusFilter] })
  }

  async getExternalRecruiterFacets({ filters }: { filters: string }) {
    const jobsClient = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.users.token,
    )
    const index = jobsClient.initIndex(this.keys.users.externalRecruiter)

    return index.search('', { filters, facets: [] })
  }

  async getCompanyUsers({
    filters,
    hitsPerPage,
  }: {
    filters: string
    hitsPerPage: number
  }) {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.users.token,
    )

    const index = client.initIndex(this.keys.users.listOfUsers)

    return index.search('', { filters, hitsPerPage })
  }

  async getCompanyUsersFacets({ filters }: { filters: string }) {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      this.keys.users.token,
    )

    const index = client.initIndex(this.keys.users.listOfUsers)

    return index.search('', { filters })
  }
  async getCandidateAssociatedMembers({
    options,
    isExternalRecruiter,
  }: {
    options: IApiFetchCandidateAssociatedMembers
    isExternalRecruiter: boolean
  }) {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID!,
      isExternalRecruiter ? this.keys.teamMembers.token : this.keys.users.token,
    )
    const index = client.initIndex(
      isExternalRecruiter
        ? this.keys.teamMembers.indexName
        : this.keys.users.listOfUsers,
    )

    return index.search('', { ...options })
  }
}

const algolia = new Algolia()

export default algolia
