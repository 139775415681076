import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { approveDeleteJObPromiseCreator, hideDeleteJobModal } from 'store/jobs'

import ArchiveJobModal from '../ArchiveJobModal/ArchiveJobModal'
import snackbar from 'app/components/Snackbar/Snackbar'
import Warning from 'assets/icons/Warning'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'

import { InfoCircleOutlined } from '@ant-design/icons'

import {
  BoldText,
  ButtonsRightSide,
  ButtonsWrapper,
  InputElement,
  ModalItem,
  Paragraph,
  Title,
} from './styles'

import { ArchiveJobType, JobStatus, RootState } from 'types'

const deleteText = 'DELETE'

const DeleteJob = () => {
  const [input, setInput] = useState<string>('')
  const dispatch = useDispatch()
  const deleteJob = useActionsRoutines(approveDeleteJObPromiseCreator)
  const history = useHistory()
  const location = useLocation()

  const handleCloseModal = () => {
    setInput('')
    dispatch(hideDeleteJobModal())
  }

  const {
    jobTitle,
    jobId,
    jobStatus,
    isOpenDeleteJobModal,
    isLoading: isDeleteJobLoading,
  } = useSelector((state: RootState) => state.jobs.deleteJob)

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value)
  }

  const handleDeleteJob = () => {
    deleteJob({ jobId })
      .then(() => {
        handleCloseModal()
        snackbar({
          content: 'Job has been deleted.',
          showIcon: true,
          type: 'info',
          icon: InfoCircleOutlined,
        })

        if (location.pathname !== ROUTES.JOBS) {
          history.push(ROUTES.JOBS)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const buttonDisabled = input.trim() !== deleteText

  const jobLive = jobStatus === JobStatus.Live
  const jobArchived = jobStatus === JobStatus.Archived
  const jobLiveOrArchived = jobLive || jobArchived

  return (
    <ModalItem
      visible={isOpenDeleteJobModal}
      title={
        <Title>
          <Warning size={18} color="#FF7875" />
          Delete {jobTitle} job
        </Title>
      }
      onCancel={handleCloseModal}
      width={550}
      footer={
        <ButtonsWrapper>
          {!jobLiveOrArchived && (
            <>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button
                type="primary"
                danger
                htmlType="submit"
                onClick={handleDeleteJob}
                loading={isDeleteJobLoading}
              >
                Delete
              </Button>
            </>
          )}
          {jobLiveOrArchived && (
            <>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <ButtonsRightSide>
                {jobLive && (
                  <span onClick={handleCloseModal}>
                    <ArchiveJobModal
                      type={ArchiveJobType.Archive}
                      title={jobTitle!}
                      buttonTitle="Archive instead"
                      jobId={+jobId!}
                      buttonType="default"
                    />
                  </span>
                )}

                <Button
                  type="primary"
                  danger
                  htmlType="submit"
                  disabled={buttonDisabled}
                  onClick={handleDeleteJob}
                  loading={isDeleteJobLoading}
                >
                  Delete
                </Button>
              </ButtonsRightSide>
            </>
          )}
        </ButtonsWrapper>
      }
    >
      {jobLiveOrArchived && (
        <>
          <Alert
            message={
              <ul>
                <li> Deleting your job cannot be undone </li>
                <li>
                  Your job will be expired on any job boards it is live on
                </li>
                <li>
                  Any candidates in your job and activities will be deleted
                </li>
                <li>You will not be able to recover the job data</li>
              </ul>
            }
            type="error"
          />
          <Paragraph>
            {jobLive && (
              <>
                Would you like to Archive your job instead? <br />
              </>
            )}
            To confirm deletion please type DELETE below
          </Paragraph>

          <InputElement
            placeholder="DELETE"
            value={input}
            onChange={handleChangeInput}
          />
        </>
      )}
      {!jobLiveOrArchived && (
        <>
          <Paragraph>
            Are you sure you want to delete the
            <BoldText> {jobTitle} </BoldText>job? <br />
            This action cannot be undone.{' '}
          </Paragraph>
        </>
      )}
    </ModalItem>
  )
}

export default DeleteJob
