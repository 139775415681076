import * as Yup from 'yup'

import {
  companyName,
  companyWebsite,
  email,
  firstName,
  lastName,
  password,
  phoneNumber,
} from './baseSchema'

import { CompanyExistStatus } from 'types'

const createUserSchema = Yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  verifyPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
  role: Yup.string().required('Please select user role below.'),
  companyStatus: Yup.string(),
  companyName: Yup.mixed()
    .nullable()
    .when('companyStatus', {
      is: (companyStatus: CompanyExistStatus) =>
        companyStatus === CompanyExistStatus.New,
      then: companyName.required('Please enter company name'),

      otherwise: Yup.mixed().notRequired(),
    }),
  companyWebsite: Yup.mixed()
    .nullable()
    .when('companyStatus', {
      is: (companyStatus: CompanyExistStatus) =>
        companyStatus === CompanyExistStatus.New,
      then: companyWebsite.required('Please enter correct url'),
      otherwise: Yup.mixed().notRequired(),
    }),
  companyId: Yup.mixed()
    .nullable()
    .when('companyStatus', {
      is: (companyStatus: CompanyExistStatus) =>
        companyStatus === CompanyExistStatus.Existing,
      then: Yup.mixed().required('Please select company below.'),
      otherwise: Yup.mixed().notRequired(),
    }),
})

export default createUserSchema
