import styled from 'styled-components'

export const ActionsSideBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  min-height: 40px;
  .ant-btn {
    display: flex;
    align-items: center;
    span {
      display: flex;
    }
  }
`
