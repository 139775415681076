import styled from 'styled-components'

import Button from 'antd/lib/button'

export const ButtonItem = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({
    $isViewerHasBalance,
  }: {
    $isViewerHasBalance?: boolean
  }) => ($isViewerHasBalance ? '32px' : '16px')};
  color: ${({ theme }) => theme.colors.blue6};
  border-color: ${({ theme }) => theme.colors.blue6};
  svg {
    margin-left: 8px;
  }
`

export const CreditBlock = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 32px;
  svg {
    position: absolute;
  }
`
export const CreditBlockText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  z-index: 2;
`
