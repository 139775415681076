import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { updateUserCompanyPromiseCreator } from 'store/users'

import { setFormErrors } from 'helpers/apiHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import FormItem from 'app/components/Forms/FormItem/FormItem'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { updateUserCompanySchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'

import { WarningOutlined } from '@ant-design/icons'

import { Footer, InfoAlert } from './styles'

import { IError, IUpdateUserCompanyForm, IUser, RootState } from 'types'

interface IEditCompanyDetails {
  user: IUser
}

const EditCompanyDetails: FC<IEditCompanyDetails> = ({ user }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const updateUserCompany = useActionsRoutines(updateUserCompanyPromiseCreator)
  const { isLoading } = useSelector(
    (state: RootState) => state.users.updateUserCompany,
  )
  const { company } = user

  const methods = useForm({
    resolver: yupResolver(updateUserCompanySchema),
    defaultValues: {
      name: company.name,
      website: company.website[0],
    },
  })

  const handleCloseModal = () => {
    setIsOpenModal(false)
    methods.reset()
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleUpdateCompany = async ({
    name,
    website,
  }: IUpdateUserCompanyForm) => {
    try {
      const { userCompany } = await updateUserCompany({
        id: company.id,
        name,
        website,
      })
      setIsOpenModal(false)
      methods.reset({
        name: userCompany.name,
        website: userCompany.website[0],
      })
    } catch (error) {
      setFormErrors({
        setError: methods.setError,
        defaultItem: 'website',
        errors: error as IError[],
      })
    }
  }

  return (
    <>
      <Button onClick={handleOpenModal}>Edit company details</Button>
      <Modal
        width={550}
        footer={null}
        title="Edit company details"
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        <InfoAlert
          message="Please note, this change affects all your company members."
          type="warning"
          showIcon
          icon={<WarningOutlined />}
        />
        <FormProvider {...methods}>
          <Form layout="vertical">
            <FormItem
              name="name"
              label="Company name"
              component={Input}
              defaultValue=""
            />

            <FormItem
              name="website"
              label="Company URL"
              component={Input}
              defaultValue=""
            />
          </Form>
        </FormProvider>

        <Footer>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            type="primary"
            loading={isLoading}
            onClick={methods.handleSubmit(handleUpdateCompany)}
          >
            Save changes
          </Button>
        </Footer>
      </Modal>
    </>
  )
}

export default EditCompanyDetails
