import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { inviteMemberPromiseCreator } from 'store/hiringTeam'
import {
  approveUserPromiseCreator,
  deleteUserPromiseCreator,
} from 'store/users'
import { getViewerSelector, isAdminOrCompanyAdminSelector } from 'store/viewer'

import { formatDate } from 'helpers/formatDate'

import DeleteUserModal from '../../../Modals/DeleteUserModal/DeleteUserModal'
import CloseIcon from 'assets/icons/Close'
import DeleteIcon from 'assets/icons/Delete'
import SuccessIcon from 'assets/icons/Success'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'

import { EditOutlined, EllipsisOutlined } from '@ant-design/icons'

import { ActionButton, Container, Date } from './styles'

import { IAlgoliaUser, UserInvites, UserStatus } from 'types'

export interface ActionsProps {
  inviteType?: UserInvites
  user: IAlgoliaUser
}

const Actions: FC<ActionsProps> = ({
  user,
  inviteType = UserInvites.HiringTeam,
}) => {
  const approveUser = useActionsRoutines(approveUserPromiseCreator)
  const resendInvite = useActionsRoutines(inviteMemberPromiseCreator)
  const deleteUser = useActionsRoutines(deleteUserPromiseCreator)
  const isAdminOrCompanyAdmin = useSelector(isAdminOrCompanyAdminSelector)
  const viewer = useSelector(getViewerSelector)
  const [isDeleteUserLoading, setDeleteUserLoading] = useState(false)
  const [isResendInviteLoading, setResendInviteLoading] = useState(false)
  const { objectID, status, signUpDate, role, companyId } = user

  const isCurrentUserViewer = user.objectID === viewer.id

  const handleApproveUser = () => approveUser({ userId: objectID, role })

  const invitedByUser = user.invitedBy.find(
    ({ status }) => status === UserStatus.Pending,
  )

  const invitedById = invitedByUser?.invitedById

  const handleDeleteUser = async () => {
    try {
      setDeleteUserLoading(true)
      await deleteUser({
        userDeleteId: user.objectID,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setDeleteUserLoading(false)
    }
  }

  const handleResendEmail = async () => {
    try {
      setResendInviteLoading(true)
      const payload =
        inviteType === UserInvites.Company
          ? { companyId, email: user.email, role: user.role }
          : {
              userId: invitedById,
              email: user.email,
              role: user.role,
              companyId,
            }
      await resendInvite(payload)
    } catch (error) {
      console.log(error)
    } finally {
      setResendInviteLoading(false)
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="Edit user" icon={<EditOutlined />}>
        <Link to={`${ROUTES.USERS}/${user.objectID}`}>Edit user</Link>
      </Menu.Item>
      <DeleteUserModal isMenuItem user={user} />
    </Menu>
  )

  if (isAdminOrCompanyAdmin && status === UserStatus.NotVerified)
    return (
      <Container>
        <ActionButton type="link" danger onClick={handleDeleteUser}>
          <CloseIcon />
          Reject
        </ActionButton>
        <ActionButton color="#08979C" type="link" onClick={handleApproveUser}>
          <SuccessIcon color="#08979C" />
          Approve
        </ActionButton>
      </Container>
    )

  if (status === UserStatus.Verified)
    return (
      <Container>
        <Date>{formatDate(signUpDate)}</Date>
        {isAdminOrCompanyAdmin && !isCurrentUserViewer && (
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            getPopupContainer={item => item}
          >
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        )}
      </Container>
    )

  if (status === UserStatus.Pending)
    return (
      <Container>
        <ActionButton
          color="#8C8C8C"
          type="link"
          onClick={handleDeleteUser}
          loading={isDeleteUserLoading}
        >
          <DeleteIcon />
          Delete
        </ActionButton>
        <ActionButton
          type="link"
          onClick={handleResendEmail}
          loading={isResendInviteLoading}
        >
          <SuccessIcon />
          Resend
        </ActionButton>
      </Container>
    )

  return <span />
}

export default Actions
