import React, { FC } from 'react'
import { connectHits } from 'react-instantsearch-dom'

import UserTable from 'app/components/ResponsiveTables/UserTable/UserTable'

interface IHits {
  hits: any[]
  columns: any
}

const Hits: FC<IHits> = ({ hits, columns }) => {
  return <UserTable hits={hits} columns={columns} />
}

const CustomHits = connectHits(Hits)

export default CustomHits
