import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import {
  fetchUsersCounterCounterPromiseCreators,
  getRefreshUserSelector,
} from 'store/users'
import { getViewerSelector } from 'store/viewer'

import { createURL, useQuery } from 'helpers'

import { columns, userBlockCell } from '../columns'
import CustomHits from './Hits'
import algoliasearch from 'algoliasearch/lite'
import AlgoliaLoaderWrapper from 'app/components/Loaders/AlgoliaLoaderWrapper/AlgoliaLoaderWrapper'
import AlgoliaPagination from 'app/components/UI/AlgoliaPagination/AlgoliaPagination'
import AlgoliaSearch from 'app/components/UI/AlgoliaSearch/AlgoliaSearch'
import useActionsRoutines from 'hooks/useActionsRoutines'
import useSearchToQuery from 'hooks/useSearchToQuery'
import { clone, findIndex } from 'lodash'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'

import { ActionFilters, Spinner } from './styles'

import { CompanyStatus, IUserCounterData, RootState, UserStatus } from 'types'

const { TabPane } = Tabs

interface IHiringTeam {
  hiringTeamCountData: IUserCounterData | null
  hiringTeamAmount: number
}

const HiringTeam: FC<IHiringTeam> = ({
  hiringTeamCountData,
  hiringTeamAmount,
}) => {
  const screens = useBreakpoint()
  const query = useQuery()
  const [pageSize, setPageSize] = useState(10)
  const defaultSubTab = query.get('subTab') || UserStatus.Verified
  const [currentTab, setCurrentTab] = useState(defaultSubTab)
  const [search, setSearch] = useState('')
  const refreshAlgoliaCache = useSelector(getRefreshUserSelector)
  const fetchUsersCounter = useActionsRoutines(
    fetchUsersCounterCounterPromiseCreators,
  )
  const viewer = useSelector(getViewerSelector)

  const { onSearchStateChange, searchState, setSearchState } = useSearchToQuery(
    {},
  )

  const { isLoading } = useSelector(
    (state: RootState) => state.users.userCounters,
  )

  const { NotVerified, Pending, Verified } = hiringTeamCountData || {}

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  const handleUsersTabsChange = useCallback(
    currentTab => {
      setCurrentTab(currentTab)
      setSearch('')
      setPageSize(10)
      setSearchState({
        ...searchState,
        subTab: currentTab,
      })
    },
    [currentTab, search, pageSize],
  )

  const filters = useMemo(() => {
    switch (currentTab) {
      case 'All':
        return ''
      case UserStatus.NotVerified:
        return `status:${currentTab}`
      default:
        return `status:${currentTab} AND companyStatus:${CompanyStatus.Verified}`
    }
  }, [currentTab])

  const usersColumns = useMemo(() => {
    const newColumns = clone(columns)

    if (currentTab === 'Blocked') {
      const index = findIndex(newColumns, { dataIndex: 'actions' })
      newColumns[index] = userBlockCell
    }

    return newColumns
  }, [currentTab, columns])

  useEffect(() => {
    fetchUsersCounter()
  }, [refreshAlgoliaCache])

  if (isLoading) {
    return (
      <Spinner>
        <Spin />
      </Spinner>
    )
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      refresh={refreshAlgoliaCache}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
      indexName={viewer.algoliaKeys.users.listOfUsers}
    >
      {screens.md && (
        <ActionFilters>
          <Select placeholder="Credit type" />
          <AlgoliaSearch />
        </ActionFilters>
      )}

      <Tabs defaultActiveKey={currentTab} onChange={handleUsersTabsChange}>
        <TabPane tab={`All (${hiringTeamAmount})`} key="All">
          <Index indexName={viewer.algoliaKeys.users.listOfUsers}>
            <Configure hitsPerPage={pageSize} filters={filters} />
            <AlgoliaLoaderWrapper>
              <CustomHits columns={columns} />
            </AlgoliaLoaderWrapper>
            <AlgoliaPagination pageSize={pageSize} setPageSize={setPageSize} />
          </Index>
        </TabPane>
        {NotVerified && (
          <TabPane
            tab={`Users to verify (${NotVerified})`}
            key={UserStatus.NotVerified}
          >
            <Index
              indexName={viewer.algoliaKeys.users.verifiedNotVerifiedUsers}
            >
              <Configure hitsPerPage={pageSize} filters={filters} />
              <AlgoliaLoaderWrapper>
                <CustomHits columns={usersColumns} />
              </AlgoliaLoaderWrapper>
              <AlgoliaPagination
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </Index>
          </TabPane>
        )}

        {Pending && (
          <TabPane
            tab={`Pending users ${Pending && `(${Pending})`}`}
            key={UserStatus.Pending}
          >
            <Index
              indexName={viewer.algoliaKeys.users.verifiedNotVerifiedUsers}
            >
              <Configure hitsPerPage={pageSize} filters={filters} />
              <AlgoliaLoaderWrapper>
                <CustomHits columns={usersColumns} />
              </AlgoliaLoaderWrapper>
              <AlgoliaPagination
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </Index>
          </TabPane>
        )}
        {Verified && (
          <TabPane
            tab={`Live users ${Verified && `(${Verified})`}`}
            key={UserStatus.Verified}
          >
            <Index
              indexName={viewer.algoliaKeys.users.verifiedNotVerifiedUsers}
            >
              <Configure hitsPerPage={pageSize} filters={filters} />
              <AlgoliaLoaderWrapper>
                <CustomHits columns={usersColumns} />
              </AlgoliaLoaderWrapper>
              <AlgoliaPagination
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </Index>
          </TabPane>
        )}
      </Tabs>
    </InstantSearch>
  )
}

export default HiringTeam
