import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'types'

const app = (state: RootState) => state.app

export const getRefreshSelector = createSelector(
  app,
  r => r.refreshAlgoliaCache,
)
