import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import Input from 'antd/lib/input'

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { ButtonDecrease, ButtonIncrease, InputWrapper } from './styles'

interface CreditsCounterProps {
  name: any
  valueFrom: any
}
const CreditsCounter: FC<CreditsCounterProps> = ({ name, valueFrom }) => {
  const { setValue, getValues, watch } = useFormContext()

  const currentValue = getValues(name)
  const fromValue = getValues(valueFrom)

  const watchForAvailableCredits = watch(valueFrom)

  const handleDecrease = () => {
    setValue(name, +currentValue - 1)
    setValue(valueFrom, fromValue + 1)
  }

  const handleIncrease = () => {
    setValue(valueFrom, fromValue - 1)
    setValue(name, +currentValue + 1)
  }

  return (
    <InputWrapper>
      <Input value={currentValue} />
      <ButtonDecrease
        type="text"
        onClick={handleDecrease}
        size="small"
        icon={<MinusCircleOutlined />}
        disabled={Number(currentValue) === 0}
      />
      <ButtonIncrease
        type="text"
        onClick={handleIncrease}
        size="small"
        icon={<PlusCircleOutlined />}
        disabled={!watchForAvailableCredits || +watchForAvailableCredits === 0}
      />
    </InputWrapper>
  )
}

export default CreditsCounter
