import * as React from 'react'

function PreviewIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={161}
      height={143}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.368 119.569c32.094 0 58.111-26.095 58.111-58.285S113.462 3 81.368 3C49.274 3 23.257 29.095 23.257 61.284c0 32.19 26.017 58.285 58.11 58.285z"
        fill="#F3F6FF"
      />
      <path
        d="M25.322 27.143a2.934 2.934 0 001.877-3.695 2.921 2.921 0 00-3.684-1.881 2.934 2.934 0 00-1.876 3.694 2.921 2.921 0 003.683 1.882zM31.702 17.577a1.995 1.995 0 001.276-2.512 1.986 1.986 0 00-2.505-1.28 1.995 1.995 0 00-1.276 2.512 1.986 1.986 0 002.505 1.28zM139.983 29.004a4.466 4.466 0 00.283-6.296 4.434 4.434 0 00-6.277-.284 4.466 4.466 0 00-.283 6.296 4.434 4.434 0 006.277.284zM136.953 92.645a1.88 1.88 0 00.119-2.651 1.867 1.867 0 00-2.643-.12 1.88 1.88 0 00-.119 2.652c.697.765 1.88.818 2.643.119z"
        fill="#EAEEF9"
      />
      <path
        d="M28.525 102.305a2.342 2.342 0 002.338-2.345 2.342 2.342 0 00-2.338-2.345 2.342 2.342 0 00-2.338 2.345 2.342 2.342 0 002.338 2.345z"
        fill="url(#prefix__paint0_linear_7034_137054)"
      />
      <path
        d="M126.382 9.333c1.034 0 1.871-.84 1.871-1.877a1.873 1.873 0 00-1.871-1.876c-1.033 0-1.87.84-1.87 1.876 0 1.037.837 1.877 1.87 1.877z"
        fill="#FFC069"
      />
      <g filter="url(#prefix__filter0_d_7034_137054)">
        <path
          d="M138.895 53.662l-19.527 51.482c-.701 1.759-2.689 2.58-4.326 1.877L65.35 87.436c-1.754-.703-2.572-2.697-1.87-4.339L89.32 17.19c.7-1.76 2.688-2.58 4.325-1.876l36.013 14.19 9.237 24.158z"
          fill="#fff"
        />
      </g>
      <path
        d="M116.447 85.677L86.632 73.95c-.468-.117-.585-.587-.468-1.056.117-.469.585-.586 1.053-.469l29.815 11.728c.468.117.585.586.468 1.055-.234.352-.702.586-1.053.47zM82.889 72.425l-5.262-2.11c-.468-.118-.585-.587-.468-1.056.117-.47.585-.586 1.053-.47l5.261 2.112c.468.117.585.586.468 1.055-.117.47-.585.587-1.052.47zM119.486 77.585l-13.33-5.277c-.467-.117-.584-.586-.467-1.056.117-.469.584-.586 1.052-.469l13.329 5.278c.468.117.585.586.468 1.055-.117.47-.585.587-1.052.47zM100.544 70.08l-19.41-7.623c-.467-.117-.584-.586-.467-1.055.117-.47.585-.587 1.052-.47l19.41 7.623c.467.118.584.587.467 1.056-.116.469-.584.586-1.052.469zM122.876 69.61l-5.144-1.993c-.468-.117-.585-.586-.468-1.055.117-.47.584-.587 1.052-.47l5.145 1.994c.467.118.584.587.467 1.056-.116.469-.584.586-1.052.469zM112.938 65.624L84.525 54.365c-.467-.117-.584-.586-.467-1.055.117-.47.584-.586 1.052-.47L113.522 64.1c.468.117.585.586.468 1.055-.117.47-.584.704-1.052.47zM90.373 85.56l-1.754-.704c-.468-.117-.584-.586-.468-1.055.118-.47.585-.587 1.053-.47l1.754.704c.467.118.584.587.467 1.056-.233.469-.701.586-1.052.469zM85.344 83.566l-10.523-4.222c-.467-.117-.584-.586-.467-1.055.117-.47.584-.587 1.052-.47l10.523 4.223c.468.117.585.586.468 1.055-.117.47-.702.586-1.053.47zM126.385 61.636l-18.357-7.27c-.467-.118-.584-.587-.467-1.056.117-.47.584-.586 1.052-.47l18.357 7.272c.468.117.585.586.468 1.055-.117.47-.702.587-1.053.47zM102.064 52.02l-14.148-5.63c-.468-.117-.584-.586-.468-1.055.117-.469.585-.586 1.053-.469l14.147 5.747c.468.117.585.586.468 1.055-.117.47-.584.586-1.052.352z"
        fill="#E3EAF2"
      />
      <path
        d="M119.252 37.83L95.4 28.448c-.585-.234-.819-.82-.585-1.407.234-.587.819-.821 1.403-.587l23.853 9.382c.584.235.818.821.584 1.408-.234.586-.818.82-1.403.586zM102.298 37.713l-9.12-3.636c-.584-.234-.818-.82-.584-1.407.233-.587.818-.821 1.403-.587l9.12 3.636c.584.234.818.82.584 1.407-.234.587-.935.821-1.403.587z"
        fill="#CED7E2"
      />
      <path
        d="M129.659 29.503l-5.495 14.073c-.819 1.994.35 4.34 2.221 5.043l12.511 4.925"
        fill="#E6EDF5"
      />
      <g filter="url(#prefix__filter1_d_7034_137054)">
        <path
          d="M83.707 31.497l22.8 50.076c.702 1.759 0 3.752-1.754 4.456l-48.874 21.578c-1.754.704-3.741 0-4.443-1.759L23.024 41.114c-.702-1.76 0-3.753 1.754-4.457l35.427-15.714 23.502 10.554z"
          fill="#fff"
        />
      </g>
      <path
        d="M90.606 69.963L61.258 82.98c-.351.234-.819 0-1.053-.352-.233-.352 0-.82.351-1.055l29.348-13.018c.35-.234.818 0 1.052.352.234.352 0 .938-.35 1.056zM57.634 84.504l-5.145 2.229c-.35.234-.818 0-1.052-.352-.234-.352 0-.821.35-1.056l5.145-2.228c.351-.234.819 0 1.053.352.233.469.117.938-.351 1.055zM86.981 62.105l-13.095 5.747c-.351.234-.819 0-1.053-.352-.233-.352 0-.821.351-1.056l13.096-5.746c.35-.234.818 0 1.052.352.234.352.117.82-.35 1.055zM68.388 70.314L49.33 78.758c-.35.235-.818 0-1.052-.352-.234-.352 0-.82.35-1.055l19.059-8.444c.35-.234.819 0 1.052.352.234.469.117.938-.35 1.055zM83.825 54.014l-5.028 2.228c-.35.234-.819 0-1.052-.352-.234-.352 0-.821.35-1.056l5.028-2.228c.35-.234.819 0 1.052.352.234.47 0 .938-.35 1.056zM74.001 58.353l-27.944 12.43c-.351.235-.819 0-1.053-.351-.234-.352 0-.821.351-1.056l28.062-12.43c.35-.235.818 0 1.052.351.117.47 0 .938-.468 1.056zM72.248 88.492l-1.754.82c-.35.235-.818 0-1.052-.351-.234-.352 0-.821.35-1.056l1.754-.82c.351-.235.819 0 1.053.351.234.352 0 .821-.351 1.056zM67.22 90.72l-10.406 4.573c-.35.235-.818 0-1.052-.352-.234-.351 0-.82.35-1.055l10.407-4.574c.35-.234.819 0 1.052.352.234.352.117.821-.35 1.056zM80.55 46.039l-18.006 7.974c-.35.235-.819 0-1.052-.351-.234-.352 0-.821.35-1.056l18.007-7.974c.35-.235.818 0 1.052.352.234.351 0 .82-.35 1.055zM56.697 56.594l-13.914 6.098c-.35.234-.818 0-1.052-.352-.234-.352 0-.821.35-1.056l13.914-6.098c.351-.234.819 0 1.053.352.234.352.117.821-.351 1.056z"
        fill="#E3EAF2"
      />
      <path
        d="M58.686 34.312l-23.502 10.32c-.584.234-1.17 0-1.403-.587-.234-.586 0-1.172.585-1.407l23.384-10.32c.585-.234 1.17 0 1.403.587.234.586 0 1.172-.467 1.407zM46.641 46.39l-9.003 3.988c-.584.235-1.169 0-1.403-.586-.234-.587 0-1.173.585-1.408l9.003-3.987c.584-.234 1.17 0 1.403.587.234.469 0 1.172-.585 1.407z"
        fill="#CED7E2"
      />
      <path
        d="M60.205 21.06l6.08 13.838c.819 1.876 3.274 2.814 5.145 1.993l12.277-5.394"
        fill="#E6EDF5"
      />
      <g filter="url(#prefix__filter2_d_7034_137054)">
        <path
          d="M119.227 35.652l.813 70.078a4.253 4.253 0 01-4.27 4.27H47.657a4.252 4.252 0 01-4.27-4.27V15.659a4.252 4.252 0 014.27-4.27h49.407l22.163 24.263z"
          fill="#fff"
        />
      </g>
      <path
        d="M64.465 43.243c6.55 0 11.86-5.31 11.86-11.86s-5.31-11.861-11.86-11.861-11.86 5.31-11.86 11.86 5.31 11.86 11.86 11.86z"
        fill="#F8F8F8"
      />
      <path
        d="M71.174 37.346c0 .61-.61.61-.61.61h-12.2s-.61 0-.61-.61c0-1.558.95-2.846 1.899-4.134.338-.474.948-1.22 1.83-1.965a4.798 4.798 0 003.253 1.288c1.22 0 2.304-.475 3.185-1.22 1.559 1.558 3.253 3.93 3.253 6.031z"
        fill="#4988FD"
      />
      <path
        d="M64.668 31.586a3.795 3.795 0 100-7.59 3.795 3.795 0 000 7.59z"
        fill="#4988FD"
      />
      <path
        d="M107.434 88.041H66.566a1.042 1.042 0 01-1.016-1.016c0-.543.474-1.017 1.016-1.017h40.868c.542 0 1.017.475 1.017 1.017 0 .542-.475 1.016-1.017 1.016zM61.551 88.041h-7.184a1.042 1.042 0 01-1.016-1.016c0-.543.474-1.017 1.016-1.017h7.184c.542 0 1.017.475 1.017 1.017 0 .542-.475 1.016-1.017 1.016zM107.3 76.994H89.068a1.042 1.042 0 01-1.016-1.016c0-.542.474-1.017 1.016-1.017H107.3c.542 0 1.016.475 1.016 1.017 0 .542-.474 1.016-1.016 1.016zM81.341 76.994H54.706a1.042 1.042 0 01-1.017-1.016c0-.542.475-1.017 1.017-1.017h26.635c.542 0 1.017.475 1.017 1.017 0 .542-.475 1.016-1.017 1.016zM106.649 66.286H79.211c-.816 0-1.53-.475-1.53-1.017 0-.542.714-1.016 1.53-1.016h27.438c.816 0 1.53.474 1.53 1.016s-.714 1.017-1.53 1.017zM72.264 66.286H54.633c-.359 0-.673-.475-.673-1.017 0-.542.314-1.016.673-1.016h17.63c.36 0 .674.474.674 1.016s-.314 1.017-.673 1.017zM76.461 100.105h-2.44a1.042 1.042 0 01-1.016-1.016c0-.543.474-1.017 1.017-1.017h2.44c.541 0 1.016.474 1.016 1.017a.998.998 0 01-1.017 1.016zM69.549 100.105H55.045a1.042 1.042 0 01-1.017-1.016c0-.543.475-1.017 1.017-1.017h14.504c.542 0 1.016.474 1.016 1.017a.998.998 0 01-1.016 1.016z"
        fill="#D7E0ED"
      />
      <path
        d="M87.78 54.087H55.111a1.36 1.36 0 01-1.355-1.356c0-.745.61-1.355 1.355-1.355H87.78c.746 0 1.356.61 1.356 1.355a1.36 1.36 0 01-1.356 1.356z"
        fill="#CED5DF"
      />
      <path
        d="M96.997 11.39v19.315c0 2.643 2.372 4.947 5.015 4.947h17.147"
        fill="#DCE0EB"
      />
      <defs>
        <filter
          id="prefix__filter0_d_7034_137054"
          x={41.228}
          y={4.062}
          width={119.666}
          height={136.21}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7034_137054"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_7034_137054"
            result="shape"
          />
        </filter>
        <filter
          id="prefix__filter1_d_7034_137054"
          x={0.755}
          y={9.943}
          width={128.021}
          height={130.934}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7034_137054"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_7034_137054"
            result="shape"
          />
        </filter>
        <filter
          id="prefix__filter2_d_7034_137054"
          x={21.388}
          y={0.389}
          width={120.652}
          height={142.611}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7034_137054"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_7034_137054"
            result="shape"
          />
        </filter>
        <linearGradient
          id="prefix__paint0_linear_7034_137054"
          x1={26.186}
          y1={99.968}
          x2={30.733}
          y2={99.968}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83A6FF" />
          <stop offset={1} stopColor="#5A78FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PreviewIcon
