import React from 'react'
import { Configure, connectStateResults } from 'react-instantsearch-dom'

import RefinementList from '../RefinementList/RefinementList'

import Col from 'antd/lib/col'
import Collapse from 'antd/lib/collapse'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Row from 'antd/lib/row'

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import {
  Container,
  DesktopContainer,
  JobFilterItem,
  MobileContainer,
  Title,
} from './styles'

const { Panel } = Collapse

const ConnectedFilter = connectStateResults(({ searchResults }) => {
  const isResultExist = !!searchResults && searchResults.nbHits !== 0
  const screens = useBreakpoint()

  if (!isResultExist) return null

  return (
    <>
      {screens.md ? (
        <DesktopContainer>
          <Row>
            <Col xs={24} md={8}>
              <Title>Jobs</Title>
              <JobFilterItem>
                <RefinementList
                  title="All jobs"
                  limit={100}
                  attribute="statusFilter"
                  showCount
                />
              </JobFilterItem>
            </Col>
            <Col xs={24} md={8}>
              <Title>Locations</Title>
              <JobFilterItem>
                <RefinementList
                  title="All locations"
                  limit={100}
                  attribute="location"
                />
              </JobFilterItem>
            </Col>

            <Col xs={24} md={8}>
              <Title>Hiring Team</Title>
              <JobFilterItem>
                <RefinementList
                  title="All Members"
                  limit={100}
                  attribute="visibleByUsers.fullName"
                />
              </JobFilterItem>
            </Col>
          </Row>
        </DesktopContainer>
      ) : (
        <MobileContainer>
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
            }
          >
            <Panel header="Jobs" key="Jobs">
              <RefinementList
                title="All jobs"
                limit={100}
                attribute="statusFilter"
                showCount
              />
            </Panel>
            <Panel header="Locations" key="Locations">
              <RefinementList
                title="All locations"
                limit={100}
                attribute="location"
              />
            </Panel>

            <Panel header="Hiring Team" key="HiringTeam">
              <RefinementList
                title="All Members"
                limit={100}
                attribute="visibleByUsers.fullName"
              />
            </Panel>
          </Collapse>
        </MobileContainer>
      )}
    </>
  )
})

const JobFilter = ({ isShowFilter, hitsPerPage }: any) => {
  return (
    <>
      <Configure hitsPerPage={hitsPerPage} />

      <Container isShowFilter={isShowFilter}>
        <ConnectedFilter />
      </Container>
    </>
  )
}

export default JobFilter
