import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { isExternalRecruiterSelector } from 'store/viewer'

import UpdateCandidateStatus from '../../Candidate/UpdateCandidateStatus/UpdateCandidateStatus'
import Actions from './Actions/Actions'
import FullName from './FullName/FullName'
import Status from './Status/Status'
import { candidatesColumns } from './columns'
import useSelectRow from 'hooks/useSelectRow'
import { ROUTES } from 'routes'

import { Empty, Tooltip } from 'antd'
import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Row from 'antd/lib/row'
import Table from 'antd/lib/table'

import {
  ColItem,
  MobileCandidateLink,
  MobileSecondRow,
  TableWrapper,
  TagItem,
} from './styles'

import {
  CandidateStage,
  CandidateStatus,
  IAlgoliaCandidate,
  ICandidateList,
} from 'types'

const SCROLL_TO_POSITION = 'scrollToPosition'
export const SCROLL_TO_ROW = 'rowId'

interface CandidatesTableProps {
  data: ICandidateList
  hasFilters?: boolean
  onPageChange: (page: number, pageSize: number) => void
  pageNumber: number
  pageSize: number
  total: number
}

const CandidatesTable = ({
  data,
  hasFilters,
  onPageChange,
  pageNumber,
  pageSize,
  total,
}: CandidatesTableProps) => {
  const rowRef = useRef<null | HTMLDivElement>(null)
  const screens = useBreakpoint()
  const history = useHistory()
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const { rowSelection, rows, setRows } = useSelectRow()
  const { jobId } = useParams<{ jobId: string }>()
  const { selectedRowKeys } = rowSelection

  const { items } = data
  const showRowSelection = isExternalRecruiter ? undefined : { ...rowSelection }

  const candidatesIdWithCv = rows
    .filter((row: any) => row.hasCv)
    .map((row: any) => +row.key)

  const hasSelection = !!rows.length
  const toolbarOffset = 10
  const screenOffset = hasFilters ? 525 : 465
  const tableOffset = hasSelection ? screenOffset + toolbarOffset : screenOffset

  useEffect(() => {
    const scrollPx = sessionStorage.getItem(SCROLL_TO_POSITION)
    const parsedScrollData = scrollPx && JSON.parse(scrollPx)
    if (parsedScrollData && parsedScrollData.jobId === jobId && items) {
      window.scrollTo(0, +parsedScrollData.scrollPx)
    }
    sessionStorage.removeItem(SCROLL_TO_POSITION)
  }, [])

  const setScrollPosition = () => {
    sessionStorage.setItem(
      SCROLL_TO_POSITION,
      JSON.stringify({
        scrollPx: window.scrollY.toString(),
        jobId,
      }),
    )
  }

  useEffect(() => {
    setRows([])
    if (rowRef.current && sessionStorage.getItem(SCROLL_TO_ROW)) {
      rowRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      })
      sessionStorage.removeItem(SCROLL_TO_ROW)
    }
  }, [items, rowRef])

  if (!items?.length) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No candidates" />
    )
  }

  if (screens.md) {
    return (
      <TableWrapper
        className={hasSelection ? 'has-selection' : ''}
        $toolbarOffset={toolbarOffset}
      >
        <Table
          scroll={{
            y: `calc(100vh - ${tableOffset}px)`,
          }}
          columns={candidatesColumns(setRows, rowRef)}
          dataSource={items}
          pagination={{
            position: ['bottomRight'],
            onChange: onPageChange,
            current: pageNumber,
            pageSize,
            showTotal: total => (
              <span>
                <strong>Records: </strong> {total}
              </span>
            ),
            total,
          }}
          onRow={record => {
            return {
              onClick: () => {
                sessionStorage.setItem(SCROLL_TO_ROW, record.key)
                setScrollPosition()
                history.push(`${ROUTES.CANDIDATE}/${record.objectID}`)
              },
            }
          }}
          rowSelection={showRowSelection}
          rowClassName={(candidate: IAlgoliaCandidate) => {
            return candidate.status === CandidateStatus.New
              ? 'row-item table-row-light selection-trigger-lg'
              : 'row-item table-row-dark selection-trigger-lg'
          }}
        />
        {hasSelection && (
          <UpdateCandidateStatus
            candidatesId={selectedRowKeys}
            setRows={setRows}
            candidatesIdWithCv={candidatesIdWithCv}
          />
        )}
      </TableWrapper>
    )
  }

  return (
    <>
      {items.map((candidate: IAlgoliaCandidate) => (
        <MobileCandidateLink
          onClick={setScrollPosition}
          key={candidate.objectID}
          to={`${ROUTES.CANDIDATE}/${candidate.objectID}`}
          $candidateStatus={candidate.status}
        >
          <Row justify="space-around" align="middle">
            <Col span={20}>
              <FullName {...candidate} />
            </Col>

            <ColItem span={4}>
              <Status {...candidate} />
            </ColItem>
          </Row>
          <MobileSecondRow justify="space-around" align="middle">
            <Col xs={12} md={6}>
              {candidate.stage === CandidateStage.Sourced ? (
                <Tooltip placement="top" title="JH CV search">
                  <TagItem> {candidate.stage}</TagItem>
                </Tooltip>
              ) : (
                <TagItem> {candidate.stage}</TagItem>
              )}
            </Col>

            <Col xs={12} md={6}>
              <Actions candidate={candidate} />
            </Col>
          </MobileSecondRow>
        </MobileCandidateLink>
      ))}
    </>
  )
}

export default CandidatesTable
