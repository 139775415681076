import { useState } from 'react'

const useSelectRow = () => {
  const [rows, setRows] = useState<any>([])

  const onSelectRow = (record: any) => {
    const recordExist = rows.findIndex((row: any) => row.key === record.key)
    if (recordExist >= 0) {
      const filteredRecords = rows.filter((row: any) => row.key !== record.key)
      setRows([...filteredRecords])
    } else {
      setRows([...rows, { ...record }])
    }
  }

  function onSelectAllRows<T>(selected: boolean, _: T[], changeRows: T[]) {
    if (selected) {
      setRows([...rows, ...changeRows])
    } else {
      const listOfUncheckedKeys: string[] = changeRows.map(
        (item: any) => item.key,
      )
      const newSelectedRows: string[] = rows.filter(
        ({ key }: { key: string }) => !listOfUncheckedKeys.includes(key),
      )
      setRows(newSelectedRows)
    }
  }

  const selectedRowKeys = rows.map((row: any) => row.key)

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelect: onSelectRow,
    onSelectAll: onSelectAllRows,
  }
  return { rowSelection, rows, setRows }
}

export default useSelectRow
