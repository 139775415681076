import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  isAdminOrCompanyAdminSelector,
  isExternalRecruiterOrTeamMemberSelector,
} from 'store/viewer'

import InviteTeamMember from 'app/components/Modals/InviteTeamMember/InviteTeamMember'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { ActionButtons } from './styles'

const SettingsAction = () => {
  const isAdminOrCompanyAdmin = useSelector(isAdminOrCompanyAdminSelector)
  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )
  return (
    <ActionButtons>
      {isAdminOrCompanyAdmin && (
        <Link to={ROUTES.SETTING_ASSIGN_CREDITS}>
          <Button>Assign Credits</Button>
        </Link>
      )}
      {!isExternalRecruiterOrTeamMember && <InviteTeamMember />}
    </ActionButtons>
  )
}

export default SettingsAction
