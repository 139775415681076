import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import {
  deleteCandidatePromiseCreator,
  fetchCandidateCountByFiltersPromiseCreator,
  fetchCandidateCountByKeywordPromiseCreator,
  listCandidatesPromiseCreator,
} from 'store/candidates'

import { getStoredCandidateFilters } from 'helpers/candidateHelpers'

import snackbar from '../../Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'

import { InfoCircleOutlined } from '@ant-design/icons'

import { ModalFooter, Title } from './styles'

import { JobDetailTab, RootState } from 'types'

interface DeleteCandidateModalProps {
  isButton?: boolean
  jobId: string
  candidateId: string
  icon?: React.ReactNode
  buttonTitle: string
  candidateName: string
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
}

const DeleteCandidateModal: FC<DeleteCandidateModalProps> = ({
  isButton,
  jobId,
  icon,
  candidateId,
  buttonTitle,
  buttonType,
  candidateName,
}) => {
  const location = useLocation()
  const history = useHistory()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const { isLoading: isDeleting } = useSelector(
    (state: RootState) => state.candidates.deleteCandidate,
  )
  const { isLoading: isListLoading } = useSelector(
    (state: RootState) => state.candidates.listCandidates,
  )
  const listCandidatesAction = useActionsRoutines(listCandidatesPromiseCreator)
  const fetchCandidateCountByKeyword = useActionsRoutines(
    fetchCandidateCountByKeywordPromiseCreator,
  )
  const fetchCandidateCountByFilters = useActionsRoutines(
    fetchCandidateCountByFiltersPromiseCreator,
  )
  const deleteCandidate = useActionsRoutines(deleteCandidatePromiseCreator)

  const handleDeleteCandidate = async () => {
    try {
      await deleteCandidate({ id: candidateId })
      fetchCandidateCountByKeyword(
        getStoredCandidateFilters({
          jobId,
          parseQs: true,
          isByKeywordOnly: true,
        }),
      )
      fetchCandidateCountByFilters(
        getStoredCandidateFilters({ jobId, parseQs: true }),
      )
      await listCandidatesAction(
        getStoredCandidateFilters({ jobId, parseQs: true }),
      )
      if (location.pathname.includes(ROUTES.CANDIDATE)) {
        const qs = sessionStorage.getItem('candidateSearch') || ''
        history.push(
          `${ROUTES.JOB}/${jobId}?tab=${JobDetailTab.Candidates}&${qs}`,
        )
      }
      snackbar({
        content: 'Candidate deleted',
        showIcon: true,
        type: 'info',
        icon: InfoCircleOutlined,
        closeText: 'OK',
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const isLoading = isListLoading || isDeleting

  return (
    <>
      {isButton ? (
        <Button danger type={buttonType} onClick={handleOpenModal} icon={icon}>
          {buttonTitle}
        </Button>
      ) : (
        <Menu.Item
          key="Delete Candidate"
          danger
          onClick={handleOpenModal}
          icon={icon}
        >
          {buttonTitle}
        </Menu.Item>
      )}

      <Modal
        width={550}
        footer={null}
        title={<Title>Delete Candidate?</Title>}
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        <p>
          Are you sure you want to delete <strong> {candidateName}</strong> -
          all data will be deleted. This action cannot be undone.
        </p>

        <ModalFooter>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            danger
            type="primary"
            loading={isLoading}
            onClick={handleDeleteCandidate}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteCandidateModal
