import React from 'react'

import Spin from 'antd/lib/spin'

import { SpinnerContainer } from './styles'

const Loader = () => {
  return (
    <SpinnerContainer>
      <Spin />
    </SpinnerContainer>
  )
}

export default Loader
