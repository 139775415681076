import styled from 'styled-components'

import Button from 'antd/lib/button'

export const BundleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
`

export const BundleItem = styled.div`
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  margin-bottom: 32px;
`
export const BundleItemRow = styled.div`
  padding: 16px 0;
  margin: 0 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 22px;
  @media (min-width: 576px) {
    flex-wrap: nowrap;
  }
`
export const Credits = styled.div`
  flex: 1;
  white-space: nowrap;
  width: 60%;
  margin-bottom: 16px;
  @media (min-width: 576px) {
    width: unset;
    margin-bottom: 0;
  }
`
export const Discount = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.orange6};
  text-align: right;
  width: 40%;
  @media (min-width: 576px) {
    max-width: 150px;
    width: 100%;
    margin: 0 8px;
  }
`
export const TotalCost = styled.div`
  color: ${({ theme }) => theme.colors.gray7};

  width: 60%;
  @media (min-width: 576px) {
    width: 100%;
    max-width: 210px;
    text-align: right;
  }
`
export const Price = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray9};
`
export const BuyButton = styled(Button)`
  padding: 0;
  text-align: right;
  width: 40%;
  @media (min-width: 576px) {
    width: 100%;
    max-width: 140px;
  }
`
