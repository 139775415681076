import styled from 'styled-components/macro'

import Button from 'antd/lib/button'

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ padding }: { padding?: string }) => padding && padding};
`

export const Tags = styled.div`
  flex: 1;
  z-index: 2;

  .ant-tag {
    margin: 6px 10px 6px 0;
    display: inline-flex;
    align-items: center;
  }

  .ant-btn {
    padding: 0;
    height: auto;
    width: auto;
    margin-left: 6px;
    display: inline-flex;
    align-items: center;

    svg {
      font-size: 14px;
    }
  }
`

export const ClearFilters = styled(Button)`
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
`
