import { createAction } from '@reduxjs/toolkit'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const startRefreshUserAlgoliaCache = createAction(
  'app/startRefreshUserAlgoliaCache',
)
export const stopRefreshUserAlgoliaCache = createAction(
  'app/stopRefreshUserAlgoliaCache',
)

export const fetchUser = createRoutine('updateUser/fetchUser')
export const fetchUserPromiseCreator = promisifyRoutine(fetchUser)

export const updateUser = createRoutine('updateUser/updateUser')
export const updateUserPromiseCreator = promisifyRoutine(updateUser)

export const updateUserCompany = createRoutine('updateUser/updateUserCompany')
export const updateUserCompanyPromiseCreator = promisifyRoutine(
  updateUserCompany,
)

export const moveUserToAnotherCompany = createRoutine(
  'updateUser/moveUserToAnotherCompany',
)
export const moveUserToAnotherCompanyPromiseCreator = promisifyRoutine(
  moveUserToAnotherCompany,
)

export const createUser = createRoutine('user/createUser')
export const createUserPromiseCreator = promisifyRoutine(createUser)

export const approveUser = createRoutine('manageUser/approveUser')
export const approveUserPromiseCreator = promisifyRoutine(approveUser)

export const changeUserRole = createRoutine('manageUser/changeUserRole')
export const changeUserRolePromiseCreator = promisifyRoutine(changeUserRole)

export const fetchCompanyUsers = createRoutine('company/fetchCompanyUsers')
export const fetchCompanyUsersPromiseCreator = promisifyRoutine(
  fetchCompanyUsers,
)

export const fetchUsersCounter = createRoutine('manageUsers/fetchUserCounter')
export const fetchUsersCounterCounterPromiseCreators = promisifyRoutine(
  fetchUsersCounter,
)

export const fetchUsersFromAlgolia = createRoutine(
  'manageUsers/fetchUsersFromAlgolia',
)
export const fetchUsersFromAlgoliaPromiseCreator = promisifyRoutine(
  fetchUsersFromAlgolia,
)

export const deleteUser = createRoutine('user/deleteUser')
export const deleteUserPromiseCreator = promisifyRoutine(deleteUser)
