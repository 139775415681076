import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  getViewerSelector,
  isCompanyAdminSelector,
  isHiringManagerSelector,
  isRecruitmentManagerSelector,
} from 'store/viewer'

import { checkIsViewerHasBalance, totalBalance } from 'helpers/creditsHelpers'

import CreditsDropdown from '../CreditsDropdown/CreditsDropdown'
import Credit from '../assets/Credit'
import { ROUTES } from 'routes'

import { ButtonItem, CreditBlock, CreditBlockText } from './styles'

const PaymentBlock = () => {
  const { balance } = useSelector(getViewerSelector)
  const history = useHistory()
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const isRecruitmentManager = useSelector(isRecruitmentManagerSelector)
  const isHiringManager = useSelector(isHiringManagerSelector)

  const balanceAmount = totalBalance(balance)

  const isViewerHasBalance = checkIsViewerHasBalance(balance)

  return (
    <>
      {(isCompanyAdmin || isRecruitmentManager || isHiringManager) && (
        <>
          {isViewerHasBalance ? (
            <CreditsDropdown>
              <CreditBlock>
                <CreditBlockText> {balanceAmount}</CreditBlockText>

                <Credit />
              </CreditBlock>
            </CreditsDropdown>
          ) : (
            <ButtonItem
              onClick={() =>
                history.push(
                  `${ROUTES.CHECKOUT}?redirectURL=${window.location.href}`,
                )
              }
            >
              Buy Credits
            </ButtonItem>
          )}
        </>
      )}
    </>
  )
}

export default PaymentBlock
