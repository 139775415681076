import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { fetchUserPromiseCreator, updateUserPromiseCreator } from 'store/users'
import { getViewerSelector, isExternalRecruiterSelector } from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import { roleForCreateUser } from '../../Jobs/constants'
import UpdateUserCompany from '../../UpdateUser/UpdateUserCompany'
import FormItem from 'app/components/Forms/FormItem/FormItem'
import snackbar from 'app/components/Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'
import updateUserSchema from 'validation/updateUserSchema'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'

import { ColItem, RowHeader } from './styles'

import { ApiUpdateUser, IError, RootState, UsersRole } from 'types'

const { Option } = Select

const Profile = () => {
  const viewer = useSelector(getViewerSelector)
  const updateUser = useActionsRoutines(updateUserPromiseCreator)
  const fetchUser = useActionsRoutines(fetchUserPromiseCreator)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const [isEditMode, setIsEditMode] = useState(false)

  const { user } = useSelector((state: RootState) => state.users.fetchUser)

  const { isLoading } = useSelector(
    (state: RootState) => state.users.updateUser,
  )

  const methods = useForm({
    resolver: yupResolver(updateUserSchema),
    mode: 'onSubmit',
  })

  useEffect(() => {
    methods.reset({
      ...viewer,
      companyName: viewer.company.name,
      companyWebsite: viewer.company.website[0],
      companyStatus: viewer.company.status,
    })
  }, [])

  const { errors, isDirty } = methods.formState

  const handleUpdateUser = async ({
    firstName,
    lastName,
    phoneNumber,
  }: ApiUpdateUser) => {
    if (!isDirty) return
    try {
      await updateUser({
        id: viewer.id,
        role: viewer.role,
        status: viewer.status,
        firstName,
        lastName,
        phoneNumber,
      })
      snackbar({
        content: 'Profile updated',
        showIcon: true,
        type: 'info',
        closeText: 'OK',
      })
      setIsEditMode(false)
    } catch (error) {
      setFormErrors({
        setError: methods.setError,
        defaultItem: 'email',
        errors: error as IError[],
      })
    }
  }

  const handleOpenEditMode = () => {
    setIsEditMode(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    methods.reset({ ...viewer })
  }

  useEffect(() => {
    isExternalRecruiter && fetchUser({ userId: viewer.id })
  }, [])

  return (
    <div>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <RowHeader>
        <Col span={12}>
          <h1>Profile</h1>
        </Col>
        <ColItem span={12}>
          {isEditMode ? (
            <>
              <Button type="default" onClick={handleCloseEditMode}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={isLoading}
                onClick={methods.handleSubmit(handleUpdateUser)}
              >
                Save
              </Button>
            </>
          ) : (
            <Button type="primary" onClick={handleOpenEditMode}>
              Edit
            </Button>
          )}
        </ColItem>
      </RowHeader>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          onFinish={methods.handleSubmit(handleUpdateUser)}
        >
          <Row gutter={24}>
            <Col xs={24} md={8}>
              <FormItem
                name="firstName"
                label="First name"
                component={Input}
                defaultValue=""
                componentProps={{ disabled: !isEditMode }}
              />
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name="lastName"
                label="Last name"
                component={Input}
                defaultValue=""
                componentProps={{ disabled: !isEditMode }}
              />
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name="signUpMethod"
                label="Sign up method"
                component={Input}
                defaultValue=""
                componentProps={{ disabled: true }}
              />
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name="email"
                label="Email address"
                component={Input}
                defaultValue=""
                componentProps={{ disabled: true }}
              />
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name="phoneNumber"
                label="Phone number"
                component={Input}
                defaultValue=""
                componentProps={{ disabled: !isEditMode }}
              />
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                hasFeedback={!!errors.role}
                validateStatus={errors.role ? 'error' : 'success'}
                help={errors.role && errors.role?.message}
                label="Role"
              >
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={field.onChange}
                      defaultValue=""
                      disabled={true}
                    >
                      {roleForCreateUser.map(({ value, title }) => (
                        <Option
                          key={value}
                          value={value}
                          hidden={value === UsersRole.ExternalRecruiter}
                        >
                          {title}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={methods.control}
                  name="role"
                  defaultValue=""
                />
              </Form.Item>
            </Col>
          </Row>

          <UpdateUserCompany user={user || viewer} />
        </Form>
      </FormProvider>
    </div>
  )
}

export default Profile
