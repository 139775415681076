import React, { FC, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { moveUserToAnotherCompanyPromiseCreator } from 'store/users'
import { getViewerSelector } from 'store/viewer'

import { setFormErrors } from 'helpers/apiHelpers'

import { yupResolver } from '@hookform/resolvers/yup'

import AlgoliaAutocomplete from '../../UI/AlgoliaAutocomplete/AlgoliaAutocomplete'
import algoliasearch from 'algoliasearch/lite'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { moveUserToAnotherCompanySchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'

import { WarningOutlined } from '@ant-design/icons'

import { Footer, InfoAlert } from './styles'

import { IError, IUser, OptionsType, RootState, UsersRole } from 'types'

interface IEditCompanyDetails {
  user: IUser
}

interface MoveToCompany {
  companyId: string
}

const MoveToExistingCompany: FC<IEditCompanyDetails> = ({ user }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const viewer = useSelector(getViewerSelector)
  const moveUserToAnotherCompany = useActionsRoutines(
    moveUserToAnotherCompanyPromiseCreator,
  )
  const { isLoading } = useSelector(
    (state: RootState) => state.users.moveUserToAnotherCompany,
  )
  const { id, company, isLastCompanyAdmin, isLastUser } = user

  const isLastCompanyAdminOrUser = isLastCompanyAdmin || isLastUser

  const methods = useForm({
    resolver: yupResolver(moveUserToAnotherCompanySchema),
  })

  const {
    setError,
    formState: { errors },
  } = methods

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.companies.token,
      ),
    [],
  )

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
    methods.reset({ companyId: null })
  }

  const handleMoveToAnotherCompany = async ({ companyId }: MoveToCompany) => {
    try {
      await moveUserToAnotherCompany({ id, companyId })
      handleCloseModal()
    } catch (errors) {
      setFormErrors({
        errors: errors as IError[],
        setError,
        defaultItem: 'companyId',
      })
    }
  }

  if (user.role === UsersRole.ExternalRecruiter) return null

  return (
    <>
      <Button onClick={handleOpenModal}>Move to existing company</Button>
      <Modal
        width={550}
        footer={null}
        title="Move to existing company"
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        {isLastUser && (
          <InfoAlert
            message="You cannot move selected user as he is the last user in the company."
            type="error"
            showIcon
          />
        )}

        {isLastCompanyAdmin && (
          <InfoAlert
            message="You cannot move selected user as he is the company admin."
            type="error"
            showIcon
          />
        )}

        {!isLastCompanyAdminOrUser && (
          <>
            <InfoAlert
              message="All credits and job data associated with the selected user will be included in the company."
              type="warning"
              showIcon
              icon={<WarningOutlined />}
            />
            <FormProvider {...methods}>
              <Form layout="vertical">
                <Form.Item
                  label="Select another company"
                  help={errors.companyId?.message}
                  validateStatus={errors.companyId ? 'error' : 'success'}
                >
                  <Controller
                    render={({ field }) => (
                      <AlgoliaAutocomplete
                        {...field}
                        isGroupByCompany
                        searchClient={searchClient}
                        handleOnSelect={companyId =>
                          methods.setValue('companyId', companyId)
                        }
                        filters={`NOT objectID:${company.id}`}
                        indexName={viewer.algoliaKeys.companies.indexName}
                        placeholder="Type to search company"
                        optionsType={OptionsType.Companies}
                      />
                    )}
                    name="companyId"
                    control={methods.control}
                  />
                </Form.Item>
              </Form>
            </FormProvider>
          </>
        )}

        <Footer>
          <Button onClick={handleCloseModal}>Cancel</Button>
          {isLastCompanyAdminOrUser ? (
            <Button type="primary" onClick={handleCloseModal}>
              Got it
            </Button>
          ) : (
            <Button
              type="primary"
              loading={isLoading}
              onClick={methods.handleSubmit(handleMoveToAnotherCompany)}
            >
              Save changes
            </Button>
          )}
        </Footer>
      </Modal>
    </>
  )
}

export default MoveToExistingCompany
