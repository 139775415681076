import styled from 'styled-components/macro'

import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'

export const SnackBarButton = styled(Button)`
  color: #ff773e;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: #ff773e;
  }
`
export const AlertItem = styled(Alert)`
  & .ant-alert-message {
    font-weight: 400;
  }

  &.ant-alert-warning .ant-alert-icon.anticon {
    color: #faad14;
  }
`
