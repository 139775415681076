export const statusOrder = {
  Shortlisted: 1,
  Maybe: 2,
  Rejected: 3,
  AutoRejected: 4,
  New: 5,
  Viewed: 6,
}

export const stagesOrder = {
  Sourced: 1,
  Applied: 2,
  'Phone Screen': 3,
  '1st Interview': 4,
  '2nd Interview': 5,
  Offer: 5,
  Hired: 6,
  Rejected: 7,
}
