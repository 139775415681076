import React, { FC, useEffect } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'

import Spin from 'antd/lib/spin'

import { SpinnerWrapper } from './styles'

interface AlgoliaLoaderWrapperProps {
  isSearchStalled: boolean
  children: React.ReactNode
  searchResults: any
  callback?: (value: any) => void
}

const AlgoliaLoaderWrapper: FC<AlgoliaLoaderWrapperProps> = ({
  isSearchStalled,
  children,
  searchResults,
  callback,
}) => {
  useEffect(() => {
    !isSearchStalled && searchResults && callback && callback(searchResults)
  }, [isSearchStalled, searchResults])

  return (
    <>
      {isSearchStalled ? (
        <SpinnerWrapper>
          <Spin />
        </SpinnerWrapper>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

const CustomAlgoliaLoaderWrapper = connectStateResults(AlgoliaLoaderWrapper)

export default CustomAlgoliaLoaderWrapper
