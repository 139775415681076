import styled from 'styled-components/macro'

import Tag from 'antd/lib/tag'

export const JobStatusTag = styled(Tag)`
  &.ant-tag {
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
  }

  &.ant-tag-red {
    color: ${({ theme }) => theme.colors.red4};
  }
`

export const JobStatusTagGray = styled(JobStatusTag)`
  color: ${({ theme }) => theme.colors.gray7};
  border-color: ${({ theme }) => theme.colors.gray6};
  background: transparent;
`
