import React, { FC } from 'react'

interface IBulb {
  size?: number
  color?: string
}

const Bulb: FC<IBulb> = ({ size = 14, color = '#8C8C8C' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.11719 10.8846C1.39453 9.88789 0.234375 8.02461 0.234375 5.89062C0.234375 2.70723 2.8166 0.125 6 0.125C9.1834 0.125 11.7656 2.70723 11.7656 5.89062C11.7656 8.02461 10.6055 9.88789 8.88281 10.8846V12.9219C8.88281 13.233 8.63144 13.4844 8.32031 13.4844H3.67969C3.36855 13.4844 3.11719 13.233 3.11719 12.9219V10.8846ZM3.89062 14.6094H8.10938C8.18672 14.6094 8.25 14.6727 8.25 14.75V15.3125C8.25 15.6236 7.99863 15.875 7.6875 15.875H4.3125C4.00137 15.875 3.75 15.6236 3.75 15.3125V14.75C3.75 14.6727 3.81328 14.6094 3.89062 14.6094Z"
        fill={color}
      />
    </svg>
  )
}

export default Bulb
