import React, { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { forwardCandidatesPromiseCreator } from 'store/candidates'
import { isExternalRecruiterSelector } from 'store/viewer'

import { yupResolver } from '@hookform/resolvers/yup'

import FormItem from 'app/components/Forms/FormItem/FormItem'
import FormMultipleItems from 'app/components/Forms/FormMultipleItems/FormMultipleItems'
import useActionsRoutines from 'hooks/useActionsRoutines'
import isArray from 'lodash/isArray'
import { ForwardCVSchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'

import { SendOutlined } from '@ant-design/icons'

import { ModalFooter } from './styles'

import { RootState } from 'types'

interface ForwardCVModalProps {
  candidateId: number | number[]
  icon?: any
  buttonTitle: string
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
  isHasCv: boolean
  isButton?: boolean
  clearSelectionRows?: () => void
}

interface UseFormInputs {
  emailList: string[]
  comment?: string
}
const ForwardCVModal: FC<ForwardCVModalProps> = ({
  candidateId,
  buttonTitle,
  icon: Icon = SendOutlined,
  isHasCv,
  isButton,
  clearSelectionRows,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const forwardCandidates = useActionsRoutines(forwardCandidatesPromiseCreator)
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    methods.reset()
    methods.setValue('emailList', [])
  }

  const { isLoading } = useSelector(
    (state: RootState) => state.candidates.forwardCandidates,
  )

  const methods = useForm({
    resolver: yupResolver(ForwardCVSchema),
    mode: 'onSubmit',
  })

  const handleFormSubmit = (data: UseFormInputs) => {
    const { emailList, comment } = data
    forwardCandidates({
      emailList,
      comment,
      ids: isArray(candidateId) ? candidateId : [candidateId],
    })
      .then(() => clearSelectionRows && clearSelectionRows())
      .finally(() => {
        handleCloseModal()
        methods.reset()
      })
  }

  return (
    <>
      {!isExternalRecruiter && (
        <>
          {isButton ? (
            <Button
              disabled={!isHasCv}
              icon={Icon && <Icon />}
              onClick={handleOpenModal}
            >
              {buttonTitle}
            </Button>
          ) : (
            <Menu.Item
              key={`Forward CV`}
              disabled={!isHasCv}
              icon={Icon && <Icon />}
              onClick={handleOpenModal}
            >
              {buttonTitle}
            </Menu.Item>
          )}

          <Modal
            width={550}
            footer={null}
            title="Forward CV"
            visible={isOpenModal}
            onCancel={handleCloseModal}
          >
            <FormProvider {...methods}>
              <Form
                layout="vertical"
                onFinish={methods.handleSubmit(handleFormSubmit)}
              >
                <p>Provide email address where CV will be forwarded to</p>

                <FormMultipleItems
                  name="emailList"
                  isRequired={true}
                  placeholder="Email address"
                />
                <FormItem
                  name="comment"
                  component={Input.TextArea}
                  componentProps={{ placeholder: 'Comment' }}
                />

                <ModalFooter>
                  <Button onClick={handleCloseModal}>Cancel</Button>
                  <Button
                    type="primary"
                    onClick={methods.handleSubmit(handleFormSubmit)}
                    loading={isLoading}
                  >
                    Send
                  </Button>
                </ModalFooter>
              </Form>
            </FormProvider>
          </Modal>
        </>
      )}
    </>
  )
}

export default ForwardCVModal
