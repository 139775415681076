import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isExternalRecruiterSelector } from 'store/viewer'

import BackButton from '../../components/UI/Buttons/BackButton/BackButton'
import CandidateActions from 'app/components/Candidate/CandidateActions/CandidateActions'
import CandidateMenu from 'app/components/Candidate/CandidateMenu/CandidateMenu'
import isNil from 'lodash/isNil'

import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { ColItem, RowItem, RowItemContainer } from './styles'

import { ICandidate, JobDetailTab, JobStatus } from 'types'

const MAX_SCROLL_TOP = 20

interface ICandidateHeader {
  candidate: ICandidate
}

const CandidateHeader: FC<ICandidateHeader> = ({ candidate }) => {
  const screens = useBreakpoint()
  const history = useHistory()
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const [isFixed, setIsFixed] = useState(false)

  const { firstName, lastName, advert } = candidate

  const handleOnScroll = () => {
    const position = window.pageYOffset
    position > MAX_SCROLL_TOP ? setIsFixed(true) : setIsFixed(false)
  }

  const isStatusVisible = !isExternalRecruiter

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll)
    return () => {
      window.removeEventListener('scroll', handleOnScroll)
    }
  }, [])

  const fullName = `${firstName} ${!isNil(lastName) ? lastName : ''}`

  const candidateSearch = sessionStorage.getItem('candidateSearch')

  return (
    <RowItemContainer isFixed={screens.md ? isFixed : false}>
      <RowItem>
        <>
          <Col span={20} md={{ span: 10 }}>
            <BackButton
              title={fullName}
              type="candidates"
              onBack={() =>
                history.push({
                  pathname: `/job/${advert.id}`,
                  search: `?tab=${JobDetailTab.Candidates}&${candidateSearch}`,
                })
              }
            />
          </Col>

          <ColItem span={4} md={{ span: 14 }}>
            {!screens.md ? (
              <CandidateMenu
                candidate={candidate}
                isStatusVisible={isStatusVisible}
              />
            ) : (
              <CandidateActions candidate={candidate} />
            )}
          </ColItem>
        </>
      </RowItem>
    </RowItemContainer>
  )
}

export default CandidateHeader
