import styled from 'styled-components/macro'

import Button from 'antd/lib/button'
import Space from 'antd/lib/space'

export const ToggleButton = styled(Button)`
  span {
    display: none;
  }

  @media (min-width: 768px) {
    &:hover svg {
      stroke: ${({ theme }) => theme.colors.blue6};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    svg {
      margin-right: 10px;
    }
    span {
      display: inline-block;
    }
  }
`

export const JobActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .ant-btn {
    margin-left: 10px;
  }

  .ant-input-search {
    max-width: 250px;
    .ant-btn {
      margin: 0;
    }
  }
`

export const MobileHeader = styled(Space)`
  width: 100%;
  .ant-space-item:first-child {
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeaderTop = styled.div`
  display: flex;
`

export const HeaderLeft = styled.div`
  flex: 1;
`
