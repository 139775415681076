import styled from 'styled-components'

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-space {
    margin-top: 24px;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 24px;
  }

  p {
    margin: 5px 0;
  }
`
