import * as React from 'react'

function CreditsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#262626"
      {...props}
    >
      <path d="M7 1.244a2 2 0 012 0l4.35 2.512a2 2 0 011 1.732v5.024a2 2 0 01-1 1.732L9 14.756a2 2 0 01-2 0l-4.35-2.512a2 2 0 01-1-1.732V5.488a2 2 0 011-1.732L7 1.244z" />
    </svg>
  )
}

export default CreditsIcon
