import { call, put } from 'redux-saga/effects'

import { getCheckoutInfo, purchaseCredits, transferCredits } from './actions'
import Api from 'services/api'

import { IPurchaseCredits, ITransferCredits } from 'types'

export function* ensurePurchaseCredits({
  payload,
}: {
  type: typeof purchaseCredits.TRIGGER
  payload: IPurchaseCredits
}) {
  try {
    const data = yield call(Api.credits.purchaseCredits, payload)
    yield put(purchaseCredits.success(data))
  } catch (err) {
    console.log(err)
    yield put(purchaseCredits.failure())
  }
}

export function* ensureGetCheckoutInfo() {
  try {
    const { data } = yield call(Api.credits.getCheckoutInfo)
    yield put(getCheckoutInfo.success(data))
  } catch (err) {
    console.log(err)
    yield put(getCheckoutInfo.failure())
  }
}

export function* ensureTransferCredits({
  payload,
}: {
  type: typeof transferCredits.TRIGGER
  payload: ITransferCredits
}) {
  try {
    const { data } = yield call(Api.credits.transferCredits, payload)
    yield put(transferCredits.success(data))
  } catch (err) {
    console.log(err)
    yield put(transferCredits.failure())
  }
}
