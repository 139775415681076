import React, { FC } from 'react'

import { CheckCircleOutlined, FieldTimeOutlined } from '@ant-design/icons'

import { CampaignItem, CampaignItemExpired } from './styles'

interface JobCampaignProps {
  isJHCampaign: boolean
  isSSCampaign: boolean
  isExpired: boolean
}

const JobCampaign: FC<JobCampaignProps> = ({
  isJHCampaign,
  isSSCampaign,
  isExpired,
}) => {
  return (
    <>
      {isExpired ? (
        <CampaignItemExpired>
          <FieldTimeOutlined />
          {isJHCampaign && 'Jobheron Campaign'}
          {isSSCampaign && 'Self Service Campaign'}
        </CampaignItemExpired>
      ) : (
        <CampaignItem>
          <CheckCircleOutlined />
          {isJHCampaign && 'Jobheron Campaign'}
          {isSSCampaign && 'Self Service Campaign'}
        </CampaignItem>
      )}
    </>
  )
}

export default JobCampaign
