import React, { FC, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { updateCandidatePromiseCreator } from 'store/candidates'

import { yupResolver } from '@hookform/resolvers/yup'

import UploadFile from 'app/components/UI/UploadFile/UploadFile'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { AddCVSchema } from 'validation'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'

import { RootState } from 'types'

interface AddCVModalProps {
  candidateId: string
  icon?: any
  buttonTitle: string
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
}

const AddCVModal: FC<AddCVModalProps> = ({
  candidateId,
  buttonTitle,
  icon,
  buttonType = 'primary',
}) => {
  const updateCandidate = useActionsRoutines(updateCandidatePromiseCreator)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { isLoading } = useSelector(
    (state: RootState) => state.candidates.updateCandidate,
  )

  const { candidate } = useSelector(
    (state: RootState) => state.candidates.fetchCandidate,
  )

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    reset()
  }

  const methods = useForm({
    resolver: yupResolver(AddCVSchema),
    mode: 'onChange',
  })

  const {
    formState: { errors },
    setValue,
    control,
    watch,
    trigger,
    reset,
  } = methods

  const handleOnChangeUploadCv = ({ file }: any) => {
    setValue('cv', file)
    trigger('cv')
  }
  const handleOnRemoveFile = () => {
    setValue('cv', undefined)
    trigger('cv')
  }

  const currentCv = watch('cv')

  const uploadFileProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    fileList: currentCv ? [currentCv] : [],
    beforeUpload: () => false,
    onChange: handleOnChangeUploadCv,
  }

  const handleUploadCv = () => {
    const newCandidate = {
      email: candidate?.email,
      phoneNumber: candidate?.phoneNumber,
      lastName: candidate?.lastName,
      firstName: candidate?.firstName,
    }
    updateCandidate({ candidateId, newCandidate, currentCv }).then(() => {
      handleCloseModal()
    })
  }

  return (
    <>
      <Button icon={icon && icon} onClick={handleOpenModal} type={buttonType}>
        {buttonTitle}
      </Button>
      <Modal
        width={550}
        footer={null}
        title="Upload CV"
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        <FormProvider {...methods}>
          <Form.Item
            hasFeedback={!!errors.cv}
            validateStatus={errors.cv ? 'error' : 'success'}
            help={errors.cv?.message}
          >
            <Controller
              render={({ field }) => (
                <UploadFile
                  {...field}
                  uploadFileProps={uploadFileProps}
                  onRemoveFile={handleOnRemoveFile}
                />
              )}
              control={control}
              name="cv"
            />
          </Form.Item>
        </FormProvider>

        <Space>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={methods.handleSubmit(handleUploadCv)}
            loading={isLoading}
          >
            Save
          </Button>
        </Space>
      </Modal>
    </>
  )
}

export default AddCVModal
