import React, { FC, useState } from 'react'

import AddCandidateModal from '../../Modals/AddCandidateModal/AddCandidateModal'

import Menu from 'antd/lib/menu'

import { EditOutlined } from '@ant-design/icons'

interface IEditCandidate {
  candidate: any
  jobId: string
}

const EditCandidate: FC<IEditCandidate> = ({ candidate, jobId }) => {
  const [isOpenEditCandidateModal, setIsOpenEditCandidateModal] = useState(
    false,
  )

  const handleCloseCandidateModal = () => {
    setIsOpenEditCandidateModal(false)
  }

  return (
    <>
      <Menu.Item
        key="Edit Candidate"
        icon={<EditOutlined />}
        onClick={() => setIsOpenEditCandidateModal(true)}
      >
        Edit Candidate
      </Menu.Item>
      <AddCandidateModal
        isEdit
        jobId={jobId}
        candidate={candidate}
        isOpenCandidateModal={isOpenEditCandidateModal}
        handleCloseCandidateModal={handleCloseCandidateModal}
      />
    </>
  )
}

export default EditCandidate
