/**
 * Create the store with dynamic reducers
 */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'

import { watchAllActions } from 'store/sagas'

import { createReducer } from './reducers'

export function configureAppStore() {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware]

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
  })

  const sagas = [watchAllActions, routinePromiseWatcherSaga]

  sagas.forEach(saga => sagaMiddleware.run(saga))
  return store
}
