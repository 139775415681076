import React, { FC } from 'react'

import { StatusTag } from './styles'

import { UserStatus } from 'types'

interface IStatus {
  status: UserStatus
}

const Status: FC<IStatus> = ({ status }) => {
  if (status === UserStatus.NotFinishedSignUp)
    return <StatusTag color="gold">Not Finished Sign Up</StatusTag>

  if (status === UserStatus.NotVerified)
    return <StatusTag color="gold">To verify</StatusTag>

  if (status === UserStatus.Verified)
    return <StatusTag color="cyan">Verified</StatusTag>

  if (status === UserStatus.Pending)
    return <StatusTag color="blue">Pending...</StatusTag>

  if (status === UserStatus.Blocked)
    return <StatusTag color="">Blocked</StatusTag>

  return null
}

export default Status
