import styled from 'styled-components/macro'

import { JobStatus } from 'types/jobs'

export const BottomRowActionsBlock = styled.div`
  display: flex;
  align-items: center;
`

export const ReferenceCodeWrapper = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }
`

export const ReferenceCode = styled.div`
  @media (min-width: 1024px) {
    margin: 0 12px;
    display: flex;
    color: ${({ theme }) => theme.colors.gray6};
    font-weight: 600;
  }
`

export const CompanyBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin: 0 10px;
  }
`
export const CompanyLink = styled.span`
  display: inline-block;
  font-weight: 600;
  text-decoration: ${({ status }: { status: JobStatus }) =>
    status === JobStatus.Draft ? 'underline' : 'none'};
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.gray9};
  flex: none;

  &:hover {
    color: ${({ theme }) => theme.colors.gray9};
  }
`
