import styled from 'styled-components'

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  svg {
    margin-right: 8px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  @media (min-width: 380px) {
    flex-direction: row;
  }
`

export const Text = styled.p`
  margin-bottom: 32px;
`
