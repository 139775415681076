import React, { FC, useMemo, useState } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { connectHits } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'

import { getViewerSelector } from 'store/viewer'

import ExternalRecruitersTable from '../../ResponsiveTables/ExternalRecruitersTable/ExternalRecruitersTable'
import AlgoliaPagination from '../../UI/AlgoliaPagination/AlgoliaPagination'
import algoliasearch from 'algoliasearch/lite'
import CustomAlgoliaLoaderWrapper from 'app/components/Loaders/AlgoliaLoaderWrapper/AlgoliaLoaderWrapper'

interface IHits {
  hits: any[]
}

const Hits: FC<IHits> = ({ hits }) => {
  return <ExternalRecruitersTable hits={hits} />
}

const CustomHits = connectHits(Hits)

interface IExternalRecruitersList {
  refresh: boolean
  filters: string
  callback?: ({ nbHits }: { nbHits: number }) => void
}

const ExternalRecruitersList: FC<IExternalRecruitersList> = ({
  refresh,
  filters = '',
  callback,
}) => {
  const [pageSize, setPageSize] = useState(10)
  const viewer = useSelector(getViewerSelector)

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.users.token,
      ),
    [],
  )

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={viewer.algoliaKeys.users.externalRecruiter}
      refresh={refresh}
    >
      <Index indexName={viewer.algoliaKeys.users.externalRecruiter}>
        <Configure hitsPerPage={pageSize} filters={filters} />
        <CustomAlgoliaLoaderWrapper callback={callback}>
          <CustomHits />
        </CustomAlgoliaLoaderWrapper>

        <AlgoliaPagination pageSize={pageSize} setPageSize={setPageSize} />
      </Index>
    </InstantSearch>
  )
}

export default ExternalRecruitersList
