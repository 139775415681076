import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { isTeamMemberSelector } from 'store/viewer'

import { formatDate } from 'helpers/formatDate'

import {
  jobCampaignTypes,
  jobCreditTypes,
  jobTimeEngagements,
  jobTypes,
} from '../../../modules/Jobs/constants'
import JobTag from '../JobTag/JobTag'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'

import Alert from 'antd/lib/alert'
import Divider from 'antd/lib/divider'
import Spin from 'antd/lib/spin'

import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'

import {
  BoardItem,
  BoardsBlockItem,
  Container,
  ExpiredBoardItem,
  InfoBlock,
  InfoBlockItem,
  Keyword,
  Main,
  SpinContainer,
} from './styles'

import { IDetailJob, JobPage, TagComponent } from 'types'

interface DetailJobProps {
  job?: IDetailJob
  page: JobPage
}

const DetailJob: FC<DetailJobProps> = ({ job, page }) => {
  const isTeamMember = useSelector(isTeamMemberSelector)

  if (!job) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    )
  }

  const {
    title,
    description,
    salaryTop,
    salaryBottom,
    timeInterval,
    timeEngaging,
    needApproval,
    campaignType,
    postCode,
    preferKw,
    benefits,
    type,
    remote,
    location,
    activatedAt,
    status,
    totalCandidates,
    isExpired,
    referenceCode,
    creditType,
  } = job

  const jobTimeEngagement = jobTimeEngagements?.find(
    ({ value }) => value === timeEngaging,
  )

  const jobCampaignType = jobCampaignTypes?.find(
    ({ value }) => value === campaignType,
  )

  const jobType = jobTypes?.find(({ value }) => value === type)

  const isAllBoardsExpired = job?.jobBoards?.every(
    board => board.isExpired === true,
  )

  const campaignTitle = jobCreditTypes?.find(
    ({ value }) => value === creditType,
  )

  const isJobBoardExist = !!job?.jobBoards?.length
  const isShowBoards = isJobBoardExist && page === JobPage.DetailJob
  const isShowAlert = isAllBoardsExpired || isExpired
  return (
    <Container>
      <Main>
        <h2>{title}</h2>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Main>
      <InfoBlock>
        <InfoBlockItem>
          <h2>JOB STATUS</h2>
          <h3>
            <JobTag
              status={status}
              tagComponent={TagComponent.JobDetails}
              jobCreatorId={job.user.id}
            />
          </h3>
        </InfoBlockItem>

        <InfoBlockItem>
          <h2>Campaign type</h2>
          <h3>
            {jobCampaignType?.title} {creditType && `(${campaignTitle?.title})`}
          </h3>
        </InfoBlockItem>
        {page === JobPage.DetailJob && (
          <>
            <InfoBlockItem>
              <h2>Live on</h2>
              <h3>{formatDate(activatedAt, 'dd/MM/yy')}</h3>
            </InfoBlockItem>
            <InfoBlockItem>
              <h2>Live for</h2>
              <h3>
                {formatDistanceToNowStrict(new Date(activatedAt), {
                  unit: 'day',
                })}
              </h3>
            </InfoBlockItem>
            <InfoBlockItem>
              <h2>Total Candidates</h2>
              <h3>{totalCandidates}</h3>
            </InfoBlockItem>
          </>
        )}

        <InfoBlockItem>
          <h2>Benefits</h2>
          <h3>{benefits ? benefits : '-'}</h3>
        </InfoBlockItem>

        <InfoBlockItem>
          <h2>Job type</h2>
          <h3>{jobType?.title}</h3>
        </InfoBlockItem>

        <InfoBlockItem>
          <h2>Time Engagement</h2>
          <h3>{jobTimeEngagement?.title}</h3>
        </InfoBlockItem>

        {!isTeamMember && (
          <InfoBlockItem>
            <h2>SALARY</h2>
            <h3>{`£${salaryBottom} - £${salaryTop} / ${timeInterval}`}</h3>
          </InfoBlockItem>
        )}

        <InfoBlockItem>
          <h2>Remote</h2>
          <h3>{remote ? 'Yes' : 'No'}</h3>
        </InfoBlockItem>

        <InfoBlockItem>
          <h2>CITY • POSTCODE</h2>
          <h3>{`${location} • ${postCode}`}</h3>
        </InfoBlockItem>

        <InfoBlockItem>
          <h2>FILTER BY KEYWORDS</h2>
          <h3>
            {!!preferKw.length
              ? preferKw.map((keyword, index) => (
                  <Keyword key={`${keyword}_${index}`}>{keyword}</Keyword>
                ))
              : '-'}
          </h3>
        </InfoBlockItem>

        {page === JobPage.DetailJob && (
          <InfoBlockItem>
            <h2>Reference code</h2>
            <h3>{referenceCode}</h3>
          </InfoBlockItem>
        )}
        {page === JobPage.PreviewJob && (
          <InfoBlockItem>
            <h2>EDITORIAL APPROVAL</h2>
            <h3>
              {needApproval
                ? 'Yes, I want to approve changes.'
                : 'No, I do not want to approve the changes.'}
            </h3>
          </InfoBlockItem>
        )}

        {isShowBoards && (
          <>
            <Divider />
            {isShowAlert && (
              <Alert
                type="warning"
                showIcon
                message={<div>All job boards are expired</div>}
              />
            )}

            <BoardsBlockItem>
              <h2>Job boards</h2>
            </BoardsBlockItem>
            {job.jobBoards?.map(({ name, isExpired: boardExpired }, index) => (
              <BoardsBlockItem key={`${index}_${name}`}>
                {isExpired || boardExpired ? (
                  <ExpiredBoardItem>
                    <ClockCircleOutlined />
                    {name}
                  </ExpiredBoardItem>
                ) : (
                  <BoardItem>
                    <CheckCircleOutlined />
                    {name}
                  </BoardItem>
                )}
              </BoardsBlockItem>
            ))}
          </>
        )}
      </InfoBlock>
    </Container>
  )
}

export default DetailJob
