import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { isExternalRecruiterOrTeamMemberSelector } from 'store/viewer'

import ChangeCandidateStatusModal from '../../Modals/ChangeCandidateStatusModal/ChangeCandidateStatusModal'
import UpdateCandidateStage from '../../Modals/UpdateCandidateStage/UpdateCandidateStage'
import DownloadCv from '../DownloadCV/DownloadCV'
import EditCandidate from '../EditCandidate/EditCandidate'
import CandidateMobileMenu from './CandidateMobileMenu/CandidateMobileMenu'
import DeleteCandidateModal from 'app/components/Modals/DeleteCandidateModal/DeleteCandidateModal'
import ForwardCVModal from 'app/components/Modals/ForwardCVModal/ForwardCVModal'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Dropdown from 'antd/lib/dropdown'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons'

import { MenuContainer, MobileMenu } from './styles'

import { ICandidate } from 'types'

interface ICandidateMenu {
  candidate: ICandidate
  isStatusVisible: boolean
}

const CandidateMenu: FC<ICandidateMenu> = ({ candidate, isStatusVisible }) => {
  const screens = useBreakpoint()

  const isTeamMemberOrExternalRecruiter = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )

  const {
    firstName = '',
    lastName = '',
    status,
    stage,
    id,
    advert,
    cv,
    appliedFromLm,
  } = candidate

  const candidateName = `${firstName} ${lastName}`

  const menu = (
    <MenuContainer>
      <div onClick={e => e.stopPropagation()}>
        <>
          <DownloadCv
            isShowIcon
            id={id}
            isHasCv={Boolean(cv)}
            fullName={`${firstName} ${lastName}`}
          />

          <ForwardCVModal
            candidateId={id}
            buttonTitle="Forward CV"
            isHasCv={Boolean(cv)}
          />
        </>

        <Divider className="job-menu-divider" />

        <UpdateCandidateStage
          currentStage={stage}
          candidatesId={[String(id)]}
          advertStatus={advert.status}
          appliedFromLm={appliedFromLm}
        />

        <ChangeCandidateStatusModal
          currentStatus={status}
          advert={advert}
          candidatesId={[String(id)]}
        />

        <Divider className="job-menu-divider" />

        <EditCandidate jobId={advert.id} candidate={candidate} />

        {!isTeamMemberOrExternalRecruiter && (
          <DeleteCandidateModal
            jobId={advert.id}
            candidateId={String(id)}
            buttonTitle="Delete Candidate"
            buttonType="link"
            candidateName={candidateName}
            icon={<DeleteOutlined />}
          />
        )}
      </div>
    </MenuContainer>
  )

  return (
    <>
      {!screens.md ? (
        <MobileMenu onClick={e => e.stopPropagation()}>
          <CandidateMobileMenu
            candidate={candidate}
            isStatusVisible={isStatusVisible}
          />
        </MobileMenu>
      ) : (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      )}
    </>
  )
}

export default CandidateMenu
