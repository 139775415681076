import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import {
  isAdminSelector,
  isCompanyAdminSelector,
  isHiringManagerSelector,
  isRecruitmentManagerSelector,
} from 'store/viewer'

import { totalBalance } from 'helpers/creditsHelpers'
import { generateAvatarName } from 'helpers/generateAvatarName'
import { getViewerSettingsMenu } from 'helpers/viewerHelpers'

import CreditsItems from '../CreditsDropdown/CreditsItems'
import Credit from '../assets/Credit'
import CreditsIcon from './assets/CreditsIcon'
import ReferAndEarnModal from 'app/components/Modals/ReferAndEarnModal/ReferAndEarnModal'
import Logo from 'assets/icons/Logo'
import { ROUTES, adminMenu, userMenu } from 'routes'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Divider from 'antd/lib/divider'
import Menu from 'antd/lib/menu'

import {
  CloseOutlined,
  LogoutOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'

import {
  CreditBlock,
  CreditBlockText,
  MenuButton,
  MobileDrawer,
  MobileHeader,
  RowItem,
  SubMenuItem,
  UserName,
  UserNameContainer,
  UserTitle,
} from './styles'

import { IUser, UsersRole } from 'types'

interface IMobileMenu {
  viewer: IUser
  logout: () => void
}

const MobileMenu: FC<IMobileMenu> = ({ viewer, logout }) => {
  const location = useLocation()
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const isRecruitmentManager = useSelector(isRecruitmentManagerSelector)
  const isHiringManager = useSelector(isHiringManagerSelector)
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
  const isJHAdmin = useSelector(isAdminSelector)
  const { role, fullName, firstName, lastName, company, balance } = viewer
  const balanceAmount = totalBalance(balance)

  const handleCloseModal = () => {
    setIsOpenMobileMenu(false)
  }
  const handleOpenModal = () => {
    setIsOpenMobileMenu(true)
  }

  const viewerSettingsMenu = getViewerSettingsMenu({
    status: viewer.status,
    role: viewer.role,
  })

  const isPaymentsAvailable =
    isCompanyAdmin || isRecruitmentManager || isHiringManager
  return (
    <>
      <MenuButton type="text" onClick={handleOpenModal}>
        <MenuOutlined />
      </MenuButton>
      <MobileDrawer
        width={'100%'}
        key="left"
        placement="left"
        closable={false}
        onClose={handleCloseModal}
        visible={isOpenMobileMenu}
      >
        <MobileHeader>
          <Link to={ROUTES.JOBS}>
            <Logo color={isJHAdmin ? '#FA8C16' : '#2688FC'} />{' '}
          </Link>
          {/* JA-1591 Temporarily Hide for Now */}
          {/* {!isJHAdmin && <ReferAndEarnModal />} */}
          <Button type="text" onClick={handleCloseModal}>
            <CloseOutlined />
          </Button>
        </MobileHeader>
        <UserNameContainer>
          <UserName role={role}>
            {generateAvatarName({ firstName, lastName })}
          </UserName>
          <div>
            <UserTitle>{fullName}</UserTitle>
            <div>{company.name}</div>
          </div>
          {isPaymentsAvailable && (
            <CreditBlock>
              <CreditBlockText>{balanceAmount}</CreditBlockText>
              <Credit />
            </CreditBlock>
          )}
        </UserNameContainer>
        <Menu mode="inline" selectedKeys={[location.pathname]}>
          {role === UsersRole.JHSuperAdmin ? (
            <>
              {adminMenu.map(({ title, link }) => (
                <Menu.Item key={link}>
                  <Link to={link}>{title}</Link>
                </Menu.Item>
              ))}
            </>
          ) : (
            <>
              {userMenu.map(({ title, link, icon }) => (
                <Menu.Item key={link} icon={icon}>
                  <Link to={link}>{title}</Link>
                </Menu.Item>
              ))}
            </>
          )}

          <Divider />

          {role !== UsersRole.JHSuperAdmin && (
            <SubMenuItem key="sub1" icon={<SettingOutlined />} title="Settings">
              {viewerSettingsMenu.map(({ group, children }) => (
                <div key={group}>
                  {!!children.length && (
                    <Menu.ItemGroup key={group} title={group}>
                      {children.map(({ title, link }) => (
                        <Menu.Item key={link} onClick={handleCloseModal}>
                          <Link to={link}>{title}</Link>
                        </Menu.Item>
                      ))}
                    </Menu.ItemGroup>
                  )}
                </div>
              ))}
            </SubMenuItem>
          )}
          {isPaymentsAvailable && (
            <SubMenuItem
              key="sub2"
              icon={<CreditsIcon />}
              title="Jobheron Campaign Credits"
            >
              <CreditsItems />
            </SubMenuItem>
          )}

          {!isJHAdmin && (
            <Menu.Item icon={<QuestionCircleOutlined />}>
              <a
                target="_blank"
                rel="noreferrer"
                href={ROUTES.JOBHERON_HELP_CENTER}
              >
                Help
              </a>
            </Menu.Item>
          )}

          {isPaymentsAvailable && (
            <RowItem gutter={16}>
              <Col span={24}>
                <Button type="primary" block>
                  <Link
                    to={`${ROUTES.CHECKOUT}?redirectURL=${window.location.href}`}
                  >
                    Buy Credits
                  </Link>
                </Button>
              </Col>
            </RowItem>
          )}
          <Menu.Item icon={<LogoutOutlined />} onClick={() => logout()}>
            Sign Out
          </Menu.Item>
        </Menu>
      </MobileDrawer>
    </>
  )
}

export default MobileMenu
