import styled from 'styled-components'

import Space from 'antd/lib/space'

export const StageBody = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  padding: 8px 12px;
  border-radius: 8px;
  width: calc(100vw - 80px);
  margin: 8px 0;
  background: #fff;
  display: flex;
  align-items: center;
  word-break: break-word;
  transition: all 0.3s;
  .ant-btn-link {
    padding: 0;
    margin-left: auto;
    height: auto;
    min-height: auto;
  }
  @media (min-width: 400px) {
    width: 330px;
  }
`

export const StageContainer = styled(Space)`
  .ant-btn-link,
  .ant-btn-dangerous {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .ant-btn-dangerous,
    .ant-btn-link {
      opacity: 1;
      visibility: visible;
    }
  }
  .ant-space.ant-space-horizontal {
    &:hover {
      width: 80vw;
    }
  }
`

export const DisabledStageItem = styled(StageBody)`
  margin-left: 35px;
  background: ${({ theme }) => theme.colors.gray3};
  color: ${({ theme }) => theme.colors.gray7};
`

export const StageIcon = styled.div`
  margin-right: 10px;
`

export const StageDraggable = styled.div`
  position: relative;
  width: 20px;
  height: 40px;
  margin-right: 5px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 23px;
    left: 0;
    right: 0;
    height: 2px;
    background: ${({ theme }) => theme.colors.gray9};
  }
  &::after {
    top: 17px;
  }
`

export const Container = styled.div`
  overflow: hidden;
  & .ant-space:hover {
    ${StageBody} {
      width: calc(85vw - 80px);
      max-width: 330px;
    }
    ${DisabledStageItem} {
      width: calc(100vw - 80px);
    }
  }

  @media (min-width: 576px) {
    & .ant-space:hover {
      ${StageBody} {
        width: 330px;
        max-width: auto;
      }
    }
  }
`
