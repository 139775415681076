import styled from 'styled-components/macro'

export const Header = styled.header`
  padding: 15px;
`

export const Block = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: auto;
`

export const Body = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  padding: 0 16px;
`
