import React from 'react'

import BalanceColumn from '../../Admin/ManageUsers/ManageUsersActions/BalanceColumn/BalanceColumn'
import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'
import Actions from 'app/components/ResponsiveTables/UserTable/Actions/Actions'
import FullName from 'app/components/ResponsiveTables/UserTable/FullName/FullName'
import Status from 'app/components/ResponsiveTables/UserTable/Status/Status'

import {
  IAlgoliaUser,
  UserBalance,
  UserInvites,
  UserStatus,
  UsersRole,
} from 'types'

export const columns = [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    ellipsis: true,
    width: 150,
    render: (_: unknown, user: IAlgoliaUser) => <FullName {...user} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 120,
    minWidth: 120,
    ellipsis: true,
    render: (_: unknown, { status }: { status: UserStatus }) => (
      <Status status={status} />
    ),
  },
  {
    title: 'Access Level',
    dataIndex: 'accessLevel',
    width: 150,
    minWidth: 150,
    ellipsis: true,
    render: (
      _: unknown,
      {
        role,
        status,
        objectID,
        fullName,
      }: {
        role: UsersRole
        status: UserStatus
        objectID: string
        fullName: string
      },
    ) => (
      <AccessLevel
        role={role}
        status={status}
        userId={objectID}
        fullName={fullName}
      />
    ),
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    ellipsis: true,
    width: 150,
  },
  {
    title: 'Credits',
    dataIndex: 'credits',
    ellipsis: true,
    width: 100,
    minWidth: 100,
    render: (_: unknown, { balance }: { balance: UserBalance }) => (
      <BalanceColumn balance={balance} />
    ),
  },
  {
    dataIndex: 'actions',
    width: 250,
    minWidth: 250,
    render: (_: unknown, user: IAlgoliaUser) => (
      <Actions user={user} inviteType={UserInvites.HiringTeam} />
    ),
  },
]
