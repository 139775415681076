import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  isAdminSelector,
  isExternalRecruiterOrTeamMemberSelector,
} from 'store/viewer'

import JobArchived from './JobStatuses/JobArchived'
import JobDraft from './JobStatuses/JobDraft'
import JobLive from './JobStatuses/JobLive'
import JobPending from './JobStatuses/JobPending'
import { ROUTES } from 'routes'

import { IAlgoliaJob, JobDetailTab, JobStatusFilter } from 'types'

const JobItem: FC<IAlgoliaJob> = props => {
  const { statusFilter, statusFilterExternal } = props
  const isAdmin = useSelector(isAdminSelector)
  const isExternalRecruiterOrTeamMember = useSelector(
    isExternalRecruiterOrTeamMemberSelector,
  )
  const currentStatus = statusFilter || statusFilterExternal

  const renderJob = () => {
    switch (currentStatus) {
      case JobStatusFilter.Live:
        return <JobLive {...props} isAdmin={isAdmin} />
      case JobStatusFilter.Draft:
        return <JobDraft {...props} isAdmin={isAdmin} />
      case JobStatusFilter.Archived:
        return <JobArchived {...props} isAdmin={isAdmin} />
      case JobStatusFilter.Pending:
        return <JobPending {...props} />
      default:
        return null
    }
  }

  const isShowOnlyJobBlock =
    isExternalRecruiterOrTeamMember &&
    (currentStatus === JobStatusFilter.Draft ||
      currentStatus === JobStatusFilter.Pending)

  const generateJobLink = () => {
    if (currentStatus === JobStatusFilter.Archived) {
      return `${ROUTES.JOB}/${props.objectID}`
    }
    if (currentStatus === JobStatusFilter.Live) {
      return `${ROUTES.JOB}/${props.objectID}/?tab=${JobDetailTab.Candidates}`
    }
    return `${ROUTES.JOB}/${props.objectID}/preview`
  }

  if (isShowOnlyJobBlock) {
    return <>{renderJob()}</>
  }

  return <Link to={generateJobLink}>{renderJob()}</Link>
}

export default JobItem
