import styled from 'styled-components/macro'

export const TabContainer = styled.div`
  .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.gray7};
  }
`

export const TabRightAction = styled.div`
  display: none;
  .ant-btn {
    margin-left: 8px;
    font-weight: 600;
  }

  @media (min-width: 768px) {
    display: block;
  }
`

export const HiringTeamTab = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`

export const ActionFilters = styled.div`
  display: flex;
  margin-bottom: 10px;
  gap: 8px;

  .ant-select {
    width: 100%;
    max-width: 250px;
  }

  .ant-input-search {
    width: 100%;
    max-width: 250px;

    input {
      &::placeholder {
        color: ${({ theme }) => theme.colors.gray7};
      }
    }
  }
`
export const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
