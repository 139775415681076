import * as React from 'react'

const NoCandidates = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={132}
      height={164}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.769 25.121a3.443 3.443 0 002.227-4.344c-.593-1.81-2.55-2.801-4.372-2.213a3.443 3.443 0 00-2.227 4.344c.593 1.811 2.55 2.802 4.372 2.213zM13.341 13.873a2.341 2.341 0 001.514-2.954 2.362 2.362 0 00-2.972-1.505 2.341 2.341 0 00-1.514 2.954 2.362 2.362 0 002.972 1.505zM119.139 11.687a1.784 1.784 0 00.115-2.532 1.811 1.811 0 00-2.548-.114 1.784 1.784 0 00-.115 2.532c.672.731 1.813.782 2.548.114zM14.678 126.756a2.197 2.197 0 00.142-3.117 2.23 2.23 0 00-3.137-.141 2.197 2.197 0 00-.141 3.117 2.23 2.23 0 003.136.141z"
        fill="#EAEEF9"
      />
      <path
        d="M131.675 45.006v51.988c0 .827 0 1.517-.139 2.344-.694 5.93-4.163 11.17-9.436 14.204l-45.236 25.924c-4.301 2.483-9.436 3.172-14.153 1.931-1.804-.414-3.47-1.103-4.996-1.931L12.34 113.404c-3.469-1.931-6.105-4.827-7.77-8.274-1.25-2.482-1.943-5.378-1.943-8.274v-51.85c0-2.758.555-5.378 1.665-7.86 1.665-3.585 4.302-6.62 7.91-8.688L57.576 2.534C62.016.05 67.012-.638 71.87.603c1.665.414 3.33 1.103 4.995 1.93L122.1 28.459c4.025 2.345 6.939 5.93 8.465 10.205a19.038 19.038 0 011.11 6.343z"
        fill="#F3F6FF"
      />
      <path
        d="M67.29 26.114L78.111 3.637"
        stroke="url(#prefix__paint0_linear_4690_126054)"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#prefix__filter0_d_4690_126054)">
        <path
          d="M109.056 18.392v106.457c0 3.034-2.498 5.516-5.55 5.516H30.795c-3.053 0-5.55-2.482-5.55-5.516V18.392c0-3.034 2.497-5.516 5.55-5.516h72.711c3.052 0 5.55 2.482 5.55 5.516z"
          fill="#fff"
        />
      </g>
      <path
        d="M67.15 31.492c3.219 0 5.828-2.593 5.828-5.791 0-3.2-2.61-5.792-5.828-5.792-3.219 0-5.828 2.593-5.828 5.792 0 3.198 2.61 5.791 5.828 5.791z"
        fill="#F3F6FF"
      />
      <path
        d="M67.012 26.114L56.05 3.637"
        stroke="url(#prefix__paint1_linear_4690_126054)"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.126 86.376c-.555.551-.971 1.103-1.526 1.516-6.245 5.792-14.57 9.24-23.867 9.24-7.355 0-14.293-2.207-19.982-6.206-1.665-1.103-3.191-2.482-4.58-3.86.695-.966 2.082-1.656 4.858-2.759l1.11-.414c2.081-.827 4.995-1.93 8.742-3.447.555-.276 1.11-.552 1.387-1.103.139-.276.416-.552.416-.828.278-.413.278-.965.278-1.516v-7.033c-.139-.138-.139-.276-.278-.414-.555-.965-.97-1.93-1.11-3.034l-.555-.275c-1.804.413-1.665-1.517-2.081-5.24-.139-1.517 0-1.793.694-2.07l.555-.827c-1.388-3.033-1.943-5.515-1.943-7.722 0-3.447 1.526-5.791 3.608-6.895-.971-2.206-1.11-3.171-1.11-3.171s7.354 1.24 9.852.69c3.191-.69 8.187.137 9.99 4.412.417.138.694.276 1.11.413 2.36 1.242 3.192 2.896 3.47 4.69.416 3.309-1.388 6.894-1.804 8.41 0 0 0 .139.139.139.555.276.694.69.416 2.068-.416 3.586-.278 5.654-2.082 5.24l-1.665 2.758c0 .276-.138.414-.138.69V77.688c0 .552.277.965.555 1.379l.416.414c.416.413.832.69 1.388.965 4.162 1.793 7.215 2.896 9.435 3.723 2.082.966 3.47 1.655 4.302 2.207z"
        fill="#EAEEF9"
      />
      <path
        d="M80.055 61.14c-.416 3.586-.277 5.654-2.08 5.24l-1.666 2.758c0 .276-.139.414-.139.69V77.688h-8.048V45.834h9.713c2.36 1.24 3.192 2.895 3.47 4.688.416 3.31-1.388 6.895-1.805 8.412 0 0 0 .138.14.138.415.276.554.69.415 2.068z"
        fill="#E3E7F5"
      />
      <path
        d="M95.457 96.718c-6.244 5.792-19.426.552-28.723.552-7.355 0-22.757 5.791-28.446 1.93-1.11-.827.277-3.999.693-4.964.694-1.93 1.388-3.861 2.36-5.654 2.08-4 11.655-5.93 15.54-7.998.694-.414 1.11-.69 1.388-1.103 4.58 6.343 14.015 6.343 18.594.138.416.413.833.69 1.388.965.138 0 .277.138.416.138 12.905 5.24 13.737 4.55 15.403 9.377.277.827 1.942 6.067 1.387 6.619z"
        fill="#fff"
      />
      <path
        d="M95.457 96.718c-6.244 5.792-19.426.551-28.723.551-7.355 0-22.757 5.792-28.446 1.931-1.11-.827.277-3.999.693-4.964.694-1.93 1.388-3.861 2.36-5.654 2.08-4 11.655-5.93 15.54-7.998h.14c5.272 7.308 16.234 7.308 21.507.138h.14c12.904 5.24 13.736 4.55 15.402 9.377.277.827 1.942 6.067 1.387 6.619z"
        fill="url(#prefix__paint2_linear_4690_126054)"
      />
      <path
        d="M79.362 58.934c.139-.827-.277-1.93-.555-2.758 0-.138-.139-.276-.139-.414-1.387-2.758-4.579-3.999-7.493-4.137-7.632-.413-8.325 1.104-10.823-1.103.694 1.103.833 2.896-.278 5.102-.832 1.517-2.359 2.207-3.746 2.62-3.47-7.86-1.804-12.824 1.665-14.617-1.11-2.344-1.249-3.31-1.249-3.31s7.355 1.242 9.852.69c3.192-.69 8.187.138 9.991 4.413 3.053 1.241 4.302 3.172 4.58 5.24.554 3.31-1.389 6.895-1.805 8.274z"
        fill="#D6DAE9"
      />
      <path
        d="M59.102 77.274v-7.032c-.139-.138-.278-.276-.278-.414v-.414c.416.69.833 1.241 1.527 1.655l5.55 3.861c1.249 1.103 3.192 1.103 4.44 0l5.135-4.688c.138-.138.416-.276.555-.552v7.309c0 .413 0 .69.138.965H58.963c.139 0 .139-.414.139-.69z"
        fill="url(#prefix__paint3_linear_4690_126054)"
      />
      <path
        d="M109.056 91.064v33.785c0 3.034-2.498 5.516-5.55 5.516H30.795c-3.053 0-5.55-2.482-5.55-5.516V91.064h83.811z"
        fill="#fff"
      />
      <path
        d="M77.973 104.027H56.326c-1.11 0-1.942-.828-1.942-1.931 0-1.103.832-1.93 1.942-1.93h21.647c1.11 0 1.943.827 1.943 1.93s-.833 1.931-1.943 1.931zM86.855 112.576h-39.27c-1.11 0-1.942-.827-1.942-1.93s.832-1.931 1.942-1.931h39.27c1.11 0 1.942.828 1.942 1.931 0 1.103-.971 1.93-1.942 1.93zM74.92 121.264H59.517a1.24 1.24 0 01-1.248-1.241v-1.379c0-.69.555-1.241 1.248-1.241H74.92a1.24 1.24 0 011.249 1.241v1.379a1.24 1.24 0 01-1.25 1.241z"
        fill="#E3EAF2"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_4690_126054"
          x1={72.7}
          y1={27.529}
          x2={72.7}
          y2={2.243}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.609} stopColor="#D0D7E8" />
          <stop offset={1} stopColor="#E4E8F1" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_4690_126054"
          x1={61.531}
          y1={27.529}
          x2={61.531}
          y2={2.243}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0D7E8" />
          <stop offset={1} stopColor="#E4E8F1" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_4690_126054"
          x1={37.918}
          y1={90.534}
          x2={95.553}
          y2={90.534}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E5F2" />
          <stop offset={1} stopColor="#D8DDEC" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_4690_126054"
          x1={67.523}
          y1={78.283}
          x2={67.523}
          y2={73.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAEEF9" />
          <stop offset={0.991} stopColor="#CED5E6" />
        </linearGradient>
        <filter
          id="prefix__filter0_d_4690_126054"
          x={3.244}
          y={1.876}
          width={127.812}
          height={161.489}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4690_126054"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4690_126054"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default NoCandidates
