import styled from 'styled-components/macro'

export const Container = styled.div`
  .ql-editor.ql-blank::before {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray7};
  }

  .ql-editor {
    line-height: 1.5em;
  }

  .quill {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
  }

  .ql-toolbar {
    border: 0;
  }

  .ql-container {
    height: 500px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    border: 0;
  }
`
