import React, { FC } from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-core'

import Button from 'antd/lib/button'

interface CustomClearFilterButtonProps {
  items: any
  refine: (value: unknown) => void
}

const CustomClearFilterButton: FC<CustomClearFilterButtonProps> = ({
  items,
  refine,
}) => {
  return (
    <Button type="default" onClick={() => refine(items)}>
      Clear filters
    </Button>
  )
}

const ClearFilterButton = connectCurrentRefinements(CustomClearFilterButton)

export default ClearFilterButton
