import styled from 'styled-components'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Tabs from 'antd/lib/tabs'

interface IRowItemContainer {
  isFixed: boolean
}

export const Container = styled.div`
  max-width: 1260px;
  padding: 10px;
  height: 100%;
  margin: 0 auto;

  & .ant-tabs-tab {
    color: ${({ theme }) => theme.colors.gray9};
  }
`

export const BackButton = styled(Button)`
  padding-left: 0;
`

export const TabWrapper = styled.div`
  position: relative;
`

export const NotesDot = styled.div`
  position: absolute;
  top: 0;
  right: -10px;
  background: ${({ theme }) => theme.colors.orange6};
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
`

export const TabsItem = styled(Tabs)`
  & .ant-tabs-nav-list {
    border-bottom: 1px solid #f0f0f0;
  }
`

export const RowItem = styled(Row)`
  margin: 0 auto;
  max-width: 1260px;
  z-index: 100;
  background: #fff;
  padding: 10px;
`

export const RowItemContainer = styled.div`
  position: ${({ isFixed }: IRowItemContainer) =>
    isFixed ? 'fixed' : 'static'};
  margin-top: ${({ isFixed }) => (isFixed ? '0' : '80px')};
  background: #fff;
  z-index: 100;
  width: 100%;
`
export const ColItem = styled(Col)`
  display: flex;
  justify-content: flex-end;
`
