import React, { FC } from 'react'

import SettingsIcon from 'assets/icons/SettingsIcon'

import Button from 'antd/lib/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { CloseOutlined } from '@ant-design/icons'

import { ToggleButton } from './styles'

interface IFilterButton {
  isShowFilter: boolean
  handleShowFilter: () => void
  handleCloseFilter: () => void
}

const FilterButton: FC<IFilterButton> = ({
  isShowFilter,
  handleShowFilter,
  handleCloseFilter,
}) => {
  const screens = useBreakpoint()
  if (isShowFilter) {
    return (
      <Button
        icon={screens.sm && <CloseOutlined />}
        onClick={handleCloseFilter}
      >
        Close filters
      </Button>
    )
  }

  return (
    <ToggleButton icon={<SettingsIcon />} onClick={handleShowFilter}>
      Filter jobs
    </ToggleButton>
  )
}

export default FilterButton
