import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { inviteCompanyMemberPromiseCreator } from 'store/hiringTeam'

import Plus from 'assets/icons/Plus'
import useActionsRoutines from 'hooks/useActionsRoutines'

import { InviteButton } from './styles'

import { UsersRole } from 'types'

const InviteCompanyMember = ({
  objectID,
  role,
}: {
  objectID: string
  role: UsersRole
}) => {
  const { jobId } = useParams<{ jobId: string }>()
  const [isInviteLoading, setInviteLoading] = useState(false)
  const inviteCompanyMember = useActionsRoutines(
    inviteCompanyMemberPromiseCreator,
  )

  const handleInviteCompanyMember = async () => {
    try {
      setInviteLoading(true)
      await inviteCompanyMember({ id: jobId, userId: objectID })
    } catch (error) {
      console.log(error)
    } finally {
      setInviteLoading(false)
    }
  }

  if (role === UsersRole.JHSuperAdmin) {
    return null
  }

  return (
    <InviteButton
      type="text"
      icon={<Plus size={18} />}
      onClick={() => handleInviteCompanyMember()}
      loading={isInviteLoading}
    >
      Invite to Job
    </InviteButton>
  )
}

export default InviteCompanyMember
