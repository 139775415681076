export enum StageType {
  Sourced = 'Sourced',
  Applied = 'Applied',
  PhoneScreen = 'PhoneScreen',
  Assessment = 'Assessment',
  Interview = 'Interview',
  Offer = 'Offer',
  Hired = 'Hired',
}

export interface IStage {
  id: number
  name: string
  type: StageType
  order: number
  isEditable: boolean
  draggableId: string
  hasCandidates: boolean
}

export interface ApiCreateStage {
  name: string
  type: StageType
}

export interface ApiDeleteStage {
  id: number
  stageId: number
}

export interface IStagesState {
  fetchStages: { isLoading: boolean; stages: IStage[] }
  createStage: { isLoading: boolean }
  deleteStage: { isLoading: boolean }
  updateStage: { isLoading: boolean }
  reorderStage: { isLoading: boolean }
}
