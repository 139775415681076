import styled from 'styled-components/macro'

import { Button } from 'antd'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  .ant-btn {
    font-weight: 600;
    padding-right: 0;
    padding-left: 0;
  }

  .ant-dropdown-menu-item {
    padding: 10px;
  }
`

export const ActionButton = styled(Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  svg {
    margin-right: 5px;
  }
  color: ${props => props.color};

  &:hover {
    color: ${props => props.color};
  }
  .ant-btn {
    color: #8c8c8c;
    &:hover {
      background: unset;
    }
  }
`

export const Date = styled.span`
  white-space: nowrap;
`
