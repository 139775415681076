import groupBy from 'lodash/groupBy'
import qs from 'qs'

export const createURL = (state: string) => `?${qs.stringify(state)}`

export const searchStateToUrl = (searchState: any, location: any) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : ''

export const urlToSearchState = ({ search }: { search: string }) =>
  qs.parse(search.slice(1))

export const groupHitsByCompany = (hits: any) => {
  const groupedByCompany = groupBy(hits, 'company')
  return Object.keys(groupedByCompany).map(key => ({
    companyTitle: key,
    companyUsers: groupedByCompany[key],
  }))
}
