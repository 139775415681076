import styled from 'styled-components/macro'

import Space from 'antd/lib/space'

export const RadioList = styled(Space)`
  margin-bottom: 15px;

  .ant-radio-wrapper {
    font-weight: normal;
  }
`
