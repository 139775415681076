import styled from 'styled-components'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'

export const Container = styled.div`
  margin: 8px 0 0 35px;
`

export const AddButton = styled(Button)`
  width: 100%;

  margin: 0;
  @media (min-width: 400px) {
    margin: 8px 0 8px 35px;
    max-width: 330px;
  }
`

export const ColItem = styled(Col)`
  width: 100%;
  max-width: 330px;
`
