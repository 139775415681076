import * as Yup from 'yup'

export const inviteMemberSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required('Provide email address in order to send an invite.')
    .email('Provide email address in order to send an invite.'),
  role: Yup.string().required('Please select user role below.'),
  adverts: Yup.lazy(val =>
    Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string(),
  ),
  companyId: Yup.number().nullable(),
})

export const inviteMemberAdminSchema = inviteMemberSchema.shape({
  companyId: Yup.mixed().required('Please select company below.'),
})

export default inviteMemberSchema
