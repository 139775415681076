import React from 'react'

import Tooltip from 'antd/lib/tooltip'

import { AutoRejectedStatusTag, StatusTag } from './styles'

import { CandidateStatus } from 'types'

interface CandidateStatusTagProps {
  status: CandidateStatus
}

const CandidateStatusTag = ({ status }: CandidateStatusTagProps) => {
  if (status === CandidateStatus.New) {
    return <StatusTag color="blue">{status}</StatusTag>
  }
  if (status === CandidateStatus.AutoRejected) {
    return (
      <Tooltip
        placement="bottomRight"
        title={'Candidate does not match any of your keywords'}
      >
        <AutoRejectedStatusTag>AUTO REJECTED</AutoRejectedStatusTag>
      </Tooltip>
    )
  }
  return null
}

export default CandidateStatusTag
