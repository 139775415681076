import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { getViewerSelector } from 'store/viewer'

import { JobStatusTag, JobStatusTagGray } from './styles'

import { JobStatus, JobStatusFilter, TagComponent } from 'types'

interface JobTagProps {
  status: JobStatus | JobStatusFilter
  tagComponent: TagComponent
  jobCreatorId: string
}

const JobTag: FC<JobTagProps> = ({ status, tagComponent, jobCreatorId }) => {
  const viewer = useSelector(getViewerSelector)

  const isViewerJobCreator = jobCreatorId === viewer.id

  switch (status) {
    case JobStatus.Live:
      return <JobStatusTag color="cyan"> Live </JobStatusTag>
    case JobStatus.Archived:
      return <JobStatusTag color="gold"> Archived </JobStatusTag>
    case JobStatus.Draft:
      return <JobStatusTagGray>Draft </JobStatusTagGray>
    case JobStatusFilter.Pending:
      return <JobStatusTag>Pending approval </JobStatusTag>
    case JobStatus.NoCredits:
      return <JobStatusTag color="red">Pending no Credits</JobStatusTag>
    case JobStatus.PendingAdminApproval:
      return <JobStatusTag> Pending Jobheron approval </JobStatusTag>
    case JobStatus.PendingUserApproval:
      if (tagComponent === TagComponent.JobDetails && isViewerJobCreator)
        return <JobStatusTag color="gold"> Pending your approval </JobStatusTag>

      if (tagComponent === TagComponent.JobItem && isViewerJobCreator) {
        return (
          <JobStatusTag color="gold"> Waiting your approval...</JobStatusTag>
        )
      }

      if (tagComponent === TagComponent.JobDetails && !isViewerJobCreator) {
        return <JobStatusTag> Pending user’s approval</JobStatusTag>
      }
      if (tagComponent === TagComponent.JobItem && !isViewerJobCreator) {
        return <JobStatusTag> Pending user’s approval ...</JobStatusTag>
      }
      return null
    default:
      return null
  }
}

export default JobTag
