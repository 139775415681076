import { createReducer } from '@reduxjs/toolkit'

import {
  deleteMember,
  inviteCompanyMember,
  inviteMember,
  startHiringTeamRefreshAlgoliaCache,
  stopHiringTeamRefreshAlgoliaCache,
} from './actions'

import { IHiringTeamState } from 'types'

export const HIRING_TEAM_STATE: IHiringTeamState = {
  fetch: { isLoading: false },
  inviteMember: { isLoading: false },
  inviteCompanyMember: { isLoading: false },
  deleteMember: { isLoading: false },
  job: null,
  refresh: false,
}

const hiringTeamReducer = createReducer(HIRING_TEAM_STATE, {
  [deleteMember.TRIGGER]: state => {
    state.deleteMember.isLoading = true
  },
  [deleteMember.FAILURE]: state => {
    state.deleteMember.isLoading = false
  },
  [deleteMember.SUCCESS]: state => {
    state.deleteMember.isLoading = false
  },

  [inviteMember.TRIGGER]: state => {
    state.inviteMember.isLoading = true
  },
  [inviteMember.FAILURE]: state => {
    state.inviteMember.isLoading = false
  },
  [inviteMember.SUCCESS]: state => {
    state.inviteMember.isLoading = false
  },

  [inviteCompanyMember.TRIGGER]: state => {
    state.inviteCompanyMember.isLoading = true
  },
  [inviteCompanyMember.FAILURE]: state => {
    state.inviteCompanyMember.isLoading = false
  },
  [inviteCompanyMember.SUCCESS]: state => {
    state.inviteCompanyMember.isLoading = false
  },

  [String(startHiringTeamRefreshAlgoliaCache)]: state => {
    state.refresh = true
  },
  [String(stopHiringTeamRefreshAlgoliaCache)]: state => {
    state.refresh = false
  },
})

export default hiringTeamReducer
