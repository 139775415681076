import { takeLatest } from 'redux-saga/effects'

import {
  createStage,
  deleteStage,
  fetchStages,
  reorderStage,
  updateStage,
} from './actions'
import {
  ensureCreateStage,
  ensureDeleteStage,
  ensureFetchStages,
  ensureReorderStage,
  ensureUpdateStage,
} from './workers'

export function* watchFetchStages() {
  yield takeLatest(fetchStages.TRIGGER, ensureFetchStages)
}

export function* watchCreateStage() {
  yield takeLatest(createStage.TRIGGER, ensureCreateStage)
}

export function* watchUpdateStage() {
  yield takeLatest(updateStage.TRIGGER, ensureUpdateStage)
}

export function* watchReorderStage() {
  yield takeLatest(reorderStage.TRIGGER, ensureReorderStage)
}

export function* watchDeleteStage() {
  yield takeLatest(deleteStage.TRIGGER, ensureDeleteStage)
}
