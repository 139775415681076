import styled from 'styled-components'

import Menu from 'antd/lib/menu'

export const MenuContainer = styled(Menu)`
  overflow: hidden;
  width: 245px;

  .ant-dropdown-menu-item {
    padding: 14px 12px;
    .anticon {
      font-size: 14px;
    }
  }
`

export const MenuItem = styled(Menu.Item)`
  min-height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  svg + span {
    margin-left: 8px;
  }
  .ant-btn {
    font-weight: 400;
    padding: 0;
    text-align: left;
    width: 100%;
  }
`

export const MobileMenu = styled.div`
  order: 1;

  @media (min-width: 768px) {
    order: inherit;
  }
`
