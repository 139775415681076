import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import HiringTeamTableActions from 'app/components/Jobs/JobHiringTeam/HiringTeamTableActions'
import HiringTeamTableFullName from 'app/components/Jobs/JobHiringTeam/HiringTeamTableFullName'
import HiringTeamTableStatus from 'app/components/Jobs/JobHiringTeam/HiringTeamTableStatus'
import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'

import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Row from 'antd/lib/row'
import Spin from 'antd/lib/spin'
import Table from 'antd/lib/table'

import { Container, RoleContainer, StatusContainer, UserItem } from './styles'

import { IHiringTeamMember, IJob, RootState } from 'types'

interface IHiringTeamTable {
  hits: any
  columns: any
  job: IJob
}

const HiringTeamTable: FC<IHiringTeamTable> = ({ columns, hits, job }) => {
  const screens = useBreakpoint()
  const hiringTeam = useSelector((state: RootState) => state.hiringTeam)

  if (!hits.length) return null

  return (
    <>
      {!screens.lg ? (
        <Spin size="small" spinning={hiringTeam.deleteMember.isLoading}>
          <Container>
            {hits.map((user: IHiringTeamMember) => {
              const { invitationStatus, role, email } = user

              return (
                <UserItem>
                  <Row justify="space-between">
                    <Col xs={12} md={{ span: 6, order: 1 }}>
                      <HiringTeamTableFullName {...user} job={job} />
                    </Col>
                    <Col xs={12} md={{ span: 6, order: 2 }}>
                      <StatusContainer>
                        <HiringTeamTableStatus
                          invitationStatus={invitationStatus}
                        />
                      </StatusContainer>
                    </Col>
                    <Col xs={12} md={{ span: 6, order: 3 }}>
                      <RoleContainer>
                        <AccessLevel role={role} />
                      </RoleContainer>
                    </Col>
                    <Col md={{ span: 6, order: 4 }}>{email}</Col>
                    <Col xs={24} md={{ span: 24, order: 6 }}>
                      <HiringTeamTableActions {...user} job={job} />
                    </Col>
                  </Row>
                </UserItem>
              )
            })}
          </Container>
        </Spin>
      ) : (
        <Table
          loading={hiringTeam.deleteMember.isLoading}
          columns={columns}
          pagination={false}
          dataSource={hits}
        />
      )}
    </>
  )
}

export default HiringTeamTable
