import { all, fork } from 'redux-saga/effects'

import * as W from './watchers'

export function* watchUsersActions() {
  yield all([
    fork(W.watchCreateUser),
    fork(W.watchFetchUser),
    fork(W.watchUpdateUser),
    fork(W.watchApproveUser),
    fork(W.watchChangeUserRole),
    fork(W.watchFetchUsersCounter),
    fork(W.watchFetchUsersFromAlgolia),
    fork(W.watchFetchUsersFromAlgolia),
    fork(W.watchFetchCompanyUsers),
    fork(W.watchUpdateUserCompany),
    fork(W.watchMoveUserToAnotherCompany),
    fork(W.watchDeleteUser),
  ])
}
