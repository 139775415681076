import styled from 'styled-components'

import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'

export const RowHeader = styled(Row)`
  margin-bottom: 52px;
`

export const ColItem = styled(Col)`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`

export const Warning = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 16px;
  margin-bottom: 32px;
  background: #fff2e8;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.orange6};
`
export const RadioGroup = styled(Radio.Group)`
  margin-bottom: 8px;
`

export const GroupContainer = styled.div`
  margin-bottom: 18px;
`
