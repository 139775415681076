import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchStagesPromiseCreator } from 'store/stages'
import { getViewerSelector } from 'store/viewer'

import { renderStageIcon } from 'helpers/stagesHelpers'

import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import Loader from 'app/components/UI/Loader/Loader'
import Slider from 'app/components/UI/Slider/Slider'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import { Body, StageIcon, StageItem } from './styles'

import { RootState } from 'types'

const JobsStages = () => {
  const viewer = useSelector(getViewerSelector)
  const fetchStages = useActionsRoutines(fetchStagesPromiseCreator)

  const { isLoading, stages } = useSelector(
    (state: RootState) => state.stages.fetchStages,
  )

  useEffect(() => {
    fetchStages({ companyId: viewer.companyId })
  }, [])

  if (isLoading) return <Loader />

  return (
    <div>
      <Row>
        <Col flex="1 1 200px">
          <BackButton title="Job stages" />
        </Col>
        <Col flex="0 1 100px">
          <Button type="primary">
            <Link to={ROUTES.SETTING_JOBS_STAGES_EDIT}>Edit stages</Link>
          </Button>
        </Col>
      </Row>

      <Body>
        <p>
          You can add stages, move their order and rename them to suit your
          hiring needs.
        </p>

        <Slider>
          {stages.map(({ id, name, type }) => (
            <div key={id}>
              <StageItem>
                <h3>{name}</h3>
                <StageIcon>{renderStageIcon(type)}</StageIcon>
              </StageItem>
            </div>
          ))}
        </Slider>
      </Body>
    </div>
  )
}

export default JobsStages
