import React from 'react'

import CompanyActions from './CompanyActions'
import AccessLevel from 'app/components/ResponsiveTables/UserTable/AccessLevel/AccessLevel'
import Actions from 'app/components/ResponsiveTables/UserTable/Actions/Actions'
import Status from 'app/components/ResponsiveTables/UserTable/Status/Status'

import { IAlgoliaUser, UserInvites, UserStatus } from 'types'

export const companyTableColumns = [
  {
    title: 'Company name',
    dataIndex: 'company',
    width: 135,
    minWidth: 135,
    render: (_: unknown, { company }: { company: string }) => (
      <strong>{company}</strong>
    ),
    ellipsis: true,
  },
  {
    title: 'Full name',
    dataIndex: 'fullName',
    render: () => null,
    width: 180,
    minWidth: 180,
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: () => <Status status={UserStatus.NotVerified} />,
    width: 110,
    minWidth: 110,
    ellipsis: true,
  },
  {
    title: 'Access Level',
    dataIndex: 'role',
    render: () => null,
    width: 160,
    minWidth: 160,
    ellipsis: true,
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    render: () => null,
    width: 150,
    minWidth: 150,
    ellipsis: true,
  },
  {
    title: 'Created',
    dataIndex: 'actions',
    width: 170,
    minWidth: 170,
    render: (_: any, user: IAlgoliaUser) => <CompanyActions {...user} />,
  },
]

export const companyUserColumns = [
  {
    title: 'Company name',
    dataIndex: 'company',
    width: 150,
    minWidth: 150,
    ellipsis: true,
  },
  {
    title: 'Full name',
    dataIndex: 'fullName',
    width: 180,
    minWidth: 180,
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'state',
    render: (_: any, user: IAlgoliaUser) => <Status status={user.status} />,
    width: 110,
    minWidth: 110,
    ellipsis: true,
  },
  {
    title: 'Access Level',
    dataIndex: 'role',
    width: 160,
    minWidth: 160,
    render: (_: unknown, user: IAlgoliaUser) => (
      <AccessLevel role={user.role} />
    ),
    ellipsis: true,
  },
  {
    title: 'Email address',
    dataIndex: 'email',
    width: 150,
    minWidth: 150,
    ellipsis: true,
  },
  {
    title: 'Created',
    dataIndex: 'actions',
    width: 170,
    minWidth: 170,
    render: (_: any, user: IAlgoliaUser) => (
      <Actions user={user} inviteType={UserInvites.Company} />
    ),
  },
]
