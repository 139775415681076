import React from 'react'

import Actions from './Actions/Actions'
import { SCROLL_TO_ROW } from './CandidatesTable'
import FullName from './FullName/FullName'
import Status from './Status/Status'

import { Tooltip } from 'antd'

import {
  CandidateSource,
  CandidateStage,
  IAlgoliaCandidate,
  ICandidateSimplified,
} from 'types'

export const candidatesColumns = (
  setRows: (value: string[]) => void,
  rowRef: any,
) => [
  {
    title: 'Full name',
    dataIndex: 'fullName',
    ellipsis: true,
    width: 350,
    minWidth: 350,
    render: (_: unknown, candidate: IAlgoliaCandidate) => {
      if (candidate.key === sessionStorage.getItem(SCROLL_TO_ROW)) {
        return (
          <div ref={rowRef}>
            <FullName {...candidate} />
          </div>
        )
      }
      return <FullName {...candidate} />
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    ellipsis: true,
    render: (_: unknown, candidate: IAlgoliaCandidate) => (
      <Status {...candidate} />
    ),
  },
  {
    title: 'Stage',
    dataIndex: 'stage',
    ellipsis: true,
    render: (_: unknown, candidate: ICandidateSimplified) => {
      if (
        candidate.stage === CandidateStage.Sourced &&
        candidate.source === CandidateSource.JobheronDb
      ) {
        return (
          <Tooltip placement="top" title="JH CV search">
            {candidate.stageName}
          </Tooltip>
        )
      }
      return candidate.stageName
    },
  },
  {
    title: 'Added',
    dataIndex: 'actions',
    width: 130,
    minWidth: 130,
    render: (_: unknown, candidate: IAlgoliaCandidate) => (
      <Actions candidate={candidate} setRows={setRows} />
    ),
  },
]
