import React, { FC } from 'react'

interface ILogo {
  color: string
  width?: number
  height?: number
}

const Logo: FC<ILogo> = ({ color, width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.92764 8.67389C7.92764 6.76534 9.46812 5.21596 11.3684 5.21596C13.2687 5.21596 14.8101 6.76534 14.8101 8.67389C14.8101 10.5816 13.2687 12.1309 11.3684 12.1309C9.46812 12.1309 7.92764 10.5816 7.92764 8.67389ZM18.784 19.1366C18.7788 17.6726 18.4365 16.2086 17.7503 14.8767L17.7274 14.8362C17.7257 14.8336 17.6887 14.7631 17.6474 14.6795L32 8.67477L18.3776 3.62696C16.8116 1.43406 14.2585 0 11.3676 0C11.2549 0 11.1458 0.0132054 11.0341 0.017607L11.0526 0.00704213H0C1.04693 1.63565 2.4924 2.96495 4.17101 3.8849L4.17628 3.88226C3.26748 5.25557 2.73697 6.90267 2.73697 8.67477C2.73697 13.4532 6.58686 17.3319 11.3429 17.3451L11.3394 17.3531C11.3491 17.3531 11.3579 17.3522 11.3676 17.3522L11.3394 19.9597C7.4508 19.9597 4.02056 17.9895 1.99445 14.9911C0.823468 16.4226 0.144283 18.1894 0.0791797 20.053L0.0739006 20.37V32C0.242817 32 0.413493 31.9886 0.585049 31.9727C7.62676 31.831 13.018 28.8255 16.0506 25.7919L16.2389 25.6053C17.9377 23.8041 18.7823 21.4889 18.784 19.1762L18.7884 19.1938C18.7884 19.1745 18.784 19.1569 18.784 19.1366Z"
      fill={color}
    />
  </svg>
)

export default Logo
