import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from 'antd/lib/button'

import { ArrowLeftOutlined } from '@ant-design/icons'

import { Container, Title } from './styles'

import { RootState } from 'types'

interface IBackButton {
  title: string
  type?: string
  onBack?: () => void
}

const BackButton: FC<IBackButton> = ({ title, type, onBack }) => {
  const history = useHistory()
  const { lastActivePage } = useSelector((state: RootState) => state.app)

  const handleOnGoBack = () => {
    // @ts-ignore
    const currentLastPage = lastActivePage[type]
    currentLastPage ? history.push(currentLastPage) : history.goBack()
  }

  return (
    <Container>
      <Button
        type="default"
        onClick={onBack ? onBack : handleOnGoBack}
        icon={<ArrowLeftOutlined />}
      />
      <Title>{title}</Title>
    </Container>
  )
}

export default BackButton
