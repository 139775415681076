import { all, fork } from 'redux-saga/effects'

import * as W from './watchers'

export function* watchHiringTeamActions() {
  yield all([
    fork(W.watchInviteCompanyMember),
    fork(W.watchDeleteMember),
    fork(W.watchInviteMember),
    fork(W.watchGetHiringTeamCounters),
  ])
}
