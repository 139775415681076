import styled from 'styled-components/macro'

import Button from 'antd/lib/button'

export const Container = styled.div`
  .ant-tabs-nav {
    margin-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const CreateJobContainer = styled.div`
  display: flex;
  margin-bottom: 100px;

  .ant-select-selection-item {
    font-weight: 600;
  }
`

export const Main = styled.div`
  width: 100%;

  @media (min-width: 992px) {
    flex: 1;
    margin-right: 32px;
    width: calc(100% - 352px);
  }
`

export const JobActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;

  .ant-btn {
    font-weight: 600;
    width: 100%;
  }

  @media (min-width: 768px) {
    position: static;

    .ant-btn {
      min-width: inherit;
    }
  }
`
export const SubmitButtonBottom = styled(Button)`
  display: none;
  margin-top: 70px;
  margin-left: auto;
  @media (min-width: 768px) {
    display: block;
  }
`
