import React, { FC } from 'react'

import StageItem from './StageItem'

import { IStage } from 'types'

interface IMemoStagesList {
  stages: IStage[]
  handleUpdateStage: (stage: IStage) => void
  handleOpenDeleteStageModal: (stage: IStage) => void
}

const MemoStagesList: FC<IMemoStagesList> = ({
  stages,
  handleUpdateStage,
  handleOpenDeleteStageModal,
}) => {
  return (
    <>
      {stages.map((stage: IStage, index: number) => (
        <StageItem
          stage={stage}
          index={index}
          key={stage.id}
          handleUpdateStage={handleUpdateStage}
          handleOpenDeleteStageModal={handleOpenDeleteStageModal}
        />
      ))}
    </>
  )
}

export default MemoStagesList
