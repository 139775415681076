import styled from 'styled-components/macro'

import Tag from 'antd/lib/tag'

export const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7% 0;
`

export const InfoBlock = styled.div`
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px 24px;
  h2 {
    color: ${({ theme }) => theme.colors.gray7};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    width: 150px;
    min-width: 150px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.gray9};
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
  }

  @media (min-width: 992px) {
    width: 445px;
  }
`

export const Container = styled.div`
  margin: 30px 0 110px;

  .ql-editor {
    padding: 0;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: flex-start;
  }
`

export const Main = styled.div`
  width: 100%;

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 30px 0;
  }
  @media (min-width: 992px) {
    flex: 1;
    margin-right: 32px;
    width: calc(100% - 477px);
  }
`

export const InfoBlockItem = styled.div`
  margin: 30px 0;
  h2 {
    margin-top: 2px;
  }

  @media (min-width: 768px) {
    margin: 15px 0;
    display: flex;
  }
`

export const JobActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;

  .ant-btn {
    font-weight: 600;
  }

  @media (min-width: 768px) {
    position: static;
    display: flex;
  }
`

export const JobActionsItem = styled.div`
  display: flex;

  .ant-btn {
    margin: 5px;
    width: 100%;
  }

  .ant-btn-primary {
    min-width: 204px;
  }

  @media (min-width: 768px) {
    .ant-btn-primary {
      min-width: inherit;
    }
  }
`

export const Keyword = styled(Tag)`
  &.ant-tag {
    display: inline-block;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: 600;
    max-width: 230px;
    white-space: nowrap;
  }
`

export const BoardsBlockItem = styled(InfoBlockItem)`
  margin: 12px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  justify-content: space-between;
  display: flex;
  h2 {
    color: ${({ theme }) => theme.colors.gray9};
  }
`
export const BoardItem = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray9};
  svg {
    margin-right: 9px;
    color: #08979c;
  }
`

export const ExpiredBoardItem = styled(BoardItem)`
  color: ${({ theme }) => theme.colors.gray7};
  text-decoration: line-through;
  svg {
    color: #fa541c;
  }
`
