import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { isAdminOrCompanyAdminSelector, isAdminSelector } from 'store/viewer'

import AccessLevel from './AccessLevel/AccessLevel'
import Actions from './Actions/Actions'
import Company from './Company/Company'
import FullName from './FullName/FullName'
import Status from './Status/Status'

import Col from 'antd/lib/col'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Row from 'antd/lib/row'
import Table from 'antd/lib/table'

import {
  Container,
  RoleContainer,
  StatusContainer,
  TableContainer,
  UserItem,
} from './styles'

import { IAlgoliaUser, RootState, UserInvites, UserStatus } from 'types'

interface IResponsiveTable {
  hits: IAlgoliaUser[]
  columns: any
}

const UserTable: FC<IResponsiveTable> = ({ hits, columns }) => {
  const screens = useBreakpoint()
  const isAdmin = useSelector(isAdminSelector)
  const isAdminOrCompanyAdmin = useSelector(isAdminOrCompanyAdminSelector)
  const users = useSelector((state: RootState) => state.users)

  return (
    <>
      {!screens.lg ? (
        <Container>
          {hits.map((user: IAlgoliaUser) => {
            const {
              objectID,
              fullName,
              status,
              role,
              email,
              company,
              companyStatus,
            } = user

            return (
              <UserItem key={objectID} status={status}>
                <Row justify="space-between">
                  <Col xs={12} md={{ span: 4, order: 1 }}>
                    <FullName {...user} />
                  </Col>
                  <Col xs={12} md={{ span: 6, order: 2 }}>
                    <StatusContainer>
                      <Status status={status} />
                    </StatusContainer>
                  </Col>
                  {isAdmin && (
                    <Company
                      id={objectID}
                      company={company}
                      companyStatus={companyStatus}
                    />
                  )}
                  <Col xs={12} md={{ span: 10, order: 3 }}>
                    <RoleContainer>
                      <AccessLevel
                        role={role}
                        status={status}
                        fullName={fullName}
                        userId={objectID}
                      />
                    </RoleContainer>
                  </Col>
                  {screens.md && <Col md={{ span: 8, order: 4 }}>{email}</Col>}
                  {isAdminOrCompanyAdmin && (
                    <Col xs={24} md={{ span: 24, order: 6 }}>
                      <Actions user={user} inviteType={UserInvites.Company} />
                    </Col>
                  )}
                </Row>
              </UserItem>
            )
          })}
        </Container>
      ) : (
        <TableContainer>
          <Table
            loading={users.approveUser.isLoading}
            columns={columns}
            dataSource={hits}
            pagination={false}
            rowClassName={(record: IAlgoliaUser) => {
              return record.status === UserStatus.NotVerified
                ? 'yellow-background'
                : ''
            }}
          />
        </TableContainer>
      )}
    </>
  )
}

export default UserTable
